
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

import { AppSettings } from '../../settings.global';
import { ActivatedRoute, Router } from '@angular/router';
declare var $: any;
import * as _ from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ClientService {

	public headers: HttpHeaders;

	constructor(private httpClient: HttpClient, private localSt: LocalStorageService) {
		this.headers = new HttpHeaders({
			'Content-Type': 'application/json;charset=UTF-8',
			'Accept': 'application/json',
			'x-access-token': this.localSt.retrieve("access_token")
		});
	}
	//Create or update client
	createUpdateClient(body: any) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		if (body.id) {
			return this.httpClient.put(AppSettings.API_ENDPOINT + '/client/profile', body, options).pipe(
				map(function (res: any) {
					return res.body || {
						status: "failed",
						message: "Something went wrong"
					};
				}));
		} else {
			return this.httpClient.post(AppSettings.API_ENDPOINT + '/client/profile', body, options).pipe(
				map(function (res: any) {
					return res.body || {
						status: "failed",
						message: "Something went wrong"
					};
				}));
		}

	}
	//Function to get client list
	getClients(body: any) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		let bodyString = $.param(body);
		return this.httpClient.get(AppSettings.API_ENDPOINT + '/client/profile?' + bodyString, options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));
	}
	sortClientList(chestData, orderBy, order) {
		return _.orderBy(chestData, [orderBy], [order]);
	}
	//Get client details
	getClientDetails(id: number) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.get(AppSettings.API_ENDPOINT + '/client/profile/' + id, options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));
	}
	//Activate client
	inActivate(data: any) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.post(AppSettings.API_ENDPOINT + '/client/profile/inactive', data, options).pipe(
			map(function (res: any) {
				return res.body || { status: "failed", message: "Somthing went wrong" };
			}));
	}
	//Inactivate client
	activate(data: any) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.post(AppSettings.API_ENDPOINT + '/client/profile/active', data, options).pipe(
			map(function (res: any) {
				return res.body || { status: "failed", message: "Somthing went wrong" };
			}));
	}
	//get clients for selectinput
	getClientSelect() {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.get(AppSettings.API_ENDPOINT + '/client/client_select?user_id=' + this.localSt.retrieve("user_details").id, options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));
	}
	//Function to get notifications
	getNotification() {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.get(AppSettings.API_ENDPOINT + '/client/notifications', options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));
	}
	updateReadStatus(data: any) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.post(AppSettings.API_ENDPOINT + '/client/notifications', data, options).pipe(
			map(function (res: any) {
				return res.body || { status: "failed", message: "Somthing went wrong" };
			}));
	}

}
