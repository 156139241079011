import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user/user.service';
import {ClientService} from '../../services/client/client.service';
import { BrandService } from '../../services/brand/brand.service';
import {NgxPaginationModule} from 'ngx-pagination';//importing ng2-pagination
import {Routes, RouterModule, ActivatedRoute, Router, Params} from '@angular/router';
import {LoaderService} from '../../services/loader/loader.service';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
declare var $:any;
import {Client} from '../../interfaces/client/client';
import {AuthService} from '../../services/auth/auth.service';
import {AppSettings} from '../../settings.global';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  public users:any;
  public user:any;
  public clients:any;
  public client:any;
  public brands:any;
  public submited:boolean;
  public title:string;
  public user_id:any;
  public edit_flag:boolean;
  public client_d:any;
 
  constructor(private userService:UserService,
    private clientService:ClientService,
    private brandService:BrandService,
    private localSt:LocalStorageService,
    private loaderService:LoaderService,
    private toastr: ToastrService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private authServiceObj:AuthService) {
      this.title="Add User";
      this.user={
        id:null,
        client:'',
        brand:[],
        fname:'',
        lname:'',
        mname:'',
        email:'',
        mobile_no:'',
        edit:false
      }
      this.submited=false;
      this.users=[];
    
   }

   ngAfterContentInit() {

    $("body").height("100%");
    $("html").height("100%");

    this.getClient();
    //this.client=this.activatedRoute.snapshot.queryParams['client_id'];
    
    this.activatedRoute.params.subscribe((params: Params) => {
    this.user_id = params['id'];
    console.log(this.user_id,"this is paramsid")
      if (this.user_id) { 
               // Updation
               this.title="Edit User";
              // this.edit_flag=true;
  
               // Getting the Brand data  
               this.userService.getUserDetails(this.user_id).subscribe((res: any) => {
                
                 if (res.status == "success") {
                   this.user={
                     id: this.user_id,
                     client:res.data_found.client_id,
                     brand:res.data_found.brand_id,
                     fname:res.data_found.first_name,
                     mname:res.data_found.middle_name,
                     lname:res.data_found.last_name,
                     email:res.data_found.email,
                     mobile_no:res.data_found.mobile,
                     edit:true
                   }
                   this.client =res.data_found.client_id              
                   this.getBrand(this.client)
                 } else {
                   if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
                     this.authServiceObj.logout();
                   }
                   else{
                     this.toastr.error(res.message,'Failed');
                   }
                 }
               });
           }else{
            this.client=this.activatedRoute.snapshot.queryParams['client_id'];
            console.log("clent",this.client);
            this.user ={
              id:null,
              client:parseInt(this.client),
              brand:[],
              fname:'',
              lname:'',
              mname:'',
              email:'',
              mobile_no:'',
              edit:false
            }
            this.getBrand(this.client);
           } 
  
       });
  }

   //get the client 
  getClient(){
    this.clientService.getClientSelect().subscribe((res:any)=>{
      if(res.status=='success'){
      this.clients=res.data_found;
      }
      else{
        if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
              this.toastr.error("Kindly login","Session Expired");
              this.authServiceObj.logout();
        }
      }
    })
  }

  getBrand(newValue) {
    this.brands = [];
		this.brandService.getBrandSelect(newValue).subscribe((res:any)=>{
		  if(res.status=='success'){
        this.brands=res.data_found;
        console.log(this.client);
		  }
		  else{
			if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
			    this.toastr.error("Kindly login","Session Expired");
				  this.authServiceObj.logout();
			}
		  }
		})
		
  }
  
  onSubmit(valid){
    console.log("this is user",this.users);
    
    this.submited=true;
    if(valid){
      this.loaderService.display(true);
      this.userService.createUpdateUser(this.user).subscribe((res: any) => {
        this.loaderService.display(false);
        if(res.status=="success"){
          this.toastr.success('Updated Successfully!','SUCCESS');
          this.route.navigate(['user/'], { queryParams: { client_id: this.client } });
        }
        else {
          if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
            this.authServiceObj.logout();
          }
          else{
            this.toastr.error(res.message,'Error');
          }
        }
  
      },(e)=>{
  
      });
    }
  
  }

  cancel(){
    this.route.navigate(['user/'], { queryParams: { client_id: this.client } });
  }

  ngOnInit() {
  }

}
