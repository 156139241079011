
import {of as observableOf, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {AppSettings} from '../../../settings.global';
import {ActivatedRoute, Router} from '@angular/router';
declare var $:any;
import * as _ from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class CurrencyService {

	public headers: HttpHeaders;

  constructor(private httpClient: HttpClient, private localSt: LocalStorageService) {
	this.headers = new HttpHeaders({
		'Content-Type': 'application/json;charset=UTF-8',
		'Accept': 'application/json',
		'x-access-token': this.localSt.retrieve("access_token")
	})
   }

   createUpdateCurrency(body:any){
	let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
	if(body.id){
		return this.httpClient.put(AppSettings.API_ENDPOINT +'/masterdata/currency',body, options).pipe(
		map(function (res: any) {
			return res.body || {
				status: "failed",
				message: "Something went wrong"
			};
		}));
	}else{
		return this.httpClient.post(AppSettings.API_ENDPOINT +'/masterdata/currency',body, options).pipe(
		map(function (res: any) {
			return res.body || {
				status: "failed",
				message: "Something went wrong"
			};
		}));
	}
  }

  getCurrency(body:any){
	let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
	let bodyString = $.param(body);
	return this.httpClient.get(AppSettings.API_ENDPOINT +'/masterdata/currency?'+bodyString, options).pipe(
	map(function (res: any) {
		return res.body || {
			status: "failed",
			message: "Something went wrong"
		};
	}),catchError((error:any) => {
		//return Observable.throw(error.json().error || 'Server error occured')
		return observableOf({status:"error",message:"Server error occured"})
	}),);
  }

  sortCurrencyList(currencyData, orderBy, order) {
	return _.orderBy(currencyData, [orderBy], [order]);
 }

  delete(id:any){
	let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.delete(AppSettings.API_ENDPOINT +'/masterdata/currency?id='+id,options).pipe(
		map(function(res:any){
			return res.body || {status: "failed", message: "Somthing went wrong"};
		}));
	}

	public importData(body: any, file: any): Promise<any> {
		return new Promise((resolve, reject) => {
			let token = this.localSt.retrieve("access_token")
			let formData: FormData = new FormData(),
				xhr: XMLHttpRequest = new XMLHttpRequest();
			if (file) {
				formData.append('file_xlsx', file, file.name);
			}

			for (let index in body) {
				formData.append(index, body[index]);
			}
			xhr.onreadystatechange = () => {

				if (xhr.readyState === 4) {

					if (xhr.status === 200) {
						resolve(JSON.parse(xhr.response));
					} else {
						reject(xhr.response);
					}
				}
			};
			xhr.open('POST', AppSettings.API_ENDPOINT + '/masterdata/currency-rate', true);
			xhr.setRequestHeader('x-access-token', token);
			xhr.send(formData);

		})
	}

}
