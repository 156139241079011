
import {of as observableOf, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {AppSettings} from '../../settings.global';
import {ActivatedRoute, Router} from '@angular/router';
declare var $:any;
import * as _ from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class UserService {
	public headers: HttpHeaders;

	constructor(private httpClient: HttpClient, private localSt: LocalStorageService) { 
		this.headers = new HttpHeaders({
			'Content-Type': 'application/json;charset=UTF-8',
			'Accept': 'application/json',
			'x-access-token': this.localSt.retrieve("access_token")
		});

  }
  
  //Create or update user
	createUpdateUser(body:any){
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		if(body.id){
			return this.httpClient.put(AppSettings.API_ENDPOINT+'/user/profile',body,options).pipe(
			map(function(res:any){
				return res.body || {
					status:"failed",
					message:"Something went wrong"
				};
			}));
		}else{
			return this.httpClient.post(AppSettings.API_ENDPOINT+'/user/profile',body,options).pipe(
			map(function(res:any){
				return res.body || {
					status:"failed",
					message:"Something went wrong"
				};
			}));
		}
		
	}

	//get users
	getUsers(body:any){
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		let bodyString = $.param(body);
		return this.httpClient.get(AppSettings.API_ENDPOINT +'/user?'+ bodyString, options).pipe(

		map(function (res: any) {
			console.log(res,"this mihal")
			return res.body || {
				status: "failed",
				message: "Something went wrong"
			};
		}),catchError((error:any) => {
			//return Observable.throw(error.json().error || 'Server error occured')
			return observableOf({status:"error",message:"Server error occured"})
		}),);
	}

	sortUserList(chestData, orderBy, order) {
		return _.orderBy(chestData, [orderBy], [order]);
	}

	//get user details to edit
	getUserDetails(id:number){
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.get(AppSettings.API_ENDPOINT +'/user/edit/'+id, options).pipe(
		map(function (res: any) {
			console.log(res,"this is kitiyye data");
			
			return res.body || {
				status: "failed",
				message: "Something went wrong"
			};
		}));
	}

	//inactivate user
	inActivate(data:any){
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.post(AppSettings.API_ENDPOINT +'/user/inactive',data,options).pipe(
		map(function(res:any){
			return res.body || {status: "failed", message: "Somthing went wrong"};
		}));
	}

	//Activate user
	activate(data:any){
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.post(AppSettings.API_ENDPOINT +'/user/active',data,options).pipe(
		map(function(res:any){
			return res.body || {status: "failed", message: "Somthing went wrong"};
		}));
	}
}
