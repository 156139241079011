import { Component, OnInit } from '@angular/core';


declare var $: any;
import * as _ from 'lodash';
import { AuthService } from '../../../services/auth/auth.service';
import { GeneralService } from '../../../services/settings/general/general.service'
import { Observable } from 'rxjs/';
import * as moment from 'moment';
import { LoaderService } from '../../../services/loader/loader.service';
import { LocalStorageService } from 'ngx-webstorage';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IAngularMyDpOptions } from 'angular-mydatepicker';



@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css'],
  styles: [
    `
       :host >>> my-date-picker >>> .mydp .selection  {
          color: #55595c !important;
          font-family: 'Montserrat', sans-serif !important;
          margin-bottom: 4px !important;
          margin-left: 5px !important;
          width: 97% !important;
        }
        :host >>> my-date-picker >>> .mydp .disabled  { 
          background: #e7e7e7 !important;
        }
        :host >>> my-date-picker >>> .mydp, .mydp .headertodaybtn {
          border: 1px solid #CCC;
          margin-top: 5%;
      }
       :host >>> my-date-picker >>> .btnpicker {
          color: #747b87;
        }
      
    `
  ]

})
export class GeneralComponent implements OnInit {
  date: Date = new Date();
  options: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mm/yyyy',
    sunHighlight: false,
    divHostElement: { enabled: true, placeholder: 'Choose a date' }
  };
  public dates: any;
  public showDate: boolean;
  public deadline: any;
  public title: any;
  public submited: boolean;
  public days: any;
  private placeholder: string = 'Choose  Date';
  public timePeriod: { id: number; name: string; }[];
  disableStatusDay: boolean;
  dateDisable: boolean;
  placeholders: string
  apiCallCount: any = 0;
  id: any;




  constructor(private toastr: ToastrService,
    private authServiceObj: AuthService,
    private genralService: GeneralService,
    private loaderService: LoaderService,
    private localStorage: LocalStorageService,
    private route: Router,
    private activatedRoute: ActivatedRoute,) {
    this.timePeriod = [{ id: 1, name: "1 Day" }, { id: 2, name: "2 Days" }, { id: 3, name: "3 Days" }, { id: 4, name: "4 Days" }, { id: 5, name: "5 Days" }, { id: 6, name: "10 Days" }, { id: 7, name: "15 Days" }, { id: 8, name: "1 Month" }]
    this.deadline = {
      days: '',
      dates: ''
    }

    this.title = "GENERAL SETTINGS"
    this.placeholders = "Choose Time Period";
    this.submited = false;
    this.showDate = true;
  }

  onDateChanged(event) {
    console.log("clicked", event);
    this.options = {
      dateFormat: 'dd/mm/yyyy',
      sunHighlight: false,
    };
    console.log();

    this.deadline.dates = event.singleDate.formatted;

    if (this.deadline.dates == "") {
      this.disableStatusDay = false
    } else {
      this.disableStatusDay = true
    }
  }

  daychanged() {
    if (this.deadline.days != "") {
      this.showDate = false;
    } else {
      this.showDate = true;
    }
  }


  getAllSetting() {
    this.genralService.getAllSetting().subscribe((res: any) => {
      this.apiCallCount++;
      if (res.status == 'success') {
        if (res.data_found[0].id) {
          this.id = res.data_found[0].id
          console.log(res.data_found[0], "id");

          let deadline = res.data_found[0].case_deadline
          let deadlineCheck = deadline.includes("Month") || deadline.includes('Days') || deadline.includes('Day')
          console.log('deadlineCheck', deadlineCheck);

          if (deadlineCheck) {
            console.log('hereee');

            this.deadline = {
              days: res.data_found[0].case_deadline,
              dates: ''
            }
            this.showDate = false;
            console.log('options', this.options);

          } else {

            var dateString = res.data_found[0].case_deadline
            var dateArray = dateString.split('/');
            var convertedDate = new Date(dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0]);
            var month = convertedDate.getUTCMonth() + 1; //months from 1-12
            var day = convertedDate.getUTCDate() + 1;
            var year = convertedDate.getUTCFullYear();
            console.log(typeof (res.data_found[0].case_deadline), convertedDate, month, day, year, 'years');


            this.deadline = {
              dates: { singleDate: { date: { year: year, month: month, day: day } } },
              days: ''
            }

            // this.deadline = {
            //   dates:'',
            //   days:''
            // }
            this.disableStatusDay = true
          }
        }
      } else {
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          this.toastr.error("Kindly login", "Session Expired");
          this.authServiceObj.logout();
        } else {
          this.toastr.error(res.message, 'Failed');
        }
      }
    })
  }

  onSubmit(valid) {
    this.submited = true;
    var user = this.localStorage.retrieve('user_details');
    if (valid) {
      let data = {
        id: this.id,
        Timeperiod: '',
        user_id: user.id
      }
      if (this.deadline.dates) {
        data.Timeperiod = this.deadline.dates.formatted
      } else {
        data.Timeperiod = this.deadline.days
      }
      console.log(data, 'data');
      this.loaderService.display(true);
      this.genralService.addEditGeneralSettings(data).subscribe((res) => {
        this.loaderService.display(false);
        if (res.status == "success") {
          this.deadline = {
            dates: '',
            days: ''
          }
          this.toastr.success('General Settings Updated Successfully!', 'SUCCESS');
          this.route.navigate(['general/']);
          this.getAllSetting()
        }
      })
    }

  }

  reset() {
    this.placeholders = "Choose Time Period"

    this.deadline = {
      days: '',
      dates: ""
    }
    this.disableStatusDay = false
    this.dateDisable = false
    // this.options={
    //   divHostElement: {enabled: true, placeholder: 'Choose a date'}
    // }
  }

  ngOnInit() {
    this.getAllSetting()
  }

  closeClicked() {
    this.disableStatusDay = false;
  }

}
