
import {fromEvent as observableFromEvent} from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { MotherCompanyService } from '../../../services/master-data/mother-company/mother-company.service';
import * as $ from 'jquery';
import * as _ from 'lodash';
import {Observable} from 'rxjs/';
import {LoaderService} from '../../../services/loader/loader.service';
import {NgxPaginationModule} from 'ngx-pagination';//importing ng2-pagination
import {AuthService} from '../../../services/auth/auth.service';
import {AppSettings} from '../../../settings.global';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import {debounceTime} from 'rxjs/operators';


@Component({
  selector: 'app-mother-company',
  templateUrl: './mother-company.component.html',
  styleUrls: ['./mother-company.component.css']
})
export class MotherCompanyComponent implements OnInit {
  public request_params:any;
  public pager:any;
  public queryLimit: number = AppSettings.DEFAULT_QUERY_LIMIT;
	public itemPerPageCount: number = AppSettings.DEFAULT_PAGER_COUNT;
	public apiCallCount: number = 0;
	public offSet: number = 0;
  public mcompany:any;
  public mcompanies:any;
  public title:any;
  public saveButton:any;
  public cancelButton:any;
  public submitted:boolean;
  public no_data:any;
  public searchInputObservable$: any;
  public type:any;
  public user_details:any;

  public isMCommerce:boolean = false;
  public isECommerce:boolean = false;
  constructor(private motherCompanyService:MotherCompanyService, private loaderService:LoaderService,
    private toastr: ToastrService, private authServiceObj:AuthService,public localSt:LocalStorageService) { 
      this.title='Parent Company';
      this.saveButton=' Add ';
      this.cancelButton= 'Clear';
      this.submitted=false;    
      this.no_data="Loading....";
      this.type ='all';
      this.request_params = {
        searchkey: "",
        orderby: "",
        order: "",
        limit: this.queryLimit,
        offset: 0
      }
      this.pager = {
        limit: this.itemPerPageCount,
        offset: 0,
        total_count: 0,
        current_page: 1
      }
      this.mcompany={
        id:'',
        type:'',
        name:'',
        description:''
      };
      this.mcompanies=[];
      this.user_details = this.localSt.retrieve("user_details");

  }

  ngAfterContentInit() {

		//Input search observable - to avoid the  onInputChange() firing whenever the input changes
		const searchInputObj = document.querySelector('#search_text');
		this.searchInputObservable$ = observableFromEvent(searchInputObj, 'keyup');

    this.searchInputObservable$.pipe(debounceTime(100))
    .subscribe((event: any) => {
      this.onInputChange(event.target.value);
    });

		$("body").height("100%");
    $("html").height("100%");
		
		 /*
			 * API call and grab full data
			 */
			setTimeout(() => {
				this.getMcompany(1);
			},10);
  }
  
  //get the mothercompany 
	getMcompany(page){
	
			// this.mcompanies = [];
			// this.apiCallCount = 0;
			// this.offSet = 0;
		
    this.request_params['offset'] = this.offSet;
    // if(this.type == 'all'){
    //   if(this.isMCommerce && this.isECommerce)
    //   this.request_params['type'] = 'all';
    //   else if(this.isMCommerce)
    //   this.request_params['type'] = 'm_commerce';
    //   else if(this.isECommerce)
    //   this.request_params['type'] = 'e_commerce';
    //   else
    //   this.request_params['type'] = 'all';
    // }
    // else
		this.request_params['type'] = 'all';
		this.loaderService.display(true); 
		this.motherCompanyService.getMcompany(this.request_params).subscribe((res:any)=>{
			this.loaderService.display(false);
			this.apiCallCount++;
			if(res.status=='success' && !_.isNull(res.company_result)){
				this.pager["total_count"] = this.mcompanies.length + res.company_result.length;
				this.pager["current_page"] = page;
				const result = this.mcompanies.concat(res.company_result);
				this.mcompanies = res.company_result;
				//Load full result
				if(!_.isNil(res.max_possible_res_length) && res.max_possible_res_length > this.mcompanies.length) {
					this.offSet = this.apiCallCount * this.queryLimit;
					//this.getBrands(this.client,1);
					 this.getMcompany(1);
				}    
			}else {
				if(this.mcompanies.length === 0 || res.max_possible_res_length === 0) {
					this.mcompanies = [];
					this.no_data = 'No Data';
					this.pager["total_count"] = 0;
					this.pager["current_page"] = page;
				}
				if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
				this.toastr.error("Kindly login","Session Expired");
				this.authServiceObj.logout();
				}
			}
		})
  }

  onChange(newValue) {

    if(newValue == 'all'){
      this.isECommerce = false;
      this.isMCommerce = false;
    }

    if(newValue == 'm_commerce' || newValue == 'e_commerce')
    this.type = 'all';
    else
    this.type = newValue;
    this.mcompany={
      id:'',
      type:'',
      name:'',
      description:''
    };
    this.title='Parent Company';
    this.saveButton=' Add ';
    this.cancelButton= 'Clear';
		this.mcompanies = [];
		this.apiCallCount = 0;
    this.offSet = 0; 
    this.pager["offset"] =0;
    this.getMcompany(1);
  
	}
  
  onSubmit(valid){
    this.submitted=true;

    

    // this.mcompany['type']=this.type;
    if(this.type == 'all'){

    /* ecomm and mcomm not configured in parent company anymore */
    this.isMCommerce=true;
    this.isECommerce=true;

      if(this.isMCommerce && this.isECommerce)
      this.mcompany['type'] = 'all';
      else if(this.isMCommerce){
        if(this.mcompany.id){
          this.mcompany['type'] = 'e_commerce';
          this.mcompany['status'] = 'active';
        }
        else
        this.mcompany['type'] = 'm_commerce';
      }
      
      else if(this.isECommerce){
        if(this.mcompany.id){
          this.mcompany['type'] = 'm_commerce';
          this.mcompany['status'] = 'active';
        }
        else
        this.mcompany['type'] = 'e_commerce';
      }
      else{
      return false;
      // this.mcompany['type'] = 'all';
      }
    }
    else{
      this.mcompany['type']=this.type;
      this.mcompany['status'] = 'active';
    }
    
    if(valid){
      this.loaderService.display(true);
      this.motherCompanyService.createUpdateMcompany(this.mcompany).subscribe((res:any)=>{
        this.loaderService.display(false);
        if(res.message=="success"){
          if(this.saveButton==' Add ') {
            this.toastr.success('Added Successfully!','SUCCESS');
          } else {
            this.toastr.success('Updated Successfully!','SUCCESS');
          }   
          this.mcompanies = [];
          this.apiCallCount = 0;
          this.offSet = 0;
          this.getMcompany(1);
          this.title='Parent Company';
          this.saveButton=' Add ';
          this.cancelButton= 'Clear';
          this.submitted=false;
          this.mcompany={
                id:'',
                type:'',
                name:'',
                description:''
              };       
        this.isECommerce = false;
        this.isMCommerce = false; 
        }else if(res.message == "Mother Company already exists"){
          this.toastr.error('Parent Company already exists','Request Failed');
        }else {
          if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
            this.toastr.error("Kindly login","Session Expired");
            this.authServiceObj.logout();
          }else{
            this.toastr.error(res.message,'Failed');
          }
        }
        },(e)=>{

            });
    }
    else{
      console.log("Not valid")
    }
  }

  onInputChange(text) {
    this.onSearch();
  }

  onSearch() {
		this.mcompanies = [];
		this.apiCallCount = 0;
    this.offSet = 0;
    this.pager["offset"] =0;    
    this.getMcompany(1);
   }

   onPageChange(page: number) {
    this.pager["current_page"] = page;
    this.pager["offset"] = this.itemPerPageCount * (page - 1);
    }

    //Get Mother company for edit
  editMcompany(mcompany:any){
    // Reset the flags
    this.isMCommerce = false;
    this.isECommerce = false;

    this.title='Edit Parent Company';
    this.saveButton=' Save ';
    this.cancelButton= 'Cancel';
    this.mcompany={
      //id:mcompany.id, 
      id : mcompany.group_id,
      name:mcompany.name,
      description:mcompany.description,
      type : mcompany.type
    };
    //Tick the checkboxes according to it's type
    if(mcompany.type == 'all' || (mcompany.status == 'active' && mcompany.status_ecomm == 'active')){
      this.isECommerce = true;
      this.isMCommerce = true;
    }
    // else if(mcompany.type == 'm_commerce')
    else if(mcompany.status == 'active')
    this.isMCommerce = true;
    // else if(mcompany.type == 'e_commerce')
    else if(mcompany.status_ecomm == 'active')  
    this.isECommerce = true;
  }

  //delete selected mothercompany
  deleteMcompany(id:any){
    var cnf=confirm("Are you sure you want to delete this Parent Company?")
    if(cnf){
      this.motherCompanyService.delete(id).subscribe((res:any)=>{
        if(res.status=='success'){
          this.toastr.success('Parent Company deleted','SUCCESS');
          this.mcompany={
            id:'',
            type:'',
            name:'',
            description:''
          };
          this.mcompanies = [];
          this.apiCallCount = 0;
          this.offSet = 0;
        
          this.getMcompany(1);
          }else {
            if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
              this.toastr.error("Kindly login","Session Expired");
              this.authServiceObj.logout();             
            }else{
              this.toastr.error(res.message,'Failed');
            }
          }
      })
    }
  }

  onSort(orderby: string, id: any) {
		if (this.request_params["orderby"] === orderby) {
			if (this.request_params["order"] === "asc") {
				this.request_params["order"] = "desc";
			} else {
				this.request_params["order"] = "asc";
			}
		} else {
			this.request_params["orderby"] = orderby;
			this.request_params["order"] = "desc"
		}
		//Reset the page to 1, offset to 0
		this.pager["current_page"] = 1;
		this.pager["offset"] = 0;
		this.mcompanies = this.motherCompanyService.sortMcompanyList(this.mcompanies, this.request_params["orderby"], this.request_params["order"]);
  }
  
  //To cancel editing and show add form
  cancel(){
    this.title='Keyword';
    this.saveButton=' Add ';
    this.cancelButton= 'Clear';
    this.mcompany={
      id:'',
      type:'',
      name:'',
      description:''
    };

    this.isMCommerce = false;
    this.isECommerce = false;
  }

  ngOnInit() {
  }

}
