
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
declare var $: any;
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { AppSettings } from '../../settings.global';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class SellersService {
  public headers: HttpHeaders;

  constructor(private httpClient: HttpClient, private localSt: LocalStorageService) {
    this.headers = new HttpHeaders({
			'Content-Type': 'application/json;charset=UTF-8',
			'Accept': 'application/json',
			'x-access-token': this.localSt.retrieve("access_token")
		});
   }

     //get product and sellers
  getSellerProducts(body: any) {
    let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
    let bodyString = $.param(body);
    return this.httpClient.get(AppSettings.API_ENDPOINT_V2 + '/sellers?' + bodyString, options).pipe(
      map(function (res: any) {
        return res.body || {
          status: "failed",
          message: "Something went wrong"
        };
      }))
  }


	sortSellerList(sellerData, orderBy, order) {
		return _.orderBy(sellerData, [orderBy], [order]);
	 }

} 
