import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoaderService {
	public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }
  display(value: boolean) {
  	setTimeout(() => {
  		this.status.next(value);
  	},5);
  }
}
