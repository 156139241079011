
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
declare var $: any;
import * as _ from 'lodash';
import { AppSettings } from '../../settings.global';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ProductService {

  public headers: HttpHeaders;

  constructor(private httpClient: HttpClient, private localSt: LocalStorageService) {
    this.headers = new HttpHeaders({
			'Content-Type': 'application/json;charset=UTF-8',
			'Accept': 'application/json',
			'x-access-token': this.localSt.retrieve("access_token")
		});
  }

  //get product and sellers
  getProductSeller(body: any) {
    let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
    let bodyString = $.param(body);
    return this.httpClient.get(AppSettings.API_ENDPOINT_V2 + '/products?' + bodyString, options).pipe(
      map(function (res: any) {
        return res.body || {
          status: "failed",
          message: "Something went wrong"
        };
      }),catchError((error: any) => {
        return observableOf({ status: "error", message: "Server error occured" })
      }),);
  }

  // Status change of a seller
  statusChange(data:any){
  	let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
  	return this.httpClient.post(AppSettings.API_ENDPOINT_V2 +'/products/status',data,options).pipe(
  	map(function(res:any){
  		return res.body || {status: "failed", message: "Something went wrong"};
  	}));
  }

  // sort product table based on values
  sortProductList(productData, orderBy, order) {
    console.log('popoi9', productData);
    
  	return _.orderBy(productData, ['email'], [order]);
   }

   getSellerFromOtherMplace(body: any) {
    let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
    let bodyString = $.param(body);
    return this.httpClient.get(AppSettings.API_ENDPOINT_V2 + 'sellers?' + bodyString, options).pipe(
      map(function (res: any) {
        return res.body || {
          status: "failed",
          message: "Something went wrong"
        };
      }),catchError((error: any) => {
        return observableOf({ status: "error", message: "Server error occured" })
      }),);
   }

  //Generate screenshot
  postScreenshotRequest(body: any) {
    let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
    return this.httpClient.post(AppSettings.API_ENDPOINT_V2 + '/products/screenshot', body, options).pipe(
      map(function (res: any) {
        return res.body || {
          status: "failed",
          message: "Something went wrong"
        };
      }),catchError((error: any) => {
        return observableOf({ status: "error", message: "Server error occured" })
      }),);
  }


  sortProductsArray(productData, orderBy, order) {
		return _.orderBy(productData, [orderBy], [order]);
	 }

}
