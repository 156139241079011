
import {fromEvent as observableFromEvent} from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin/admin.service';
//import * as $ from 'jquery';
import * as _ from 'lodash';
import { Observable } from 'rxjs/';
import { LoaderService } from '../../services/loader/loader.service';
import { ToastrService } from 'ngx-toastr';
import { Routes, RouterModule, ActivatedRoute, Router, Params } from '@angular/router';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { NgxPaginationModule } from 'ngx-pagination';//importing ng2-pagination
import { AuthService } from '../../services/auth/auth.service';
import { AppSettings } from '../../settings.global';
declare var $: any;
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-admin-list',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.css']
})
export class AdminListComponent implements OnInit {
  public request_params: any;
  public pager: any;
  public queryLimit: number = AppSettings.DEFAULT_QUERY_LIMIT;
  public itemPerPageCount: number = AppSettings.DEFAULT_PAGER_COUNT;
  public apiCallCount: number = 0;
  public offSet: number = 0;
  public admins: any;
  public no_data: any;
  public searchInputObservable$: any;
  public modal_title: any;
  public brands: any;
  public clients:any;

  constructor(private adminService: AdminService,
    private toastr: ToastrService,
    private route: Router,
    public localSt: LocalStorageService,
    private loaderService: LoaderService,
    private activatedRoute: ActivatedRoute,
    private authServiceObj: AuthService) {
    this.no_data = "Loading...."
    this.request_params = {
      searchkey: "",
      orderby: "",
      order: "",
      limit: this.queryLimit,
      offset: 0
    }
    this.pager = {
      limit: this.itemPerPageCount,
      offset: 0,
      total_count: 0,
      current_page: 1
    }
    this.clients=[]
    this.admins = []
  }
  ngAfterContentInit() {

    //Input search observable - to avoid the  onInputChange() firing whenever the input changes
    const searchInputObj = document.querySelector('#search_text');
    this.searchInputObservable$ = observableFromEvent(searchInputObj, 'keyup');

    this.searchInputObservable$.pipe(debounceTime(100))
      .subscribe((event: any) => {
        this.onInputChange(event.target.value);
      });
    $("body").height("100%");
    $("html").height("100%");
    this.getAdmins(1);

  }
  getAdmins(page) {
    if (this.offSet != 0 && this.request_params['searchkey'] != '') {
      this.admins = [];
      this.apiCallCount = 0;
      this.offSet = 0;
    }
    this.request_params['offset'] = this.offSet;
    this.loaderService.display(true);
    this.adminService.getAdmins(this.request_params).subscribe((res: any) => {
      this.loaderService.display(false);
      this.apiCallCount++;
      if (res.status == 'success' && !_.isNull(res.user_result)) {
        this.pager["total_count"] = this.admins.length + res.user_result.length;
        this.pager["current_page"] = page;
        const result = this.admins.concat(res.user_result);
        this.admins = res.user_result;
        //Load full result
        if (!_.isNil(res.max_possible_res_length) && res.max_possible_res_length > this.admins.length) {
          this.offSet = this.apiCallCount * this.queryLimit;
          //this.getBrands(this.client,1);
          // this.getCountry(1);
        }
      } else {
        if (this.admins.length === 0 || res.max_possible_res_length === 0) {
          this.admins = [];
          this.no_data = 'No Data';
          this.pager["total_count"] = 0;
          this.pager["current_page"] = page;
        }
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          this.toastr.error("Kindly login", "Session Expired");
          this.authServiceObj.logout();
        }
      }
    })

  }
  onInputChange(text) {
    this.onSearch();
  }

  addAdmin() {
    this.route.navigate(['admin/add']);
  }

  onChange(newValue) {
    this.admins = [];
    this.apiCallCount = 0;
    this.offSet = 0;
    
    this.getAdmins(1);
  }

  onPageChange(page: number) {
    this.pager["current_page"] = page;
    this.pager["offset"] = this.itemPerPageCount * (page - 1);
  }

  onSearch() {
    console.log(this.request_params)
    this.admins = [];
    this.apiCallCount = 0;
    this.offSet = 0;
    this.pager["offset"] = 0;
    this.getAdmins(1);
  }

  onSort(orderby: string, id: any) {
    if (this.request_params["orderby"] === orderby) {
      if (this.request_params["order"] === "asc") {
        this.request_params["order"] = "desc";
      } else {
        this.request_params["order"] = "asc";
      }
    } else {
      this.request_params["orderby"] = orderby;
      this.request_params["order"] = "desc"
    }
    //Reset the page to 1, offset to 0
    this.pager["current_page"] = 1;
    this.pager["offset"] = 0;
    this.admins = this.adminService.sortAdminList(this.admins, this.request_params["orderby"], this.request_params["order"]);
  }


  //inactivate user
  inActivate(id: any) {
    let data = { id: id };
    var cnf = confirm("Are you sure you want to deactivate this user?");
    if (cnf) {
      this.adminService.inActivate(data).subscribe((res: any) => {
        if (res.status == "success") {
          this.toastr.success('Inactivated', 'SUCCESS');
          this.admins = [];
          this.apiCallCount = 0;
          this.offSet = 0;
          this.getAdmins(1);

        }
        else {
          if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
            this.authServiceObj.logout();
          }
        }
      })
    }
  }

  //Activate user
  activate(id: any, type: any, sid: any) {
    var cnf = confirm("Are you sure you want to activate this user?");
    if (cnf) {
      let data = { id: id };
      this.adminService.activate(data).subscribe((res: any) => {
        if (res.status == "success") {
          this.toastr.success('Activated', 'SUCCESS');
          this.admins = [];
          this.apiCallCount = 0;
          this.offSet = 0;
          this.getAdmins(1);
          //this.getClientData(1);
        } else {
          if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
            this.authServiceObj.logout();
          }
          else {
            this.toastr.error(res.message, 'Failed');
          }
        }
      })
    }

  }
  getClients(first_name:any,last_name:any,clients:any){
    //alert("dd")
    this.brands=[];
    this.modal_title=first_name+' '+last_name;
    if(clients){
      this.clients=clients;
      $('#clientModal').modal('show');
    }
  }
    //delete selected Currency
deleteAdmin(id:any){
  var cnf=confirm("Are you sure you want to delete this Admin?")
  if(cnf){
    this.adminService.delete(id).subscribe((res:any)=>{
      if(res.status=='success'){
        this.toastr.success('Admin Deleted Successfully!','SUCCESS');
        this.apiCallCount = 0;
        this.offSet = 0;         
        this.getAdmins(1);
        }else {
          if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
            this.toastr.error("Kindly login","Session Expired");
            this.authServiceObj.logout();             
          }else{
            this.toastr.error(res.message,'Failed');
          }
        }
    })
  }
}
  ngOnInit() {
  }

}
