
import {fromEvent as observableFromEvent} from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/';
import { LoaderService } from '../../../services/loader/loader.service';
import { MarketplaceService } from '../../../services/settings/marketplace/marketplace.service';
import { AppSettings } from '../../../settings.global';
declare var $: any;
import * as _ from 'lodash';
import { AuthService } from '../../../services/auth/auth.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-marketplace-list',
  templateUrl: './marketplace-list.component.html',
  styleUrls: ['./marketplace-list.component.css']
})
export class MarketplaceListComponent implements OnInit {
  public searchInputObservable$: any;
  public request_params: any;
  public offSet: number = 0;
  public pager: any;
  public test: any
  public queryLimit: number = AppSettings.DEFAULT_QUERY_LIMIT;
  public apiCallCount: number = 0;
  public marketplaces: any;
  public marketplace: any;
  public marketplace_id: any;
  public no_data: any;
  public no_of_entry: any;
  public itemPerPageCount: number = AppSettings.DEFAULT_PAGER_COUNT;
  public selectAllFlag: boolean;
  public checkBox: any;
  constructor(private loaderService: LoaderService,
    private route: Router,
    private marketplaceService: MarketplaceService,
    private toastr: ToastrService,
    private authServiceObj: AuthService,
    private localStorage: LocalStorageService
  ) {
    this.request_params = {
      searchkey: "",
      orderby: "",
      order: "",
      limit: this.queryLimit,
      offset: 0
    };
    this.test = [];
    this.pager = {
      limit: this.itemPerPageCount,
      offset: 0,
      total_count: 0,
      current_page: 1
    };
    this.marketplace_id = [];
    this.no_of_entry = 10;
    this.selectAllFlag = false;
    this.checkBox = [];
    this.marketplaces = [];
  }
  ngOnInit(): void {
    // throw new Error('Method not implemented.');
  }
  ngAfterContentInit() {
    // Input search observable - to avoid the  onInputChange() firing whenever the input changes
    const searchInputObj = document.querySelector('#search_text');
    this.searchInputObservable$ = observableFromEvent(searchInputObj, 'keyup');
    // this.searchInputObservable$.debounceTime(100)
    //   .subscribe((event: any) => {
    //     this.onInputChange(event.target.value);
    //   });
    $("body").height("100%");
    $("html").height("100%");
    this.getMarketplace(1);
  }
  //function to get the list of marketplaces
  getMarketplace(page) {
    this.request_params['offset'] = this.offSet;
    this.loaderService.display(true);
    this.marketplaceService.getMarketPlaces(this.request_params).subscribe((res: any) => {
      this.loaderService.display(false);
      this.apiCallCount++;
      console.log('resresres', res);
      if (res.status == 'success') {
        this.marketplaces = res.market_result;
        if (this.marketplaces == null) {
          this.marketplaces = [];
          this.no_data = 'No Data';
        } else {
          this.pager["total_count"] = res.market_result.length;
          this.pager["current_page"] = page;
        }

        //     const result = this.admins.concat(res.user_result);
        //     this.admins = res.user_result;
        //     //Load full result
        // if (!_.isNil(res.max_possible_res_length) && res.max_possible_res_length > this.marketplaces.length) {
        //   this.offSet = this.apiCallCount * this.queryLimit;
        //   this.getMarketplace(1);
        // }
      } else {
        if (this.marketplaces.length === 0 || res.max_possible_res_length === 0 || this.marketplaces == null) {
          this.marketplaces = [];
          this.no_data = 'No Data';
          this.pager["total_count"] = 0;
          this.pager["current_page"] = page;
        }
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          this.toastr.error("Kindly login", "Session Expired");
          this.authServiceObj.logout();
        }
      }
    });
  }
  // function works when search button clicked
  onSearch() {
    this.apiCallCount = 0;
    this.offSet = 0;
    this.pager["offset"] = 0;
    this.getMarketplace(1);
  }
  // redirect to add admin page when click Add New button
  addMarketplace() {
    this.route.navigate(['marketplace/add']);
  }
  // Function works when check box checked
  statusChange(id, value) {
    this.marketplace_id.push(id);
  }
  // To activate marketplace status
  activateMarketplace(id, value) {
    console.log('this.marketplace_id', this.marketplace_id);
    var user = this.localStorage.retrieve('user_details');
    if (value == "single") {
      this.marketplace_id = [];
      this.marketplace_id.push(id);
    }
    var data = {
      marketplaces: this.marketplace_id,
      status: 'Active',
      user_id: user.id
    }
    if (this.marketplace_id.length > 0) {
      if (this.marketplace_id.length == 1) {
        var cnf = confirm("Are you sure you want to activate this marketplace?");
      }
      if (this.marketplace_id.length > 1) {
        var cnf = confirm("Are you sure you want to activate these marketplaces?");
      }
      if (cnf) {
        this.marketplaceService.activate(data).subscribe((res: any) => {
          if (res.status == "success") {
            this.toastr.success('Activated', 'SUCCESS');
            this.apiCallCount = 0;
            this.offSet = 0;
            this.checkBox = [];
            this.marketplace_id = [];
            this.selectAllFlag = false;
            this.getMarketplace(1);
          } else {
            if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
              this.authServiceObj.logout();
            }
            else {
              this.toastr.error(res.message, 'Failed');
            }
          }
        })
      }
    } else {
      this.toastr.info('Please select the marketplace to activate.');
      return false;
    }
  }
  // To deactivate marketplace status
  deActivateMarketplace(id, value) {
    var user = this.localStorage.retrieve('user_details');
    if (value == "single") {
      this.marketplace_id = [];
      this.marketplace_id.push(id);
    }
    var data = {
      marketplaces: this.marketplace_id,
      status: 'Inactive',
      user_id: user.id
    }
    if (this.marketplace_id.length > 0) {
      if (this.marketplace_id.length == 1) {
        var cnf = confirm("Are you sure you want to deactivate this marketplace?");
      }
      if (this.marketplace_id.length > 1) {
        var cnf = confirm("Are you sure you want to deactivate these marketplaces?");
      }
      if (cnf) {
        this.marketplaceService.inActivate(data).subscribe((res: any) => {
          if (res.status == "success") {
            this.toastr.success('Deactivated', 'SUCCESS');
            this.apiCallCount = 0;
            this.offSet = 0;
            this.checkBox = [];
            this.marketplace_id = [];
            this.selectAllFlag = false;
            this.getMarketplace(1);
          } else {
            if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
              this.authServiceObj.logout();
            }
            else {
              this.toastr.error(res.message, 'Failed');
            }
          }
        })
      }
    } else {
      this.toastr.info('Please select the marketplace to deactivate.');
      return false;
    }
  }
  entryNoChange(value: number) {
    this.apiCallCount = 0;
    this.offSet = 0;
    this.pager["limit"] = value;
    this.pager["total_count"] = 0;
    this.pager["current_page"] = 1;
    this.pager["offset"] = 0;
    this.getMarketplace(1);
    this.itemPerPageCount = value;
  }
  //To make all check box true or false
  selectAll() {
    this.marketplace_id = [];
    if (this.selectAllFlag == false) {
      
      for (let e of this.marketplaces) {
        console.log(e,'rj');
        this.marketplace_id.push(e.id)
        this.checkBox[e.id] = true;
      }
      this.selectAllFlag = true;
      // this.selectFalg = true;
    } else {
      for (let e of this.marketplaces) {
        this.checkBox[e.id] = false;
      }
      this.marketplace_id = [];
      this.selectAllFlag = false;
      // this.selectFalg = false;
    }
    console.log('marketplace_id', this.marketplace_id);
  }
  //To manage check box selection
  selection(id) {
    var index = this.marketplace_id.indexOf(id);
    if (index > -1) {
      this.marketplace_id.splice(index, 1)
    }
    // is newly selected
    else if (index < 0) {
      this.marketplace_id.push(id)
    }
    //To make select all check box true or false
    for (let e of this.marketplaces) {
      this.test.push(e.id)
    }
    if (this.test.length != this.marketplace_id.length) {
      this.selectAllFlag = false;
    } else {
      this.selectAllFlag = true;
    }
    console.log(this.marketplaces, "mT")

  }
  // to sort table column
  onSort(orderby: string, id: any) {
    console.log('orderby', orderby);
    console.log('this.request_params["orderby"]', this.request_params["orderby"]);
    
    
    if (this.request_params["orderby"] == orderby) {
      if (this.request_params["order"] == "asc") {
        this.request_params["order"] = "desc";
      } else {
        this.request_params["order"] = "asc";
      }
    } else {
      console.log('inside else');
      
      this.request_params["orderby"] = orderby;
      if (this.request_params["order"] == "asc") {
        this.request_params["order"] = "desc";
      } else {
        this.request_params["order"] = "asc";
      }
      console.log('this.request_params["orderby"]#####', this.request_params["orderby"]);
      console.log('this.request_params["orderby"]****', this.request_params["order"]);
    }
    //Reset the page to 1, offset to 0
    this.pager["current_page"] = 1;
    this.pager["offset"] = 0;
    this.marketplaces = this.marketplaceService.sortMarketplaceList(this.marketplaces, this.request_params["orderby"], this.request_params["order"]);
 console.log(this.marketplaces,'sort');
 
  }
  // function works when page number changes
  onPageChange(page: number) {
    this.pager["current_page"] = page;
    this.pager["offset"] = this.itemPerPageCount * (page - 1);
  }

  runScrap(id) {
    this.route.navigate(['marketplace/scrap'], { queryParams: { marketplace_id: id } });
  }

  //function works when enter pressed on search bar
  keyDownFunction(event) {
    console.log('here event');
    console.log('here event', event);
    if (event.keyCode === 13) {
      this.apiCallCount = 0;
      this.offSet = 0;
      this.pager["offset"] = 0;
      this.getMarketplace(1);
    }
  }
}
