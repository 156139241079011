
import {fromEvent as observableFromEvent} from 'rxjs';
import { Component, OnInit } from '@angular/core';
import {ProductCategoryService} from '../../../services/master-data/product-category/product-category.service'
import * as $ from 'jquery';
import * as _ from 'lodash';
import {Observable} from 'rxjs/';
import {LoaderService} from '../../../services/loader/loader.service';
import {NgxPaginationModule} from 'ngx-pagination';//importing ng2-pagination
import {AuthService} from '../../../services/auth/auth.service';
import {AppSettings} from '../../../settings.global';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-product-category',
  templateUrl: './product-category.component.html',
  styleUrls: ['./product-category.component.css']
})
export class ProductCategoryComponent implements OnInit {
  public request_params:any;
  public pager:any;
  public queryLimit: number = AppSettings.DEFAULT_QUERY_LIMIT;
	public itemPerPageCount: number = AppSettings.DEFAULT_PAGER_COUNT;
	public apiCallCount: number = 0;
	public offSet: number = 0;
  public productCategory:any;
  public productCategorys: any;
  public title:any;
  public saveButton:any;
  public cancelButton:any;
  public submitted:boolean;
  public no_data:any;
  public searchInputObservable$: any;
  public user_details: any;

  constructor(public productCategoryService:ProductCategoryService,public localSt:LocalStorageService,  private loaderService:LoaderService, private toastr: ToastrService, private authServiceObj:AuthService) { 
    this.title='Product Category';
    this.saveButton=' Add ';
    this.cancelButton= 'Clear';
    this.submitted=false;    
    this.no_data="Loading...."
    this.request_params = {
			searchkey: "",
			orderby: "",
			order: "",
			limit: this.queryLimit,
			offset: 0
		}
		this.pager = {
			limit: this.itemPerPageCount,
			offset: 0,
			total_count: 0,
			current_page: 1
		}
    this.productCategory={
      id:'',
      name:'',
      description:'',
      val:true
    };
   this.productCategorys =[]
    this.user_details = this.localSt.retrieve("user_details");
  }




  ngAfterContentInit() {
    const searchInputObj = document.querySelector('#search_text');
		this.searchInputObservable$ = observableFromEvent(searchInputObj, 'keyup');

		// this.searchInputObservable$.debounceTime(100)
		// .subscribe((event: any) => {
		// 	this.onInputChange(event.target.value);
		// });

    $("body").height("100%");
    $("html").height("100%");
    
    /*
         * API call and grab full data
         */
		setTimeout(() => {
		
		},10);
  }


  onSubmit(valid){
    this.submitted=true;
    console.log('hel',this.productCategory)
    if(valid){
      this.loaderService.display(true);
      this.productCategoryService.createUpdateProductCategory(this.productCategory).subscribe((res:any)=>{
        this.loaderService.display(false);
        if(res.message=="success"){
          if(this.saveButton==' Add ') {
            this.toastr.success('Added Successfully!','SUCCESS');
          } else {
            this.toastr.success('Updated Successfully!','SUCCESS');
          }   
          this.productCategorys = [];
          this.apiCallCount = 0;
          this.offSet = 0;
          // this.getProductCategory(1);
          this.title='Status';
          this.saveButton=' Add ';
          this.cancelButton= 'Clear';
          this.submitted=false;
          // this.productCategory={
          //       id:'',
          //       name:'',
          //       description:'',
          //       val:true
          // };
        }else if(res.message == "Product Category already exists"){
          this.toastr.error('Product Category already exists','Request Failed');
        }else {
          if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
            this.toastr.error("Kindly login","Session Expired");
            this.authServiceObj.logout();
          }else{
            this.toastr.error(res.message,'Failed');
          }
        }
      },(e)=>{

          });
    }
  }

  onSearch() {
    this.productCategorys = [];
    this.apiCallCount = 0;
    this.offSet = 0;  
    this.pager["offset"] =0;  
    // this.getProductCategory(1);
  }


  onPageChange(page: number) {
    this.pager["current_page"] = page;
    this.pager["offset"] = this.itemPerPageCount * (page - 1);
    }



    // getProductCategory(page: number){
    //   this.request_params['offset'] = this.offSet;
    //   this.loaderService.display(true);  
    //   this.productCategoryService.getProductCategory(this.request_params).subscribe((res:any)=>{
    //     this.loaderService.display(false);
    //     this.apiCallCount++;
    //     if(res.status=='success' && !_.isNull(res.status_result)){
    //       this.pager["total_count"] = this.productCategorys.length + res.status_result.length;
    //       this.pager["current_page"] = page;
    //       const result = this.productCategorys.concat(res.status_result);
    //       this.productCategorys = res.category_result;
    //       //Load full result
    //       if(!_.isNil(res.max_possible_res_length) && res.max_possible_res_length > this.productCategorys.length) {
    //         this.offSet = this.apiCallCount * this.queryLimit;
  
    //         this.getProductCategory(1);
    //       }    
    //     }else {
    //       if(this.productCategorys.length === 0) {
    //         this.productCategorys = [];
    //         this.no_data = 'No Data';
    //         this.pager["total_count"] = 0;
    //         this.pager["current_page"] = page;
    //       }
    //       if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
    //         this.toastr.error("Kindly login","Session Expired");
    //         this.authServiceObj.logout();
    //       }
    //     }
    //   })
    // }


    deleteStatus(id:any){
      var cnf=confirm("Are you sure you want to delete this Status?")
      if(cnf){
        this.productCategoryService.delete(id).subscribe((res:any)=>{
          if(res.status=='success'){
            this.toastr.success('Status deleted successfully!','SUCCESS');
            this.productCategory={
              id:'',
              name:'',
              description:'',
              val:true
            };
            this.productCategorys = [];
            this.apiCallCount = 0;
            this.offSet = 0;        
            // this.getProductCategory(1);
            }else {
              if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
                this.toastr.error("Kindly login","Session Expired");
                this.authServiceObj.logout();             
              }else{
                this.toastr.error(res.message,'Failed');
              }
            }
        })
      }
    }


    onSort(orderby: string, id: any) {
      if (this.request_params["orderby"] === orderby) {
        if (this.request_params["order"] === "asc") {
          this.request_params["order"] = "desc";
        } else {
          this.request_params["order"] = "asc";
        }
      } else {
        this.request_params["orderby"] = orderby;
        this.request_params["order"] = "desc"
      }
      //Reset the page to 1, offset to 0
      this.pager["current_page"] = 1;
      this.pager["offset"] = 0;
      this.productCategorys = this.productCategoryService.sortCategoryList(this.productCategorys, this.request_params["orderby"], this.request_params["order"]);
    }


    editStatus(editCategory:any){
      this.title='Edit Product Category';
      this.saveButton=' Save ';
      this.cancelButton= 'Cancel';
      this.productCategory={
        id:editCategory.id,
        name:editCategory.name,
        description:editCategory.description,
        val:editCategory.value
      };
    }


      //To cancel editing and show add form
  // cancel(){
  //   this.title='Status';
  //   this.saveButton=' Add ';
  //   this.cancelButton= 'Clear';
  //   this.productCategory={
  //     id:'',
  //     name:'',
  //     description:'',
  //     val: true
  //   };
  // }

  ngOnInit() {
  }

}
