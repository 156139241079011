
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';

import {Observable} from 'rxjs';
import {AppSettings} from '../../../settings.global';
import {ActivatedRoute, Router} from '@angular/router';
declare var $:any;
import * as _ from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class SearchEngineService {

	public headers: HttpHeaders;

  constructor(private httpClient: HttpClient, private localSt: LocalStorageService) {
	this.headers = new HttpHeaders({
		'Content-Type': 'application/json;charset=UTF-8',
		'Accept': 'application/json',
		'x-access-token': this.localSt.retrieve("access_token")
	});
   }

   createUpdateSearch(body:any){
	let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
	if(body.id){
		return this.httpClient.put(AppSettings.API_ENDPOINT +'/masterdata/searchengine',body, options).pipe(
		map(function (res: any) {
			return res.body || {
				status: "failed",
				message: "Something went wrong"
			};
		}));
	}else{
		return this.httpClient.post(AppSettings.API_ENDPOINT +'/masterdata/searchengine',body, options).pipe(
		map(function (res: any) {
			return res.body || {
				status: "failed",
				message: "Something went wrong"
			};
		}));
	}
  }

  getSearchEngine(body:any){
	let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
	let bodyString = $.param(body);
	return this.httpClient.get(AppSettings.API_ENDPOINT +'/masterdata/searchengine?'+bodyString, options).pipe(
	map(function (res: any) {
		return res.body || {
			status: "failed",
			message: "Something went wrong"
		};
	}));
  }

  sortSearchList(searchData, orderBy, order) {
	return _.orderBy(searchData, [orderBy], [order]);
 }

  delete(id:any){
	let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
	return this.httpClient.delete(AppSettings.API_ENDPOINT +'/masterdata/searchengine?id='+id,options).pipe(
	map(function(res:any){
		return res.body || {status: "failed", message: "Somthing went wrong"};
	}));
  }

}
