
import { fromEvent as observableFromEvent } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ClientService } from '../../services/client/client.service';
import { BrandService } from '../../services/brand/brand.service';
//import * as $ from 'jquery';
import * as _ from 'lodash';
declare var $: any;
import { Observable } from 'rxjs/';
import { LoaderService } from '../../services/loader/loader.service';
import { Routes, RouterModule, ActivatedRoute, Router, Params } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';//importing ng2-pagination
import { AuthService } from '../../services/auth/auth.service';
import { AppSettings } from '../../settings.global';
import { ToastrService } from 'ngx-toastr';
import {debounceTime} from 'rxjs/operators';

@Component({
	selector: 'app-brand-list',
	templateUrl: './brand-list.component.html',
	styleUrls: ['./brand-list.component.css']
})

export class BrandListComponent implements OnInit {
	public request_params: any;
	public pager: any;
	public queryLimit: number = AppSettings.DEFAULT_QUERY_LIMIT;
	public itemPerPageCount: number = AppSettings.DEFAULT_PAGER_COUNT;
	public apiCallCount: number = 0;
	public offSet: number = 0;
	public brands: any;
	public clients: any;
	public client: any;
	public no_data: any;
	public searchInputObservable$: any;
	public content: any;
	public title: any;
	public users: any;
	public modal_title: any;

	constructor(private brandService: BrandService,
		private clientService: ClientService,
		private toastr: ToastrService,
		private route: Router,
		private loaderService: LoaderService,
		private activatedRoute: ActivatedRoute,
		private authServiceObj: AuthService) {
		this.no_data = "Loading...."
		this.request_params = {
			searchkey: "",
			orderby: "",
			order: "",
			limit: this.queryLimit,
			offset: 0
		}
		this.pager = {
			limit: this.itemPerPageCount,
			offset: 0,
			total_count: 0,
			current_page: 1
		}
		this.brands = []
		this.title = "erwerwer"

	}

	ngAfterContentInit() {

		//Input search observable - to avoid the  onInputChange() firing whenever the input changes
		const searchInputObj = document.querySelector('#search_text');
		this.searchInputObservable$ = observableFromEvent(searchInputObj, 'keyup');

		this.searchInputObservable$.pipe(debounceTime(100))
			.subscribe((event: any) => {
				this.onInputChange(event.target.value);
			});
		$("body").height("100%");
		$("html").height("100%");

		/*
			* API call and grab full data
			*/
		setTimeout(() => {
			this.getClient(1);
		}, 10);
	}

	//get the client 
	getClient(page) {
		this.clientService.getClientSelect().subscribe((res: any) => {
			if (res.status == 'success') {
				if (res.data_found) {
					this.clients = res.data_found;
				} else {
					this.clients = null;
				}
				if (this.activatedRoute.snapshot.queryParams['client_id']) {
					this.client = parseInt(this.activatedRoute.snapshot.queryParams['client_id'])
					console.log(this.client, "activat");
				} else {
					if (this.clients) {
						this.client = this.clients[0].id;
					} else {
						this.client = null;
					}

				}
				console.log(this.client, "ddd brand")
				if (this.client != null) {
					this.getBrands(this.client, page);
				} else {
					this.no_data = 'No Data';
				}

			}
			else {
				if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
					this.toastr.error("Kindly login", "Session Expired");
					this.authServiceObj.logout();
				}
			}
		})
	}

	addBrand() {
		this.route.navigate(['/brand/add'], { queryParams: { client_id: this.client } });
	}

	onInputChange(text) {
		this.onSearch();
	}

	onChange(newValue) {
		this.client = newValue;
		this.brands = [];
		this.apiCallCount = 0;
		this.offSet = 0;
		this.pager["offset"] = 0;
		this.getBrands(newValue, 1);
	}

	onPageChange(page: number) {
		this.pager["current_page"] = page;
		this.pager["offset"] = this.itemPerPageCount * (page - 1);
	}

	onSearch() {
		this.brands = [];
		this.apiCallCount = 0;
		this.offSet = 0;
		this.pager["offset"] = 0;
		this.getBrands(this.client, 1);
	}

	getBrands(client_id, page) {
		if (this.offSet != 0 && this.request_params['searchkey'] != '') {
			this.brands = [];
			this.apiCallCount = 0;
			this.offSet = 0;
		}
		this.request_params['offset'] = this.offSet;
		this.request_params['client'] = client_id;
		this.loaderService.display(true);
		this.brandService.getBrands(this.request_params).subscribe((res: any) => {
			this.loaderService.display(false);
			this.apiCallCount++;
			if (res.status == 'success' && !_.isNull(res.brand_result)) {
				this.pager["total_count"] = this.brands.length + res.brand_result.length;
				this.pager["current_page"] = page;
				const result = this.brands.concat(res.brand_result);
				this.brands = res.brand_result;
				//Load full result
				if (!_.isNil(res.max_possible_res_length) && res.max_possible_res_length > this.brands.length) {
					this.offSet = this.apiCallCount * this.queryLimit;
					//this.getBrands(this.client,1);
					// this.getCountry(1);
				}
			} else {
				if (this.brands.length === 0 || res.max_possible_res_length === 0) {
					this.brands = [];
					this.no_data = 'No Data';
					this.pager["total_count"] = 0;
					this.pager["current_page"] = page;
				}
				if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
					this.toastr.error("Kindly login", "Session Expired");
					this.authServiceObj.logout();
				}
			}
		})

	}

	//inactivate brand
	inActivate(id: any) {
		let data = { id: id };
		var cnf = confirm("Are you sure you want to deactivate this brand?");
		if (cnf) {
			this.brandService.inActivate(data).subscribe((res: any) => {
				if (res.status == "success") {
					this.toastr.success('Inactivated', 'SUCCESS');
					this.brands = [];
					this.apiCallCount = 0;
					this.offSet = 0;
					this.getBrands(this.client, 1);
					//this.getClient(1);
				}
				else {
					if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
						this.authServiceObj.logout();
					}
				}
			})
		}
	}

	//Activate brand
	activate(id: any, type: any, sid: any) {
		var cnf = confirm("Are you sure you want to activate this brand?");
		if (cnf) {
			let data = { id: id };
			this.brandService.activate(data).subscribe((res: any) => {
				if (res.status == "success") {
					this.toastr.success('Activated', 'SUCCESS');
					this.brands = [];
					this.apiCallCount = 0;
					this.offSet = 0;
					this.getBrands(this.client, 1);
					//this.getClientData(1);
				} else {
					if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
						this.authServiceObj.logout();
					}
					else {
						this.toastr.error(res.message, 'Failed');
					}
				}
			})
		}

	}

	onSort(orderby: string, id: any) {
		if (this.request_params["orderby"] === orderby) {
			if (this.request_params["order"] === "asc") {
				this.request_params["order"] = "desc";
			} else {
				this.request_params["order"] = "asc";
			}
		} else {
			this.request_params["orderby"] = orderby;
			this.request_params["order"] = "desc"
		}
		//Reset the page to 1, offset to 0
		this.pager["current_page"] = 1;
		this.pager["offset"] = 0;
		this.brands = this.brandService.sortBrandList(this.brands, this.request_params["orderby"], this.request_params["order"]);
	}
	getUsers(title: any, users: any) {
		//alert("dd")
		this.users = [];
		this.modal_title = title;
		if (users) {
			this.users = users;
			$('#userModal').modal('show');
		}
	}

	ngOnInit() {
	}

}
