import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import {ClientService} from '../../services/client/client.service';
import { BrandService } from '../../services/brand/brand.service';
import * as $ from 'jquery';
declare var $ : any;
import {AuthService} from '../../services/auth/auth.service';
import {LoaderService} from '../../services/loader/loader.service';
import {ReportService} from '../../services/report/report.service';
import {AppSettings} from '../../settings.global';
import { ToastrService } from 'ngx-toastr';
@Component({
	selector: 'app-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
	public clients:any;
	public brands:any;
	public submitted:boolean;
	public reportSectionError:boolean;
	public image_base:any=AppSettings.IMAGE_BASE_URL;
	public fileErrorFlag:boolean;
	public fileToUpload:any;
	public uploaded:boolean;
	public success_upload_flag:boolean;
	public upload_fail_flag:boolean;
	daterange: any = {
		start: moment().startOf('year').format('YYYY-MM-DD'),
		end : moment().format('YYYY-MM-DD')
	};
	cumulativedaterange: any = {
		start: null, //moment().startOf('year').format('YYYY-MM-DD'),
		end : null, //moment().format('YYYY-MM-DD')
	};
	public report:{
		client:any,
		brand_id:any,
		brand_name:string,
		fromDate:string,
		toDate:string,
		social_media:boolean,
		market_place:boolean,
		standalone:boolean,
		m_commerce:boolean,
		requested_date:string,
		e_commerce:boolean,
		cumulativeFromDate:string,
		cumulativeToDate:string
	}
	public reportUpload:{
		client:any,
		brand:any,
		type:string
	}


	public datepickerOptions: any = {
		startDate: moment().startOf('year'),
		endDate: moment(),
		minDate: '01/01/2000',
		maxDate: moment(),
		showDropdowns: !0,
		showWeekNumbers: !0,
		timePicker: !1,
		timePickerIncrement: 1,
		timePicker12Hour: !0,
		ranges: {
			'Last 30 Days': [moment().subtract(29, 'days'), moment()],
			'This Quarter': [moment().quarter(moment().quarter()).startOf('quarter'), moment()],
			'Since January 1st': [moment().startOf('year'), moment()]
		},
		opens: 'left',
		buttonClasses: ['btn btn-default'],
		applyClass: 'btn-small btn-primary',
		cancelClass: 'btn-small',
		format: 'MM/DD/YYYY',
		separator: ' to ',
		locale: {
			applyLabel: 'Submit',
			cancelLabel: 'Clear',
			fromLabel: 'From',
			toLabel: 'To',
			customRangeLabel: 'Time Range',
			daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
			// tslint:disable-next-line:max-line-length
			monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
			firstDay: 1
		}
	};

	/**
	 * Cumulative Date range
	 */
	public datepickerOptions2: any = {
		autoUpdateInput : false,
		// startDate: moment().startOf('year'),
		// endDate: moment(),
		minDate: '01/01/2000',
		maxDate: moment(),
		showDropdowns: !0,
		showWeekNumbers: !0,
		timePicker: !1,
		timePickerIncrement: 1,
		timePicker12Hour: !0,
		ranges: {
			'Last 30 Days': [moment().subtract(29, 'days'), moment()],
			'This Quarter': [moment().quarter(moment().quarter()).startOf('quarter'), moment()],
			'Since January 1st': [moment().startOf('year'), moment()]
		},
		opens: 'left',
		buttonClasses: ['btn btn-default'],
		applyClass: 'btn-small btn-primary',
		cancelClass: 'btn-small',
		format: 'MM/DD/YYYY',
		separator: ' to ',
		locale: {
			applyLabel: 'Submit',
			cancelLabel: 'Clear',
			fromLabel: 'From',
			toLabel: 'To',
			customRangeLabel: 'Time Range',
			daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
			// tslint:disable-next-line:max-line-length
			monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
			firstDay: 1
		}
	};

	constructor(private clientService:ClientService,private brandService:BrandService,
		private authServiceObj:AuthService,private toastr: ToastrService,
		private loaderService:LoaderService,private reportService:ReportService) {
		this.submitted=false; 
		this.reportSectionError=false;
		this.fileErrorFlag=false;
		this.fileToUpload='';
		this.uploaded=false;
		this.report={
			client:'',
			brand_id:'',
			brand_name:'',
			fromDate:this.daterange.start,
			toDate:this.daterange.end,
			social_media:false,
			market_place:false,
			standalone:false,
			m_commerce:false,
			requested_date:moment().format('DD/MM/YYYY'),
			e_commerce : false,
			cumulativeFromDate : this.cumulativedaterange.start,
			cumulativeToDate : this.cumulativedaterange.end
		}
		this.reportUpload={
			client:'',
			brand:'',
			type:'report'
		}
		this.success_upload_flag=false;
		this.upload_fail_flag=false;
	}
	ngAfterContentInit() {

		$("body").height("100%");
		$("html").height("100%");

		this.getClient();
	}
	selectedDate(value: any) {
		this.daterange.start = moment(value.start._d).format('YYYY-MM-DD');
		this.daterange.end = moment(value.end._d).format('YYYY-MM-DD');
		this.report.fromDate=moment(value.start._d).format('YYYY-MM-DD');
		this.report.toDate=moment(value.end._d).format('YYYY-MM-DD');
	}
	selectedCumulativeDate(value: any) {
		console.log(value.start._d, value.end._d)
		this.cumulativedaterange.start = moment(value.start._d).format('YYYY-MM-DD');
		this.cumulativedaterange.end = moment(value.end._d).format('YYYY-MM-DD');
		this.report.cumulativeFromDate=moment(value.start._d).format('YYYY-MM-DD');
		this.report.cumulativeToDate=moment(value.end._d).format('YYYY-MM-DD');
	}
	//get the client 
	getClient(){
		this.clientService.getClientSelect().subscribe((res:any)=>{
			if(res.status=='success'){
				this.clients=res.data_found;
			}
			else{
				if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
					this.toastr.error("Kindly login","Session Expired");
					this.authServiceObj.logout();
				}
			}
		})
	}
	getBrand(newValue) {
		this.brands = [];
		this.loaderService.display(true);
		this.brandService.getBrandSelect(newValue).subscribe((res:any)=>{
			this.loaderService.display(false);
			if(res.status=='success'){
				this.brands=res.data_found;
			}
			else{
				if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
					this.toastr.error("Kindly login","Session Expired");
					this.authServiceObj.logout();
				}
			}
		})
		
	}
	onClientChange(clientID:number){
		console.log(clientID)
		this.report.client=clientID;
		this.reportUpload.client=clientID;
		this.report.brand_id = "";
		this.getBrand(clientID);
	}
	onBrandChange(brandID:number){
		console.log(brandID);
		this.report.brand_id=brandID;
		this.reportUpload.brand=brandID;
		for(let brand of this.brands){
			if(brand.id==brandID){
				this.report.brand_name=brand.name;
			}
		}
	}
	reportSectionSelect(value){
		if(value==true){
			this.reportSectionError=false;
		}
	}
	onSubmit(valid){
		console.log(this.report);
		this.submitted=true;
		if(!this.report.standalone && !this.report.social_media  && !this.report.m_commerce && !this.report.e_commerce){
			this.reportSectionError=true;
			return false;
		}
		if(valid){
			this.loaderService.display(true);
			this.reportService.generateReport(this.report).subscribe((res:any)=>{
				this.loaderService.display(false);
				console.log(res);
				this.submitted=false;
				if(res.status=="success"){
					this.downloadFile(res.message);
				}else {
					if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
						this.toastr.error("Kindly login","Session Expired");
						this.authServiceObj.logout();
					}else{
						this.toastr.error(res.message,'Failed');
					}
				}
			},(e)=>{

			});
		}
	}
	downloadFile(file){
		var link = document.createElement("a");
			link.download = file;
			link.href = this.image_base+file;
			link.target="_blank";
			document.body.appendChild(link);
			this.toastr.success('Report is generated successfully!','SUCCESS');
			link.click();
		
	}
	 downloadFileExists(file:string):boolean
	{

    var http = new XMLHttpRequest();
    http.open('HEAD', file, false);
	http.send();
	alert(http.status);
	alert(file);
    return http.status!=404;

	}
	upload(){
		this.success_upload_flag=false;
		this.upload_fail_flag=false;
		this.fileToUpload='';
		this.fileErrorFlag=false;
		this.uploaded=false;
		$("#upload_file").replaceWith($("#upload_file").val(''));
		$('#uploadFileModal').modal('show');
	}
	getFile(event) {
		this.fileErrorFlag=true;
		if (event.target.files && event.target.files[0]) {
			var reader = new FileReader();
			this.fileToUpload=event.target.files[0];

			//reader.readAsDataURL(event.target.files[0]);
		}
	}

	onUpload(valid){
		// var cnf=true;
		// if(this.fileToUpload.name){
		// 	if(this.fileToUpload.name.toLowerCase().search(this.report.brand_name.toLowerCase())==-1){
		// 		var cnf=confirm("The brand is invalid. Do you want to continue?");
		// 	}
		// }
		
		// if(cnf){
			this.uploaded=true;
			if(valid && this.fileErrorFlag){
				this.loaderService.display(true);
				this.reportService.uploadReport(this.reportUpload,this.fileToUpload).then((res)=>{
					this.loaderService.display(false);
					if(res.status=="success"){
						this.fileErrorFlag=true;
						this.success_upload_flag=true;
						this.toastr.success("Uploaded Successfully",'Success');
						// console.log(res.successRecords)
						console.log("success");
					}else{
						this.success_upload_flag=true;
						this.upload_fail_flag=true;
						this.toastr.error(res.message,'Error');
					}

				},(e)=>{
					console.log(e);
				});
			}
		// }
		// return false;

	}
	ngOnInit() {
	}

	onCancel(event:Event){
		this.cumulativedaterange ={
			start: null,
			end : null, 
		};

		this.report.cumulativeFromDate = null;
		this.report.cumulativeToDate = null;

	}
}
