
import {of as observableOf,  Observable } from 'rxjs';
import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRoute} from '@angular/router';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';


@Injectable()
export class LoggedInGuard implements CanActivate {
    constructor(private router: Router, private localSt: LocalStorageService) {
    }

    canActivate() : Observable<boolean> {
        console.log("canActivated"); 
        //console.log("Access token",this.localSt.retrieve("access_token"));
        if (this.localSt.retrieve("access_token") == null) {
            console.log("canActivate");
            this.router.navigate(['/login']);
            return observableOf(false)
        }
        return observableOf(true)
    }
}