import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule, JsonpModule } from '@angular/http';
import * as $ from 'jquery';
import { routing } from './app.routing';
import {NgxWebstorageModule} from 'ngx-webstorage';
import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';
import { Daterangepicker } from 'ng2-daterangepicker';
import { MomentModule } from 'ngx-moment';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ModalModule, BsDropdownModule, BsDatepickerModule } from 'ngx-bootstrap';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AuthService } from './services/auth/auth.service';
import { LoggedInGuard } from './providers/auth/auth.providers';
import { ProfileService } from './services/profile/profile.service';
import { LoaderService } from './services/loader/loader.service';
import { ClientService } from './services/client/client.service';
import { MarketPlacesService } from './services/master-data/market-places/market-places.service';
import { SocialMediaService } from './services/master-data/social-media/social-media.service';
import { SearchEngineService } from './services/master-data/search-engine/search-engine.service';
import { CountryService } from './services/master-data/country/country.service';
import { CurrencyService } from './services/master-data/currency/currency.service';
import { StatusService } from './services/master-data/status/status.service';
import {ProductCategoryService} from './services/master-data/product-category/product-category.service'
import { KeywordsService } from './services/master-data/keywords/keywords.service';
import { MotherCompanyService } from './services/master-data/mother-company/mother-company.service';
import { LanguageService } from './services/master-data/language/language.service';
import { BrandService } from './services/brand/brand.service';
import { UserService } from './services/user/user.service';
import { DataDeleteRequestService } from './services/data-delete-request/data-delete-request.service';
import { ReportService } from './services/report/report.service';
import { DashboardService } from './services/dashboard/dashboard.service';
import { AdminService } from './services/admin/admin.service';
import { SuperAdminProvider } from './providers/admin/super-admin.provider';
import { DataSearchService } from './services/data-search/data-search.service';
import { MarketplaceService } from './services/settings/marketplace/marketplace.service';


import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ClientComponent } from './components/client/client.component';
import { ClientListComponent } from './components/client/client-list.component';
import { MarketPlacesComponent } from './components/master-data/market-places/market-places.component';
import { SocialMediaComponent } from './components/master-data/social-media/social-media.component';
import { SearchEngineComponent } from './components/master-data/search-engine/search-engine.component';
import { CountryComponent } from './components/master-data/country/country.component';
import { CurrencyComponent } from './components/master-data/currency/currency.component';
import { StatusComponent } from './components/master-data/status/status.component';
import { KeywordsComponent } from './components/master-data/keywords/keywords.component';
import { BrandComponent } from './components/brand/brand.component';
import { BrandListComponent } from './components/brand/brand-list.component';
import { UserComponent } from './components/user/user.component';
import { UserListComponent } from './components/user/user-list.component';
import { DataDeleteRequestComponent } from './components/data-delete-request/data-delete-request.component';
import { MotherCompanyComponent } from './components/master-data/mother-company/mother-company.component';
import { ClientDataComponent } from './components/client-data/client-data.component';
import { LanguageComponent } from './components/master-data/language/language.component';
import { ReportComponent } from './components/report/report.component';
import { ThousandSuffixPipePipe } from './pipes/ThousandSuffixPipe/thousand-suffix-pipe.pipe';
import { AdminComponent } from './components/admin/admin.component';
import { AdminListComponent } from './components/admin/admin-list.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { DataSearchComponent } from './components/data-search/data-search.component';
import { SocialMediaSearchComponent } from './components/data-search/social-media-search.component';
import { MarketplaceComponent } from './components/settings/marketplace/marketplace.component';
import { MarketplaceListComponent } from './components/settings/marketplace/marketplace-list.component';
import { UpcComponent } from './components/settings/upc/upc.component';
import { UpcAddComponent } from './components/settings/upc/upc-add/upc-add.component'
import {UpcService} from './services/upc/upc.service';
import {SellerService} from './services/settings/sellers/seller.service'
import {GeneralService} from './services/settings/general/general.service'
import { MarketplaceScrapaddComponent } from './components/settings/marketplace/marketplace-scrapadd/marketplace-scrapadd.component';
import { SchedulerComponent } from './components/settings/scheduler/scheduler.component';
import { SchedulerListComponent } from './components/settings/scheduler/scheduler-list.component';
import { SchedulerService } from './services/settings/scheduler/scheduler.service';
import { SellersComponent } from './components/settings/sellers/sellers.component';
import { GeneralComponent } from './components/settings/general/general.component';
import { ProductListComponent } from './components/products/product-list.component';
import { ProductService } from './services/product/product.service';
// import { LinechartDirective } from './directives/linechart.directive';
import { MultilinechartDirective } from './directives/multilinechart.directive';
import { ProductCategoryComponent } from './components/master-data/product-category/product-category.component';
import { SellersService } from './services/sellers/sellers.service';
import { SellerComponent } from './components/seller/seller/seller.component';
import { HttpClientModule } from '@angular/common/http';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { SmartProxyService } from './services/smartproxy';
import { SellerDataComponent } from './components/seller-data/seller-data.component';
import { DuplicateModalComponent } from './components/duplicate-modal/duplicate-modal.component';







@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    LoginComponent,
    ForgotPasswordComponent,
    DashboardComponent,
    ProfileComponent,
    ClientComponent,
    ClientListComponent,
    MarketPlacesComponent,
    SocialMediaComponent,
    CountryComponent,
    SearchEngineComponent,
    CurrencyComponent,
    StatusComponent,
    KeywordsComponent,
    BrandComponent,
    BrandListComponent,
    UserComponent,
    UserListComponent,
    DataDeleteRequestComponent,
    MotherCompanyComponent,
    ClientDataComponent,
    LanguageComponent,
    ReportComponent,
    ThousandSuffixPipePipe,
    AdminComponent,
    AdminListComponent,
    NotFoundComponent,
    DataSearchComponent,
    SocialMediaSearchComponent,
    MarketplaceComponent,
    MarketplaceListComponent,
    UpcComponent,
    UpcAddComponent,
    MarketplaceScrapaddComponent,
    SchedulerComponent,
    SchedulerListComponent,
    SellersComponent,
    GeneralComponent,
    ProductListComponent,
    MultilinechartDirective,
    SellerComponent,
    ProductCategoryComponent,
    SellerDataComponent,
    DuplicateModalComponent,
  ],
  imports: [
    BrowserModule,
    routing,
    FormsModule,
    HttpClientModule,
    ImageCropperModule,
    ReactiveFormsModule,
    NgxWebstorageModule.forRoot(),
    ToastrModule.forRoot(),
    HttpModule,
    JsonpModule,
    NgxPaginationModule,
    Daterangepicker,
    MomentModule,
    PopoverModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    RoundProgressModule,
    BrowserAnimationsModule,
    NgMultiSelectDropDownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    AngularMyDatePickerModule

  ],
  providers: [AuthService,
    LoggedInGuard,
    ProfileService,
    LoaderService,
    UpcService,
    ClientService,
    MarketPlacesService,
    SocialMediaService,
    SearchEngineService,
    CountryService,
    CurrencyService,
    StatusService,
    ProductCategoryService,
    KeywordsService,
    BrandService,
    UserService,
    SellerService,
    GeneralService,
    MotherCompanyService,
    LanguageService,
    DataDeleteRequestService,
    ReportService,
    DashboardService,
    AdminService,
    MarketplaceService,
    ProductService,
    SchedulerService,
    SellersService,
    SuperAdminProvider,
    DataSearchService,
    SmartProxyService],
    entryComponents: [
      DuplicateModalComponent
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
