import { Component, OnInit } from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
public user_details:any;
  constructor(public localSt:LocalStorageService) {
    this.user_details = this.localSt.retrieve("user_details");
   }

  ngOnInit() {
  }

}
