
import {of as observableOf, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {AppSettings} from '../../../settings.global';
import {ActivatedRoute, Router} from '@angular/router';
declare var $:any;
import * as _ from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class MotherCompanyService {
  public headers: HttpHeaders;

  constructor(private httpClient: HttpClient, private localSt: LocalStorageService) {
  	this.headers = new HttpHeaders({
			'Content-Type': 'application/json;charset=UTF-8',
			'Accept': 'application/json',
			'x-access-token': this.localSt.retrieve("access_token")
		});
   }
   //get mother company accodring to type
   getMcompany(body:any){
    let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
    let bodyString = $.param(body);
    return this.httpClient.get(AppSettings.API_ENDPOINT +'/masterdata/mothercompany?'+bodyString, options).pipe(
    map(function (res: any) {
      return res.body || {
        status: "failed",
        message: "Something went wrong"
      };
    }),catchError((error:any) => {
      //return Observable.throw(error.json().error || 'Server error occured')
      return observableOf({status:"error",message:"Server error occured"})
    }),);
    }

    //get mother company accodring to type
   getParentcompany(){
    let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
    return this.httpClient.get(AppSettings.API_ENDPOINT +'/masterdata/parentcompany?', options).pipe(
    map(function (res: any) {
      return res.body || {
        status: "failed",
        message: "Something went wrong"
      };
    }),catchError((error:any) => {
      //return Observable.throw(error.json().error || 'Server error occured')
      return observableOf({status:"error",message:"Server error occured"})
    }),);
    }
    
    //create or update mother company
    createUpdateMcompany(body:any){
      let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
      if(body.id){
        return this.httpClient.put(AppSettings.API_ENDPOINT +'/masterdata/mothercompany',body, options).pipe(
        map(function (res: any) {
          return res.body || {
            status: "failed",
            message: "Something went wrong"
          };
        }));
      }else{
        console.log(body);
        return this.httpClient.post(AppSettings.API_ENDPOINT +'/masterdata/mothercompany',body, options).pipe(
        map(function (res: any) {
          return res.body || {
            status: "failed",
            message: "Something went wrong"
          };
        }));
        }
    }
    
    //delete the mother company
    delete(id:any){
      let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
      return this.httpClient.delete(AppSettings.API_ENDPOINT +'/masterdata/mothercompany?id='+id,options).pipe(
      map(function(res:any){
        return res.body || {status: "failed", message: "Somthing went wrong"};
      }));
    }

    sortMcompanyList(mcompanyData, orderBy, order) {
      return _.orderBy(mcompanyData, [orderBy], [order]);
    }

  //get mother company for selectinput
	getMcompanySelect(body:any){
    let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.get(AppSettings.API_ENDPOINT +'/masterdata/mother_company_select/'+body, options).pipe(
		map(function (res: any) {
			return res.body || {
				status: "failed",
				message: "Something went wrong"
			};
		}));
    }
    

    //get mother company for selectinput
	getCountrySelect(body:any){
    let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.get(AppSettings.API_ENDPOINT +'/masterdata/country/', options).pipe(
		map(function (res: any) {
			return res.body || {
				status: "failed",
				message: "Something went wrong"
			};
		}));
	  }

}
