
import {fromEvent as observableFromEvent} from 'rxjs';
import { Component, OnInit } from '@angular/core';
import {MarketPlacesService} from '../../../services/master-data/market-places/market-places.service';
import { MotherCompanyService } from '../../../services/master-data/mother-company/mother-company.service';
import * as $ from 'jquery';
import * as _ from 'lodash';
import {Observable} from 'rxjs/';
import {LoaderService} from '../../../services/loader/loader.service';
import {NgxPaginationModule} from 'ngx-pagination';//importing ng2-pagination
import {AuthService} from '../../../services/auth/auth.service';
import {AppSettings} from '../../../settings.global';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import {debounceTime} from 'rxjs/operators';


@Component({
  selector: 'app-market-places',
  templateUrl: './market-places.component.html',
  styleUrls: ['./market-places.component.css']
})
export class MarketPlacesComponent implements OnInit {
  public request_params:any;
  public pager:any;
  public queryLimit: number = AppSettings.DEFAULT_QUERY_LIMIT;
	public itemPerPageCount: number = AppSettings.DEFAULT_PAGER_COUNT;
	public apiCallCount: number = 0;
	public offSet: number = 0;
  public market:any;
  public markets:any;
  public title:any;
  public saveButton:any;
  public cancelButton:any;
  public submitted:boolean;
  public no_data:any;
  public searchInputObservable$: any;
  public mcompanies: any;
  public countries:any;
  public checkboxValue:boolean;
  public getData:any;
  //public master:any;
  public checked:any;
  public user_details:any;
  public checkboxValueEcom:boolean;
  public showCheckBoxError:boolean = false;
  constructor(private marketPlaceService:MarketPlacesService,public localSt:LocalStorageService, private motherCompanyService:MotherCompanyService,
     private loaderService:LoaderService, private toastr: ToastrService, private authServiceObj:AuthService) { 
    this.title='Marketplace';
    this.saveButton=' Add ';
    this.cancelButton= 'Clear';
    this.submitted=false;
    this.no_data="Loading...."
    this.request_params = {
			searchkey: "",
			orderby: "",
			order: "",
			limit: this.queryLimit,
			offset: 0
		}
		this.pager = {
			limit: this.itemPerPageCount,
			offset: 0,
			total_count: 0,
			current_page: 1
		} 

    this.market={
      id:'',
      mcompany:'',
      name:'',
      description:'',
      mcommerce:'',
      ecommerce:'',
      country:0
    };
    //this.checkboxValue=true;
    this.markets=[];
    this.getData='market_palce';
    this.user_details = this.localSt.retrieve("user_details");

  }

  ngAfterContentInit() { 

    //Input search observable - to avoid the  onInputChange() firing whenever the input changes
		const searchInputObj = document.querySelector('#search_text');
		this.searchInputObservable$ = observableFromEvent(searchInputObj, 'keyup');

		this.searchInputObservable$.pipe(debounceTime(100))
      .subscribe((event: any) => {
        this.onInputChange(event.target.value);
      });
    $("body").height("100%");
    $("html").height("100%");

    this.getMcompany();
    this.getAllCountries();

    

    /*
         * API call and grab full data
         */
		setTimeout(() => {
			this.getMarketPlace(1);
		},10);    
  }

  getAllCountries()
  {
    this.motherCompanyService.getCountrySelect(this.getData).subscribe((res:any)=>{
      if(res.status=='success'){
      this.countries=res.country_result;
      }
      else{
        if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
              this.toastr.error("Kindly login","Session Expired");
              this.authServiceObj.logout();
        }
      }
    });
  }

    //To get mothercompany
    getMcompany(){
      // if(this.checkboxValue && this.checkboxValueEcom){
      //   this.getData='all';
      // }
      // else if(this.checkboxValue && !this.checkboxValueEcom){
      //   this.getData='m_commerce';
      // } 
      // else if(!this.checkboxValue && this.checkboxValueEcom){
      //   this.getData='e_commerce';
      // }
      // else{
      //   this.getData='market_place';
      // }
      this.getData='all';
      this.motherCompanyService.getMcompanySelect(this.getData).subscribe((res:any)=>{
        if(res.status=='success'){
        this.mcompanies=res.data_found;
        }
        else{
          if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
                this.toastr.error("Kindly login","Session Expired");
                this.authServiceObj.logout();
          }
        }
      })
    }

  onSubmit(valid){
    
    this.submitted=true;
    /**
     * Check for the checkboxes are valid
     */
    if(!this.checkboxValue && !this.checkboxValueEcom){
      this.showCheckBoxError = true;
      return false;
    }

    if(valid){
      if(this.checkboxValue == null){
        this.checkboxValue=false;
      }
      if(this.checkboxValueEcom == null){
        this.checkboxValueEcom=false;
      }
      this.market['mcommerce']=this.checkboxValue;
      this.market['ecommerce']=this.checkboxValueEcom;

      if(this.market.country===0)
      {
        this.market.country=null;
      }

      console.log(this.market);
      
      this.loaderService.display(true);
      this.marketPlaceService.createUpdateMarket(this.market).subscribe((res:any)=>{
        this.loaderService.display(false);
        if(res.message=="success"){
          this.toastr.success('Updated Successfully!','SUCCESS');
          this.markets = [];
          this.apiCallCount = 0;
          this.offSet = 0;
          this.getMarketPlace(1);
          this.title='Marketplace';
          this.saveButton=' Add ';
          this.cancelButton= 'Clear';
          this.submitted=false;
          this.market={
            id:'',
            mcompany:'',
            name:'',
            description:'',
            mcommerce:'',
            ecommerce:'',
            country:0
          };
          this.checkboxValue = false;
          this.checkboxValueEcom = false;
          this.showCheckBoxError = false;
        }else if(res.message == "marketplace already exists"){
          this.toastr.error('Marketplace already exists','Request Failed');
        }else {
          if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
            this.toastr.error("Kindly login","Session Expired");
            this.authServiceObj.logout();
          }else{
            this.toastr.error(res.message,'Failed');
          }
        }
      },(e)=>{

          });
    }
  }

  onInputChange(text) {
    this.onSearch();
  }

  //To get marketplaces
  getMarketPlace(page: number){
    this.request_params['offset'] = this.offSet; 
    this.loaderService.display(true); 
    this.marketPlaceService.getMarketPlaces(this.request_params).subscribe((res:any)=>{
      this.loaderService.display(false);
      this.apiCallCount++;
      if(res.status=='success' && !_.isNull(res.market_result)){
        this.pager["total_count"] = this.markets.length + res.market_result.length;
				this.pager["current_page"] = page;
				const result = this.markets.concat(res.market_result);
        this.markets = res.market_result;
        console.log(this.markets);
        //Load full result
				if(!_.isNil(res.max_possible_res_length) && res.max_possible_res_length > this.markets.length) {
					this.offSet = this.apiCallCount * this.queryLimit;

					this.getMarketPlace(1);
				}    
      }else {
        if(this.markets.length === 0 || res.max_possible_res_length === 0) {
          this.markets = [];
          this.no_data = 'No Data';
					this.pager["total_count"] = 0;
					this.pager["current_page"] = page;
				}
        if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
          this.toastr.error("Kindly login","Session Expired");
          this.authServiceObj.logout();
        }
      }
    })
  }

  onSearch() {
		this.markets = [];
		this.apiCallCount = 0;
    this.offSet = 0; 
    this.pager["offset"] =0;   
    this.getMarketPlace(1);
   }

   onPageChange(page: number) {
    this.pager["current_page"] = page;
    this.pager["offset"] = this.itemPerPageCount * (page - 1);
    }

  //delete selected market place
  deleteMarketPlace(id:any){
    var cnf=confirm("Are you sure you want to delete this marketplace?")
    if(cnf){
      this.marketPlaceService.delete(id).subscribe((res:any)=>{
        if(res.status=='success'){
          this.toastr.success('Marketplace Deleted Successfully!','SUCCESS');
          this.market={
            id:'',
            mcompany:'',
            name:'',
            description:'',
            mcommerce:'',
            ecommerce:'',
            country:0

          };
          this.markets = [];
          this.apiCallCount = 0;
          this.offSet = 0;
          this.checkboxValue=false;
          this.checkboxValueEcom=false;
          this.getAllCountries();
          this.getMcompany();
          this.getMarketPlace(1);
          }else {
            if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
              this.toastr.error("Kindly login","Session Expired");
              this.authServiceObj.logout();             
            }else{
              this.toastr.error(res.message,'Failed');
            }
          }
      })
    }
  }

  onSort(orderby: string, id: any) {
		if (this.request_params["orderby"] === orderby) {
			if (this.request_params["order"] === "asc") {
				this.request_params["order"] = "desc";
			} else {
				this.request_params["order"] = "asc";
			}
		} else {
			this.request_params["orderby"] = orderby;
			this.request_params["order"] = "desc"
		}
		//Reset the page to 1, offset to 0
		this.pager["current_page"] = 1;
		this.pager["offset"] = 0;
		this.markets = this.marketPlaceService.sortMarketList(this.markets, this.request_params["orderby"], this.request_params["order"]);
	}
  
  //Get marketplace for edit
  editMarketPlace(market:any){
    this.title='Edit Marketplace';
    this.saveButton=' Save ';
    this.cancelButton= 'Cancel';
    this.market={
      id:market.id,
      mcompany:market.mother_company_id,
      name:market.name,
      description:market.description,

      mcommerce:market.m_commerce,
      ecommerce:market.e_commerce,
      country:market.country
    };
    
    this.checkboxValue=market.m_commerce;
    this.checkboxValueEcom=market.e_commerce;
    this.getMcompany();
    console.log("Market");
    console.log(market);
  }

  //To cancel editing and show add form
  cancel(){
    this.title='Marketplace';
    this.saveButton=' Add ';
    this.cancelButton= 'Clear';
    this.market={
      id:'',
      mcompany:'',
      name:'',
      description:'',
      mcommerce:'',
      ecommerce:'',
      country:0

    };
    this.checkboxValue=false;
    this.checkboxValueEcom=false;
    this.getMcompany();
  }

  onMcommerce(){
    this.checkboxValue=!this.checkboxValue;
    //this.getMcompany();    
  }

  onEcommerce(){
    this.checkboxValueEcom=!this.checkboxValueEcom;
    //this.getMcompany();    
  }

  ngOnInit() {
  }

}
