
import {fromEvent as observableFromEvent} from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { KeywordsService } from '../../../services/master-data/keywords/keywords.service';
import * as $ from 'jquery';
import * as _ from 'lodash';
import {Observable} from 'rxjs/';
import {LoaderService} from '../../../services/loader/loader.service';
import {NgxPaginationModule} from 'ngx-pagination';//importing ng2-pagination
import {AuthService} from '../../../services/auth/auth.service';
import {AppSettings} from '../../../settings.global';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-keywords',
  templateUrl: './keywords.component.html',
  styleUrls: ['./keywords.component.css']
})
export class KeywordsComponent implements OnInit {
  public request_params:any;
  public pager:any;
  public queryLimit: number = AppSettings.DEFAULT_QUERY_LIMIT;
	public itemPerPageCount: number = AppSettings.DEFAULT_PAGER_COUNT;
	public apiCallCount: number = 0;
	public offSet: number = 0;
  public keyword:any;
  public keywords:any;
  public title:any;
  public saveButton:any;
  public cancelButton:any;
  public submitted:boolean;
  public no_data:any;
  public searchInputObservable$: any;
  public user_details:any;

  constructor(private keywordsService:KeywordsService, private loaderService:LoaderService,
    private toastr: ToastrService, private authServiceObj:AuthService,public localSt:LocalStorageService) { 
    this.title='Keyword';
    this.saveButton=' Add ';
    this.cancelButton= 'Clear';
    this.submitted=false;    
    this.no_data="Loading...."
    this.request_params = {
			searchkey: "",
			orderby: "",
			order: "",
			limit: this.queryLimit,
			offset: 0
		}
		this.pager = {
			limit: this.itemPerPageCount,
			offset: 0,
			total_count: 0,
			current_page: 1
		}
    this.keyword={
      id:'',
      name:'',
      description:''
    };
    this.keywords=[];
    this.user_details = this.localSt.retrieve("user_details");
  }

  ngAfterContentInit() {

    //Input search observable - to avoid the  onInputChange() firing whenever the input changes
		const searchInputObj = document.querySelector('#search_text');
		this.searchInputObservable$ = observableFromEvent(searchInputObj, 'keyup');

    this.searchInputObservable$.pipe(debounceTime(100))
    .subscribe((event: any) => {
      this.onInputChange(event.target.value);
    });
    $("body").height("100%");
    $("html").height("100%");

    /*
         * API call and grab full data
         */
		setTimeout(() => {
			this.getKeywords(1);
		},10);    
  }

  onSubmit(valid){
    this.submitted=true;
    if(valid){
      this.loaderService.display(true);
      this.keywordsService.createUpdateKeywords(this.keyword).subscribe((res:any)=>{
        this.loaderService.display(false);
        if(res.message=="success"){
          if(this.saveButton==' Add ') {
            this.toastr.success('Added Successfully!','SUCCESS');
          } else {
            this.toastr.success('Updated Successfully!','SUCCESS');
          }             
          this.keywords = [];
          this.apiCallCount = 0;
          this.offSet = 0;
          this.getKeywords(1);
          this.title='Keyword';
          this.saveButton=' Add ';
          this.cancelButton= 'Clear';
          this.submitted=false;
          this.keyword={
                id:'',
                name:'',
                description:''
              };
        }else if(res.message == "Keyword already exists"){
          this.toastr.error('Keyword already exists','Request Failed');
        }else {
          if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
            this.toastr.error("Kindly login","Session Expired");
            this.authServiceObj.logout();
          }else{
            this.toastr.error(res.message,'Failed');
          }
        }
        },(e)=>{

            });
    }
  }

  onInputChange(text) {
    this.onSearch();
  }

  //To get keywords
  getKeywords(page: number){
    this.request_params['offset'] = this.offSet;
    this.loaderService.display(true);  
    this.keywordsService.getKeywords(this.request_params).subscribe((res:any)=>{
      this.loaderService.display(false);
      this.apiCallCount++;
      if(res.status=='success' && !_.isNull(res.keywords_result)){
        this.pager["total_count"] = this.keywords.length + res.keywords_result.length;
				this.pager["current_page"] = page;
				const result = this.keywords.concat(res.keywords_result);
        this.keywords = res.keywords_result;
        //Load full result
				if(!_.isNil(res.max_possible_res_length) && res.max_possible_res_length > this.keywords.length) {
					this.offSet = this.apiCallCount * this.queryLimit;

					this.getKeywords(1);
				}    
      }else {
        if(this.keywords.length === 0 || res.max_possible_res_length === 0) {
          this.keywords = [];
          this.no_data = 'No Data';
					this.pager["total_count"] = 0;
					this.pager["current_page"] = page;
				}
        if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
          this.toastr.error("Kindly login","Session Expired");
          this.authServiceObj.logout();
        }
      }
    })
  
  }
  onSearch() {
		this.keywords = [];
		this.apiCallCount = 0;
    this.offSet = 0;
    this.pager["offset"] =0; 
    this.getKeywords(1);
   }

  onPageChange(page: number) {
  this.pager["current_page"] = page;
  this.pager["offset"] = this.itemPerPageCount * (page - 1);
  }

  //Get keyword for edit
  editKeyword(keyword:any){
    this.title='Edit Keyword';
    this.saveButton=' Save ';
    this.cancelButton= 'Cancel';
    this.keyword={
      id:keyword.id,
      name:keyword.name,
      description:keyword.description
    };
  }

  //delete selected keyword
  deleteKeyword(id:any){
    var cnf=confirm("Are you sure you want to delete this Keyword?")
    if(cnf){
      this.keywordsService.delete(id).subscribe((res:any)=>{
        if(res.status=='success'){
          this.toastr.success('Keyword deleted successfully!','SUCCESS');
          this.keyword={
            id:'',
            name:'',
            description:''
          };
          this.keywords = [];
          this.apiCallCount = 0;
          this.offSet = 0;
        
          this.getKeywords(1);
          }else {
            if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
              this.toastr.error("Kindly login","Session Expired");
              this.authServiceObj.logout();             
            }else{
              this.toastr.error(res.message,'Failed');
            }
          }
      })
    }
  }
  onSort(orderby: string, id: any) {
		if (this.request_params["orderby"] === orderby) {
			if (this.request_params["order"] === "asc") {
				this.request_params["order"] = "desc";
			} else {
				this.request_params["order"] = "asc";
			}
		} else {
			this.request_params["orderby"] = orderby;
			this.request_params["order"] = "desc"
		}
		//Reset the page to 1, offset to 0
		this.pager["current_page"] = 1;
		this.pager["offset"] = 0;
		this.keywords = this.keywordsService.sortKeywordList(this.keywords, this.request_params["orderby"], this.request_params["order"]);
	}   

  //To cancel editing and show add form
  cancel(){
    this.title='Keyword';
    this.saveButton=' Add ';
    this.cancelButton= 'Clear';
    this.keyword={
      id:'',
      name:'',
      description:''
    };
  }

  ngOnInit(){    
  }

}
