import {ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {LoginComponent} from './components/login/login.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {HomeComponent} from './components/home/home.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import { MarketPlacesComponent } from './components/master-data/market-places/market-places.component';
import { SocialMediaComponent } from './components/master-data/social-media/social-media.component';
import { SearchEngineComponent } from './components/master-data/search-engine/search-engine.component';
import { CountryComponent } from './components/master-data/country/country.component';
import { CurrencyComponent } from './components/master-data/currency/currency.component';
import { StatusComponent } from './components/master-data/status/status.component';
import {ProductCategoryComponent} from './components/master-data/product-category/product-category.component'
import { KeywordsComponent } from './components/master-data/keywords/keywords.component';
import {LoggedInGuard} from './providers/auth/auth.providers';
import {ProfileComponent} from './components/profile/profile.component';
import {ClientComponent} from './components/client/client.component';
import {ClientListComponent} from './components/client/client-list.component';
import {BrandListComponent} from './components/brand/brand-list.component';
import {BrandComponent} from './components/brand/brand.component';
import {UserListComponent} from './components/user/user-list.component';
import {DataDeleteRequestComponent} from './components/data-delete-request/data-delete-request.component';
import {ClientDataComponent} from './components/client-data/client-data.component';
import {ReportComponent} from './components/report/report.component';
import {AdminComponent} from './components/admin/admin.component';
import {SellersComponent}from './components/settings/sellers/sellers.component'
import {GeneralComponent} from './components/settings/general/general.component'
import {UpcComponent} from './components/settings/upc/upc.component';
import {AdminListComponent} from './components/admin/admin-list.component';
import {MarketplaceComponent} from './components/settings/marketplace/marketplace.component';
import {MarketplaceScrapaddComponent} from './components/settings/marketplace/marketplace-scrapadd/marketplace-scrapadd.component'
import {MarketplaceListComponent} from './components/settings/marketplace/marketplace-list.component';
import {NotFoundComponent}          from './components/not-found/not-found.component';
import {SuperAdminProvider} from './providers/admin/super-admin.provider';

const appRoutes: Routes = [

    {
        path:"upc",
        component: HomeComponent,
        canActivate: [LoggedInGuard],
        data: {activeComponent: "UpcComponent"}
    },
    
    {
        path:"upc/add",
        component: HomeComponent,
        canActivate: [LoggedInGuard],
        data: {activeComponent: "UpcAddComponent"}
    },
    {
        path:"upc/:id",
        component: HomeComponent,
        canActivate: [LoggedInGuard],
        data: {activeComponent: "UpcAddComponent"}
    },
    {
        path:"sellers",
        component: HomeComponent,
        canActivate: [LoggedInGuard],
        data: {activeComponent: "SellersComponent"}
    },
    {
        path:"general",
        component: HomeComponent,
        canActivate: [LoggedInGuard],
        data: {activeComponent: "GeneralComponent"}
    },
{
    path:"",
    component: HomeComponent,
    canActivate: [LoggedInGuard],
    data: {activeComponent: "DashboardComponent"}
},
{
    path: "login",
    component: LoginComponent,
    pathMatch: 'full'
},
{
    path: "forgot-password",
    component: ForgotPasswordComponent,
    pathMatch: 'full'
},

{
    path: "market",
    component: HomeComponent,
    data: {activeComponent: "MarketPlacesComponent"}
},
{
    path: "social",
    component: HomeComponent,
    data: {activeComponent: "SocialMediaComponent"}
},
{
    path: "search",
    component: HomeComponent,
    data: {activeComponent: "SearchEngineComponent"}
},
{
    path: "country",
    component: HomeComponent,
    data: {activeComponent: "CountryComponent"}
},
{
    path: "currency",
    component: HomeComponent,
    data: {activeComponent: "CurrencyComponent"}
},
{
    path: "status",
    component: HomeComponent,
    data: {activeComponent: "StatusComponent"}
},
{
    path:'product-category',
    component:HomeComponent,
    data:{activeComponent:'ProductCategoryComponent'}
},
{
    path: "keywords",
    component: HomeComponent,
    data: {activeComponent: "KeywordsComponent"}
},
{
    path: "profile",
    component: HomeComponent,
    canActivate: [LoggedInGuard],
    data: {activeComponent: "ProfileComponent"}
},
{
    path:"client",
    component: HomeComponent,
    canActivate: [LoggedInGuard],
    data: {activeComponent: "ClientListComponent"}
},
{
    path:"client/add",
    component: HomeComponent,
    canActivate: [LoggedInGuard,SuperAdminProvider],
    data: {activeComponent: "ClientComponent"}
},
{
    path:"client/update/:id",
    component: HomeComponent,
    canActivate: [LoggedInGuard,SuperAdminProvider],
    data: {activeComponent: "ClientComponent"}
},
{
    path:"brand",
    component: HomeComponent,
    canActivate: [LoggedInGuard],
    data: {activeComponent: "BrandListComponent"}
},
{
    path:"brand/add",
    component: HomeComponent,
    canActivate: [LoggedInGuard],
    data: {activeComponent: "BrandComponent"}
},
{
    path:"brand/:id",
    component: HomeComponent,
    canActivate: [LoggedInGuard],
    data: {activeComponent: "BrandComponent"}
},
{
    path:"user",
    component: HomeComponent,
    canActivate: [LoggedInGuard],
    data: {activeComponent: "UserListComponent"}
},
{
    path:"user/add",
    component: HomeComponent,
    canActivate: [LoggedInGuard],
    data: {activeComponent: "UserComponent"}
},  
{
    path:"user/:id",
    component: HomeComponent,
    canActivate: [LoggedInGuard],
    data: {activeComponent: "UserComponent"}
},
{
    path:"mcompany",
    component: HomeComponent,
    canActivate: [LoggedInGuard],
    data: {activeComponent: "MotherCompanyComponent"}
},
{
    path:"language",
    component: HomeComponent,
    canActivate: [LoggedInGuard],
    data: {activeComponent: "LanguageComponent"}
},
{
    path:"delete-request",
    component: HomeComponent,
    canActivate: [LoggedInGuard,SuperAdminProvider],
    data: {activeComponent: "DataDeleteRequestComponent"}
},
{
    path:"data",
    component: HomeComponent,
    canActivate: [LoggedInGuard],
    data: {activeComponent: "ClientDataComponent"}
},
{
    path:"admin",
    component: HomeComponent,
    canActivate: [LoggedInGuard,SuperAdminProvider],
    data: {activeComponent: "AdminListComponent"}
},
{
    path:"admin/add",
    component: HomeComponent,
    canActivate: [LoggedInGuard,SuperAdminProvider],
    data: {activeComponent: "AdminComponent"}
},
{
    path:"admin/:id",
    component: HomeComponent,
    canActivate: [LoggedInGuard,SuperAdminProvider],
    data: {activeComponent: "AdminComponent"}
},
{
    path:"marketplace",
    component: HomeComponent,
    canActivate: [LoggedInGuard,SuperAdminProvider],
    data: {activeComponent: "MarketplaceListComponent"}
},
{
    path:"marketplace/scrap",
    component: HomeComponent,
    canActivate: [LoggedInGuard],
    data: {activeComponent: "MarketplaceScrapaddComponent"}
},
{
    path:"marketplace/add",
    component: HomeComponent,
    canActivate: [LoggedInGuard,SuperAdminProvider],
    data: {activeComponent: "MarketplaceComponent"}
},
{
    path:"marketplace/:id",
    component: HomeComponent,
    canActivate: [LoggedInGuard,SuperAdminProvider],
    data: {activeComponent: "MarketplaceComponent"}
},
{
    path:"scheduler",
    component: HomeComponent,
    canActivate: [LoggedInGuard,SuperAdminProvider],
    data: {activeComponent: "SchedulerListComponent"}
},
{
    path:"scheduler/add",
    component: HomeComponent,
    canActivate: [LoggedInGuard,SuperAdminProvider],
    data: {activeComponent: "SchedulerComponent"}
},
{
    path:"scheduler/:id",
    component: HomeComponent,
    canActivate: [LoggedInGuard,SuperAdminProvider],
    data: {activeComponent: "SchedulerComponent"}
},
{
    path:"products",
    component: HomeComponent,
    canActivate: [LoggedInGuard,SuperAdminProvider],
    data: {activeComponent: "ProductListComponent"}
},
{
    path:"seller",
    component: HomeComponent,
    canActivate: [LoggedInGuard,SuperAdminProvider],
    data: {activeComponent: "SellerComponent"}
},
{
    path:"report",
    component: HomeComponent,
    canActivate: [LoggedInGuard],
    data: {activeComponent: "ReportComponent"}
},
{
    path:"search-data",
    component: HomeComponent,
    canActivate: [LoggedInGuard],
    data: {activeComponent: "DataSearchComponent"}
},
{
    path:"seller-data",
    component: HomeComponent,
    canActivate: [LoggedInGuard],
    data: {activeComponent: "SellerDataComponent"}
},
{
    component: HomeComponent,
    path: "**",
    data: {activeComponent: "NotFoundComponent"}
},
{
    component: HomeComponent,
    path: "notfound",
    data: {activeComponent: "NotFoundComponent"}
},


];

export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes,{ useHash: true });
