import { Component, OnInit } from '@angular/core';
import { ClientService } from '../../services/client/client.service';
import { NgxPaginationModule } from 'ngx-pagination';//importing ng2-pagination
import { Routes, RouterModule, ActivatedRoute, Router, Params } from '@angular/router';
import { LoaderService } from '../../services/loader/loader.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
declare var $: any;
import { Client } from '../../interfaces/client/client';
// import {ImageCropperComponent, CropperSettings, Bounds} from 'ng2-img-cropper';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { AuthService } from '../../services/auth/auth.service';
import { AppSettings } from '../../settings.global';


@Component({
	selector: 'app-client',
	templateUrl: './client.component.html',
	styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {
	//Variable declaration

	public client: Client;
	public submited: boolean;
	public errorFlag: boolean;
	public title: string;
	public client_id: any;
	public edit_flag: boolean;
	// cropperSettings1:CropperSettings;
	public imageFlag: boolean;
	public imageData: any;
	public signatureData: any;
	public image_base: any = AppSettings.IMAGE_BASE_URL;
	imageChangedEvent: any = '';
	imageChangedEventS: any = '';
	croppedImage: any = '';

	constructor(private clientService: ClientService,
		private localSt: LocalStorageService,
		private loaderService: LoaderService,
		private toastr: ToastrService,
		private route: Router,
		private activatedRoute: ActivatedRoute,
		private authServiceObj: AuthService) {
		this.title = "Add Client";
		this.client = {
			id: null,
			name: '',
			address: '',
			work_phone: '',
			owner: '',
			website: '',
			email: '',
			fax: '',
			linkedin: '',
			description: '',
			logo: '',
			old_logo: '',
			edit: false,
			signature: '',//added
			old_signature: ''//added
		}
		this.submited = false;
		this.errorFlag = false;
		this.edit_flag = false;
		this.imageFlag = false;
		this.imageData = {};
		this.signatureData = {};//new

		//Image cropper

		// this.cropperSettings1 = new CropperSettings();
		// this.cropperSettings1.width = 200;
		// this.cropperSettings1.height = 200;
		// this.cropperSettings1.keepAspect = false;

		// this.cropperSettings1.croppedWidth = 400;
		// this.cropperSettings1.croppedHeight = 400;

		// this.cropperSettings1.canvasWidth = 330;
		// this.cropperSettings1.canvasHeight = 200;

		// this.cropperSettings1.minWidth = 100;
		// this.cropperSettings1.minHeight = 100;

		// this.cropperSettings1.rounded = false;
		// this.cropperSettings1.minWithRelativeToResolution = false;

		// this.cropperSettings1.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
		// this.cropperSettings1.cropperDrawSettings.strokeWidth = 2;
	}
	logoCropped(event) {
		this.imageData.image = event.base64;
		this.client.edit = true;
		this.imageFlag = false;
		this.client.old_logo = '';
		this.client.logo = this.imageData.image;
		console.log(this.imageData, "image data")
	}
	// new
	signatureCropped(event) {
		this.signatureData.image = event.base64;
		this.client.edit = true;
		this.imageFlag = false;
		this.client.old_signature = '';
		this.client.signature = this.signatureData.image;
	}
	// new

	onSubmit(valid) {
		console.log('clienttttt',this.client, valid)
		this.submited = true;
		if (valid) {
			// this.loaderService.display(true);
			console.log();
			
			this.clientService.createUpdateClient(this.client).subscribe((res: any) => {
				// this.loaderService.display(false);
				if (res.status == "success") {
					this.edit_flag = false;
					this.toastr.success('Updated Successfully!', 'SUCCESS');
					this.route.navigate(['/client']);
				}
				else {
					if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
						this.authServiceObj.logout();
					}
					else {
						this.toastr.error(res.message, 'Error');
					}
				}
			}, (e) => {
			});
		}
	}
	removeImage() {

		this.client.logo = '';
		this.client.old_logo = '';
		this.imageData.image = '';
		$("#clinetLogo canvas").hide();
		$("#clinetLogo input").replaceWith($("#clinetLogo input").val(''));
	}
	// new
	removeImage2() {
		this.client.signature = '';
		this.client.old_signature = '';
		this.signatureData.image = '';
		$("#clinetSignature canvas").hide();
		$("#clinetSignature input").replaceWith($("#clinetSignature input").val(''));
	}
	// new
	ngAfterContentInit() {

		$("body").height("100%");
		$("html").height("100%");
		this.activatedRoute.params.subscribe((params: Params) => {
			this.client_id = params['id'];

			if (this.client_id) {
				// Updation
				this.title = "Edit Client";
				this.edit_flag = true;
				// Getting the Cleint data  
				this.clientService.getClientDetails(this.client_id).subscribe((res: any) => {
					if (res.status == "success") {
						console.log('res.data_found', res.data_found);
						
						this.client = {
							id: res.data_found.id,
							name: res.data_found.name,
							address: res.data_found.address,
							work_phone: res.data_found.work_phone,
							owner: res.data_found.owner,
							website: res.data_found.website,
							email: res.data_found.email,
							fax: res.data_found.fax,
							linkedin: res.data_found.linkedin,
							description: res.data_found.description,
							logo: '',
							old_logo: res.data_found.logo_url,
							edit: false,
							//  new
							signature: '',
							old_signature: res.data_found.signature_url
							//  --------
						}
						console.log('this.client', this.client);
						
					} else {
						if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
							this.authServiceObj.logout();
						}
						else {
							this.toastr.error(res.message, 'Failed');
						}
					}
				});
			}
		});
	}

// image crop functionality
	fileChangeEvent(event: any): void {
		this.imageChangedEvent = event;
	}
	fileChangeEventSignature(event: any): void {
		this.imageChangedEventS = event;
	}
	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
	}
	imageLoaded(image: HTMLImageElement) {
		// show cropper
	}
	cropperReady() {
		// cropper ready
	}
	loadImageFailed() {
		// show message
	}

	ngOnInit() {

	}

}
