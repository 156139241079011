
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

import { AppSettings } from '../../settings.global';
import { ActivatedRoute, Router } from '@angular/router';
declare var $: any;
import * as _ from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()
export class UpcService {
	public headers: HttpHeaders;
	constructor(private httpClient: HttpClient, private localSt: LocalStorageService) {
		this.headers = new HttpHeaders({
			'Content-Type': 'application/json;charset=UTF-8',
			'Accept': 'application/json',
			'x-access-token': this.localSt.retrieve("access_token")
		});
	}
	//Create or update Upc
	createUpdateUpc(body: any) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		if (body.upc.id) {
			console.log(body.upc, 'this is body ID');
			return this.httpClient.put(AppSettings.API_ENDPOINT_V2 + '/settings/upc', body, options).pipe(
				map(function (res: any) {
					return res.body || {
						status: "failed",
						message: "Something went wrong"
					};
				}));
		} else {
			console.log("yse");
			return this.httpClient.post(AppSettings.API_ENDPOINT_V2 + '/settings/upc', body, options).pipe(
				map(function (res: any) {
					return res.body || {
						status: "failed",
						message: "Something went wrong"
					};
				}));
		}
	}
	//Function to get Upc list
	getUpcs(body: any) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		let bodyString = $.param(body);
		return this.httpClient.get(AppSettings.API_ENDPOINT_V2 + '/settings/upc?' + bodyString, options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));
	}
	// sort upc
	sortUpcList(chestData, orderBy, order) {
		return _.orderBy(chestData, [orderBy], [order]);
	}
	//Get Upc details to edit
	getUpcDetails(id: number) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.get(AppSettings.API_ENDPOINT_V2 + '/settings/upc/edit/' + id, options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));
	}
	//Inactivate Upc
	inActivate(data: any) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.post(AppSettings.API_ENDPOINT_V2 + '/settings/upc/inactive', data, options).pipe(
			map(function (res: any) {
				return res.body || { status: "failed", message: "Somthing went wrong" };
			}));
	}
	//Activate Upc
	activate(data: any) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.post(AppSettings.API_ENDPOINT_V2 + '/settings/upc/active', data, options).pipe(
			map(function (res: any) {
				return res.body || { status: "failed", message: "Somthing went wrong" };
			}));
	}

	//   delete upc
	// delete(id:any){
	// 	let headers = new Headers();
	// 	this.headers.set('x-access-token', this.localSt.retrieve("access_token"));
	// 	this.headers.append('Accept', 'application/json');
	// 	let options = new RequestOptions({ headers: this.headers });

	// 	return this.http.delete(AppSettings.API_ENDPOINT_V2 +'/upc?id='+id,options)
	// 	.map(function(res:any){
	// 		return res.json() || {status: "failed", 	message: "Somthing went wrong"};
	// 	});
	// }



}
