
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { AppSettings } from '../../../settings.global';

declare var $: any;
import * as _ from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class MarketplaceService {

	public headers: HttpHeaders;

  constructor(private localStorage: LocalStorageService,
    private httpClient: HttpClient) {
		this.headers = new HttpHeaders({
			'Content-Type': 'application/json;charset=UTF-8',
			'Accept': 'application/json',
			'x-access-token': this.localStorage.retrieve("access_token")
		});
   }

  	//Create or update marketplace
	createUpdateMarketplace(body: any) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		if (body.marketplace.id) {
			return this.httpClient.put(AppSettings.API_ENDPOINT_V2 + '/settings/marketplace', body, options).pipe(
				map(function (res: any) {
					return res.body || {
						status: "failed",
						message: "Something went wrong"
					};
				}));
		} else {
			return this.httpClient.post(AppSettings.API_ENDPOINT_V2 + '/settings/marketplace', body, options).pipe(
				map(function (res: any) {
					return res.body || {
						status: "failed",
						message: "Something went wrong"
					};
				}));
		}

	}


// Get the list of marketplaces
	getMarketPlaces(body:any){
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		let bodyString = $.param(body);
		console.log('bodyString', bodyString);
		return this.httpClient.get(AppSettings.API_ENDPOINT_V2 +'/settings/marketplace?'+bodyString, options).pipe(
		map(function (res: any) {
			return res.body || {
				status: "failed",
				message: "Something went wrong"
			};
		}),catchError((error:any) => {
			return observableOf({status:"error",message:"Server error occured"})
		}),);
	}

		// Add edit Scrapmap
		addEditScrapMap(body:any){
			let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
			return this.httpClient.put(AppSettings.API_ENDPOINT_V2 + '/settings/marketplace/scrapadd', body, options).pipe(
				map(function (res: any) {
					return res.body || {
						status: "failed",
						message: "Something went wrong"
					};
				}));
		}
		// // edit scrapmap
		getMarketPlacesDetails(id:any){
			let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
			return this.httpClient.get(AppSettings.API_ENDPOINT_V2 + '/settings/marketplace/scrapadd/edit/' + id, options).pipe(
				map(function (res: any) {
					console.log(res,"jano");
					
					return res.body || {
						status: "failed",
						message: "Something went wrong"
					};
				}));
		}

	//get name for name dropdown
	getName(){
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
	  return this.httpClient.get(AppSettings.API_ENDPOINT_V2 +'/settings/marketplace/scrapadd?user_id='+this.localStorage.retrieve("user_details").id, options).pipe(
	  map(function (res: any) {
		  return res.body || {
			  status: "failed",
			  message: "Something went wrong"
		  };
	  }),catchError((error:any) => {
		return observableOf({status:"error",message:"Server error occured"})
	}),);
	}



	getScrapMapCheck(body:any){
		// console.log('body', body);
		// this.headers.set('x-access-token', this.localStorage.retrieve("access_token"));
		// let options = new RequestOptions({headers: this.headers});
		// let bodyString = $.param(body);
		// return this.http.post(AppSettings.API_ENDPOINT+'/scrap?'+ bodyString, options)
		// .map(function (res: any) {
		// 	return res.json() || {
		// 		status: "failed",
		// 		message: "Something went wrong"
		// 	};
		// })
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.post(AppSettings.API_ENDPOINT +'/scrap',body,options).pipe(
		map(function(res:any){
			return res.body || {status: "failed", message: "Something went wrong"};
		}),catchError((error:any) => {
		return observableOf({status:"error",message:"Server error occured"})
	}),);
	}

	// Get single marketplace details
	getMarketplaceDetails(id: any) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.get(AppSettings.API_ENDPOINT_V2 + '/settings/marketplace/edit/' + id, options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));
	}

	//inactivate marketplace
	inActivate(data:any){
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.post(AppSettings.API_ENDPOINT_V2 +'/settings/marketplace/inactivate',data,options).pipe(
		map(function(res:any){
			return res.body || {status: "failed", message: "Something went wrong"};
		}));
	}

	//Activate marketplace
	activate(data:any){
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.post(AppSettings.API_ENDPOINT_V2 +'/settings/marketplace/activate',data,options).pipe(
		map(function(res:any){
			return res.body || {status: "failed", message: "Something went wrong"};
		}));
	}


	
    // get marketplace detail by ID
	getMarketplaceById(id: number) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.get(AppSettings.API_ENDPOINT_V2 + '/settings/marketplace/edit/' + id, options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));

	}

	sortMarketplaceList(marketData, orderBy, order) {
		return _.orderBy(marketData, [orderBy], [order]);
	 }

}
