import { Component, OnInit, TemplateRef, ElementRef, ViewChild } from '@angular/core';

import * as moment from 'moment';
import { LoaderService } from '../../services/loader/loader.service';
import { MarketplaceService } from '../../services/settings/marketplace/marketplace.service';
import { AppSettings } from '../../settings.global';
declare var $: any;
import { BsModalService } from 'ngx-bootstrap/modal';
import { ProductService } from '../../services/product/product.service';
import { CountryService } from '../../services/master-data/country/country.service';
import { AuthService } from '../../services/auth/auth.service';
import * as _ from 'lodash';
import * as XLSX from 'xlsx';
import { MotherCompanyService } from '../../services/master-data/mother-company/mother-company.service';
import { LocalStorageService } from 'ngx-webstorage';
// import { CropperSettings } from 'ng2-img-cropper';
import { SellerService } from '../../services/settings/sellers/seller.service';
import { ToastrService } from 'ngx-toastr';
// import { SellerService } from '../../services/seller/seller.service';
// import { empty } from 'rxjs/Observer';


@Component({
	selector: 'app-product-list',
	templateUrl: './product-list.component.html',
	styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {

	public objectKeys = Object.keys;
	public cropedImage: any;
	public dropDown: boolean;
	public imageAdded: boolean;
	public temp: any;
	public datepickerOptions: any = {
		startDate: moment().startOf('year'),
		endDate: moment(),
		minDate: '01/01/2000',
		maxDate: moment(),
		showDropdowns: !0,
		showWeekNumbers: !0,
		timePicker: !1,
		timePickerIncrement: 1,
		timePicker12Hour: !0,
		opens: 'right',
		buttonClasses: ['btn btn-default'],
		applyClass: 'btn-small btn-primary',
		cancelClass: 'btn-small',
		format: 'MM/DD/YYYY',
		separator: ' to ',
		locale: {
			applyLabel: 'Submit',
			cancelLabel: 'Clear',
			fromLabel: 'From',
			toLabel: 'To',
			customRangeLabel: 'Time Range',
			daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
			// tslint:disable-next-line:max-line-length
			monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
			firstDay: 1
		}
	};
	daterange: any = {
		start: moment().startOf('year').utc().format(),
		end: moment.utc().format()
	};
	price_daterange: any = {
		start: moment().startOf('year').utc().format(),
		end: moment.utc().format()
	};
	public price_range: any = {
		max_value: 0,
		min_value: 0,
		price_options: "Price range "
	}
	public extraDynamiData: any;
	public editedOutput: any;
	public showPriceForm: any;
	public formArray: any;
	public mcompany: any;
	// public cropperSettings1: CropperSettings;
	public dynamicSellerInfo: any;
	noPointOfContact: any = false;
	public marketplaces: any;
	public expand: any;
	public request_params: any;
	public selectedFile: any;
	public seller_request_params: any;
	public queryLimit: number = AppSettings.DEFAULT_QUERY_LIMIT;
	public offSet: number = 0;
	public apiCallCount: number = 0;
	public productsArray: any;
	public product: any;
	public currentCurrency: any;
	public img_url = AppSettings.IMAGE_BASE_URL;
	public seller_id: any;
	public selectedOption: any;
	public errMsg: boolean;
	public nil: any;
	public countries: any;
	public pager: any;
	public no_data: any;
	public no_p_data: any;
	public pinned: any;
	public seller_ids: any;
	public pinSeller: boolean;
	public no_seller_data: any;
	public selectAllFlag: boolean;
	public ad_ids: any;
	public checkBox: any;
	public itemPerPageCount: number = AppSettings.DEFAULT_PAGER_COUNT;
	public parent_company: any;
	public mplaces: any;
	public selectedMplace: any;
	public mcompanies: any;
	public imageData: any;
	public selectedMcompany: any;
	public m_request_params: any;
	public p_request_params: any;
	public productData: any;
	public firstMplace: boolean;
	public country_request_params: any;
	sellerData: { id: number; name: string; condition: string; price: number; status: string; country: string; currency: string; marketplace: string; checked: boolean; seller_url: string; ads_screenshot: { id: number; path: string; }[]; }[];
	sellerId: any;
	data: { name: any; sellername: any; };
	data1: { name: any; sellername: string; };
	productId: any;
	static prevProdID: any;
	static products: any;
	public selected_price_range: string;
	public details: any;
	selectedSeller: any;
	filteredSeller: any;
	sortOrder: boolean;
	message: any;
	sellerLogo: any;
	sellerInfo: any;
	field: string = 'Add More';
	inputName: any = '';
	editDetails: any;
	sellerdata: any;
	editedSellerData: any;
	extraSellerData: any;
	editedSeller: any[];
	source: string;
	scrapFields: any;
	tempScrapField: any;
	edited: any;
	results: any;
	customInputValue: any;
	sellersLogo: string;
	fileToUpload: File;
	imageUrl: any;
	selectedMcompanyName: any;
	dynamictemp: any;
	selectedMplaceName: any;

	constructor(private marketplaceService: MarketplaceService,
		private loaderService: LoaderService,
		private toastr: ToastrService,
		private productService: ProductService,
		private countryService: CountryService,
		private authServiceObj: AuthService,
		private motherCompanyService: MotherCompanyService,
		private localStorage: LocalStorageService,
		private sellerService: SellerService
	) {
		this.sortOrder = true;
		this.imageUrl = '';
		this.editDetails = {}
		this.extraDynamiData = [];
		this.editedOutput = [];
		this.dynamictemp = [];
		this.request_params = {
			searchkey: "",
			orderby: "",
			order: "",
			limit: this.queryLimit,
			offset: 0,
			marketplace: "",
			date_range: "",
			condition: "",
			seller_country: "",
			price_range: "",
			status: ""
		};
		this.selectedFile = {
			src: ''
		}
		this.temp = [];
		this.imageData = {};
		this.seller_request_params = {
			searchkey: "",
			orderby: "",
			order: "",
			limit: this.queryLimit,
			offset: 0,
			marketplace: "",
			start_date: "",
			end_date: "",
			condition: "",
			seller_country: "",
			price_range: "",
			status: "",
			product_id: ""
		};
		this.mcompany = [];
		this.sellerdata = [];
		this.showPriceForm = false;
		this.formArray = [];
		this.dropDown = false;
		this.cropedImage = true
		this.errMsg = false;
		this.pinned = false;

		this.imageAdded = false;
		this.selectedOption = ''
		this.pager = {
			limit: this.itemPerPageCount,
			offset: 0,
			total_count: 0,
			current_page: 1
		};
		this.productsArray = [];
		this.expand = false;
		this.nil = '--';
		this.dynamicSellerInfo = {};
		this.countries = [];
		this.no_data = "Loading...."
		this.no_p_data = "No Data"
		this.selectAllFlag = false;
		this.ad_ids = [];
		this.checkBox = [];
		this.marketplaces = [];
		this.parent_company = [];
		this.mplaces = [];
		this.sellerInfo = {};
		this.selected_price_range = '';
		this.mcompanies = [];
		this.pinSeller = false;
		this.details = [];
		this.productData = [];
		this.seller_ids = [];
		ProductListComponent.products = [];
		this.firstMplace = true;
		this.product = [];
		//Image cropper
		// this.cropperSettings1 = new CropperSettings();
		// this.cropperSettings1.width = 200;
		// this.cropperSettings1.height = 200;
		// this.cropperSettings1.keepAspect = false;

		// this.cropperSettings1.croppedWidth = 400;
		// this.cropperSettings1.croppedHeight = 400;

		// this.cropperSettings1.canvasWidth = 330;
		// this.cropperSettings1.canvasHeight = 200;

		// this.cropperSettings1.minWidth = 100;
		// this.cropperSettings1.minHeight = 100;

		// this.cropperSettings1.rounded = false;
		// this.cropperSettings1.minWithRelativeToResolution = false;

		// this.cropperSettings1.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
		// this.cropperSettings1.cropperDrawSettings.strokeWidth = 2;
		this.m_request_params = {
			searchkey: "",
			orderby: "",
			order: "",
			limit: this.queryLimit,
			offset: 0
		}
		this.p_request_params = {
			searchkey: "",
			orderby: "",
			order: "",
			limit: this.queryLimit,
			offset: 0
		}
		this.country_request_params = {
			searchkey: "",
			orderby: "",
			order: "",
			limit: this.queryLimit,
			offset: 0
		}
		var link_selectedSummaryObj = { id: 0, name: 'All', spcialClass: 'active-brands-tab ' };
	}

	ngAfterContentInit() {
		$("body").height("100%");
		$("html").height("100%");
		this.getCountry();
		this.getParentCompany();
		console.log('ProductListComponent.prevProdID', ProductListComponent.prevProdID);
	}

	ngAfterViewInit() {
	}


	ngOnInit() {
	}


	//To get Country
	getCountry() {
		this.country_request_params['offset'] = this.offSet;
		this.countryService.getCountry(this.country_request_params).subscribe((res: any) => {
			this.apiCallCount++;
			if (res.status == 'success' && !_.isNull(res.country_result)) {
				this.countries = res.country_result;
			} else {
				if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
					this.toastr.error("Kindly login", "Session Expired");
					this.authServiceObj.logout();
				}
			}
		})
	}
	//get the mothercompany for the top bar
	getParentCompany() {
		this.m_request_params['offset'] = this.offSet;
		this.m_request_params['type'] = 'all';
		this.loaderService.display(true);
		this.motherCompanyService.getParentcompany().subscribe((res: any) => {
			this.apiCallCount++;
			console.log('res m c', res);
			if (res.status == 'success' && !_.isNull(res.data_found)) {
				this.loaderService.display(false);
				this.mcompanies = res.data_found;
				console.log('this.mcompany', this.mcompany);
				this.getMarketplace(this.mcompanies[0].id)
				this.selectedMcompany = this.mcompanies[0].id;
				this.selectedMcompanyName = this.mcompanies[0].name;
			} else {
				if (this.mcompanies.length === 0 || res.max_possible_res_length === 0) {
					this.mcompanies = [];
				}
				if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
					this.toastr.error("Kindly login", "Session Expired");
					this.authServiceObj.logout();
				}
			}
		})
	}
	//function to get the list of marketplaces
	getMarketplace(id) {
		console.log('id', id);

		this.mplaces = [];
		// console.log('selected id', id);
		this.p_request_params['offset'] = 0;
		this.loaderService.display(true);
		this.marketplaceService.getMarketPlaces(this.p_request_params).subscribe((res: any) => {
			if (res.status == 'success') {
				this.loaderService.display(false);
				this.marketplaces = res.market_result;
				console.log('resresres', this.marketplaces);
				if (this.marketplaces.length > 0) {
					this.marketplaces.forEach(element => {
						if (element.parent_id === id) {
							this.mplaces.push(element);
						}
					});

				}
				// if (this.mcompanies.length == 0) {
				// 	this.marketplaces.forEach(element => {
				// 		this.mcompany.forEach(ele => {
				// 			if (ele.id == element.parent_id) {
				// 				this.mcompanies.push(ele)
				// 			}
				// 		});
				// 	});
				// }
				// if (id) {
				// 	console.log('1111111111111111111',);

				// 	this.marketplaces.forEach(element => {
				// 		console.log('lol', element.parent_id, id);

				// 		if (element.parent_id == id) {
				// 			this.mplaces.push(element);
				// 		}
				// 	});
				// } 
				// else {
				// 	console.log('2222222222222222222222222');
				// 	this.selectedMcompany = this.mcompanies[0].id
				// 	this.marketplaces.forEach(element => {
				// 		// console.log('io', element.parethis.mcompanies[0].parent_id);

				// 		if (element.parent_id == this.mcompanies[0].id) {
				// 			this.mplaces.push(element);
				// 		}
				// 	});
				// }
				console.log('parent', this.mcompanies);
				console.log('child', this.mplaces);
				if (this.mplaces.length > 0 && this.firstMplace) {
					this.selectedMplace = this.mplaces[0].id;
					this.selectedMplaceName = this.mplaces[0].name;
					this.currentCurrency = this.mplaces[0].currency_code;
				}
				console.log('abc', this.selectedMplace);
				this.getProductSeller(1, this.selectedMplace);
			}
		});
	}

	// get product array when clicks GO button
	goSearch() {
		console.log('hui89');
		this.getProductSeller(1, this.selectedMplace)
	}

	//function to get the details of products and sellers corresponding to a marketplace
	getProductSeller(page, id) {
		this.loaderService.display(true);
		this.expand = false;
		this.request_params.marketplace = id;
		var start_date = new Date(this.daterange.start);
		var end_date = new Date(this.daterange.end);
		var start = moment.utc(start_date).format();
		var end = moment.utc(end_date).format();
		this.request_params.date_range = start + '#' + end;
		this.productService.getProductSeller(this.request_params).subscribe((res: any) => {
			console.log('res', res);

			this.loaderService.display(false);
			this.apiCallCount++;
			if (res.status == 'success' && !_.isNull(res.p_scrap_result)) {
				this.pager["total_count"] = this.productsArray.length + res.p_scrap_result.length;
				this.pager["current_page"] = page;
				const result = this.productsArray.concat(res.p_scrap_result);
				res.p_scrap_result.forEach(element => {
					if (element.ads.length > 0) {
						element.pinSeller = false;
						element.expanded = false;
						element.checkedAll = false;
						console.log('element789', element);
						element.searchkey = ''
						if (element.ads[0].title) {
							element.title = element.ads[0].title;
						}
					}
				});
				this.productsArray = res.p_scrap_result;
				console.log('final', this.productsArray);
				console.log('final1', ProductListComponent.products);
				if (this.productsArray.length > 0) {
					ProductListComponent.products.forEach(element1 => {
						console.log('here', element1, this.productsArray);
						var idx = this.productsArray.findIndex(x => x.productupc == element1.productupc);
						console.log('idx', idx);
						if (idx != -1) {
							this.productsArray[idx].pinSeller = true;
							this.productsArray[idx].expanded = true;
						}
					});

				}
				// ProductListComponent.products.forEach(element1 => {
				// 	console.log('here', element1, this.productsArray);
				// 	var idx = this.productsArray.findIndex(x => x.productupc == element1.productupc);
				// 	console.log('idx', idx);
				// 	if (idx != -1) {
				// 		console.log('this.productsArray[idx]888', this.productsArray[idx]);
				// 		this.productsArray[idx].ads.forEach(ad => {
				// 			element1.ads.forEach(el => {
				// 				var index = element1.ads.findIndex(x => x.id == ad.id);
				// 				console.log('index', index);

				// 				if (index != -1) {
				// 					console.log('po', el, ad);
				// 					if (el.id == ad.id) {
				// 						el.status = ad.status;
				// 						el.seller = ad.seller;
				// 					}


				// 				}
				// 			});
				// 		});
				// 		console.log('ProductListComponent.products0', ProductListComponent.products);

				// 		this.productsArray[idx] = element1;
				// 	} else {
				// 		this.productsArray.push(element1)
				// 	}
				// });

				// this.productsArray.forEach((element2, index) => {
				// 	if (element2.pinSeller) {
				// 		this.productsArray.splice(index, 1);
				// 		this.productsArray.unshift(element2);
				// 	}
				// });

				this.product = _.cloneDeep(this.productsArray);
				//Load full result
				if (!_.isNil(res.max_possible_res_length) && res.max_possible_res_length > this.productsArray.length) {
					this.offSet = this.apiCallCount * this.queryLimit;
				}
			} else {
				this.productsArray = [];

				if (this.productsArray.length === 0 || res.max_possible_res_length === 0) {
					this.productsArray = [];
					this.product = _.cloneDeep(res.p_scrap_result);
					this.no_p_data = 'No Data';
					this.pager["total_count"] = 0;
					this.pager["current_page"] = page;
				}
				if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
					this.toastr.error("Kindly login", "Session Expired");
					this.authServiceObj.logout();
				}
			}
		})
	};

	//function to expand all products when clicking + icon
	expandAll() {
		this.expand = !this.expand;
		if (ProductListComponent.products.length > 0) {
			ProductListComponent.products.forEach(element => {
				this.productsArray.forEach(product => {
					if (element.pinSeller) {
						if (element.productupc === product.productupc) {
							console.log('product((((', product);
							product.pinSeller = true;
							product.expanded = true;
						}
					}
				})
			});
			this.product = _.cloneDeep(this.productsArray);
		}
		else {
			this.productsArray.map(product => {
				product.expanded = this.expand
			});
			this.product = _.cloneDeep(this.productsArray);
		}

		this.productsArray.map(product => {
			if (!product.pinSeller) {
				product.expanded = this.expand
			}
			this.product = _.cloneDeep(this.productsArray);
		});
	}

	//function works when a date range is submitted from the top dropdown box.
	selectedDate(value: any) {
		this.daterange.start = moment(value.start._d).toISOString();
		this.daterange.end = moment(value.end._d).utc().format();
	}

	//To make all check box true or false
	selectAll(product, index) {
		// this.marketplace_id = [];
		if (!product.checkedAll) {
			if (product.ads.length > 0) {
				product.ads.forEach(ad => {
					ad.seller.checked = true;
				});
			}
			product.checkedAll = true;
		} else {
			if (product.ads.length > 0) {
				product.ads.forEach(ad => {
					ad.seller.checked = false;
				});
			}
			product.checkedAll = false;
		}

		this.product = _.cloneDeep(this.productsArray);

	}

	// function to change seller status.
	changeSellerStatus(type, index, template: TemplateRef<any>, id) {
		if (type === 'Approved') {
			var type1 = 'approve'
		} else {
			var type1 = 'reject'
		}
		this.ad_ids = [];
		if (id) {
			this.ad_ids.push(id);
		} else {
			if (this.productsArray[index].checkedAll) {
				if (this.productsArray[index].ads.length > 0) {
					this.productsArray[index].ads.forEach(ad => {
						this.ad_ids.push(ad.id);
					});
				}
			} else {
				if (this.productsArray[index].ads.length > 0) {
					this.productsArray[index].ads.forEach(ad => {
						if (ad.seller.checked) {
							this.ad_ids.push(ad.id)
						}
					});
				} else {
					this.toastr.info('Please select the seller');
					return false;
				}
			}
		}

		var user = this.localStorage.retrieve('user_details');
		let data = {
			id: this.ad_ids,
			status: type,
			user_id: user.id
		}
		if (data.id.length == 0) {
			this.toastr.info('Please select the checkbox to ' + type1 + ' the seller');
		} else {
			if (data.id.length > 1) {
				var cnf = confirm('Are you sure you want to ' + type1 + ' these sellers?');
			} else {
				var cnf = confirm('Are you sure you want to ' + type1 + ' this seller?');
			}
			if (cnf) {
				this.productService.statusChange(data).subscribe((res: any) => {
					if (res.status == "success") {
						this.toastr.success(type, 'SUCCESS');
						this.productsArray[index].selectAll = false;
						console.log('this.productsArray[index]1', this.productsArray[index]);
						// if (this.productsArray[index].pinSeller) {
						// 	console.log('this.ad_ids', this.ad_ids);
						// 	this.ad_ids.forEach(element => {
						// 		const indx = this.productsArray[index].ads.findIndex(x => x.id == element);
						// 		console.log('index90', indx);

						// 		if (index != 1) {
						// 			this.productsArray[index].ads[indx].status = type;
						// 		}
						// 	});
						// }
						this.getProductSeller(1, this.selectedMplace);
						// $('#sellerModal').modal('show');
					} else {
						if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
							this.authServiceObj.logout();
						}
						else {
							this.toastr.error('Failed');
						}
					}
				});
			}

		}
		this.product = _.cloneDeep(this.productsArray);
	}
	onSelectSeller(seller, type) {
		console.log('defgb', seller, type);
	}
	//function to pin a product to the top of the page
	pinProduct(productId, status) {
		this.pinned = !this.pinned;
		console.log('this.pinned', status);
		if (!status) {
			this.productsArray.forEach((element, i) => {
				if (element.productupc == productId) {
					element.pinSeller = true;
					ProductListComponent.products.push(element)
				}
			});
		} else {
			this.productsArray.forEach((element, i) => {
				if (element.productupc == productId) {
					element.pinSeller = false;
				}
			});
			ProductListComponent.products.forEach((element, i) => {
				if (element.productupc == productId) {
					ProductListComponent.products.splice(i, 1);
				}
			});
		}
		console.log('ProductListComponent.products', ProductListComponent.products);
		this.product = _.cloneDeep(this.productsArray);
	}

	// display marketplace specific data when selecting on the top list of marketplaces
	onMarketplaceSelection(mplace) {
		this.selectedMplace = mplace.id;
		this.selectedMplaceName = mplace.name
		this.getProductSeller(1, this.selectedMplace)
	}

	// display marketplaces under parent companies
	onMcompanySelection(parent) {
		this.selectedMcompany = parent.id;
		this.selectedMcompanyName = parent.name;
		this.firstMplace = false;
		this.getMarketplace(parent.id);
	}

	// export seller data
	exportSeller(type: string, seller) {
		console.log('ertio', seller);
		var sellerData = [];
		seller.ads.forEach(element => {
			sellerData.push(element.seller)
		});
		//Format the data to avoid unwanted fields
		var keyMap;
		keyMap = {
			seller_id: 'SellerId',
			name: 'SellerName',
			seller_url: 'SellerUrl',
			country: 'Country',
			email: 'Email',
			phone: 'Phone',
			website: 'Website',
			address: 'Address'
		};
		var excelData = sellerData.map((obj) => {
			return _.mapKeys(obj, (value, key) => {
				return keyMap[key];
			});
		});
		const excelDataModified = excelData.map((sData, i) => {
			return {
				Id: sData.SellerId,
				Name: sData.SellerName,
				Email: sData.Email,
				Country: sData.Country,
				Phone: sData.Phone,
				Website: sData.Website,
				Address: sData.Address,
				Url: sData.SellerUrl,

			}
		});
		// const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.myChart.nativeElement);
		// const wb: XLSX.WorkBook = XLSX.utils.book_new();
		// XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

		// /* save to file */
		// XLSX.writeFile(wb,'Market_Place_'+this.breakdown.company+'Report.xlsx');

		const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelDataModified);
		// console.log('worksheet', worksheet);

		const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
		XLSX.writeFile(workbook, this.selectedMplaceName + '_' + 'seller.xlsx', { bookType: 'xlsx', type: 'buffer' });
	}

	//function works when export products button clicked
	exportProducts(type: string) {
		//Copy to variable to avoid original data mutation
		this.productData = [...this.productsArray]
		if (this.productData.length == 0) {
			return false;
		};
		//Format the data to avoid unwanted fields
		const productMapArray = [];
		this.productData.forEach(element => {
			element.ads.map((item, index) => {
				let prodObj = {
					productId: element.productupc,
					name: item.details.title,
					description: item.details.description,
					sellerName: item.seller.name,
					productPrice: item.details.price,
					productUrl: item.details.ad_url,
					productStatus: item.details.condition
				}
				productMapArray.push(prodObj);
			})

		});

		var keyMap;
		keyMap = {
			productId: 'ProductId',
			name: 'ProductName',
			description: 'Description',
			sellerName: 'sellerName',
			productPrice: 'productPrice',
			productUrl: 'productUrl',
			productStatus: 'productStatus'
		};
		//Product ID, Name, Description, Seller Name, Product Price, Product URL, Product Status
		var excelData = productMapArray.map((obj) => {
			return _.mapKeys(obj, (value, key) => {
				return keyMap[key];
			});
		});
		const excelDataModified = excelData.map((sData, i) => {
			return {
				ProductID: sData.ProductId,
				Name: sData.ProductName,
				Description: sData.description,
				SellerName: sData.sellerName,
				ProductPrice: sData.productPrice,
				ProductUrl: sData.productUrl,
				ProductStatus: sData.productStatus
			}
		});
		// const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.myChart.nativeElement);
		// const wb: XLSX.WorkBook = XLSX.utils.book_new();
		// XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

		// /* save to file */
		// XLSX.writeFile(wb,'Market_Place_'+this.breakdown.company+'Report.xlsx');
		if (type == 'excel') {
			const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelDataModified);
			console.log('worksheet', worksheet);

			const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
			XLSX.writeFile(workbook, this.selectedMplaceName + '_' + 'products.xlsx', { bookType: 'xlsx', type: 'buffer' });
		}
	}

	// to scroll marketplaces on the top when '>' clicks
	slideBrand(position: any, type: any) {
		//alert(type);
		//   const conent = document.querySelector('link_brndListCoverMain');
		const abc = document.getElementById('link_brndListCoverMain');
		//   console.log('conent', conent);
		if (position == 'right') abc.scrollLeft += 100;;
		if (position == 'left') abc.scrollLeft -= 100;;
		event.preventDefault();
		//alert(position);
	}

	// to scroll marketplaces on the top when '>' clicks
	slideBrands(position: any, type: any) {
		//alert(type);
		//   const conent = document.querySelector('link_brndListCoverMain');
		const abc = document.getElementById('link_brndListCoverMains');
		//   console.log('conent', conent);
		if (position == 'right') abc.scrollLeft += 100;;
		if (position == 'left') abc.scrollLeft -= 100;;
		event.preventDefault();
		//alert(position);
	}

	//function works when enter pressed on search box
	keyDownFunction(event) {
		if (event.keyCode === 13) {
			this.apiCallCount = 0;
			this.offSet = 0;
			this.pager["offset"] = 0;
			this.getProductSeller(1, this.selectedMplace);
		}
	}
	//function works when search icon is clicked
	onSearch() {
		this.apiCallCount = 0;
		this.offSet = 0;
		this.pager["offset"] = 0;
		this.request_params.orderby = "",
			this.request_params.order = "",
			this.getProductSeller(1, this.selectedMplace);
	}

	// on changing entries count per page
	entryNoChange(value: number) {
		this.apiCallCount = 0;
		this.offSet = 0;
		this.pager["limit"] = value;
		this.pager["total_count"] = 0;
		this.pager["current_page"] = 1;
		this.pager["offset"] = 0;
		this.getProductSeller(1, this.selectedMplace);
		this.itemPerPageCount = value;
	}

	// to sort table column based on seller details
	onSellerSort(orderby: string, id: any, index) {
		console.log('this.productsArray[index].ads', this.productsArray[index].ads);

		this.sortOrder = !this.sortOrder;
		if (this.sortOrder) {
			if (orderby == 'name') {
				this.productsArray[index].ads = this.productsArray[index].ads.sort(function (a, b) {
					if (a.seller.name < b.seller.name) { return -1; }
					if (a.seller.name > b.seller.name) { return 1; } return 0;
				})
			}
			if (orderby == 'country') {
				this.productsArray[index].ads = this.productsArray[index].ads.sort(function (a, b) {
					console.log('ajui', a);

					if (a.seller.country < b.seller.country) { return -1; }
					if (a.seller.country > b.seller.country) { return 1; } return 0;
				})
			}
			if (orderby == 'condition') {
				this.productsArray[index].ads = this.productsArray[index].ads.sort(function (a, b) {
					console.log('ajui', a);

					if (a.details.condition < b.details.condition) { return -1; }
					if (a.details.condition > b.details.condition) { return 1; } return 0;
				})
			}
			if (orderby == 'price') {
				this.productsArray[index].ads = this.productsArray[index].ads.sort(function (a, b) {
					console.log('ajui', a);

					if (a.details.price < b.details.price) { return -1; }
					if (a.details.price > b.details.price) { return 1; } return 0;
				})
			}
			if (orderby == 'status') {
				this.productsArray[index].ads = this.productsArray[index].ads.sort(function (a, b) {
					console.log('ajui', a);

					if (a.status < b.status) { return -1; }
					if (a.status > b.status) { return 1; } return 0;
				})
			}
		} else {
			if (orderby == 'name') {
				this.productsArray[index].ads = this.productsArray[index].ads.sort(function (a, b) {
					if (a.seller.name > b.seller.name) { return -1; }
					if (b.seller.name > a.seller.name) { return 1; } return 0;
				})
			}
			if (orderby == 'country') {
				this.productsArray[index].ads = this.productsArray[index].ads.sort(function (a, b) {
					console.log('ajui', a);

					if (a.seller.country > b.seller.country) { return -1; }
					if (b.seller.country > a.seller.country) { return 1; } return 0;
				})
			}
			if (orderby == 'condition') {
				this.productsArray[index].ads = this.productsArray[index].ads.sort(function (a, b) {
					console.log('ajui', a);

					if (a.details.condition > b.details.condition) { return -1; }
					if (b.details.condition > a.details.condition) { return 1; } return 0;
				})
			}
			if (orderby == 'price') {
				this.productsArray[index].ads = this.productsArray[index].ads.sort(function (a, b) {
					console.log('ajui', a);

					if (a.details.price > b.details.price) { return -1; }
					if (b.details.price > a.details.price) { return 1; } return 0;
				})
			}
			if (orderby == 'status') {
				this.productsArray[index].ads = this.productsArray[index].ads.sort(function (a, b) {
					console.log('ajui', a);

					if (a.status > b.status) { return -1; }
					if (b.status > a.status) { return 1; } return 0;
				})
			}
		}
		console.log('this.productsArray8999', this.productsArray);
		console.log('this.productsArray8999', ProductListComponent.products);



		// this.productsArray[index].ads = this.productService.sortProductList(this.productsArray[index].ads, orderby, this.sortOrder);
	}

	handleImageInput(file: FileList) {
		this.fileToUpload = file.item(0);
		this.imageAdded = true;

		//Show image preview
		let reader = new FileReader();
		reader.onload = (event: any) => {
			console.log('event.target', event.target);

			this.imageUrl = event.target.result;
			console.log('this.selectedFile.src', this.imageUrl);
			// this.sellerdata['logo'] = this.imageData;
		}
		console.log('sellle', this.sellerdata);


	}

	//function works when search icon for seller is clicked
	onSellerSearch(ad, index) {
		console.log('ic', ad, index);
		this.filteredSeller = _.cloneDeep(this.product[index]);
		console.log('this.filteredSeller', this.filteredSeller);
		var searchKey = ad.searchkey;
		this.productsArray[index] = this.filteredSeller;
		this.productsArray[index].searchkey = ad.searchkey;
		console.log('o[k]', this.productsArray[index],)
		if (searchKey == '') {
			console.log('null search key');

			this.productsArray[index] = this.filteredSeller;
			this.product[index] = _.cloneDeep(this.productsArray[index]);
		} else {
			if (ad.ads.length > 0) {
				console.log('uyuy67');

				this.productsArray[index].ads = this.productsArray[index].ads.filter(item => {
					// console.log('itemitemitem', item);

					// item.details.status = item.status;

					for (const [key, value] of Object.entries(item)) {
						console.log('key67', item);
						if (key == 'status') {
							console.log('value1', value, ad.searchkey);
							if (value.toString().toLowerCase().includes(ad.searchkey.toLowerCase())) {
								console.log('item here', item);
								return item
							}
						}
						if (item[key] !== null && item[key] != "") {

							for (const [key1, value1] of Object.entries(value)) {
								console.log('valuesderg', value1);
								if (key1 == 'email' || key1 == 'name' || key1 == 'condition' || key1 == 'price') {
									// console.log('value1', key1, value1);
									if (value1.toLowerCase().includes(ad.searchkey.toLowerCase())) {
										// console.log('item', item);
										return item
									}
								}
							}
						}

					}
				});


			}
			this.no_data = "No data"
		}


		// var abc = this.productsArray[index].filter(x => x.type.toLowerCase().includes('pizza'))
		// if (this.productsArray[index].sellers.length == 0) {
		// 	this.no_data = 'No Data'

		// }

		// }
		// function filterByValue(array, string) {
		// 	return  array.filter(o =>
		// 		Object.keys(o).some(k => o[k].toString().toLowerCase().includes(strings.toLowerCase())));
		// }

		// const arrayOfObject = [{ name: 'Paul', country: 'Canada', }, { name: 'Lea', country: 'Italy', }, { name: 'John', country: 'Italy' }];

		// console.log(filterByValue(this.productsArray[0].sellers, 'lea')); // [{name: 'Lea', country: 'Italy'}]
		console.log('ag67777', this.productsArray);
		// this.productsArray
		// this.productsArray.forEach(element => {
		// 	if (element.id == id) {
		// 		const filtered = element.sellers.filter(function (str) {
		// 			return str.includes(string);
		// 		});
		// 		// element.sellers.filter((seller) =>
		// 		// Object.keys(seller).some(k =>
		// 		// console.log('seller[k]', seller[k])))

		// 		// seller[k].toString().toLowerCase().includes(string.toLowerCase())))
		// 		// console.log('56',seller[k].toString().toLowerCase().includes(string.toLowerCase()))))
		// 	}



		// });

		console.log('drfg', this.productsArray);

		// this.getSeller(1, index);
	}

	onSearchChange(event, i) {
		console.log('event', i, event, this.productsArray);
		this.seller_request_params.searchkey = this.productsArray[i].searchKey;
	}

	//function works when enter pressed on seller's search box
	keyDownFunctionSeller(event, ad, i) {
		if (event.keyCode === 13) {
			this.apiCallCount = 0;
			this.offSet = 0;
			this.pager["offset"] = 0;
			this.onSellerSearch(ad, i);
		}
	}

	// function works when page number changes
	onPageChange(page: number) {
		this.pager["current_page"] = page;
		this.pager["offset"] = this.itemPerPageCount * (page - 1);
	}

	//to view screenshot image
	screenShot(ad) {
		this.selectedSeller = ad;
		$('#screenshotModal').modal('show');
	}

	// to sort product table column
	onSort(orderby: string, id: any) {
		if (this.request_params["orderby"] === orderby) {
			if (this.request_params["order"] === "asc") {
				this.request_params["order"] = "desc";
			} else {
				this.request_params["order"] = "asc";
			}
		} else {
			this.request_params["orderby"] = orderby;
			this.request_params["order"] = "desc"
		}
		//Reset the page to 1, offset to 0
		this.pager["current_page"] = 1;
		this.pager["offset"] = 0;
		this.productsArray = this.productService.sortProductsArray(this.productsArray, this.request_params["orderby"], this.request_params["order"]);
		console.log('this.productsArray8999', this.productsArray);
		console.log('this.productsArray8999', ProductListComponent.products);
		ProductListComponent.products.forEach(element1 => {
			console.log('here', element1, this.productsArray);
			var idx = this.productsArray.findIndex(x => x.productupc == element1.productupc);
			console.log('idx', idx);
			if (idx != -1) {
				console.log('this.productsArray[idx]888', this.productsArray[idx]);
				this.productsArray[idx].ads.forEach(ad => {
					element1.ads.forEach(el => {
						var index = element1.ads.findIndex(x => x.id == ad.id);
						console.log('index', index);

						if (index != -1) {
							console.log('po', el, ad);
							if (el.id == ad.id) {
								el.status = ad.status;
								el.seller = ad.seller;
							}


						}
					});
				});
				console.log('ProductListComponent.products0', ProductListComponent.products);

				this.productsArray[idx] = element1;
			} else {
				this.productsArray.push(element1)
			}
		});

		this.productsArray.forEach((element2, index) => {
			if (element2.pinSeller) {
				this.productsArray.splice(index, 1);
				this.productsArray.unshift(element2);
			}
		});
	}




	// $('.dropdown-menu.ddRange')
	// .click(function(e) {
	//   e.stopPropagation();
	// });

	fdisableDropDownRangeOptions(max_values, minValue) {
		if (max_values) {
			max_values.each(function () {
				var maxValue = $(this).attr("value");

				if (parseInt(maxValue) < parseInt(minValue)) {
					$(this).addClass('disabled');
				} else {
					$(this).removeClass('disabled');
				}
			});
		}
	}

	setuinvestRangeDropDownList(min_values, max_values, min_input, max_input, clearLink, dropDownControl) {
		min_values.click(function () {
			var minValue = $(this).attr('value');
			min_input.val(minValue);
			document.getElementById('price_range1').innerHTML = minValue;

			//   disableDropDownRangeOptions(max_values, minValue);

			validateDropDownInputs();
		});

		max_values.click(function () {
			var maxValue = $(this).attr('value');
			max_input.val(maxValue);
			document.getElementById('price_range2').innerHTML = maxValue;

			toggleDropDown();
		});

		clearLink.click(function () {
			min_input.val('');
			max_input.val('');

			//   disableDropDownRangeOptions(max_values);

			validateDropDownInputs();
		});

		min_input.on('input',
			function () {
				var minValue = min_input.val();

				// disableDropDownRangeOptions(max_values, minValue);
				validateDropDownInputs();
			});

		max_input.on('input', validateDropDownInputs);

		max_input.blur('input',
			function () {
				toggleDropDown();
			});

		function validateDropDownInputs() {
			var minValue = parseInt(min_input.val());
			var maxValue = parseInt(max_input.val());

			if (maxValue > 0 && minValue > 0 && maxValue < minValue) {
				min_input.addClass('inputError');
				max_input.addClass('inputError');

				return false;
			} else {
				min_input.removeClass('inputError');
				max_input.removeClass('inputError');

				return true;
			}
		}

		function toggleDropDown() {
			if (validateDropDownInputs() &&
				parseInt(min_input.val()) > 0 &&
				parseInt(max_input.val()) > 0) {

				// auto close if two values are valid
				dropDownControl.dropdown('toggle');
			}
		}
	}

	//   setuinvestRangeDropDownList(
	// 	// $('.investRange .min_value'),
	// 	$('.investRange .max_value'),
	// 	$('.investRange .freeformPrice .min_input'),
	// 	$('.investRange .freeformPrice .max_input'),
	// 	$('.investRange .btnClear'),
	// 	$('.investRange .dropdown-toggle'));

	// when price range option changed
	onItemChange(value) {
		console.log('selected ioption', this.selectedOption);
		var value = this.selectedOption;

		if (value == 'checked1') {
			this.selected_price_range = '> ' + this.price_range.min_value;
		}
		if (value == 'checked2') {
			this.selected_price_range = '< ' + this.price_range.max_value;
		}

		if (value == 'checked3') {
			this.selected_price_range = this.price_range.min_value + ' and ' + this.price_range.max_value;
		}
		if (value == 'checked4') {
			this.selected_price_range = this.price_range.max_value + ' * ' + this.price_range.min_value;
		}
		if (value == 'all') {
			this.selected_price_range = "";
			this.request_params.price_range = "";
			this.seller_request_params.price_range = "";
			this.price_range.price_options = "All"
		}

		if (this.selected_price_range != '' && this.selectedOption != "") {
			this.price_range.price_options = this.selected_price_range;
			this.request_params.price_range = this.price_range.price_options;
			this.seller_request_params.price_range = this.price_range.price_options;
		}

		$('#rangemenu').hide();
		// console.log('price_range.maxttt_value', this.selected_price_range);
	}

	priceChange() {
		this.selectedOption = "";
		console.log('value6', this.price_range);
		if (this.price_range.min_value > this.price_range.min_value) {
			this.message = ""

		}
	}



	submitPrice(value) {
		console.log('aftr submit', this.selectedOption);

		console.log('vdddalue');
		console.log('value', value);
		if (!value) {
			this.errMsg = true;
		}
		console.log('this.selected_price_range', this.selected_price_range);
		if (this.selected_price_range != '' && this.selectedOption != "") {
			this.price_range.price_options = this.selected_price_range;
			this.request_params.price_range = this.price_range.price_options;
			this.seller_request_params.price_range = this.price_range.price_options;
		}
		console.log('this.price_range', this.request_params, this.seller_request_params);

	}

	// change status of seller from other marketplace
	changeStatus(type) {
		console.log('juj', type, this.sellerData);
		if (this.sellerData.length == 0) {
			this.toastr.info('Please select the seller');
			return false;
		}
		var seller_ids = [];
		var market_ids = [];
		this.sellerData.forEach(element => {
			if (element.checked) {
				seller_ids.push(element.id);
				market_ids.push(element.marketplace)

			}
		});
		console.log('seller ids', seller_ids);
		if (seller_ids.length > 1) {
			var cnf = confirm("Are you sure you want to " + type + " these sellers?");
		} else {
			var cnf = confirm("Are you sure you want to " + type + " this seller");
		}

		if (cnf) {
			var data = {
				ids: seller_ids,
				market_ids: market_ids,
				status: type
			}
			console.log('datadata', data);
			this.productService.statusChange(data).subscribe((res: any) => {
				if (res.status == "success") {
					this.toastr.success('Status changed', 'SUCCESS');
					this.apiCallCount = 0;
					this.offSet = 0;
					console.log('tyweurighj', ProductListComponent.products);

					this.getProductSeller(1, this.selectedMplace);
				} else {
					if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
						this.authServiceObj.logout();
					}
					else {
						this.toastr.error(res.message, 'Failed');
					}
				}
			});
		}

	}

	//expanding individual products
	onExpand(product, index) {
		product.expanded = !product.expanded;
		console.log('this.poio', this.productsArray);
		this.product = _.cloneDeep(this.productsArray);

	}

	//To view seller info in pop up  dialoge box------------------------------------------------
	openSellerInfoModal(id: any, mp_id: any) {
		console.log('id2', id, mp_id);

		$('#sellerInfo').modal('show');
		this.sellerLogo = ''
		this.sellersLogo = ''
		this.dropDown = false
		this.selectedFile.src = '';
		this.field = "Add More"
		this.formArray = []
		this.loaderService.display(true);
		var ids = {
			id: id,
			mp_id: mp_id
		}
		this.sellerService.getSellerById(ids).subscribe((res: any) => {
			console.log('resres', res);

			this.loaderService.display(false);
			if (res.status == "success") {
				console.log(res, "part2seller");

				this.temp = res.data_found[0]
				console.log(this.temp.details, 'sellerlogo');
				if (!res.data_found[0].details.email || !res.data_found[0].details.phone) {
					this.noPointOfContact = true
				}
				if (res.data_found[0].details.logo || res.data_found[0].details.old_logo) {
					this.sellerLogo = res.data_found[0].details.logo || res.data_found[0].details.old_logo
					// this.source = URL.createObjectURL(res.data_found[0].sellerdetails.logo)
					// this.selectedFile.src =   this.source
				} else {
					this.sellersLogo = 'images/yourlogohere.jpg'
					// this.source = URL.createObjectURL('images/yourlogohere.jpg')
					// this.selectedFile.src =  this.source
				} if (!this.sellerLogo) {
					this.sellersLogo = "../../../../assets/images/yourlogohere.jpg"
					// this.source = URL.createObjectURL("../../../../assets/images/yourlogohere.jpg" )
					// this.selectedFile.src =  this.source
				}
				this.dynamictemp = _.cloneDeep(res.data_found[0].details);
				delete this.dynamictemp['country_id']
				delete this.dynamictemp['marketplace_id']
				delete this.dynamictemp['name']
				delete this.dynamictemp['country']
				delete this.dynamictemp['website']
				delete this.dynamictemp['phone']
				delete this.dynamictemp['email'],
					delete this.dynamictemp['logo'],
					delete this.dynamictemp['old_logo']
				delete this.dynamictemp['address']
				delete this.dynamictemp['notes']
				delete this.dynamictemp['id']
			} else {
				if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
					this.authServiceObj.logout();
				}
				else {
					this.toastr.error(res.message, 'Failed');
				}
			}
		})
	}

	// TO edit seller info in pop-up Dialoge box
	editSeller(seller_id: any, mp_id: any) {
		$('#sellerInfo').modal('hide');
		setTimeout(() => {
			$('#editSeller').modal('show');
		}, 10);
		var ids = {
			id: seller_id,
			mp_id: mp_id
		}
		if (seller_id) {
			this.loaderService.display(true);
			this.sellerService.getSellerById(ids).subscribe((res: any) => {
				this.loaderService.display(false);
				console.log(res, "resulterrrs");
				if (res.status == "success") {
					this.editDetails = {
						id: seller_id,
						name: res.data_found[0].details.name || null,
						phone: res.data_found[0].details.phone || null,
						email: res.data_found[0].details.email || null,
						address: res.data_found[0].details.address || null,
						website: res.data_found[0].details.website || null,
						country: res.data_found[0].details.country || null,
						notes: res.data_found[0].details.notes || null,
						old_logo: this.sellerLogo || this.sellersLogo,
						logo: ''
					}
					if ((res.data_found[0].details.email || res.data_found[0].details.phone) == null) {
						this.noPointOfContact = true
					}
				} else {
					if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
						this.authServiceObj.logout();
					}
					else {
						this.toastr.error(res.message, 'Failed');
					}
				}
			})
		}
		this.edited = Object.entries(this.editDetails).map(([key, value]) => ({ key, value }));
		this.extraDynamiData = Object.entries(this.dynamictemp).map(([key, value]) => ({ key, value }));
		const formArrayData = [...this.edited, ...this.formArray]
		const deepClonedOutPut = _.cloneDeep(formArrayData)
		this.editedOutput = [...deepClonedOutPut, ...this.extraDynamiData]

	}

	logoCropped(event) {
		this.sellerdata['logo'] = this.imageData.image;
		console.log(this.imageData, "image man")
	}

	loadFile(event: any) {
		this.cropedImage = false;
		this.source = URL.createObjectURL(event.target.files[0]);
		this.selectedFile.src = this.source;
		console.log('this.selectedFile', this.selectedFile);

	};


	// showDropDown() {
	// 	this.marketplaceService.getName().subscribe((res: any) => {
	// 		if (res.status == "success") {
	// 			this.scrapFields = res.data_found;

	// 			this.scrapFields.showFieldDropdown = true;
	// 			console.log(this.scrapFields, 'scrapfiels');

	// 			this.tempScrapField = _.cloneDeep(this.scrapFields)
	// 			this.filterOnBasisOfPageKey(this.scrapFields)
	// 		}
	// 	})

	// 	this.field = "Add More"
	// 	this.dropDown = true

	// }
	showDropDown() {
		this.marketplaceService.getName().subscribe((res: any) => {
			this.loaderService.display(false);
			if (res.status == "success") {
				this.scrapFields = res.data_found;
				this.scrapFields.showFieldDropdown = true;
				console.log(this.scrapFields, 'scrapfiels');
				this.tempScrapField = _.cloneDeep(this.scrapFields)
				this.filterOnBasisOfPageKey(this.scrapFields)
			} else {
				if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
					this.authServiceObj.logout();
				}
				else {
					this.toastr.error(res.message, 'Failed');
				}
			}
		})
		this.dropDown = true
	}

	//dropdown filtering
	filterOnBasisOfPageKey(array: any) {
		console.log('editedSellerData', this.sellerdata,);
		console.log('editedSellerData1', this.dynamicSellerInfo,);

		this.editedSellerData = Object.entries(this.sellerdata).map(([key, value]) => ({ key, value }));
		this.extraSellerData = Object.entries(this.dynamicSellerInfo).map(([key, value]) => ({ key, value }));
		const sellerInfoArray = [...this.editedSellerData, ...this.extraSellerData]
		const sellerInfoArray1 = _.cloneDeep(sellerInfoArray)
		this.editedSeller = [...sellerInfoArray1, ...this.extraSellerData]
		this.results = array.filter(({ name: id1 }) => !this.editedSeller.some(({ key: id2 }) => id2 === id1));
		console.log('this.resultsaswrfh ', this.results);

	}

	hideDropdown() {
		this.field = "Add Custom Input"
		this.dropDown = false
	}

	// onchange dropdown
	onChangeDropdown(name: any) {
		console.log('name', name);

		// this.filterOnBasisOfPageKey(this.scrapFields)
	}
	// adding additional input on seller edit form
	addInput() {
		let duplicate = this.editedOutput.filter((element) => {
			return element.key === this.inputName
		})
		if (duplicate.length == 0) {
			if (this.inputName) {
				if (this.customInputValue) {
					this.formArray.push({
						key: this.inputName,
						value: this.customInputValue
					})
					this.inputName = ''
					this.customInputValue = ''
					this.filterOnBasisOfPageKey(this.scrapFields)
				} else {
					this.toastr.error('Please enter some value')
				}
			} else {
				this.toastr.error('Please enter  field name')
			}
		} else {
			this.toastr.error('Field is already declared')
		}
	}

	// Update Seller Details onsubmitting form

	onSellerdataSubmit(valid: any) {
		var user = this.localStorage.retrieve('user_details');
		this.edited = Object.entries(this.editDetails).map(([key, value]) => ({ key, value }));
		this.extraDynamiData = Object.entries(this.dynamictemp).map(([key, value]) => ({ key, value }));
		const formArrayData = [...this.edited, ...this.formArray]
		const deepClonedOutPut = _.cloneDeep(formArrayData)
		this.editedOutput = [...deepClonedOutPut, ...this.extraDynamiData]
		const finalResult = this.editedOutput
		const data = {
			seller_edit: finalResult,
			user_id: user.id,
			marketplace_id: this.selectedMplace
		}
		console.log(data, "edit seller");
		if (valid) {
			this.loaderService.display(true);
			this.sellerService.addEditSellerInfo(data).subscribe((res: any) => {
				this.loaderService.display(false);
				if (res.status == "success") {
					this.getProductSeller(1, this.selectedMplace)
					this.toastr.success('Seller Info Edited Successfully!', 'SUCCESS');
					$('#editSeller').modal('hide');
					this.dropDown = false
					this.selectedFile.src = '';
					this.field = "Add More..."
					this.editDetails.old_logo = ''
					this.sellerLogo = ''
					this.sellersLogo = ''
					this.formArray = []
					this.cropedImage = true
				} else {
					if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
						this.authServiceObj.logout();
					}
					else {
						this.toastr.error(res.message, 'Failed');
					}
				}
			});
		}
	}
	// removing aditional input from seller edit form
	removeInput(index: any) {
		this.formArray.splice(index, 1)
		this.filterOnBasisOfPageKey(this.scrapFields)
		this.inputName = '';
	}

	// checking whether there is email||phone  in no ponit of contact checkbox
	onChangeContact(event: any) {
		// if (event == !empty) {
		// 	this.noPointOfContact = true;
		// } else if (event != empty) {
		// 	this.noPointOfContact = false;
		// }
	}

	// on change country
	onCountryChange(newValue) {
		this.sellerdata.country = newValue;
	}

	 // canceling seller pop-up dialoe box
	 cancelModel() {
		$('#sellerInfo').modal('hide');
	  }


}