
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { AppSettings } from '../../../settings.global';
import * as _ from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare var $: any;

@Injectable()
export class SchedulerService {
	public headers: HttpHeaders;

	constructor(private localStorage: LocalStorageService,
		private httpClient: HttpClient) {
			this.headers = new HttpHeaders({
				'Content-Type': 'application/json;charset=UTF-8',
				'Accept': 'application/json',
				'x-access-token': this.localStorage.retrieve("access_token")
			});
	}

	// Get the list of marketplaces
	getFrequencies() {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.get(AppSettings.API_ENDPOINT_V2 + '/settings/scheduler/frequency', options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}),catchError((error: any) => {
				return observableOf({ status: "error", message: "Server error occured" })
			}),);
	}

	//Create or update scheduler
	createUpdateScheduler(body: any) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		if (body.scheduler.id) {
			return this.httpClient.put(AppSettings.API_ENDPOINT_V2 + '/settings/scheduler', body, options).pipe(
				map(function (res: any) {
					return res.body || {
						status: "failed",
						message: "Something went wrong"
					};
				}));
		} else {
			return this.httpClient.post(AppSettings.API_ENDPOINT_V2 + '/settings/scheduler', body, options).pipe(
				map(function (res: any) {
					return res.body || {
						status: "failed",
						message: "Something went wrong"
					};
				}));
		}

	}

	// Get the list of marketplaces
	getSchedulerList(body: any) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		let bodyString = $.param(body);
		console.log('bodyString', bodyString);
		return this.httpClient.get(AppSettings.API_ENDPOINT_V2 + '/settings/scheduler?' + bodyString, options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}),catchError((error: any) => {
				return observableOf({ status: "error", message: "Server error occured" })
			}),);
	}

	// get scheduler detail by ID
	getSchedulerById(id: number) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.get(AppSettings.API_ENDPOINT_V2 + '/settings/scheduler/edit/' + id, options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));

	}

	//Status change
	statusChange(data:any){
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.post(AppSettings.API_ENDPOINT_V2 +'/settings/scheduler/status',data,options).pipe(
		map(function(res:any){
			return res.body || {status: "failed", message: "Something went wrong"};
		}));
	}

	delete(id:any){
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.delete(AppSettings.API_ENDPOINT_V2 +'/settings/scheduler?id='+id,options).pipe(
		map(function(res:any){
			return res.body || {status: "failed", message: "Something went wrong"};
		}));
	}

	sortSchedulerList(schedulerData, orderBy, order) {
		return _.orderBy(schedulerData, [orderBy], [order]);
	 }
}
