
import {fromEvent as observableFromEvent} from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs/';
declare var $: any;
import * as _ from 'lodash'
import { AppSettings } from '../../../settings.global';
import { LoaderService } from '../../../services/loader/loader.service';
import { SchedulerService } from '../../../services/settings/scheduler/scheduler.service';
import { AuthService } from '../../../services/auth/auth.service';
import * as moment from 'moment';
import { LocalStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-scheduler-list',
  templateUrl: './scheduler-list.component.html',
  styleUrls: ['./scheduler-list.component.css']
})
export class SchedulerListComponent implements OnInit {
  public searchInputObservable$: any;
  public request_params: any;
  public queryLimit: number = AppSettings.DEFAULT_QUERY_LIMIT;
  public offSet: number = 0;
  public apiCallCount: number = 0;
  public scheduler: any;
  public schedulerList: any;
  public no_of_entry: number;
  public pager: any;
  public no_data: any;
  public itemPerPageCount: number = AppSettings.DEFAULT_PAGER_COUNT;
  public difftime: number;
  public scheduler_id: any;
  public selectAllFlag: boolean;
  public checkBox: any;
  schedulerArray: any[];
  daterange: any = {
    start: moment().startOf('year').utc().format(),
    end: moment.utc().format()
  };
  private placeholder: string = 'Select a date';

  constructor(private route: Router,
    private loaderService: LoaderService,
    private schedulerService: SchedulerService,
    private authServiceObj: AuthService,
    private toastr: ToastrService,
    private localStorage: LocalStorageService
  ) {

    this.checkBox = [];
    this.request_params = {
      searchkey: "",
      orderby: "",
      order: "",
      limit: this.queryLimit,
      offset: 0
    };
    this.no_of_entry = 10;
    this.scheduler_id = [];
    this.schedulerList = [];
    this.selectAllFlag = false;
    this.pager = {
      limit: this.itemPerPageCount,
      offset: 0,
      total_count: 0,
      current_page: 1
    };

  }
  ngOnInit(): void {
    // throw new Error('Method not implemented.  ');
  }

  ngAfterContentInit() {
    // Input search observable - to avoid the  onInputChange() firing whenever the input changes
    const searchInputObj = document.querySelector('#search_text');
    this.searchInputObservable$ = observableFromEvent(searchInputObj, 'keyup');

    // this.searchInputObservable$.debounceTime(100)
    //   .subscribe((event: any) => {
    //     this.onInputChange(event.target.value);
    //   });

    $("body").height("100%");
    $("html").height("100%");
    this.getSchedulerList(1);
  }

  // redirect to add scheduler page when click Add New button
  addScheduler() {
    this.route.navigate(['scheduler/add']);
  }

  //function to get list of scheduler
  getSchedulerList(page) {
    this.request_params['offset'] = this.offSet;
    this.loaderService.display(true);
    this.schedulerService.getSchedulerList(this.request_params).subscribe((res: any) => {
      console.log('res', res);

      this.loaderService.display(false);
      this.apiCallCount++;
      if (res.status == 'success') {
        this.schedulerList = res.market_result;
        if (this.schedulerList == null) {
          this.schedulerList = [];
          this.no_data = 'No Data';
        } else {
          this.pager["total_count"] = res.market_result.length;
          this.pager["current_page"] = page;
          this.schedulerList.map(scheduler => {
            var end_date = moment(scheduler.end_time);
            var today = new Date();
            var date_today = moment(today);
            this.difftime = end_date.diff(date_today, 'days');
            console.log('this.difftime', this.difftime);

            if ((end_date > date_today) && (this.difftime <= 30 && this.difftime >= 0)) {
              scheduler['dateAlert'] = true;
            } else {
              scheduler['dateAlert'] = false;
            }
          });
        }
        console.log('this.marketplaces', this.schedulerList);



        //     //Load full result
        // if (!_.isNil(res.max_possible_res_length) && res.max_possible_res_length > this.schedulerList.length) {
        //   this.offSet = this.apiCallCount * this.queryLimit;
        //   this.getSchedulerList(1);
        // }
      } else {
        console.log('here', this.schedulerList);

        if (this.schedulerList.length === 0 || res.max_possible_res_length === 0 || this.schedulerList == null) {
          this.schedulerList = [];
          this.no_data = 'No Data';
          this.pager["total_count"] = 0;
          this.pager["current_page"] = page;
        }
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          this.toastr.error("Kindly login", "Session Expired");
          this.authServiceObj.logout();
        }
      }
    });
  }

  // function works when search button clicked
  onSearch() {
    this.apiCallCount = 0;
    this.offSet = 0;
    this.pager["offset"] = 0;
    this.getSchedulerList(1);
  }

  // To change status of scheduler

  changeSchedulerStatus(id, value, status) {
    console.log('yuo' ,id, status, status);
    var user = this.localStorage.retrieve('user_details');
    if (id) {
      this.scheduler_id = [];
      var selectedScheduler = this.schedulerList.filter(scheduler =>
        scheduler.id == id);
      this.scheduler_id.push(id);
      console.log('selectedScheduler', selectedScheduler);
    }
    if (this.scheduler_id.length == 0) {
      this.toastr.info('Please select the scheduler to change status.');
      return false;
    }
    if (this.scheduler_id.length > 1) {
      var cnf = confirm("Are you sure you want to " + value + " these schedulers?");
    } else if (this.scheduler_id.length == 1) {
      var selectedScheduler = this.schedulerList.filter(scheduler =>
        scheduler.id == this.scheduler_id[0]);
      var date1 = new Date(selectedScheduler[0].end_time);
      var date2 = new Date();
      date2.setUTCHours(0, 0, 0, 0);
      if (date1.getTime() < date2.getTime() && status) {
        alert('Update the End Date to Activate the Scheduler.');
      } else {
        var cnf = confirm("Are you sure you want to " + value + " this scheduler?");
      }
    }

    if (cnf) {
      var data = {
        scheduler: this.scheduler_id,
        status: status,
        user_id: user.id
      }
      console.log('dat3', data);

      this.schedulerService.statusChange(data).subscribe((res: any) => {
        if (res.status == "success") {
          this.toastr.success(value + 'd', 'SUCCESS');
          this.apiCallCount = 0;
          this.offSet = 0;
          this.checkBox = [];
          this.scheduler_id = [];
          this.selectAllFlag = false;
          this.getSchedulerList(1);
        } else {
          if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
            this.authServiceObj.logout();
          }
          else {
            this.toastr.error(res.message, 'Failed');
          }
        }
      });
    } else {
      this.checkBox = [];
      this.scheduler_id = [];
      this.selectAllFlag = false;
    }

  }

  //To make all check box true or false
  selectAll() {
    this.scheduler_id = [];
    if (!this.selectAllFlag) {
      for (let e of this.schedulerList) {
        this.scheduler_id.push(e.id)
        this.checkBox[e.id] = true;
      }
      this.selectAllFlag = true;
      // this.selectFalg = true;
    } else {
      for (let e of this.schedulerList) {
        this.checkBox[e.id] = false;
      }
      this.scheduler_id = [];
      this.selectAllFlag = false;
      // this.selectFalg = false;
    }
    console.log('marketplace_id', this.scheduler_id);
  }

  //To manage check box selection
  selection(id) {
    this.schedulerArray = []
    var index = this.scheduler_id.indexOf(id);
    if (index > -1) {
      this.scheduler_id.splice(index, 1)
    }
    // is newly selected
    else if (index < 0) {
      this.scheduler_id.push(id)
    }
    //To make select all check box true or false
    for (let e of this.schedulerList) {
      this.schedulerArray.push(e.id)
    }
    if (this.schedulerArray.length != this.scheduler_id.length) {
      this.selectAllFlag = false;
    } else {
      this.selectAllFlag = true;
    }
    console.log(this.scheduler_id)
  }

  //delete selected Currency
  deleteScheduler(scheduler: any) {
    console.log('scheduler', scheduler);
    if (scheduler.status) {
      console.log('active');
      var cnf = confirm("Data scrapping is in progress. Are you sure you want to delete this schedule?")
    } else {
      var cnf = confirm("Are you sure you want to delete this Scheduler?")
    }

    if (cnf) {
      this.schedulerService.delete(scheduler.id).subscribe((res: any) => {
        if (res.status == 'success') {
          this.toastr.success('Scheduler Deleted Successfully!', 'SUCCESS');
          this.apiCallCount = 0;
          this.offSet = 0;
          this.getSchedulerList(1);
        } else {
          if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
            this.toastr.error("Kindly login", "Session Expired");
            this.authServiceObj.logout();
          } else {
            this.toastr.error(res.message, 'Failed');
          }
        }
      })
    }
  }

  // to sort table column
  onSort(orderby: string, id: any) {
    if (this.request_params["orderby"] === orderby) {
      if (this.request_params["order"] === "asc") {
        this.request_params["order"] = "desc";
      } else {
        this.request_params["order"] = "asc";
      }
    } else {
      this.request_params["orderby"] = orderby;
      this.request_params["order"] = "desc"
    }
    //Reset the page to 1, offset to 0
    this.pager["current_page"] = 1;
    this.pager["offset"] = 0;
    this.schedulerList = this.schedulerService.sortSchedulerList(this.schedulerList, this.request_params["orderby"], this.request_params["order"]);
    console.log('check null', this.schedulerList);
  }

  // function works when page number changes
  onPageChange(page: number) {
    this.pager["current_page"] = page;
    this.pager["offset"] = this.itemPerPageCount * (page - 1);
  }

  //function works when enter pressed on search bar
  keyDownFunction(event) {
    console.log('here event');
    console.log('here event', event);
    if (event.keyCode === 13) {
      this.apiCallCount = 0;
      this.offSet = 0;
      this.pager["offset"] = 0;
      this.getSchedulerList(1);
    }
  }

  //function works when a date range is submitted.
  selectedDate(value: any) {
    console.log('moment(value.start._d)', value);
    // this.daterange.start = moment(value.start._d).toISOString();
    // this.daterange.end = moment(value.end._d).utc().format();
    console.log('this.daterange', this.daterange);
    // var start_date = new Date(this.daterange.start);
    // var end_date = new Date(this.daterange.end);
    // this.request_params.start_date = moment.utc(start_date).format()
    // this.request_params.end_date = moment.utc(end_date).format();
    console.log('this.request_params', this.request_params);
  }

  entryNoChange(value: number) {
    this.apiCallCount = 0;
    this.offSet = 0;
    this.pager["limit"] = value;
    this.pager["total_count"] = 0;
    this.pager["current_page"] = 1;
    this.pager["offset"] = 0;
    this.getSchedulerList(1);
    this.itemPerPageCount = value;
  }

}
