import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-duplicate-modal',
  templateUrl: './duplicate-modal.component.html',
  styleUrls: ['./duplicate-modal.component.css']
})
export class DuplicateModalComponent implements OnInit {

  sellerName = false;
  phone = false;
  email = false;
  address = false;

  public onClose: Subject<any> = new Subject();


  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() { 
    this.sellerName = this.sellerName !== undefined ? this.sellerName : false;
    this.phone = this.phone !== undefined ? this.phone : false;
    this.email = this.email !== undefined ? this.email : false;
    this.address = this.address !== undefined ? this.address : false;
  }

  applyFilters() {
    const filters = {
      seller_name: this.sellerName,
      phone: this.phone,
      email: this.email,
      address: this.address
    };
    this.onClose.next(filters);
    this.bsModalRef.hide();
  }

  clearFilters() {
    this.sellerName = false;
    this.phone = false;
    this.email = false;
    this.address = false;
  
    const filters = {
      seller_name: false,
      phone: false,
      email: false,
      address: false
    };
  
    this.onClose.next(filters);
    this.bsModalRef.hide();
  }

  onCheckboxChange(event: Event, filterName: string) {
    const isChecked = (event.target as HTMLInputElement).checked;
    switch(filterName) {
      case 'seller_name':
        this.sellerName = isChecked;
        break;
      case 'phone':
        this.phone = isChecked;
        break;
      case 'email':
        this.email = isChecked;
        break;
      case 'address':
        this.address = isChecked;
        break;
    }
  }


}
