
import { fromEvent as observableFromEvent } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ClientService } from '../../services/client/client.service';
import { BrandService } from '../../services/brand/brand.service';
declare var $: any;
import * as _ from 'lodash';
import { Observable } from 'rxjs/';
import { LoaderService } from '../../services/loader/loader.service';
import { NgxPaginationModule } from 'ngx-pagination';//importing ng2-pagination
import { AuthService } from '../../services/auth/auth.service';
import { AppSettings } from '../../settings.global';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { debounceTime } from 'rxjs/operators';


@Component({
	selector: 'app-client-data',
	templateUrl: './client-data.component.html',
	styleUrls: ['./client-data.component.css']
})
export class ClientDataComponent implements OnInit {
	public request_params: any;
	public pager: any;
	public queryLimit: number = AppSettings.DEFAULT_QUERY_LIMIT;
	public itemPerPageCount: number = AppSettings.DEFAULT_PAGER_COUNT;
	public apiCallCount: number = 0;
	public offSet: number = 0;
	public brands: any;
	public clients: any;
	public client: any;
	public no_data: any;
	public searchInputObservable$: any;
	public modal_title: any;
	public submited: boolean;
	public fileErrorFlag: boolean;
	public fileToImport: any;
	public import_data: any;
	public file_base: any = AppSettings.FILE_BASE_URL;
	public img_url = AppSettings.IMAGE_BASE_URL;
	public screenshot_url = AppSettings.SCREENSHOT_BASE_URL;
	public success_file: any;
	public error_file: any;
	public error_log: any;
	public success_import_flag: boolean;
	public success_row_count: number;
	public error_row_count: number;
	public import_fail_flag: boolean;
	public error_from_server: any;
	public hide_tag: boolean;
	public deleted_row_count: number;
	socialmediaList = [];
	marketplaceList = [];
	socialmediaItems = [];
	marketplaceItems = [];
	dropdownSettings = {};
	showMarketplace = false;
	showSocialmedia = false;
	showMCommerce = false;
	showECommerce = false;
	mCommerceList = [];
	eCommerceList = [];
	mCommerceItems = [];
	eCommerceItems = [];
	//Screenshoting
	daterange: any = {
		start: null,
		end: null
	};
	public image_base:any=AppSettings.IMAGE_BASE_URL;
	public screenshot: {
		brand: any,
		fromDate: string,
		toDate: string,
		social_media: boolean,
		market_place: boolean,
		standalone: boolean,
		m_commerce: boolean,
		e_commerce: boolean,
		requested_date: string
	}
	public downloadScreenshotData: {
		brand: any,
		fromDate: string,
		toDate: string,
		social_media: boolean,
		market_place: boolean,
		standalone: boolean,
		marketplaceItems: any,
		socialmediaItems: any,
		mCommerceItems: any,
		eCommerceItems: any,
		m_commerce: boolean,
		e_commerce: boolean

	}
	public datepickerOptions: any = {
		//startDate: moment().startOf('year'),
		//endDate: moment(),
		minDate: '01/01/2000',
		maxDate: moment(),
		showDropdowns: !0,
		showWeekNumbers: !0,
		timePicker: !1,
		timePickerIncrement: 1,
		timePicker12Hour: !0,
		alwaysShowCalendars: false,
		ranges: {
			'Last 30 Days': [moment().subtract(29, 'days'), moment()],
			'This Quarter': [moment().quarter(moment().quarter()).startOf('quarter'), moment()],
			'Since January 1st': [moment().startOf('year'), moment()]
		},
		opens: 'left',
		buttonClasses: ['btn btn-default'],
		applyClass: 'btn-small btn-primary',
		cancelClass: 'btn-small',
		format: 'MM/DD/YYYY',
		separator: ' to ',
		locale: {
			applyLabel: 'Submit',
			cancelLabel: 'Clear',
			fromLabel: 'From',
			toLabel: 'To',
			customRangeLabel: 'Time Range',
			daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
			// tslint:disable-next-line:max-line-length
			monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
			firstDay: 1
		}
	};
	public reportSectionError: boolean;
	isdownload: boolean;


	constructor(private brandService: BrandService, private clientService: ClientService, private toastr: ToastrService,
		private loaderService: LoaderService, private authServiceObj: AuthService) {
		this.no_data = "Loading...."
		this.submited = false;
		this.success_import_flag = false;
		this.import_fail_flag = false;
		this.hide_tag = true;
		this.error_from_server = "View Details";
		this.fileToImport = '';
		this.request_params = {
			searchkey: "",
			orderby: "",
			order: "",
			limit: this.queryLimit,
			offset: 0
		}
		this.pager = {
			limit: this.itemPerPageCount,
			offset: 0,
			total_count: 0,
			current_page: 1
		}
		this.brands = [];
		this.modal_title = '';
		this.import_data = {
			import_to: "market_place",
			brand: null,
			client: null
		}
		this.success_file = '';
		this.error_file = '';
		this.error_log = '';
		this.fileErrorFlag = false;
		this.success_row_count = 0;
		this.error_row_count = 0;
		this.deleted_row_count = 0;
		this.screenshot = {
			brand: '',
			fromDate: this.daterange.start,
			toDate: this.daterange.end,
			social_media: false,
			market_place: false,
			m_commerce: false,
			e_commerce: false,
			standalone: false,
			requested_date: moment().format('YYYY-MM-DD')
		}
		this.downloadScreenshotData = {
			brand: '',
			fromDate: this.daterange.start,
			toDate: this.daterange.end,
			social_media: false,
			market_place: false,
			standalone: false,
			marketplaceItems: [],
			socialmediaItems: [],
			mCommerceItems: [],
			eCommerceItems: [],
			m_commerce: false,
			e_commerce: false
		}
		this.reportSectionError = false;
	}

	ngAfterContentInit() {
		this.dropdownSettings = {
			singleSelection: false,
			idField: 'id',
			textField: 'name',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 1,
			allowSearchFilter: true
		};

		//Input search observable - to avoid the  onInputChange() firing whenever the input changes
		const searchInputObj = document.querySelector('#search_text');
		this.searchInputObservable$ = observableFromEvent(searchInputObj, 'keyup');

		this.searchInputObservable$.pipe(debounceTime(100))
			.subscribe((event: any) => {
				this.onInputChange(event.target.value);
			});
		$("body").height("100%");
		$("html").height("100%");

		/*
			* API call and grab full data
			*/
		setTimeout(() => {
			this.getClient(1);
		}, 10);
	}
	selectedDate(value: any) {
		this.daterange.start = moment(value.start._d).format('YYYY-MM-DD');
		this.daterange.end = moment(value.end._d).format('YYYY-MM-DD');
		this.screenshot.fromDate = moment(value.start._d).format('YYYY-MM-DD');
		this.screenshot.toDate = moment(value.end._d).format('YYYY-MM-DD');
		this.downloadScreenshotData.fromDate = moment(value.start._d).format('YYYY-MM-DD');
		this.downloadScreenshotData.toDate = moment(value.end._d).format('YYYY-MM-DD');

	}

	//get the client 
	getClient(page) {
		this.clientService.getClientSelect().subscribe((res: any) => {
			if (res.status == 'success') {
				this.clients = res.data_found;
				this.client = this.clients[0].id;
				this.getBrands(this.client, page);
			}
			else {
				if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
					//   this.toastr.error('Invalid User','Request Failed');
					this.authServiceObj.logout();
				}
			}
		})
	}

	onInputChange(text) {
		this.onSearch();
	}

	onChange(newValue) {
		this.client = newValue;
		this.brands = [];
		this.apiCallCount = 0;
		this.offSet = 0;
		this.pager["offset"] = 0;
		this.getBrands(newValue, 1);
	}

	onPageChange(page: number) {
		this.pager["current_page"] = page;
		this.pager["offset"] = this.itemPerPageCount * (page - 1);
	}

	onSearch() {
		this.brands = [];
		this.apiCallCount = 0;
		this.offSet = 0;
		this.pager["offset"] = 0;
		this.getBrands(this.client, 1);
	}

	getBrands(client_id, page) {
		if (this.offSet != 0 && this.request_params['searchkey'] != '') {
			this.brands = [];
			this.apiCallCount = 0;
			this.offSet = 0;
		}
		this.request_params['offset'] = this.offSet;
		this.request_params['client'] = client_id;
		this.loaderService.display(true);
		this.brandService.getBrands(this.request_params).subscribe((res: any) => {
			this.loaderService.display(false);
			this.apiCallCount++;
			if (res.status == 'success' && !_.isNull(res.brand_result)) {
				this.pager["total_count"] = this.brands.length + res.brand_result.length;
				this.pager["current_page"] = page;
				const result = this.brands.concat(res.brand_result);
				this.brands = res.brand_result;
				//Load full result
				if (!_.isNil(res.max_possible_res_length) && res.max_possible_res_length > this.brands.length) {
					this.offSet = this.apiCallCount * this.queryLimit;
					//this.getBrands(this.client,1);
					// this.getCountry(1);
				}
			} else {
				if (this.brands.length === 0 || res.max_possible_res_length === 0) {
					this.brands = [];
					this.no_data = 'No Data';
					this.pager["total_count"] = 0;
					this.pager["current_page"] = page;
				}
				if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
					this.toastr.error("Kindly login", "Session Expired");
					this.authServiceObj.logout();
				}
			}
		})

	}

	onSort(orderby: string, id: any) {
		if (this.request_params["orderby"] === orderby) {
			if (this.request_params["order"] === "asc") {
				this.request_params["order"] = "desc";
			} else {
				this.request_params["order"] = "asc";
			}
		} else {
			this.request_params["orderby"] = orderby;
			this.request_params["order"] = "desc"
		}
		//Reset the page to 1, offset to 0
		this.pager["current_page"] = 1;
		this.pager["offset"] = 0;
		this.brands = this.brandService.sortBrandList(this.brands, this.request_params["orderby"], this.request_params["order"]);
	}

	import() {
		this.modal_title = 'Import Data';
		this.isdownload = false;
		this.import_data.client = this.client;
		this.success_file = '';
		this.error_file = '';
		this.error_log = '';
		this.fileToImport = '';
		this.fileErrorFlag = false;
		this.success_import_flag = false;
		this.submited = false;
		this.success_row_count = 0;
		this.error_row_count = 0;
		this.deleted_row_count = 0;
		this.import_fail_flag = false;
		this.hide_tag = true;
		this.error_from_server = '';
		$("#import_file").replaceWith($("#import_file").val(''));
		$('#changePassword').modal('show');
	}
	getFile(event) {
		this.fileErrorFlag = true;
		if (event.target.files && event.target.files[0]) {
			var reader = new FileReader();
			this.fileToImport = event.target.files[0];

			//reader.readAsDataURL(event.target.files[0]);
		}
	}
	onSubmit(valid) {
		this.submited = true;
		console.log(this.fileErrorFlag)
		if (valid && this.fileErrorFlag) {
			this.loaderService.display(true);
			this.brandService.importData(this.import_data, this.fileToImport).then((res) => {
				this.loaderService.display(false);
				if (res.status == "success") {
					this.fileErrorFlag = true;
					this.success_import_flag = true;
					console.log(res.successRecords)
					if (res.successRecords) {
						this.success_file = res.successRecords;
						this.success_row_count = res.successRowCount;
					}
					if (res.errorRecords) {
						this.error_file = res.errorRecords;
						this.error_row_count = res.errorRowCount;
					}
					if (res.deletedRowCount) {
						this.deleted_row_count = res.deletedRowCount;
					}
					if (res.errorlog) {
						this.error_log = res.errorlog;
					}
					console.log("success");
				} else {
					this.success_import_flag = true;
					this.import_fail_flag = true;
					if (!Array.isArray(res.message)) {
						this.error_from_server = res.message.split();
					}
					else {
						this.error_from_server = res.message;
					}
					this.toastr.error("An error occured. Please check 'View details'.", 'Error');
				}

			}, (e) => {
				console.log(e);
			});
		}

	}
	viewDetails() {
		this.hide_tag = false;
	}
	//    downloadErrorLog(){
	//    	let url=this.file_base+this.error_log
	//    	let a = document.createElement('a');
	// a.href = 'data:text/plain;charset=UTF-8,' + '' + url;
	// a.download = url.substr(url.lastIndexOf('/') + 1);
	// document.body.appendChild(a);
	// a.click();
	// document.body.removeChild(a);
	//    }

	ngOnInit() {
	}
	screenshotFilter(brand: any) {
		console.log(AppSettings.screenShot)
		if (AppSettings.screenShot == true) {
			this.toastr.error('Screenshotting is in progress.', 'Failed');
			return false;
		}
		console.log(brand)
		this.modal_title = brand.name;
		this.screenshot.brand = brand.id;
		this.daterange.start = null;
		this.daterange.end = null;
		$('#screenshot').modal('show');
	}

	onScreenshotSubmit(valid) {
		console.log('scrnsht takinggg', this.screenshot);
		if (!this.screenshot.standalone && !this.screenshot.social_media && !this.screenshot.m_commerce && !this.screenshot.e_commerce) {
			this.reportSectionError = true;
			return false;
		}
		console.log(valid)
		if (valid) {
			this.loaderService.display(true);
			this.brandService.postScreenshotRequest(this.screenshot).subscribe((res: any) => {
				this.loaderService.display(false);
				console.log('YUYUUU', res);
				if (res.status == "success") {
					this.toastr.success('Screenshotting is in progress!', 'SUCCESS');
					$('#screenshot').modal('hide');
					AppSettings.screenShot = true;
					let screenshotDetails = {
						screenshot: true,
						brand: res.data_found.brand,
						client: res.data_found.client,
						urls: res.urls
					}
					this.authServiceObj.sendMessage(screenshotDetails);

				} else {
					if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
						this.toastr.error("Kindly login", "Session Expired");
						this.authServiceObj.logout();
					} else {
						this.toastr.error(res.message, 'Failed');
					}
				}
			}, (e) => {

			});
		}
	}
	deleteScreenshot(brand: any) {
		console.log(brand)
		this.modal_title = brand.name;
		this.screenshot.brand = brand.id;
		this.screenshot.social_media = false;
		this.screenshot.market_place = false;
		this.screenshot.standalone = false;
		this.screenshot.fromDate = null;
		this.screenshot.toDate = null;
		this.daterange.start = null;
		this.daterange.end = null;
		$('#screenshotDelete').modal('show');
	}
	onScreenshotDelete(valid) {
		console.log(this.screenshot);
		if (!this.screenshot.standalone && !this.screenshot.social_media && !this.screenshot.m_commerce && !this.screenshot.e_commerce) {
			this.reportSectionError = true;
			return false;
		}
		console.log(valid)
		if (valid) {
			this.loaderService.display(true);
			this.brandService.deleteScreenshot(this.screenshot).subscribe((res: any) => {
				this.loaderService.display(false);
				console.log(res);
				if (res.status == "success") {
					this.toastr.success('Screenshots deleted successfully', 'Success');
					$('#screenshotDelete').modal('hide');

				} else {
					if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
						this.toastr.error("Kindly login", "Session Expired");
						this.authServiceObj.logout();
					} else {
						this.toastr.error(res.message, 'Failed');
					}
				}
			}, (e) => {

			});
		}
	}
	public calendarCanceled(e: any) {
		this.daterange = {
			start: null,
			end: null
		};
		this.screenshot.fromDate = null;
		this.screenshot.toDate = null;
		this.downloadScreenshotData.fromDate = null;
		this.downloadScreenshotData.toDate = null;
	}
	downloadScreenshot(brand: any) {
		console.log(brand)
		this.modal_title = brand.name;
		this.downloadScreenshotData.brand = brand.id;
		this.downloadScreenshotData.social_media = false;
		this.downloadScreenshotData.market_place = false;
		this.downloadScreenshotData.standalone = false;
		this.downloadScreenshotData.fromDate = null;
		this.downloadScreenshotData.toDate = null;
		this.downloadScreenshotData.socialmediaItems = [];
		this.socialmediaList = [];
		this.downloadScreenshotData.marketplaceItems = [];
		this.marketplaceList = [];
		this.showMarketplace = false;
		this.showSocialmedia = false;
		this.mCommerceList = [];
		this.eCommerceList = [];
		this.downloadScreenshotData.mCommerceItems = [];
		this.downloadScreenshotData.eCommerceItems = [];
		this.showMCommerce = false;
		this.showECommerce = false;
		this.daterange.start = null;
		this.daterange.end = null;
		$('#screenshotDownload').modal('show');
	}
	reportSectionSelectDownload(value: any, type: any) {
		if (value == true) {
			this.reportSectionError = false;
			if (type == 'market_place') {
				this.marketplaceList = [];
				this.showMarketplace = true;
			} else if (type == 'social_media') {
				this.socialmediaList = [];
				this.showSocialmedia = true;
			}
			else if (type == 'm_commerce') {
				this.mCommerceList = [];
				this.showMCommerce = true;
			}
			else if (type == 'e_commerce') {
				this.eCommerceList = [];
				this.showECommerce = true;
			}
			this.loaderService.display(true);
			let data = {
				"type": type,
				"id": this.downloadScreenshotData.brand
			}

			this.brandService.getSolcialmediaOrMarketplace(data).subscribe((res: any) => {
				console.log(res);
				this.loaderService.display(false);
				if (res.status == 'success') {
					if (type == 'social_media') {
						this.socialmediaList = res.data_found;
					} else if (type == 'market_place') {
						this.marketplaceList = res.data_found;
					} else if (type == 'm_commerce') {
						this.mCommerceList = res.data_found;
					}
					else if (type == 'e_commerce') {
						this.eCommerceList = res.data_found;
					}

				} else {
					if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
						this.toastr.error("Kindly login", "Session Expired");
						this.authServiceObj.logout();
					}
				}

			})

		} else {
			if (type == 'market_place') {
				this.marketplaceList = [];
				this.showMarketplace = false;
			} else if (type == 'social_media') {
				this.socialmediaList = [];
				this.showSocialmedia = false;
			}
			else if (type == 'm_commerce') {
				this.mCommerceList = [];
				this.showMCommerce = false;
			}
			else if (type == 'e_commerce') {
				this.eCommerceList = [];
				this.showECommerce = false;
			}
		}
	}
	reportSectionSelect(value: any) {
		if (value == true) {
			this.reportSectionError = false;
		}
	}
	onMarketplaceSelect(event) {
		this.downloadScreenshotData.marketplaceItems.push(event.id);
		console.log(this.marketplaceItems)
	}
	onSocialmediaSelect(event) {
		this.downloadScreenshotData.socialmediaItems.push(event.id);
	}
	onMarketplaceDeSelect(event) {
		var idx = this.downloadScreenshotData.marketplaceItems.indexOf(event.id)
		if (idx > -1) {
			this.downloadScreenshotData.marketplaceItems.splice(idx, 1)
		}

	}
	onSocialmediaDeSelect(event) {
		var idx = this.downloadScreenshotData.socialmediaItems.indexOf(event.id)
		if (idx > -1) {
			this.downloadScreenshotData.socialmediaItems.splice(idx, 1)
		}
	}
	onMarketplaceSelectAll(event) {
		console.log(event)
		event.forEach(element => {
			console.log(element)
			this.downloadScreenshotData.marketplaceItems.push(element.id);
		});
		console.log(this.downloadScreenshotData.marketplaceItems)
	}
	onMarketplaceDeSelectAll(event) {
		this.downloadScreenshotData.marketplaceItems = [];

	}
	onSocialmediaSelectAll(event) {
		event.forEach(element => {
			this.downloadScreenshotData.socialmediaItems.push(element.id);
		});
	}
	onSocialmediaDeSelectAll(event) {
		this.downloadScreenshotData.socialmediaItems = [];

	}
	onScreenshotDownload(valid) {
		console.log(this.downloadScreenshotData);
		if (!this.downloadScreenshotData.standalone && !this.downloadScreenshotData.social_media && !this.downloadScreenshotData.m_commerce && !this.downloadScreenshotData.e_commerce) {
			this.reportSectionError = true;
			return false;
		}
		console.log(valid)
		if (valid) {
			this.loaderService.display(true);
			this.brandService.downloadScreenshots(this.downloadScreenshotData).subscribe(async (res: any) => {
				if (res.status == 'success') {
					console.log('res7654', res);

					var link = document.createElement("a");
					var path = this.screenshot_url+ res.key;
					console.log('path', path);
					link.download = path;
					link.href = path;
					document.body.appendChild(link);
					await link.click();
					$('#screenshotDownload').modal('hide');
					this.loaderService.display(false);
					// this.brandService.deleteZip(res.path).subscribe(async (res: any) => {
					// 	console.log("success")
					// }
					// )
				} else {
					this.toastr.error(res.message, 'Failed');
				}
			}, (e) => {

			});
		}
	}
	//------------- M commerce------------
	onMCommerceSelect(event) {
		this.downloadScreenshotData.mCommerceItems.push(event.id);
		console.log(this.mCommerceItems)
	}

	onMCommerceDeSelect(event) {
		var idx = this.downloadScreenshotData.mCommerceItems.indexOf(event.id)
		if (idx > -1) {
			this.downloadScreenshotData.mCommerceItems.splice(idx, 1)
		}

	}

	onMCommerceSelectAll(event) {
		console.log(event)
		event.forEach(element => {
			console.log(element)
			this.downloadScreenshotData.mCommerceItems.push(element.id);
		});
		console.log(this.downloadScreenshotData.mCommerceItems)
	}
	onMCommerceDeSelectAll(event) {
		this.downloadScreenshotData.mCommerceItems = [];

	}

	//------------- E commerce------------
	onECommerceSelect(event) {
		this.downloadScreenshotData.eCommerceItems.push(event.id);
		console.log(this.eCommerceItems)
	}

	onECommerceDeSelect(event) {
		var idx = this.downloadScreenshotData.eCommerceItems.indexOf(event.id)
		if (idx > -1) {
			this.downloadScreenshotData.eCommerceItems.splice(idx, 1)
		}

	}

	onECommerceSelectAll(event) {
		console.log(event)
		event.forEach(element => {
			console.log(element)
			this.downloadScreenshotData.eCommerceItems.push(element.id);
		});
		console.log(this.downloadScreenshotData.eCommerceItems)
	}
	onECommerceDeSelectAll(event) {
		this.downloadScreenshotData.eCommerceItems = [];

	}
	downloadTemplate(){
		this.isdownload = true
		this.modal_title = 'Choose Platform to Download Template'
		// $("#import_file").replaceWith($("#import_file").val(''));
		$('#downloadModel').modal('show');
	}
	onDownloadTemplate(valid){
		this.submited = true;
		if (valid) {
			this.loaderService.display(true);
			this.brandService.downloadTemplates(this.import_data.import_to).subscribe((res) => {
				this.loaderService.display(false);
				if (res.status == "success") {
					this.downloadFile(res.message);
				} else {
					this.toastr.error(res.message, 'Error');
				}

			}, (e) => {
				console.log(e);
			});
		}
	}
	downloadFile(file){
		var link = document.createElement("a");
			link.download = file;
			link.href = this.image_base+file;
			link.target="_blank";
			document.body.appendChild(link);
			this.toastr.success('Template downloaded successfully!','SUCCESS');
			link.click();
		
	}
}
