
import {of as observableOf, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';

import {AppSettings} from '../../../settings.global';
import {ActivatedRoute, Router} from '@angular/router';
declare var $:any;
import * as _ from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';



@Injectable()
export class GeneralService {
	public headers: HttpHeaders;
	constructor(private httpClient: HttpClient, private localSt: LocalStorageService) { 
		this.headers = new HttpHeaders({
			'Content-Type': 'application/json;charset=UTF-8',
			'Accept': 'application/json',
			'x-access-token': this.localSt.retrieve("access_token")
		});
	}

    getAllSetting(){
        let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
            return this.httpClient.get(AppSettings.API_ENDPOINT_V2 + '/settings/general' , options).pipe(
            map(function (res: any) {
                return res.body || {
                    status: "failed",
                    message: "Something went wrong"
                };
            }),catchError((error:any) => {
                return observableOf({status:"error",message:"Server error occured"})
            }),);
      }

    addEditGeneralSettings(body:any){
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.post(AppSettings.API_ENDPOINT_V2 + '/settings/general', body, options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));
	}

}