
import { of as observableOf, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { AppSettings } from '../../settings.global';
import { ActivatedRoute, Router } from '@angular/router';
declare var $: any;
import * as _ from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class BrandService {

	public headers: HttpHeaders;

	constructor(private httpClient: HttpClient, private localSt: LocalStorageService) {
		this.headers = new HttpHeaders({
			'Content-Type': 'application/json;charset=UTF-8',
			'Accept': 'application/json',
			'x-access-token': this.localSt.retrieve("access_token")
		});
	}

	//Create or update brand
	createUpdateBrand(body: any) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		if (body.id) {
			return this.httpClient.put(AppSettings.API_ENDPOINT + '/brand', body, options).pipe(
				map(function (res: any) {
					return res.body || {
						status: "failed",
						message: "Something went wrong"
					};
				}));
		} else {
			return this.httpClient.post(AppSettings.API_ENDPOINT + '/brand', body, options).pipe(
				map(function (res: any) {
					return res.body || {
						status: "failed",
						message: "Something went wrong"
					};
				}));
		}

	}

	//get brands
	getBrands(body: any) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		let bodyString = $.param(body);
		return this.httpClient.get(AppSettings.API_ENDPOINT + '/brand?' + bodyString, options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}), catchError((error: any) => {
				//return Observable.throw(error.json().error || 'Server error occured')
				return observableOf({ status: "error", message: "Server error occured" })
			}),);
	}

	getBrandDetails(id: number) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.get(AppSettings.API_ENDPOINT + '/brand/edit/' + id, options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));

	}

	//inactivate brand
	inActivate(data: any) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.post(AppSettings.API_ENDPOINT + '/brand/inactive', data, options).pipe(
			map(function (res: any) {
				return res.body || { status: "failed", message: "Somthing went wrong" };
			}));
	}

	//Activate client
	activate(data: any) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.post(AppSettings.API_ENDPOINT + '/brand/active', data, options).pipe(
			map(function (res: any) {
				return res.body || { status: "failed", message: "Somthing went wrong" };
			}));
	}

	sortBrandList(chestData, orderBy, order) {
		return _.orderBy(chestData, [orderBy], [order]);
	}

	//get brands for selectinput
	getBrandSelect(id: number) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.get(AppSettings.API_ENDPOINT + '/brand/brand_select/' + id, options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));
	}
	public importData(body: any, file: any): Promise<any> {
		return new Promise((resolve, reject) => {
			let token = this.localSt.retrieve("access_token")
			let formData: FormData = new FormData(),
				xhr: XMLHttpRequest = new XMLHttpRequest();
			if (file) {
				formData.append('file_xlsx', file, file.name);
			}

			for (let index in body) {
				formData.append(index, body[index]);
			}
			xhr.onreadystatechange = () => {

				if (xhr.readyState === 4) {

					if (xhr.status === 200) {
						resolve(JSON.parse(xhr.response));
					} else {
						reject(xhr.response);
					}
				}
			};
			if (body.import_to == "market_place" || body.import_to == "m_commerce" || body.import_to == "e_commerce") {
				xhr.open('POST', AppSettings.API_ENDPOINT + '/brand/import-to-market-place', true);
			} else if (body.import_to == "social_media") {
				xhr.open('POST', AppSettings.API_ENDPOINT + '/brand/import-to-social-media', true);
			} else if (body.import_to == "standalone_website") {
				xhr.open('POST', AppSettings.API_ENDPOINT + '/brand/import-to-standalone-website', true);
			}
			xhr.setRequestHeader('x-access-token', token);
			xhr.send(formData);

		})
	}
	//Generate report
	postScreenshotRequest(body: any) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.post(AppSettings.API_ENDPOINT + '/brand/screenshot', body, options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));
	}
	//get screenshot request count
	getScreenshotRequestCount() {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.get(AppSettings.API_ENDPOINT + '/brand/screenshot', options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));
	}
	//Cancel screenshot request
	cancelScreenshotRequest() {
		let body = {};
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.put(AppSettings.API_ENDPOINT + '/brand/screenshot', body, options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));
	}
	//Delete screenshot
	deleteScreenshot(screenshot: any) {
		let body = {};
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		let bodyString = $.param(screenshot);
		return this.httpClient.delete(AppSettings.API_ENDPOINT + '/brand/screenshot?' + bodyString, options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));
	}
	//get screenshot request count
	getSuccessScreenshots(id) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.get(AppSettings.API_ENDPOINT + '/brand/success-screenshots?id=' + id, options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));
	}
	//Download screenshots
	downloadSuccessScreenshots(body: any) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.post(AppSettings.API_ENDPOINT + '/brand/success-screenshots', body, options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));
	}
	//Delete zip
	deleteZip(path: any) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.delete(AppSettings.API_ENDPOINT + '/brand/success-screenshots?path=' + path, options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));
	}


	getSolcialmediaOrMarketplace(data: any) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		let bodyString = $.param(data);
		return this.httpClient.get(AppSettings.API_ENDPOINT + '/brand/select-socialmedia-marketplace?' + bodyString, options).pipe(
			map(function (res: any) {
				return res.body || { status: "failed", message: "Something went wrong" };

			}), catchError((error: any) => {
				return observableOf({ status: "error", message: "Server error occured" })
			}),);
	}
	//Download screenshots
	downloadScreenshots(body: any) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.post(AppSettings.API_ENDPOINT + '/brand/download-screenshots', body, options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));
	}

	//download template
	downloadTemplates(source:string) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.get(AppSettings.API_ENDPOINT + '/brand/download-templates?source=' + source , options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));
	}
}
