import { Component,ViewChild,ViewContainerRef } from '@angular/core';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import {LoaderService} from './services/loader/loader.service';

@Component({
  selector: 'app-root',
  template: `
	<router-outlet>
	<span *ngIf="showLoader" class="loading"></span>
	</router-outlet>
	`,
  styleUrls: ['./app.component.css']
})
export class AppComponent {
	@ViewChild(ToastContainerDirective)
	toastContainer: ToastContainerDirective;
	public viewContainerRef: ViewContainerRef;
	title = 'app';
	showLoader: boolean;
  constructor(
		viewContainerRef: ViewContainerRef,private toastr: ToastrService,private loaderService:LoaderService) {
		this.viewContainerRef = viewContainerRef;
		
	}
	ngOnInit() {
		this.loaderService.status.subscribe((val: boolean) => {
			this.showLoader = val;
		});
	}
}
