
import {fromEvent as observableFromEvent} from 'rxjs';
import { Component, OnInit } from '@angular/core';
import {SearchEngineService} from '../../../services/master-data/search-engine/search-engine.service';
import { MotherCompanyService } from '../../../services/master-data/mother-company/mother-company.service';
import * as $ from 'jquery';
import * as _ from 'lodash';
import {Observable} from 'rxjs/';
import {LoaderService} from '../../../services/loader/loader.service';
import {NgxPaginationModule} from 'ngx-pagination';//importing ng2-pagination
import {AuthService} from '../../../services/auth/auth.service';
import {AppSettings} from '../../../settings.global';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-search-engine',
  templateUrl: './search-engine.component.html',
  styleUrls: ['./search-engine.component.css']
})
export class SearchEngineComponent implements OnInit {
  public request_params:any;
  public pager:any;
  public queryLimit: number = AppSettings.DEFAULT_QUERY_LIMIT;
	public itemPerPageCount: number = AppSettings.DEFAULT_PAGER_COUNT;
	public apiCallCount: number = 0;
	public offSet: number = 0;
  public search:any;
  public search_engines:any;
  public title:any;
  public saveButton:any;
  public cancelButton:any;
  public submitted:boolean;
  public no_data:any;
  public mcompanies: any;
  public searchInputObservable$: any;
  public user_details:any;

  constructor(private searchEngineService:SearchEngineService,public localSt:LocalStorageService,private motherCompanyService:MotherCompanyService,
     private loaderService:LoaderService, private toastr: ToastrService, private authServiceObj:AuthService) {
    this.title='Search Engine';
    this.saveButton=' Add ';
    this.cancelButton= 'Clear';
    this.submitted=false;    
    this.no_data="Loading...."
    this.request_params = {
			searchkey: "",
			orderby: "",
			order: "",
			limit: this.queryLimit,
			offset: 0
		}
		this.pager = {
			limit: this.itemPerPageCount,
			offset: 0,
			total_count: 0,
			current_page: 1
		}
    this.search={
      id:'',
      name:'',
      description:'',
      mcompany:''
    };
      this.search_engines=[];
      this.user_details = this.localSt.retrieve("user_details");

   }

   ngAfterContentInit() {
     //Input search observable - to avoid the  onInputChange() firing whenever the input changes
		const searchInputObj = document.querySelector('#search_text');
		this.searchInputObservable$ = observableFromEvent(searchInputObj, 'keyup');

    this.searchInputObservable$.pipe(debounceTime(100))
    .subscribe((event: any) => {
      this.onInputChange(event.target.value);
    });
    $("body").height("100%");
    $("html").height("100%");
    this.getMcompany();
    /*
         * API call and grab full data
         */
		setTimeout(() => {
			this.getSearchEngine(1);
		},10);
  }

  onInputChange(text) {
    this.onSearch();
  }

  //To get mothercompany
  getMcompany(){ 
    this.motherCompanyService.getMcompanySelect('search_engine').subscribe((res:any)=>{
      if(res.status=='success'){
      this.mcompanies=res.data_found;
      }
      else{
        if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
              this.toastr.error("Kindly login","Session Expired");
              this.authServiceObj.logout();
        }
      }
    })
  }

  onSubmit(valid){
    this.submitted=true;
    if(valid){
      this.loaderService.display(true);
      this.searchEngineService.createUpdateSearch(this.search).subscribe((res:any)=>{
        this.loaderService.display(false);
        if(res.message=="success"){
          this.toastr.success('Updated Successfully!','SUCCESS');
          this.search_engines = [];
          this.apiCallCount = 0;
          this.offSet = 0; 
          this.getSearchEngine(1);
          this.title='Search Engine';
          this.saveButton=' Add ';
          this.cancelButton= 'Clear';
          this.submitted=false;
          this.search={
                id:'',
                name:'',
                description:'',
                mcompany:''
              };
        }else if(res.message == "Search Engine already exists"){
          this.toastr.error('Search Engine already exists','Request Failed');
        }else {
          if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
            this.toastr.error("Kindly login","Session Expired");
            this.authServiceObj.logout();
          }else{
            this.toastr.error(res.message,'Failed');
          }
        }
      },(e)=>{

          });
    }
  }

  //To get SearchEngine
  getSearchEngine(page: number){
    this.request_params['offset'] = this.offSet;
    this.loaderService.display(true);  
    this.searchEngineService.getSearchEngine(this.request_params).subscribe((res:any)=>{
      this.loaderService.display(false);
      this.apiCallCount++;
      if(res.status=='success' && !_.isNull(res.search_result)){
        this.pager["total_count"] = this.search_engines.length + res.search_result.length;
				this.pager["current_page"] = page;
				const result = this.search_engines.concat(res.search_result);
        this.search_engines = res.search_result;
        //Load full result
				if(!_.isNil(res.max_possible_res_length) && res.max_possible_res_length > this.search_engines.length) {
					this.offSet = this.apiCallCount * this.queryLimit;

					this.getSearchEngine(1);
				}    
      }else {
        if(this.search_engines.length === 0 || res.max_possible_res_length === 0) {
          this.search_engines = [];
          this.no_data = 'No Data';
					this.pager["total_count"] = 0;
					this.pager["current_page"] = page;
				}
        if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
          this.toastr.error("Kindly login","Session Expired");
          this.authServiceObj.logout();
        }
      }
    })
  }

  onSearch() {
		this.search_engines = [];
		this.apiCallCount = 0;
    this.offSet = 0; 
    this.pager["offset"] =0;   
    this.getSearchEngine(1);
   }

  onPageChange(page: number) {
  this.pager["current_page"] = page;
  this.pager["offset"] = this.itemPerPageCount * (page - 1);
  }

  onSort(orderby: string, id: any) {
    if (this.request_params["orderby"] === orderby) {
      if (this.request_params["order"] === "asc") {
        this.request_params["order"] = "desc";
      } else {
        this.request_params["order"] = "asc";
      }
    } else {
      this.request_params["orderby"] = orderby;
      this.request_params["order"] = "desc"
    }
    //Reset the page to 1, offset to 0
    this.pager["current_page"] = 1;
    this.pager["offset"] = 0;
    this.search_engines = this.searchEngineService.sortSearchList(this.search_engines, this.request_params["orderby"], this.request_params["order"]);
  }

  //Get SearchEngine for edit
  editSearchEngine(search:any){
    this.title='Edit Search Engine';
    this.saveButton=' Save ';
    this.cancelButton= 'Cancel';
    this.search={
      id:search.id,
      name:search.name,
      description:search.description,
      mcompany:search.mother_company_id
    };
  }

  //delete selected Search Engine
  deleteSearchEngine(id:any){
    var cnf=confirm("Are you sure you want to delete this Search Engine?")
    if(cnf){
      this.searchEngineService.delete(id).subscribe((res:any)=>{
        if(res.status=='success'){
          this.toastr.success('Search Engine Deleted Successfully!','SUCCESS');
          this.search={
            id:'',
            name:'',
            description:'',
            mcompany:''
          };
          this.search_engines = [];
          this.apiCallCount = 0;
          this.offSet = 0;          
          this.getSearchEngine(1);
          }else {
            if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
              this.toastr.error("Kindly login","Session Expired");
              this.authServiceObj.logout();             
            }else{
              this.toastr.error(res.message,'Failed');
            }
          }
      })
    }
  }

  //To cancel editing and show add form
  cancel(){
    this.title='Search Engine';
    this.saveButton=' Add ';
    this.cancelButton= 'Clear';
    this.search={
      id:'',
      name:'',
      description:'',
      mcompany:''
    };
  }

  ngOnInit() {
  }

}
