
import {fromEvent as observableFromEvent,  concat } from 'rxjs';
import { Component, OnInit } from '@angular/core';
declare var $: any;
import { NgxPaginationModule } from 'ngx-pagination';
import { Observable } from 'rxjs/';
import * as moment from 'moment';
import { MotherCompanyService } from '../../../services/master-data/mother-company/mother-company.service';
import { LoaderService } from '../../../services/loader/loader.service';
import { AuthService } from '../../../services/auth/auth.service';
import { SellerService } from '../../../services/settings/sellers/seller.service'
import { MarketplaceService } from '../../../services/settings/marketplace/marketplace.service'
import { Routes, RouterModule, ActivatedRoute, Router, Params } from '@angular/router';
import * as _ from 'lodash';
import { HttpClientModule } from '@angular/common/http';
import { AppSettings } from '../../../settings.global';
// import { ImageCropperComponent, CropperSettings, Bounds } from 'ng2-img-cropper';



import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
// import { forEach } from '@angular/router/src/utils/collection';
import { nextTick } from 'process';
import { element } from 'protractor';
import { log } from 'console';
import { userInfo } from 'os';
import { ToastrService } from 'ngx-toastr';
// import { empty } from 'rxjs/Observer';

@Component({
  selector: 'app-sellers',
  templateUrl: './sellers.component.html',
  styleUrls: ['./sellers.component.css']
})
export class SellersComponent implements OnInit {

  public objectKeys = Object.keys
  public tabitems: any = ['Watch List', 'Ignore List']
  public selected_item: any;
  public sellersCountry: any;
  public sellersDetails: any;
  public no_data: any;
  public searchInputObservable$: any;
  public offSet: any = 0;
  public itemPerPageCount: number = AppSettings.DEFAULT_PAGER_COUNT;
  public country: any
  public dynamictemp: any;
  public imageData: any
  // cropperSettings1: CropperSettings;
  public image_base: any = AppSettings.IMAGE_BASE_URL;


  selectedList:any;
  mp_id: any;
  nil: any = '--';
  sellerindividual: any = [];
  temp: any;
  request_params: any;
  apiCallCount: number;
  pager: any;
  countries: any = ''
  queryLimit: number = AppSettings.DEFAULT_QUERY_LIMIT;
  sellers: any[];
  prodId: any[];
  selectAllFlag: boolean
  checkBox: any;

  selectOne: any[];
  sellerId: any;
  no_of_entry: number;
  watchSellerList: any;
  ignoreSellerList: any;
  sellerID: any[];
  source: string;
  selectedFile: any;
  editDetails: any;
  menu: any;
  dropDown: boolean;
  formArray: any;
  inputName: any = '';
  valuePlaceholder: string;
  scrapedName: any;
  scrapFields: any;
  editedOutput: any;
  extraDynamiData: { key: string; value: any; }[];
  edited: { key: string; value: any; }[];
  results: any;
  fieldDropdown: any;
  resultdropdown: any;
  custom: boolean;
  customInputValue: string;
  value: any;
  validStatus: boolean;
  field: string='Add More...';
  logUrl: any;
  sellerSort:any;
  sellerLogo: any;
  tempScrapField: any;
  cropedImage: any;
  sellersLogo: string;
  noPointOfContact: any 
  sortOrder: boolean;



  constructor(private motherCompanyService: MotherCompanyService,
    private marketplaceService: MarketplaceService,
    private sellerService: SellerService,
    private loaderService: LoaderService,
    private toastr: ToastrService,
    private route: Router,
    public localSt: LocalStorageService,
    private activatedRoute: ActivatedRoute,
    private authServiceObj: AuthService
  ) {
    this.request_params = {
      searchkey: "",
      orderby: "",
      order: "",
      country: this.country,
      limit: this.queryLimit,
      offset: 0
    }
    this.editDetails = {
      id:'',
      name: '',
      phone: '',
      email: '',
      website: '',
      country: '',
      address: '',
      notes: '',
      old_logo:'',
      logo:''
    }

    this.selectedFile = {
      src: ''
    }
    this.pager = {
      limit: this.itemPerPageCount,
      offset: 0,
      total_count: 0,
      current_page: 1
    };
    this.sellersCountry = [0];
    this.countries = [];
    this.dropDown = false;
    this.selectedList = 0
    this.sellerID = [];
    this.dynamictemp = {};
    this.formArray = [];
    this.sellers = [];
    this.value = false
    this.no_of_entry = 10;
    this.selectAllFlag = false;
    this.checkBox = [];
    this.sellerId = [];
    this.imageData = {};
    this.editedOutput = [];
    this.cropedImage =true
    this.no_data = "No Data";
    this.sellersDetails = [];
    this.watchSellerList = [];
    this.noPointOfContact = false;
    this.tempScrapField =[];
    this.ignoreSellerList = []

    //Image cropper
    // this.cropperSettings1 = new CropperSettings();
    // this.cropperSettings1.width = 200;
    // this.cropperSettings1.height = 200;
    // this.cropperSettings1.keepAspect = false;

    // this.cropperSettings1.croppedWidth = 400;
    // this.cropperSettings1.croppedHeight = 400;

    // this.cropperSettings1.canvasWidth = 330;
    // this.cropperSettings1.canvasHeight = 200;

    // this.cropperSettings1.minWidth = 100;
    // this.cropperSettings1.minHeight = 100;

    // this.cropperSettings1.rounded = false;
    // this.cropperSettings1.minWithRelativeToResolution = false;

    // this.cropperSettings1.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
    // this.cropperSettings1.cropperDrawSettings.strokeWidth = 2;
  }

  ngAfterContentInit() {

    //Input search observable - to avoid the  onInputChange() firing whenever the input changes
    const searchInputObj = document.querySelector('#search_text');
    this.searchInputObservable$ = observableFromEvent(searchInputObj, 'keyup');



    // this.searchInputObservable$.debounceTime(100)
    //   .subscribe((event: any) => {
    //      this.onInputChange(event.target.value);
    //   });

    $("body").height("100%");
    $("html").height("100%");
    /*
      * API call and grab full data
      */
    setTimeout(() => {
      this.getAllCountries(1);
    }, 10);

  }





  // get all country to dropdown
  getAllCountries(page) {
    this.loaderService.display(true);
    this.motherCompanyService.getCountrySelect('').subscribe((res: any) => {
      this.loaderService.display(false);
      if (res.status == 'success') {
       
        if(res.country_result){

          this.countries = res.country_result;
          this.sellersCountry = this.countries
        }else{
          this.sellersCountry = null;
        }

        if (this.activatedRoute.snapshot.queryParams['country_id']) {
          this.country = parseInt(this.activatedRoute.snapshot.queryParams['country_id'])
        }
        else {
          if(this.sellersCountry){
            this.country = this.sellersCountry[0].name
          } else {
            this.country = null;
          }
        }
        if (this.sellersCountry != null) {
      
            this.getSeller(this.country, page);
          
        } else {
          this.no_data = 'No Data';
        }
      } else {
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          this.toastr.error("Kindly login", "Session Expired");
          this.authServiceObj.logout();
        }
      }
    });
  }

  // get all sellers to the table

  getSeller(country_id, page) {
    this.watchSellerList = [];
    this.ignoreSellerList = []
    this.sellersDetails = []
    if (this.offSet != 0 && this.request_params['searchkey'] != '') {

      this.apiCallCount = 0;
      this.offSet = 0;
    }
    this.request_params['offset'] = 0;
    this.request_params['country'] = country_id

    console.log(this.request_params, "requeest params");
    this.loaderService.display(true);
    this.sellerService.getSeller(this.request_params).subscribe((res: any) => {
      console.log(res,'jevitham');
      
      this.loaderService.display(false);
      this.apiCallCount++;
      if (res.status === 'success') {
        this.sellerSort = [];
         
       
        if(res.p_seller_result != null){
          res.p_seller_result.forEach(element => {
            if (element.status === true) {
              this.watchSellerList.push(element)
             
              const result = this.sellerSort.concat(element.details);
				this.sellerSort = result;
            }
            if (element.status === false) {
              this.ignoreSellerList.push(element)
             
              const result = this.sellerSort.concat(element.details);
              this.sellerSort = result;
            }
          });

          this.sellersDetails = this.watchSellerList
          console.log(this.watchSellerList, "watch")
          console.log(this.ignoreSellerList, "ignore");

          this.pager["total_count"] = this.sellers.length
          this.pager["current_page"] = page;
        }else{
          this.no_data = 'No Data';
        }
          //Load full result
          if (!_.isNil(res.max_possible_res_length) && res.max_possible_res_length > this.sellers.length) {
            this.offSet = this.apiCallCount * this.queryLimit;
          }
      } else {
        if (this.sellers.length === 0 || res.max_possible_res_length === 0) {
          this.sellers = [];
          this.no_data = 'No Data';
          this.pager["total_count"] = 0;
          this.pager["current_page"] = page;
        }
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          this.toastr.error("Kindly login", "Session Expired");
          this.authServiceObj.logout();
        }
      }
    })
  }
  // exclude from detection single row button
  Exclude(id: any, state: any) {
    if (state == "multiple") {
      if (this.sellerId.length == 0) {
        this.toastr.info('Please Select Atleast One Seller To Exclude From Detection.');
      } else {
        alert("Are you Sure You Want To Exclude From Detection")
        this.sellerID = this.sellerId
      }
    } else {
      alert("Are you Sure You Want To Exclude From Detection")
      this.sellerID = [id]
    }
    var user = this.localSt.retrieve('user_details');
    if (this.sellerID.length != 0) {
      let data = {
        id: this.sellerID,
        status: false,
        user_id: user.id
      }
      this.loaderService.display(true);
      this.sellerService.changeStatus(data).subscribe((res: any) => {
        this.loaderService.display(false);
        if (res.status == "success") {
          this.selectAllFlag = false
          this.watchSellerList = [];
          this.ignoreSellerList = []
          this.checkBox = false;
          this.sellerID = [];
          this.sellerId = []
          this.offSet = 0;
          this.checkBox = [];
          this.selected_item = this.tabitems[0]
          this.apiCallCount = 0;
          this.getSeller(this.country, 1);
          this.watchSellerList.splice(this.sellerID, 1)
          this.toastr.success('Updated Successfully!', 'SUCCESS');
          this.pager["total_count"] = this.sellers.length
          //Load full result
          if (!_.isNil(res.max_possible_res_length) && res.max_possible_res_length > this.sellers.length) {
            this.offSet = this.apiCallCount * this.queryLimit;
          }
        } else {
          if (res.status == 404 || res.status == "failed") {
            this.toastr.error('Something went wrong try after Sometime!')
          }
          if (this.sellers.length === 0 || res.max_possible_res_length === 0) {
            this.sellers = [];
            this.no_data = 'No Data';
            this.pager["total_count"] = 0;
          }
          if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
            this.toastr.error("Kindly login", "Session Expired");
            this.authServiceObj.logout();
          }
        }
      })
    }

  }



  // include from detection single row
  Include(id: any, state) {
    if (state == "multiple") {
      if (this.sellerId.length == 0) {
        this.toastr.info('Please Select Atleast One Seller To Include To Detection.');
      } else {
        alert("Are You Sure You Want To Include To Detection")
        this.sellerID = this.sellerId
      }
    } else {
      alert("Are You Sure You Want To Include To Detection")
      this.sellerID = [id]
    }
    var user = this.localSt.retrieve('user_details');
    if (this.sellerID.length !== 0) {
      let data = {
        id: this.sellerID,
        status: true,
        user_id: user.id
      }
      this.loaderService.display(true);
      this.sellerService.changeStatus(data).subscribe((res: any) => {
        this.loaderService.display(false);
        if (res.status == "success") {
          this.sellerId = []
          this.selectAllFlag = false
          this.ignoreSellerList = [];
          this.watchSellerList = [];
          this.checkBox = false;
          this.offSet = 0;
          this.sellerID = [];
          this.checkBox = [];
          this.apiCallCount = 0;
          this.selected_item = this.tabitems[1]
          this.getSeller(this.country, 1);
          this.ignoreSellerList.splice(this.sellerID, 1)
          this.toastr.success('Updated Successfully!', 'SUCCESS');
          this.pager["total_count"] = this.sellers.length
          //Load full result
          if (!_.isNil(res.max_possible_res_length) && res.max_possible_res_length > this.sellers.length) {
            this.offSet = this.apiCallCount * this.queryLimit;
          }
        } else {
          if (res.status == 404 || res.status === "failed") {
            this.toastr.error('Something went wrong try after Sometime!')
          }
          if (this.sellers.length === 0 || res.max_possible_res_length === 0) {
            this.sellers = [];
            this.no_data = 'No Data';
            this.pager["total_count"] = 0;
          }
          if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
            this.toastr.error("Kindly login", "Session Expired");
            this.authServiceObj.logout();
          }
        }
      })
    }
  }

  // select all
  //To make all check box true or false
  selectAll() {
    this.sellerId = []
    if (this.selectAllFlag == false) {
      if (this.menu === "Ignore List") {
        for (let e of this.ignoreSellerList) {
          this.sellerId.push(e.id)
          this.checkBox[e.id] = true;
        }
      } else {
        for (let e of this.watchSellerList) {
          this.sellerId.push(e.id)
          this.checkBox[e.id] = true;
        }
      }
      this.selectAllFlag = true;
      // this.selectFalg = true;
    }
    else {
      if (this.menu === "Ignore List") {
        for (let e of this.ignoreSellerList) {
          this.checkBox[e.id] = false;
        }
      } else {
        for (let e of this.watchSellerList) {
          this.checkBox[e.id] = false;
        }
      }
      this.sellerId = []
      this.selectAllFlag = false;
      // this.selectFalg = false;
    }
  }
  //To manage check box selection
  selection(payout: any) {
    this.selectOne = []
    if (this.sellerId) {
      var idx = this.sellerId.indexOf(payout)
    }
    if (idx > -1) {
      this.sellerId.splice(idx, 1)
    }
    // is newly selected
    else if (idx < 0) {
      this.sellerId.push(payout)
    }
    //To make select all check box true or false
    if (this.menu === "Ignore List") {
      for (let e of this.ignoreSellerList) {
        this.selectOne.push(e.id)
      }
    } else {
      for (let e of this.watchSellerList) {
        this.selectOne.push(e.id)
      }
    }
    if (this.selectOne.length != this.sellerId.length) {
      this.selectAllFlag = false;
    } else {
      this.selectAllFlag = true;
    }
  }
  //  function limiting items per page on baseis of country cange
  entryNoChange(value: number) {
    this.sellers = []
    this.apiCallCount = 0;
    this.offSet = 0;
    this.pager["limit"] = value;
    this.pager["total_count"] = 0;
    this.pager["current_page"] = 1;
    this.pager["offset"] = 0;
    if(this.country==="All"){
      this.getSeller('',1)
    }else{
      this.getSeller(this.country, 1);
    }
    this.itemPerPageCount = value;
  }


  // on change event of search
  onChange(newValue) {
    this.country = newValue;
    this.selectAllFlag = false
    this.checkBox = [];
    this.watchSellerList = [];
    this.ignoreSellerList = []
    this.sellers = [];
    this.apiCallCount = 0;
    this.offSet = 0;
    this.pager["offset"] = 0;
    console.log(this.country,'its maoon');
    if(this.country ==="All"){
      this.getSeller('',1)
    }else{

      this.getSeller(newValue, 1);
    }
  }

  // function works when page number changes
  onPageChange(page: number) {
    this.pager["current_page"] = page;
    this.pager["offset"] = this.itemPerPageCount * (page - 1);
  }

  //fuction works when search button clicked
  onSearch() {
    this.apiCallCount = 0;
    this.offSet = 0;
    this.pager["offset"] = 0;
    this.getSeller(this.country, 1);
  }

  keyDownFunction(event) {
    
    console.log(event, "its event");
    if (event.keyCode === 13) {
      this.onSearch()
    }
  }

  // function to sort items in table
  onSort(orderby: string, id: any) {

  
// if (this.request_params["orderby"] === orderby) {
//   if (this.request_params["order"] === "asc") {
//         this.request_params["order"] = "desc";
//       } else {
//         this.request_params["order"] = "asc";
//       }
//     } else {
//       this.request_params["orderby"] = orderby;
//       this.request_params["order"] = "desc"
//     }
//     //Reset the page to 1, offset to 0
//     this.pager["current_page"] = 1;
//     this.pager["offset"] = 0;
//     this.sellerSort = this.sellerService.sortSellersList(this.sellerSort, this.request_params["orderby"], this.request_params["order"]);
//     console.log(this.request_params,'jinja');
//     console.log(this.sellerSort,'sort');
    
  }
  


  importSeller() {
    $('#importSeller').modal('show');
  }
  // Setting for tab
  select(menu: any, index: any) {
    this.selectedList = index;
    if (menu == "Ignore List") {
      this.menu = menu
      this.sellersDetails = this.ignoreSellerList
      this.checkBox = false
      this.checkBox = []
      this.selectAllFlag = false;
      this.sellerId = []
    }
    if (menu == "Watch List") {
      this.menu = menu
      this.sellersDetails = this.watchSellerList
      this.checkBox = false
      this.sellerId = [];
      this.checkBox = [];
      this.selectAllFlag = false;
    }
    this.selected_item = menu
  }



  //To view seller info in pop up  dialoge box------------------------------------------------
  sellerInfo(id: any,mp_id:any) {
    console.log('id2', id, mp_id);
    
    $('#sellerInfo').modal('show');
    this.sellerLogo =''
    this.sellersLogo =''
    this.dropDown = false
    this.selectedFile.src ='';
    this.field ="Add More..."
    this.formArray =[]
    this.loaderService.display(true);
    var ids ={
      id:id,
      mp_id:mp_id
    }
    this.sellerService.getSellerById(ids).subscribe((res: any) => {
      console.log('resres',res);
      
      this.loaderService.display(false);
      if (res.status == "success") {
        console.log(res,"part2seller");

        this.temp = res.data_found[0]
        console.log(this.temp.details,'sellerlogo');
        if(!res.data_found[0].details.email){
          this.noPointOfContact = true
        }else{
          this.noPointOfContact =false
        }
        if(res.data_found[0].details.logo||res.data_found[0].details.old_logo){
          this.sellerLogo =  res.data_found[0].details.logo || res.data_found[0].details.old_logo
          // this.source = URL.createObjectURL(res.data_found[0].sellerdetails.logo)
          // this.selectedFile.src =   this.source
        }else{
          this.sellersLogo = 'images/sellersLogo.jpg'
          // this.source = URL.createObjectURL('images/yourlogohere.jpg')
          // this.selectedFile.src =  this.source
        }if(!this.sellerLogo){
          this.sellersLogo = "../../../../assets/images/yourlogohere.jpg" 
          // this.source = URL.createObjectURL("../../../../assets/images/yourlogohere.jpg" )
          // this.selectedFile.src =  this.source
        }
        this.dynamictemp = _.cloneDeep(res.data_found[0].details);
            delete this.dynamictemp['country_id']
            delete this.dynamictemp['marketplace_id']
            delete this.dynamictemp['name']
            delete this.dynamictemp['country']
            delete this.dynamictemp['website']
            delete this.dynamictemp['phone']
            delete this.dynamictemp['email'],
            delete this.dynamictemp['logo'],
            delete this.dynamictemp['old_logo']
            delete this.dynamictemp['address']
            delete this.dynamictemp['notes']
            delete this.dynamictemp['id']
      } else {
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          this.authServiceObj.logout();
        }
        else {
          this.toastr.error(res.message, 'Failed');
        }
      }
    })
  }


  // TO edit seller info in pop-up Dialoge box
  editSeller(seller_id: any,mp_id:any) {
    $('#sellerInfo').modal('hide');
    setTimeout(() => {
      $('#editSeller').modal('show');
    }, 10);
    this.mp_id = mp_id
    var ids ={
      id:seller_id,
      mp_id:mp_id
    }
    if (seller_id) {
      this.loaderService.display(true);
      this.sellerService.getSellerById(ids).subscribe((res: any) => {
        this.loaderService.display(false);
        console.log(res, "resulterrrs");
        if (res.status == "success") {
          this.editDetails = {
            id:seller_id,
            name: res.data_found[0].details.name || null,
            phone: res.data_found[0].details.phone || null,
            email: res.data_found[0].details.email || null,
            address: res.data_found[0].details.address || null,
            website: res.data_found[0].details.website || null,
            country: res.data_found[0].details.country || null,
            notes: res.data_found[0].details.notes || null,
            old_logo:this.sellerLogo || this.sellersLogo,
            logo:''
          }
          if(!res.data_found[0].details.email){
            this.noPointOfContact =true
          }else{
            this.noPointOfContact =false
          }
        } else {
          if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
            this.authServiceObj.logout();
          }
          else {
            this.toastr.error(res.message, 'Failed');
          }
        }
      })
    }
    this.edited = Object.entries(this.editDetails).map(([key, value]) => ({ key, value }));
    this.extraDynamiData = Object.entries(this.dynamictemp).map(([key, value]) => ({ key, value }));
    const formArrayData = [...this.edited, ...this.formArray]
    const deepClonedOutPut = _.cloneDeep(formArrayData)
    this.editedOutput = [...deepClonedOutPut, ...this.extraDynamiData]

  }
// checking whether there is email||phone  in no ponit of contact checkbox
  onChangeContact(event:any){
      // if(event == !empty){
      //   this.noPointOfContact = true;
      // }else if(event != empty){
      //   this.noPointOfContact = false;
      // }
  }



    //loding image file into background
    loadFile(event: any) {
      this.cropedImage = false;
      this.source = URL.createObjectURL(event.target.files[0]);
      this.selectedFile.src = this.source
    };
    

  // cropped logo image in imagedata
  logoCropped(event) {
    this.editDetails['old_logo']=''
    this.editDetails['logo'] = this.imageData.image;
    console.log(this.imageData, "image man")
  }

  // show dropdown menu when button clicked
  showDropDown() {
    this.loaderService.display(true);
    this.marketplaceService.getName().subscribe((res: any) => {
      this.loaderService.display(false);
      if (res.status == "success") {
        this.scrapFields = res.data_found;

        this.scrapFields.showFieldDropdown = true;
        console.log(this.scrapFields,'scrapfiels');
        
        this.tempScrapField = _.cloneDeep(this.scrapFields)
        this.filterOnBasisOfPageKey(this.scrapFields)
      } else {
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          this.authServiceObj.logout();
        }
        else {
          this.toastr.error(res.message, 'Failed');
        }
      }
    })
      this.dropDown = true
  }

  

  // customme input is generated 
  showCostomInput() {
    this.custom = true
    this.filterOnBasisOfPageKey(this.scrapFields)
  }

  // onchange dropdown
  onChangeDropdown(name: any) {
    this.filterOnBasisOfPageKey(this.scrapFields)
  }

  // Update Seller Details onsubmitting form

  onSubmit(valid: any) {
    var user = this.localSt.retrieve('user_details');
    this.edited = Object.entries(this.editDetails).map(([key, value]) => ({ key, value }));
    this.extraDynamiData = Object.entries(this.dynamictemp).map(([key, value]) => ({ key, value }));
    const formArrayData = [...this.edited, ...this.formArray]
    const deepClonedOutPut = _.cloneDeep(formArrayData)
    this.editedOutput = [...deepClonedOutPut, ...this.extraDynamiData]
    const finalResult = this.editedOutput
    const data = {
      seller_edit:finalResult,
      user_id:user.id,
      marketplace_id:this.mp_id
    }
    console.log(data,"edit seller");

    if (valid) {
      this.loaderService.display(true);
      this.sellerService.addEditSellerInfo(data).subscribe((res: any) => {
        this.loaderService.display(false);
        if (res.status == "success") {
            this.getSeller(this.country,1)
 
          this.toastr.success('Seller Info Edited Successfully!', 'SUCCESS');
          $('#editSeller').modal('hide');
          this.dropDown = false
          this.selectedFile.src ='';
          this.field ="Add More..."
          this.editDetails.old_logo = ''
          this.sellerLogo = ''
          this.sellersLogo =''
          this.formArray =[]
          this.cropedImage =true
        } else {
          if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
            this.authServiceObj.logout();
          }
          else {
            this.toastr.error(res.message, 'Failed');
          }
        }
      });
    }
  }

  // adding additional input on seller edit form
  addInput() {
    let duplicate = this.editedOutput.filter((element) => {
      return element.key === this.inputName
    })
    if (duplicate.length == 0) {
      if(this.inputName){
        if(this.customInputValue){
          this.formArray.push({
            key: this.inputName,
            value: this.customInputValue
          })
          this.inputName = ''
          this.customInputValue = ''
          this.filterOnBasisOfPageKey(this.scrapFields)
        }else{
          this.toastr.error('Please enter some value')
        }
      }else{
        this.toastr.error('Please enter  field name')
      }
    } else {
      this.toastr.error('Field is already declared')
    }

  }
 





  // removing aditional input from seller edit form
  removeInput(index: any) {
    this.formArray.splice(index, 1)
    this.filterOnBasisOfPageKey(this.scrapFields)
    this.inputName = '';
  }

  // canceling seller pop-up dialoe box
  cancelModel() {
    $('#sellerInfo').modal('hide');
  }

  //dropdown filtering
  filterOnBasisOfPageKey(array: any) {
    this.edited = Object.entries(this.editDetails).map(([key, value]) => ({ key, value }));
    this.extraDynamiData = Object.entries(this.dynamictemp).map(([key, value]) => ({ key, value }));
    const formArrayData = [...this.edited, ...this.formArray]
    const deepClonedOutPut = _.cloneDeep(formArrayData)
    this.editedOutput = [...deepClonedOutPut, ...this.extraDynamiData]
    this.results = array.filter(({ name: id1 }) => !this.editedOutput.some(({ key: id2 }) => id2 === id1));
  }

  ngOnInit() {
    this.selected_item = this.tabitems[0]
    // this.getSeller(this.country, 1)

  }
}
