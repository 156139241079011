
import {of as observableOf, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {AppSettings} from '../../settings.global';
import {ActivatedRoute, Router} from '@angular/router';
declare var $:any;
import * as _ from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class DataDeleteRequestService {

	public headers: HttpHeaders;

	constructor(private httpClient: HttpClient, private localSt: LocalStorageService) { 
		this.headers = new HttpHeaders({
			'Content-Type': 'application/json;charset=UTF-8',
			'Accept': 'application/json',
			'x-access-token': this.localSt.retrieve("access_token")
		});
  }

  //get data-delete-requests
	getRequests(body:any){
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		let bodyString = $.param(body);
		return this.httpClient.get(AppSettings.API_ENDPOINT +'/brand/data_delete_request?'+ bodyString, options).pipe(
		map(function (res: any) {
			return res.body || {
				status: "failed",
				message: "Something went wrong"
			};
		}),catchError((error:any) => {
			//return Observable.throw(error.json().error || 'Server error occured')
			return observableOf({status:"error",message:"Server error occured"})
		}),);
	}

	//delete the data tempory
	deleteRequests(body:any){
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
        return this.httpClient.put(AppSettings.API_ENDPOINT +'/brand/data_delete_request',body, options).pipe(
		map(function(res:any){
			return res.body || {status: "failed", message: "Somthing went wrong"};
		}));
	}

	//restore the data
	restoreRequests(body:any){
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.put(AppSettings.API_ENDPOINT +'/brand/data_delete_request_restore',body, options).pipe(
		map(function(res:any){
			return res.body || {status: "failed", message: "Somthing went wrong"};
		}));
	}

	sortRequestList(requestData, orderBy, order) {
		return _.orderBy(requestData, [orderBy], [order]);
	 }

}
