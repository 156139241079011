
import {fromEvent as observableFromEvent} from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { StatusService } from '../../../services/master-data/status/status.service';
import * as $ from 'jquery';
import * as _ from 'lodash';
import {Observable} from 'rxjs/';
import {LoaderService} from '../../../services/loader/loader.service';
import {NgxPaginationModule} from 'ngx-pagination';//importing ng2-pagination
import {AuthService} from '../../../services/auth/auth.service';
import {AppSettings} from '../../../settings.global';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import {debounceTime} from 'rxjs/operators';


@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css']
})
export class StatusComponent implements OnInit {
  public request_params:any;
  public pager:any;
  public queryLimit: number = AppSettings.DEFAULT_QUERY_LIMIT;
	public itemPerPageCount: number = AppSettings.DEFAULT_PAGER_COUNT;
	public apiCallCount: number = 0;
	public offSet: number = 0;
  public status:any;
  public statuses:any;
  public title:any;
  public saveButton:any;
  public cancelButton:any;
  public submitted:boolean;
  public no_data:any;
  public searchInputObservable$: any;
  public user_details:any;

  constructor(private statusService:StatusService,public localSt:LocalStorageService,  private loaderService:LoaderService,private toastr: ToastrService, private authServiceObj:AuthService) { 
    this.title='Status';
    this.saveButton=' Add ';
    this.cancelButton= 'Clear';
    this.submitted=false;    
    this.no_data="Loading...."
    this.request_params = {
			searchkey: "",
			orderby: "",
			order: "",
			limit: this.queryLimit,
			offset: 0
		}
		this.pager = {
			limit: this.itemPerPageCount,
			offset: 0,
			total_count: 0,
			current_page: 1
		}
    this.status={
      id:'',
      name:'',
      description:'',
      val:true
    };
    this.statuses=[];
    this.user_details = this.localSt.retrieve("user_details");

  }

  ngAfterContentInit() {
    //Input search observable - to avoid the  onInputChange() firing whenever the input changes
		const searchInputObj = document.querySelector('#search_text');
		this.searchInputObservable$ = observableFromEvent(searchInputObj, 'keyup');

		this.searchInputObservable$.pipe(debounceTime(100))
      .subscribe((event: any) => {
        this.onInputChange(event.target.value);
      });

    $("body").height("100%");
    $("html").height("100%");
    
    /*
         * API call and grab full data
         */
		setTimeout(() => {
			this.getStatus(1);
		},10);
  }

  onInputChange(text) {
    this.onSearch();
  }

  onSubmit(valid){
    this.submitted=true;
    if(valid){
      this.loaderService.display(true);
      this.statusService.createUpdateStatus(this.status).subscribe((res:any)=>{
        this.loaderService.display(false);
        if(res.message=="success"){
          if(this.saveButton==' Add ') {
            this.toastr.success('Added Successfully!','SUCCESS');
          } else {
            this.toastr.success('Updated Successfully!','SUCCESS');
          }   
          this.statuses = [];
          this.apiCallCount = 0;
          this.offSet = 0;
          this.getStatus(1);
          this.title='Status';
          this.saveButton=' Add ';
          this.cancelButton= 'Clear';
          this.submitted=false;
          this.status={
                id:'',
                name:'',
                description:'',
                val:true
          };
        }else if(res.message == "Status already exists"){
          this.toastr.error('Status already exists','Request Failed');
        }else {
          if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
            this.toastr.error("Kindly login","Session Expired");
            this.authServiceObj.logout();
          }else{
            this.toastr.error(res.message,'Failed');
          }
        }
      },(e)=>{

          });
    }
  }

  onSearch() {
    this.statuses = [];
    this.apiCallCount = 0;
    this.offSet = 0;  
    this.pager["offset"] =0;  
    this.getStatus(1);
  }

  onPageChange(page: number) {
  this.pager["current_page"] = page;
  this.pager["offset"] = this.itemPerPageCount * (page - 1);
  }

  //To get Status
  getStatus(page: number){
    this.request_params['offset'] = this.offSet;
    this.loaderService.display(true);  
    this.statusService.getStatus(this.request_params).subscribe((res:any)=>{
      this.loaderService.display(false);
      this.apiCallCount++;
      if(res.status=='success' && !_.isNull(res.status_result)){
        this.pager["total_count"] = this.statuses.length + res.status_result.length;
				this.pager["current_page"] = page;
				const result = this.statuses.concat(res.status_result);
        this.statuses = res.status_result;
        //Load full result
				if(!_.isNil(res.max_possible_res_length) && res.max_possible_res_length > this.statuses.length) {
					this.offSet = this.apiCallCount * this.queryLimit;

					this.getStatus(1);
				}    
      }else {
        if(this.statuses.length === 0) {
          this.statuses = [];
          this.no_data = 'No Data';
					this.pager["total_count"] = 0;
					this.pager["current_page"] = page;
				}
        if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
          this.toastr.error("Kindly login","Session Expired");
          this.authServiceObj.logout();
        }
      }
    })
  }

  //delete selected status
  deleteStatus(id:any){
    var cnf=confirm("Are you sure you want to delete this Status?")
    if(cnf){
      this.statusService.delete(id).subscribe((res:any)=>{
        if(res.status=='success'){
          this.toastr.success('Status deleted successfully!','SUCCESS');
          this.status={
            id:'',
            name:'',
            description:'',
            value:true
          };
          this.statuses = [];
          this.apiCallCount = 0;
          this.offSet = 0;        
          this.getStatus(1);
          }else {
            if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
              this.toastr.error("Kindly login","Session Expired");
              this.authServiceObj.logout();             
            }else{
              this.toastr.error(res.message,'Failed');
            }
          }
      })
    }
  }

  onSort(orderby: string, id: any) {
		if (this.request_params["orderby"] === orderby) {
			if (this.request_params["order"] === "asc") {
				this.request_params["order"] = "desc";
			} else {
				this.request_params["order"] = "asc";
			}
		} else {
			this.request_params["orderby"] = orderby;
			this.request_params["order"] = "desc"
		}
		//Reset the page to 1, offset to 0
		this.pager["current_page"] = 1;
		this.pager["offset"] = 0;
		this.statuses = this.statusService.sortStatusList(this.statuses, this.request_params["orderby"], this.request_params["order"]);
	}

  //Get status for edit
  editStatus(status:any){
    this.title='Edit Status';
    this.saveButton=' Save ';
    this.cancelButton= 'Cancel';
    this.status={
      id:status.id,
      name:status.name,
      description:status.description,
      val:status.value
    };
  }

  //To cancel editing and show add form
  cancel(){
    this.title='Status';
    this.saveButton=' Add ';
    this.cancelButton= 'Clear';
    this.status={
      id:'',
      name:'',
      description:'',
      val: true
    };
  }

  ngOnInit() {
  }

}
