import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { LoaderService } from '../../services/loader/loader.service';
import { AuthService } from '../../services/auth/auth.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import {SmartProxyService} from '../../services/smartproxy'
declare var $: any;

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
    public dashboardDetails: any;
    public proxyDataLeft:any;
    constructor(private dashboardService: DashboardService,
        private loaderService: LoaderService,
        private toastr: ToastrService,
        private authServiceObj: AuthService,
        private smartProxy:SmartProxyService) {
        this.dashboardDetails = {
            clients: 0,
            social_medias: 0,
            market_places: 0,
            search_engines: 0
        }

    }
    ngAfterContentInit() {
        $("body").height("100%");
        $("html").height("100%");
        /*
         * load chest types
         */
        this.getDashboardDetails();

    }

    ngOnInit() {
        // this.getProxyDataLimit();
    }

    getProxyDataLimit() {
        this.proxyDataLeft = "Calculating.."
        this.smartProxy.getProxyLimit().subscribe((res:any)=>{
        //   this.proxyDataLeft = res[0]['traffic'] + " / " + res[0]['traffic_limit'] + " GB"
          this.proxyDataLeft = res[0]&&res[0]['traffic'] + " / " +res[0]&& res[0]['traffic_limit'] + " GB"
          /*if(res['status'] == 'success') {
            this.proxyDataLeft = res['message'];
          } else {
            console.log('proxy responce ');
            console.log(res)
            this.proxyDataLeft = res['message'];// res['traffic_limit'];
          }*/
        })
      }
    
    getDashboardDetails() {
        this.loaderService.display(true);
        this.dashboardService.getDashboardDetails().subscribe((res: any) => {
            this.loaderService.display(false);
            console.log(res)
            if (res.status == 'success') {

                this.dashboardDetails = {
                    clients: res.data.clients,
                    social_medias: res.data.social_medias,
                    market_places: res.data.market_places,
                    search_engines: res.data.search_engine
                }
            }
            else {
                if (res.message == "invalid_accesstoken" || res.message == "un_authorised" || res.message == "Invalid token") {
                    //this.toastr.error('Invalid User','Request Failed');
                    this.authServiceObj.logout();
                }
            }
        })
    }


    // upload() {
    //     console.log('yes upload');
    //     this.dashboardService.imageUpload().subscribe((res: any) => {

    //      })
    // }


}
