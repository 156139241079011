import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Login} from '../../interfaces/login/login';
import {AuthService} from '../../services/auth/auth.service';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import * as $ from 'jquery';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public login_c: Login;
  public errorMessage: string;
  public login_from_logout:any;
  public submitted:boolean;
  public password_error_flag:boolean;
  public email_error_flag:boolean;

  constructor(public route: Router,  public localSt: LocalStorageService,private AuthServiceObj:AuthService) {
    this.login_c = {email: '', password: ''}
    this.errorMessage = "";
    this.submitted=false;
    this.password_error_flag=false;
    this.email_error_flag=false;

    this.login_from_logout = this.localSt.retrieve("login_after_logout");
    if(this.login_from_logout == true) {
      this.localSt.store("login_after_logout",false);
    } 

  }
  onSubmit = function (valid) {
    this.submitted=true;
    if(valid){
      this.AuthServiceObj.authenticate(this.login_c).subscribe((res: any) => {
        
           if (res.status == "success") {
             this.email_error_flag=false;
             this.password_error_flag=false;
             let user_details = res.userdetails;
             this.localSt.store("user_details",user_details );
             this.localSt.store("access_token", res.token);
             console.log(' this.localSt',  this.localSt);
             
                  // redirect to dashbord
                  this.route.navigate(['/']);

                } else {
                  if(res.message=="invalid_email"){
                    this.email_error_flag=true;
                  }
                  else if (res.message=="invalid_password") {
                    this.email_error_flag=false;
                    this.password_error_flag=true;
                  }
                  else{
                    this.errorMessage = res.message;
                  }
                  
                }
              });
    }
        /*
         * authenticate using Service API
         */
         
       }
       resetEmailErrors(){
        this.email_error_flag=false;
       }
       resetPasswordErrors(){
        this.password_error_flag=false;
       }

       ngOnInit() {
       }
       ngAfterContentInit() {

         $("body").height("100%");
         $("html").height("100%");
       }

     }
