import { Component, OnInit,Compiler } from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {AuthService} from '../../services/auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ClientService} from '../../services/client/client.service';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public logoMini:any;
  public logoLg:any;
  public user_details:any;
  public user_type:any;
  public notifications:any;
  public notification_count:number;
  public notication_count_after_view:number

  constructor(public localSt:LocalStorageService,
    public authServiceObj: AuthService,
    public _compiler: Compiler,
    public router:Router,
    private toastr: ToastrService,
    private clientService:ClientService) { 
    this.user_details = this.localSt.retrieve("user_details");
    this.notifications=[];
    this.notification_count=0;
    this.notication_count_after_view=0;

  }

  ngOnInit() {
    this.getNotifications();
  }
  

  logout(){
    let res = this.authServiceObj.logout();
    if (res) {

      console.log('logout Success');
    } else {
      console.log(res,"sdsdsd")
      console.log('logout Failed');
    }
  }
  getNotifications(){
    this.clientService.getNotification().subscribe((res: any) => {
      if(res.status=="success"){

        this.notifications=res.data
        //this.notification_count=0;
        this.notification_count=res.total_count;
        
      }
      else {
        if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
          this.authServiceObj.logout();
        }
        else{
          this.toastr.error(res.message,'Error');
        }
      }

    },(e)=>{

    });
  }
  navigateToDeleteRequest(request_id:any,id:any){
           let data={id:id};
       this.clientService.updateReadStatus(data).subscribe((res:any)=>{
         if(res.status=="success"){
           this.getNotifications()
           this.router.navigate(['delete-request'], { queryParams: { request_id: request_id } });
         } else {
           if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
             this.authServiceObj.logout();
           }
           else{
             this.toastr.error(res.message,'Failed');
           }
         }

       })
       
    }
}
