
import {fromEvent as observableFromEvent} from 'rxjs';
import { Component, OnInit } from '@angular/core';
import {CountryService} from '../../../services/master-data/country/country.service';
import * as $ from 'jquery';
import * as _ from 'lodash';
import {Observable} from 'rxjs/';
import {LoaderService} from '../../../services/loader/loader.service';
import {NgxPaginationModule} from 'ngx-pagination';//importing ng2-pagination
import {AuthService} from '../../../services/auth/auth.service';
import {CurrencyService} from '../../../services/master-data/currency/currency.service';
import {AppSettings} from '../../../settings.global';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import {debounceTime} from 'rxjs/operators';



@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.css']
})
export class CountryComponent implements OnInit {
  public request_params:any;
  public currency_request_params:any;
  public pager:any;
  public queryLimit: number = AppSettings.DEFAULT_QUERY_LIMIT;
	public itemPerPageCount: number = AppSettings.DEFAULT_PAGER_COUNT;
	public apiCallCount: number = 0;
	public offSet: number = 0;
  public country:any;
  public countries:any;
  public currencyList:any;
  public title:any;
  public saveButton:any;
  public cancelButton:any;
  public submitted:boolean;
  public no_data:any;
  public searchInputObservable$: any;
  public regions: any;
  public user_details:any;

  constructor(private countryService:CountryService,public localSt:LocalStorageService, private loaderService:LoaderService, private toastr: ToastrService, private authServiceObj:AuthService, private currencyService:CurrencyService) { 
    this.title='Country';
    this.saveButton=' Add ';
    this.cancelButton= 'Clear';
    this.submitted=false;    
    this.no_data="Loading...."
    this.request_params = {
			searchkey: "",
			orderby: "",
			order: "",
			limit: this.queryLimit,
			offset: 0
    }
    this.currency_request_params = {
      searchkey: "",
      orderby: "",
      order: "",
      limit: this.queryLimit,
      offset: 0
    }
		this.pager = {
			limit: this.itemPerPageCount,
			offset: 0,
			total_count: 0,
			current_page: 1
		}
    this.country={
      id:'',
      name:'',
      code:'',
      region:'',
      currency:''
    };
    this.countries=[];
    this.currencyList=[];
    this.regions=[];
    this.user_details = this.localSt.retrieve("user_details");
  }

  ngAfterContentInit() {

    //Input search observable - to avoid the  onInputChange() firing whenever the input changes
		const searchInputObj = document.querySelector('#search_text');
		this.searchInputObservable$ = observableFromEvent(searchInputObj, 'keyup');

    this.searchInputObservable$.pipe(debounceTime(100))
    .subscribe((event: any) => {
      this.onInputChange(event.target.value);
    });

    $("body").height("100%");
    $("html").height("100%");
    this.getRegion();

     /*
     * API call and grab full data
     */
		setTimeout(() => {
			this.getCountry(1);
    },10);

    /*
    * API call and grab currency data
    */
		setTimeout(() => {
			this.getCurrency(1);
    },10);    
  }

  //get the region 
	getRegion(){
		this.countryService.getRegionSelect().subscribe((res:any)=>{
		  if(res.status=='success'){
      this.regions=res.array;
      console.log('^^^^this.regions--', this.regions)
		  }
		  else{
			if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
			    this.toastr.error("Kindly login","Session Expired");
				  this.authServiceObj.logout();
			}
		  }
		})
	}

  onSubmit(valid){
    console.log('^^^^^onsubmit---',this.country)
    this.submitted=true;
    if(valid){
      this.loaderService.display(true);
      this.countryService.createUpdateCountry(this.country).subscribe((res:any)=>{
        this.loaderService.display(false);
        if(res.message=="success") {         
          if(this.saveButton==' Add ') {
            this.toastr.success('Added Successfully!','SUCCESS');
          } else {
            this.toastr.success('Updated Successfully!','SUCCESS');
          }          
          this.countries = [];
          this.apiCallCount = 0;
          this.offSet = 0;
          this.getRegion();
          this.getCountry(1);
          this.title='Country';
          this.saveButton=' Add ';
          this.cancelButton= 'Clear';
          this.submitted=false;

          this.country={
                id:'',
                name:'',
                code:'',
                region:'',
                currency:''
            };
        }else if(res.message == "Country already exists"){
          this.toastr.error('Country already exists','Request Failed');
        }else {
          if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
            this.toastr.error("Kindly login","Session Expired");
            this.authServiceObj.logout();
          }else{
            console.log("the error is",res);
            this.toastr.error(res.message,'Success');
          }
        }
      },(e)=>{

          });
    }
  }

  onInputChange(text) {
    this.onSearch();
  }

  // //-------Get currency list------
  //To get Currency
  getCurrency(page: number){
    this.currency_request_params['offset'] = this.offSet;
    this.loaderService.display(true);  
    this.currencyService.getCurrency(this.currency_request_params).subscribe((res:any)=>{
      this.loaderService.display(false);
      this.apiCallCount++;
      if(res.status=='success' && !_.isNil(res.currency_result) && ! _.isNull(res.currency_result)){
        this.pager["total_count"] = this.currencyList.length + res.currency_result.length;
                this.pager["current_page"] = page;
                const result = this.currencyList.concat(res.currency_result);
        this.currencyList = res.currency_result;

        //Sort the list of currency in Ascending order
        this.currencyList = this.currencyList.sort((a, b) => (a.code > b.code) ? 1 : -1) 


        //Load full result
                if(!_.isNil(res.max_possible_res_length) && res.max_possible_res_length > this.currencyList.length) {
                    this.offSet = this.apiCallCount * this.queryLimit;
                    this.getCurrency(1);
        }  
      }else {
        if(this.currencyList.length === 0 || res.max_possible_res_length === 0) {
          this.currencyList = [];
          this.no_data = 'No Data';
                    this.pager["total_count"] = 0;
                    this.pager["current_page"] = page;
                }
        if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
          this.toastr.error("Kindly login","Session Expired");
          this.authServiceObj.logout();
        }
      }
    })
  }


  //-------Get currency list------


  //To get Country
  getCountry(page: number){
    this.request_params['offset'] = this.offSet;
    console.log(this.request_params,"paras ");
    this.loaderService.display(true);  
    this.countryService.getCountry(this.request_params).subscribe((res:any)=>{
      this.loaderService.display(false);
      this.apiCallCount++;
      if(res.status=='success' && !_.isNull(res.country_result)){
        this.pager["total_count"] = this.countries.length + res.country_result.length;
				this.pager["current_page"] = page;
        const result = this.countries.concat(res.country_result);
        console.log("The countries are: ",res.country_result);
        this.countries = res.country_result;

        // this.currencyList = res.country_result;
        //Load full result
				if(!_.isNil(res.max_possible_res_length) && res.max_possible_res_length > this.countries.length) {
					this.offSet = this.apiCallCount * this.queryLimit;

					this.getCountry(1);
				}    
      }else {
        if(this.countries.length === 0 || res.max_possible_res_length === 0) {
          this.countries = [];
          this.no_data = 'No Data';
					this.pager["total_count"] = 0;
					this.pager["current_page"] = page;
				}
        if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
          this.toastr.error("Kindly login","Session Expired");
          this.authServiceObj.logout();
        }
      }
    })
  }
  onSearch() {
		this.countries = [];
		this.apiCallCount = 0;
    this.offSet = 0; 
    this.pager["offset"] =0;   
    this.getCountry(1);
   }

  onPageChange(page: number) {
  this.pager["current_page"] = page;
  this.pager["offset"] = this.itemPerPageCount * (page - 1);
  }
   
  onSort(orderby: string, id: any) {
		if (this.request_params["orderby"] === orderby) {
			if (this.request_params["order"] === "asc") {
				this.request_params["order"] = "desc";
			} else {
				this.request_params["order"] = "asc";
			}
		} else {
			this.request_params["orderby"] = orderby;
			this.request_params["order"] = "desc"
		}
		//Reset the page to 1, offset to 0
		this.pager["current_page"] = 1;
		this.pager["offset"] = 0;
		this.countries = this.countryService.sortCountryList(this.countries, this.request_params["orderby"], this.request_params["order"]);
	}   

  //Get Country for edit
  editCountry(country:any){
    console.log("The country is: ",country)
    console.log("^^^^country list is: ",this.currencyList)
    this.title='Edit Country';
    this.saveButton=' Save ';
    this.cancelButton= 'Cancel';
    this.country={
      id:country.id,
      name:country.name,
      code:country.code,      
      region:country.region,
      currency:country.currency
    };
  }

  //delete selected Country
  deleteCountry(id:any){
    var cnf=confirm("Are you sure you want to delete this Country?")
    if(cnf){
      this.countryService.delete(id).subscribe((res:any)=>{
        if(res.status=='success'){
          this.toastr.success('Country Deleted Successfully!','SUCCESS');
          this.country={
            id:'',
            name:'',            
            code:'',
            currency:'',
            region:''
          };
          this.countries = [];
          this.apiCallCount = 0;
          this.offSet = 0;        
          this.getCountry(1);
          }else {
            if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
              this.toastr.error("Kindly login","Session Expired");
              this.authServiceObj.logout();             
            }else{
              this.toastr.error(res.message,'Failed');
            }
          }
      })
    }
  }

  //To cancel editing and show add form
  cancel(){
    this.title='Country';
    this.saveButton=' Add ';
    this.cancelButton= 'Clear';
    this.country={
      id:'',
      name:'',
      code:'',
      currency:'',
      region:''
    };
  }

  ngOnInit() {
  }

}
