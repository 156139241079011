
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

import { AppSettings } from '../../settings.global';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class DashboardService {
	public headers: HttpHeaders;

	constructor(private httpClient: HttpClient, private localSt: LocalStorageService) {
		this.headers = new HttpHeaders({
			'Content-Type': 'application/json;charset=UTF-8',
			'Accept': 'application/json',
			'x-access-token': this.localSt.retrieve("access_token")
		});
	}
	//Function to get chest icons
	getDashboardDetails() {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.get(AppSettings.API_ENDPOINT + '/dashboard/admin?user_id=' + this.localSt.retrieve("user_details").id, options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));
	}

	//Function to get chest icons
	// imageUpload() {
	// 	this.headers = new HttpHeaders({
	// 		'x-access-token': this.localSt.retrieve("access_token")
	// 	});
	// 	// console.log('imageForm', imageForm);
	// 	// const formData = new FormData();
	// 	// formData.append('image', imageForm);
	// 	// console.log('imageFormformData', formData);
	// 	let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
	// 	return this.httpClient.post('http://192.168.43.240:4400/api/v1/brand/s3-upload', {}, options).pipe(
	// 		map(function (res: any) {
	// 			return res.body || { status: "failed", message: "Somthing went wrong" };
	// 		}));
	// }
}
