import { Component, OnInit } from '@angular/core';
import {ProfileService} from '../../services/profile/profile.service';
import {AuthService} from '../../services/auth/auth.service';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
declare var $:any;
import {LoaderService} from '../../services/loader/loader.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
	public profile:any;
	public errorMessage='';
	public modalErrorMessage='';
	public cur_password='';
	public new_password='';
	public cnf_password='';
	public submitted:boolean;
	public submit_change_password:boolean;
	public user_details:any;

	constructor(private adminService:ProfileService,
		private localSt: LocalStorageService,private authServiceObj:AuthService,
		private toastr: ToastrService,private loaderService:LoaderService) {
		this.submitted=false;
		this.submit_change_password=false;
		this.profile={
			id:null,
			firstname:'',
			middlename:'',
			lastname:'',
			email:'',
			mobile:'',
		}
		this.user_details=this.localSt.retrieve("user_details");

	}
	getAdminDetails(){
		this.loaderService.display(true);
		this.adminService.getAdminDetails().subscribe((res: any) => {
			this.loaderService.display(false);
			if (res.status == "success") {
				this.profile={
					id:res.data_found.id,
					firstname:res.data_found.first_name,
					middlename:res.data_found.middle_name,
					lastname:res.data_found.last_name,
					email:res.data_found.email,
					mobile:res.data_found.mobile_no,
				}
			}

		});
	}
	//Update profile
	onSubmit(valid){
		this.submitted=true;
		if(valid){
			this.loaderService.display(true);
			this.adminService.updateProfile(this.profile).subscribe((res:any)=>{
				this.loaderService.display(false);
				if(res.status=="success"){
					
					this.toastr.success('Updated Successfully','Success');
					this.errorMessage='';
					this.submitted=false;
					this.getAdminDetails();
				}
				else{
					this.errorMessage=res.message;
				}
			});
		}		
	}
	newForm(){
		this.cur_password='';
		this.new_password='';
		this.cnf_password='';
		this.modalErrorMessage='';
		this.submit_change_password=false;
		$('#changePassword').modal('show');
	}
	changePassword(valid){
		console.log(this.cur_password,this.cnf_password)
		this.modalErrorMessage="";
		this.submit_change_password=true;
		if(valid){
		if(this.new_password!==this.cnf_password){
			this.modalErrorMessage="New password and confirm password do not match";
			return false;
		}
		let request_params: any = {
			cur_password: this.cur_password,
			new_password:this.new_password,
			id:this.localSt.retrieve("user_details").id

		}
		this.authServiceObj.changePassword(request_params).subscribe((res: any) => {
			if (res.status == "success") {
				this.submit_change_password=false;
				$("#changePassword").modal('hide');
				window.alert("Successfully changed");
			}
			else{
				this.modalErrorMessage=res.message;
			}
		});
	}
}

  ngOnInit() {
  	this.getAdminDetails();
  }

}
