import { Component, OnInit } from '@angular/core';
import {UpcService} from '../../../../services/upc/upc.service';
import {ClientService} from '../../../../services/client/client.service';
import {NgxPaginationModule} from 'ngx-pagination';//importing ng2-pagination
import {Routes, RouterModule, ActivatedRoute, Router, Params} from '@angular/router';
import {LoaderService} from '../../../../services/loader/loader.service';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
declare var $:any;
import {Client} from '../../../../interfaces/upc/upc';
import {AuthService} from '../../../../services/auth/auth.service';
import {AppSettings} from '../../../../settings.global';
import { BrandService } from '../../../../services/brand/brand.service';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MarketplaceService } from '../../../../services/settings/marketplace/marketplace.service';
import { ToastrService } from 'ngx-toastr';




@Component({
  selector: 'app-upc-add',
  templateUrl: './upc-add.component.html',
  styleUrls: ['./upc-add.component.css'],
  providers: [UpcService]
})
export class UpcAddComponent implements OnInit {
// Variable decleration
dropdownList = [];
tempDropdown = [];
temp2Dropdown = [];
dropdownSettings = {};

public upc:any;
public upcs:any;
public clients:any;
public client:any;
public brands:any;
public submited:boolean;
public errorFlag:boolean;
public title:any;
public request_params:any
public upc_id:any;
public brand_id:any;
public flag:boolean
public newMarketPlace:any



  constructor(
    private brandService:BrandService,
private marketPlaceService:MarketplaceService,
private upcService:UpcService,
private clientService:ClientService,
private localSt:LocalStorageService,
	private loaderService:LoaderService,
	private toastr: ToastrService,
	private route: Router,
	private activatedRoute: ActivatedRoute,
	private authServiceObj:AuthService) {
    this.title = "Add New Product";
    this.upc={
      id:null,
      client:'',
      brand:'',
      marketplace:'',
      markertPlace:[],
      productId:'',
      edit:false,
    }
    this.submited = false;
    this.errorFlag = false;
    this.flag =false;
    this.upcs=[];
   }
   


   ngAfterContentInit() {

    $("body").height("100%");
    $("html").height("100%");

    this.getClient();
    this.getMarketPlace()



    this.activatedRoute.params.subscribe((params: Params) => {
    this.upc_id = params['id'];
    console.log(this.upc_id,"this is paramsid")
  
      if (this.upc_id) { 
               // Updation
               this.title="Edit Upc";
               this.flag = true
              // this.edit_flag=true;

               // Getting the Upc data  
               this.upcService.getUpcDetails(this.upc_id).subscribe((res: any) => {
                 console.log(res.data_found[0],"this is edit details")
                 if (res.status == "success") {
                   this.upc={
                     id: this.upc_id,
                     client:res.data_found[0].client_id,
                     brand:res.data_found[0].brand_id,
                     marketPlace:res.data_found[0].marketplace_id,
                     productId:res.data_found[0].product_code,
                     edit:true
                   }
                   console.log(this.upc,"upccccccc");
                   
                   this.client=res.data_found[0].client_id;
                   this.getBrand(this.client)
                  
                   
                 } else {
                   if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
                     this.authServiceObj.logout();
                   }
                   else{
                     this.toastr.error(res.message,'Failed');
                   }
                 }
               });
           }else{
            this.client=this.activatedRoute.snapshot.queryParams['client_id'];
            this.upc={
              id:'',
              client:parseInt(this.client),
              brand:'',
              markertPlace:'',
              productId:'',
              edit:false
            };
           } 
  
       });
  }

      //get the client 
      getClient(){
        this.clientService.getClientSelect().subscribe((res:any)=>{
          if(res.status=='success'){
          this.clients=res.data_found;
          this.getBrand(this.client)
          }
          else{
            if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
                  this.toastr.error("Kindly login","Session Expired");
                  this.authServiceObj.logout();
            }
          }
        })
      }
  



// get MarketPlaces
getMarketPlace(){
  
  this.marketPlaceService.getMarketPlaces('').subscribe((res:any)=>{
    
    if(res.status=='success'){

      this.newMarketPlace = res.market_result
      console.log(this.newMarketPlace,"new marketplace")
      
     for(let data of res.market_result){
       this.tempDropdown.push({
         "item_text":data.name,
         "item_id":data.id
        })
     }
    }else{
      if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
        this.toastr.error("Kindly login","Session Expired");
        this.authServiceObj.logout();
  }
    }
    this.dropdownList  = this.tempDropdown
    console.log(this.dropdownList,"this is dropdownsss")
    
  });
}

  // on from submit
   onSubmit(valid){
   console.log(this.upc,"this is upc");
   
    this.submited=true;
    var user = this.localSt.retrieve('user_details');
    let data = {
      upc : this.upc,
      user_id : user.id
    }
    if(valid){
      this.loaderService.display(true);
      this.upcService.createUpdateUpc(data).subscribe((res: any) => {
        this.loaderService.display(false);
        if(res.status=="success"){
          this.toastr.success('Updated Successfully!','SUCCESS');
          this.route.navigate(['upc/'],{ queryParams: { client_id: this.client } });
        }
        else {
          if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
            this.authServiceObj.logout();
          }
          else{
            this.toastr.error(res.message,'Error');
          }
        }
      },(e)=>{
      });
    }
  }


 
    // get brand for select
    getBrand(newValue) {
      this.brands = [];
      this.loaderService.display(true);
      this.brandService.getBrandSelect(newValue).subscribe((res:any)=>{
        console.log(res,"this is brand datas")
          this.loaderService.display(false);
          if(res.status=='success'){
              this.brands=res.data_found;
          }
          else{
              if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
                  this.toastr.error("Kindly login","Session Expired");
                  this.authServiceObj.logout();
              }
          }
      })
  }




  // cancel button
    cancel(){

      this.route.navigate(['upc/'], { queryParams: { client_id: this.client } });
    }

  ngOnInit() {

 this.getMarketPlace() 
// multidropdown settings
this.dropdownSettings = {
  singleSelection: false,
  idField: 'item_id',
  textField: 'item_text',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 3,
  allowSearchFilter: false
};
  }


  //selecting one elements in dropdown
  onItemSelect(item: any) {
    console.log(item);
   this.temp2Dropdown.push(item.item_id)
  
   console.log(this.temp2Dropdown,'this is drpdn');
   
   this.upc.markertPlace =this.temp2Dropdown
  }
  // seleting all elements in dropdown
  onSelectAll(items: any) {
    items.forEach((item:any)=>{
   this.temp2Dropdown.push(item.item_id)
   })
   
    this.upc.markertPlace = this.temp2Dropdown
  }


}
