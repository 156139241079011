
import {fromEvent as observableFromEvent} from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../../services/master-data/language/language.service';
import * as $ from 'jquery';
import * as _ from 'lodash';
import {Observable} from 'rxjs/';
import {LoaderService} from '../../../services/loader/loader.service';
import {NgxPaginationModule} from 'ngx-pagination';//importing ng2-pagination
import {AuthService} from '../../../services/auth/auth.service';
import {AppSettings} from '../../../settings.global';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit {
  public request_params:any;
  public pager:any;
  public queryLimit: number = AppSettings.DEFAULT_QUERY_LIMIT;
	public itemPerPageCount: number = AppSettings.DEFAULT_PAGER_COUNT;
	public apiCallCount: number = 0;
	public offSet: number = 0;
  public language:any;
  public languages:any;
  public title:any;
  public saveButton:any;
  public cancelButton:any;
  public submitted:boolean;
  public no_data:any;
  public searchInputObservable$: any;
  public user_details:any;

  constructor(private languageService:LanguageService,private loaderService:LoaderService,
    private toastr: ToastrService, private authServiceObj:AuthService,public localSt:LocalStorageService) { 
      this.title='Language';
      this.saveButton=' Add ';
      this.cancelButton= 'Clear';
      this.submitted=false;    
      this.no_data="Loading...."
      this.request_params = {
        searchkey: "",
        orderby: "",
        order: "",
        limit: this.queryLimit,
        offset: 0
      }
      this.pager = {
        limit: this.itemPerPageCount,
        offset: 0,
        total_count: 0,
        current_page: 1
      }
      this.language={
        id:'',
        name:'',
        description:''
      };
      this.languages=[];
      this.user_details = this.localSt.retrieve("user_details");
  }

  ngAfterContentInit() {

    //Input search observable - to avoid the  onInputChange() firing whenever the input changes
		const searchInputObj = document.querySelector('#search_text');
		this.searchInputObservable$ = observableFromEvent(searchInputObj, 'keyup');

    this.searchInputObservable$.pipe(debounceTime(100))
    .subscribe((event: any) => {
      this.onInputChange(event.target.value);
    });

    $("body").height("100%");
    $("html").height("100%");

    /*
         * API call and grab full data
         */
		setTimeout(() => {
			this.getLanguages(1);
		},10);    
  }

  //To get languages
  getLanguages(page: number){
    this.request_params['offset'] = this.offSet;
    this.loaderService.display(true);  
    this.languageService.getLanguages(this.request_params).subscribe((res:any)=>{
      this.loaderService.display(false);
      this.apiCallCount++;
      if(res.status=='success' && !_.isNull(res.language_result)){
        this.pager["total_count"] = this.languages.length + res.language_result.length;
				this.pager["current_page"] = page;
				const result = this.languages.concat(res.language_result);
        this.languages = res.language_result;
        //Load full result
				if(!_.isNil(res.max_possible_res_length) && res.max_possible_res_length > this.languages.length) {
					this.offSet = this.apiCallCount * this.queryLimit;

					this.getLanguages(1);
				}    
      }else {
        if(this.languages.length === 0 || res.max_possible_res_length === 0) {
          this.languages = [];
          this.no_data = 'No Data';
					this.pager["total_count"] = 0;
					this.pager["current_page"] = page;
				}
        if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
          this.toastr.error("Kindly login","Session Expired");
          this.authServiceObj.logout();
        }
      }
    })
  
  }

  onSubmit(valid){
    this.submitted=true;
    if(valid){
      this.loaderService.display(true);
      this.languageService.createUpdateLanguage(this.language).subscribe((res: any) => {
        this.loaderService.display(false);
        if(res.status=="success"){
          if(this.saveButton==' Add ') {
            this.toastr.success('Added Successfully!','SUCCESS');
          } else {
            this.toastr.success('Updated Successfully!','SUCCESS');
          }           
          this.languages = [];
          this.apiCallCount = 0;
          this.offSet = 0;
          this.getLanguages(1);
          this.title='Language';
          this.saveButton=' Add ';
          this.cancelButton= 'Clear';
          this.submitted=false;
          this.language={
                id:'',
                name:'',
                description:''
              };
        }else if(res.message == "Language already exists"){
          this.toastr.error('Language already exists','Request Failed');
        }
        else {
          if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
            this.authServiceObj.logout();
          }
          else{
            this.toastr.error(res.message,'Error');
          }
        }
  
      },(e)=>{
  
      });
    }
  
  }

  onInputChange(text) {
    this.onSearch();
  }

  onSearch() {
		this.languages = [];
		this.apiCallCount = 0;
    this.offSet = 0; 
    this.pager["offset"] =0;  
    this.getLanguages(1);
   }

  onPageChange(page: number) {
  this.pager["current_page"] = page;
  this.pager["offset"] = this.itemPerPageCount * (page - 1);
  }

  //Get language for edit
  editLanguage(language:any){
    this.title='Edit Language';
    this.saveButton=' Save ';
    this.cancelButton= 'Cancel';
    this.language={
      id:language.id,
      name:language.name,
      description:language.description
    };
  }

  //delete selected language
  deleteLanguage(id:any){
    var cnf=confirm("Are you sure you want to delete this Language?")
    if(cnf){
      this.languageService.delete(id).subscribe((res:any)=>{
        if(res.status=='success'){
          this.toastr.success('Language deleted successfully!','SUCCESS');
          this.language={
            id:'',
            name:'',
            description:''
          };
          this.languages = [];
          this.apiCallCount = 0;
          this.offSet = 0;
        
          this.getLanguages(1);
          }else {
            if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
              this.toastr.error("Kindly login","Session Expired");
              this.authServiceObj.logout();             
            }else{
              this.toastr.error(res.message,'Failed');
            }
          }
      })
    }
  }

  onSort(orderby: string, id: any) {
		if (this.request_params["orderby"] === orderby) {
			if (this.request_params["order"] === "asc") {
				this.request_params["order"] = "desc";
			} else {
				this.request_params["order"] = "asc";
			}
		} else {
			this.request_params["orderby"] = orderby;
			this.request_params["order"] = "desc"
		}
		//Reset the page to 1, offset to 0
		this.pager["current_page"] = 1;
		this.pager["offset"] = 0;
		this.languages = this.languageService.sortLanguageList(this.languages, this.request_params["orderby"], this.request_params["order"]);
	}   

  //To cancel editing and show add form
  cancel(){
    this.title='Language';
    this.saveButton=' Add ';
    this.cancelButton= 'Clear';
    this.language={
      id:'',
      name:'',
      description:''
    };
  }

  ngOnInit() {
  }

}
