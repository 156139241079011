
import {of as observableOf, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {AppSettings} from '../../../settings.global';
import {ActivatedRoute, Router} from '@angular/router';
declare var $:any;
import * as _ from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()
export class ProductCategoryService {

	public headers: HttpHeaders;

  constructor(private httpClient: HttpClient, private localSt: LocalStorageService) {
	this.headers = new HttpHeaders({
		'Content-Type': 'application/json;charset=UTF-8',
		'Accept': 'application/json',
		'x-access-token': this.localSt.retrieve("access_token")
	});
  }


  createUpdateProductCategory(body:any){
	let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		if(body.id){
			return this.httpClient.put(AppSettings.API_ENDPOINT +'/masterdata/category',body, options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));
		}else{
			return this.httpClient.post(AppSettings.API_ENDPOINT +'/masterdata/category',body, options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));
		}
  }

  getProductCategory(body:any){
	let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		let bodyString = $.param(body);
		return this.httpClient.get(AppSettings.API_ENDPOINT +'/masterdata/category?'+bodyString, options).pipe(
		map(function (res: any) {
			return res.body || {
				category: "failed",
				message: "Something went wrong"
			};
		}),catchError((error:any) => {
			return observableOf({category:"error",message:"Server error occured"})
		}),);
  }

  delete(id:any){
	let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.delete(AppSettings.API_ENDPOINT +'/masterdata/category?id='+id,options).pipe(
		map(function(res:any){
			return res.body || {category: "failed", message: "Somthing went wrong"};
		}));
	}
    sortCategoryList(statusData, orderBy, order){
      return _.orderBy(statusData, [orderBy], [order]);
  }
  }

