
import {fromEvent as observableFromEvent} from 'rxjs';
import { Component, OnInit } from '@angular/core';

declare var $: any;
import * as _ from 'lodash';
import { Observable } from 'rxjs/';
import { LoaderService } from '../../../services/loader/loader.service';
import { CurrencyService } from '../../../services/master-data/currency/currency.service';
import { AppSettings } from '../../../settings.global';
import { AuthService } from '../../../services/auth/auth.service';
import { CountryService } from '../../../services/master-data/country/country.service';
import { MotherCompanyService } from '../../../services/master-data/mother-company/mother-company.service';
import { MarketplaceService } from '../../../services/settings/marketplace/marketplace.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.css']
})
export class MarketplaceComponent implements OnInit {
  public submited: boolean;
  public request_params: any;
  public title: string;
  public searchInputObservable$: any;
  public currency_request_params: any;
  public m_request_params: any;
  public queryLimit: number = AppSettings.DEFAULT_QUERY_LIMIT;
  public itemPerPageCount: number = AppSettings.DEFAULT_PAGER_COUNT;
  public offSet: number = 0;
  public apiCallCount: number = 0;
  public pager: any;
  public currencyList: any;
  public no_data: any;
  public countries: any;
  public marketplace: any;
  public mcompanies: any;
  public marketplace_id: any;
  mcompany: any;
  marketplace_data: any;
  country: any;
  curList: any[];
  abc: any;
  public disableCurrency: boolean;

  constructor(private currencyService: CurrencyService,
    private loaderService: LoaderService,
    private toastr: ToastrService,
    private authServiceObj: AuthService,
    private countryService: CountryService,
    private motherCompanyService: MotherCompanyService,
    private marketplaceService: MarketplaceService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private localStorage: LocalStorageService) {

    this.title = "ADD NEW MARKETPLACE ";
    this.disableCurrency = false;
    this.currency_request_params = {
      searchkey: "",
      orderby: "",
      order: "",
      limit: this.queryLimit,
      offset: 0
    };
    this.request_params = {
      searchkey: "",
      orderby: "",
      order: "",
      limit: this.queryLimit,
      offset: 0
    };
    this.m_request_params = {
      searchkey: "",
      orderby: "",
      order: "",
      limit: this.queryLimit,
      offset: 0
    }
    this.pager = {
      limit: this.itemPerPageCount,
      offset: 0,
      total_count: 0,
      current_page: 1
    };
    this.marketplace = {
      name: "",
      description: "",
      parent_company: "",
      country: "",
      currency: "",

    };
    this.currencyList = [];
    this.no_data = "Loading....";
    this.countries = [];
    this.mcompanies = [];

  }

  ngOnInit() {
  }

  ngAfterContentInit() {

    //Input search observable - to avoid the  onInputChange() firing whenever the input changes
    const searchInputObj = document.querySelector('#search_text');
    this.searchInputObservable$ = observableFromEvent(searchInputObj, 'keyup');
    

    $("body").height("100%");
    $("html").height("100%");

    setTimeout(() => {
      this.getCountry(1);
    }, 10);

    /*
    * API call and grab currency data
    */
    setTimeout(() => {
      this.getCurrency(1);
    }, 10);

    // * API call and grab parent company data
    setTimeout(() => {
      this.getParentCompany(1)
    }, 10);

    // Function to get single marketplace data by ID
    this.activatedRoute.params.subscribe((params: Params) => {
      this.marketplace_id = params['id'];
      if (this.marketplace_id) {
        // Updation
        this.title = "EDIT MARKETPLACE ";
        this.marketplaceService.getMarketplaceById(this.marketplace_id).subscribe((res: any) => {
          console.log('to editt', res);
          this.disableCurrency = false;
          if (res.status == "success") {
            this.marketplace_data = res.data_found[0];
            this.marketplace = {
              id: this.marketplace_data.id,
              name: this.marketplace_data.name,
              description: this.marketplace_data.description,
              edit: true,
              parent_company: this.marketplace_data.parent_id,
              country: this.marketplace_data.country_id,
              currency: this.marketplace_data.currency_id
            }
            console.log('this.marketplace', this.marketplace);
            if (this.marketplace.country !== null || this.marketplace.country !== 'All') {
              console.log('kokoooooooooooooooooooooooooooooooooo');
              this.disableCurrency = true;
            }
          } else {
            if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
              this.authServiceObj.logout();
            }
            else {
              this.toastr.error(res.message, 'Failed');
            }
          }
        });
      }

    });
  }

  //To get Currency
  getCurrency(page: number) {
    this.currency_request_params['offset'] = this.offSet;
    this.loaderService.display(true);
    this.currencyService.getCurrency(this.currency_request_params).subscribe((res: any) => {
      this.loaderService.display(false);
      this.apiCallCount++;
      if (res.status == 'success' && !_.isNil(res.currency_result) && !_.isNull(res.currency_result)) {
        this.pager["total_count"] = this.currencyList.length + res.currency_result.length;
        this.pager["current_page"] = page;
        const result = this.currencyList.concat(res.currency_result);
        this.currencyList = res.currency_result;
        this.curList = [...this.currencyList]
        //Sort the list of currency in Ascending order
        this.currencyList = this.currencyList.sort((a, b) => (a.code > b.code) ? 1 : -1)
        //Load full result
        if (!_.isNil(res.max_possible_res_length) && res.max_possible_res_length > this.currencyList.length) {
          this.offSet = this.apiCallCount * this.queryLimit;
          this.getCurrency(1);
        }
      } else {
        if (this.currencyList.length === 0 || res.max_possible_res_length === 0) {
          this.currencyList = [];
          this.no_data = 'No Data';
          this.pager["total_count"] = 0;
          this.pager["current_page"] = page;
        }
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          this.toastr.error("Kindly login", "Session Expired");
          this.authServiceObj.logout();
        }
      }
    })
  }

  // To get Country
  getCountry(page: number) {
    this.request_params['offset'] = this.offSet;
    this.loaderService.display(true);
    this.countryService.getCountry(this.request_params).subscribe((res: any) => {
      this.loaderService.display(false);
      this.apiCallCount++;
      if (res.status == 'success' && !_.isNull(res.country_result)) {
        this.pager["total_count"] = this.countries.length + res.country_result.length;
        this.pager["current_page"] = page;
        const result = this.countries.concat(res.country_result);
        this.countries = res.country_result;
        //Load full result
        if (!_.isNil(res.max_possible_res_length) && res.max_possible_res_length > this.countries.length) {
          this.offSet = this.apiCallCount * this.queryLimit;

          this.getCountry(1);
        }
      } else {
        if (this.countries.length === 0 || res.max_possible_res_length === 0) {
          this.countries = [];
          this.no_data = 'No Data';
          this.pager["total_count"] = 0;
          this.pager["current_page"] = page;
        }
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          this.toastr.error("Kindly login", "Session Expired");
          this.authServiceObj.logout();
        }
      }
    })
  }

  // To get parent company data
  getParentCompany(page: number) {
    this.m_request_params['offset'] = this.offSet;
    this.m_request_params['type'] = 'all';
    console.log('this.m_request_params', this.m_request_params);

    this.loaderService.display(true);
    this.motherCompanyService.getMcompany(this.m_request_params).subscribe((res: any) => {
      this.loaderService.display(false);
      this.apiCallCount++;
      console.log('mres', res);

      if (res.status == 'success' && !_.isNull(res.company_result)) {
        const result = this.mcompanies.concat(res.company_result);
        this.mcompanies = res.company_result;
      } else {
        if (this.mcompanies.length === 0 || res.max_possible_res_length === 0) {
          this.mcompanies = [];
        }
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          this.toastr.error("Kindly login", "Session Expired");
          this.authServiceObj.logout();
        }
      }
    })
  }

  // Function works when save/update marketplace
  onSubmit(valid) {
    console.log('this.marketplace', this.marketplace);

    this.submited = true;
    var user = this.localStorage.retrieve('user_details');
    let data = {
      marketplace: this.marketplace,
      user_id: user.id
    }
    console.log('data', data);

    // if(data.marketplace.parent_company == "") {
    //   data.marketplace.parent_company = 0
    // }
    if (valid) {
      this.loaderService.display(true);
      this.marketplaceService.createUpdateMarketplace(data).subscribe((res: any) => {
        console.log(res, 'this is cap');
        this.loaderService.display(false);
        if (res.status == "success") {
          if (this.marketplace.id) {
            this.toastr.success('Marketplace Updated Successfully!', 'SUCCESS');
          } else {
            this.toastr.success('Marketplace Added Successfully!', 'SUCCESS');
          }

          this.route.navigate(['marketplace/']);
        } else {
          if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
            this.authServiceObj.logout();
          }
          else {
            this.toastr.error(res.message, 'Error');
          }
        }
      });
    }
  }

  //function works when country dropdown changes
  onCountryChange(value) {
    console.log('value', value);

    this.currencyList = this.curList;
    if (value) {
      var country = this.countries.filter(country => country.id == value);
      console.log(';country', country);
      if (country.length > 0) {
        var country_code = country[0].currency;
        console.log('country_code', this.curList);
        this.currencyList = this.currencyList.filter(currency =>
          currency.code === country_code
        );
        console.log('hgf', this.currencyList);
        // var currency = this.currencyList.filter(currency => currency.code == country_code);
        this.marketplace.country_id = value;
        this.marketplace.currency = this.currencyList[0].id;
      } else {
        this.marketplace.currency = ""
      }
    } else {
      console.log('hete');
      
      this.disableCurrency = false;
    }
  }
}
