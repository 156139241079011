import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, ActivatedRoute, Router, Params } from '@angular/router';
import { LoaderService } from '../../../../services/loader/loader.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
declare var $: any;
import { AuthService } from '../../../../services/auth/auth.service';
import { AppSettings } from '../../../../settings.global';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MarketplaceService } from '../../../../services/settings/marketplace/marketplace.service';
import { model } from '../../../../model/item.model'
import { ToastrService } from 'ngx-toastr';




@Component({
  selector: 'app-marketplace-scrapadd',
  templateUrl: './marketplace-scrapadd.component.html',
  styleUrls: ['./marketplace-scrapadd.component.css']
})
export class MarketplaceScrapaddComponent implements OnInit {

  public objectKeys = Object.keys
  public marketplace_id: any;
  public title: any;
  public flag: any;
  public marketplaces: any;
  public scrap: any
  public queryParam: any;
  public namedata: any;
  public selectStep: any;
  public marketplacewithstatus: any;
  public submited: any;
  public scrapstep: any;
  public newScrapVal: any
  public scrapForSelect: any
  public scrapArray: any
  public choosedStep: any = '';
  public choosedMarketplace: any
  public UPC: any;
  model = new model();
  formArray = [];

  scrapData: any;
  selectedIndex: any;

  choosedStepMiddle: any = '';
  titleCheck: string;
  scrapCheckData: any


  constructor(
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private authServiceObj: AuthService,
    private route: Router,
    private marketPlaceService: MarketplaceService,
    private toastr: ToastrService,
    private localSt: LocalStorageService,



  ) {
    this.scrapCheckData = {}
    this.marketplacewithstatus = []
    this.scrap = {
      id: null,
      marketplace: this.queryParam,
      choosedMarketplace: '',
      searchURL: "",
      edit: false,
      steps: this.scrapArray
    }

    this.scrapArray = []
    this.title = 'RUN SCRAP MAPPING'
    this.titleCheck = "CHECK SCRAP MAPPING"
    this.scrapstep = []

    this.selectStep = [{ id: 1, name: "Loop" }, { id: 2, name: "Scrap" }, { id: 3, name: "Click" }]
  }


  ngAfterContentInit() {

    this.getMarketPlace()
    this.getName()


    $("body").height("100%");
    $("html").height("100%");
    this.queryParam = parseInt(this.activatedRoute.snapshot.queryParams['marketplace_id']);

    // Initalising scrap data
    this.scrap = {
      id: null,
      marketplace: this.queryParam,
      choosedMarketplace: '',
      searchURL: "",
      edit: false,
      steps: this.scrapArray
    }

  }


  // Navigate to marketplace
  cancel(id) {
    this.route.navigate(['marketplace/'], { queryParams: { marketplace_id: id } });
  }

  //Geting scrap data if scrap steps and data are present in that perticular marketplace
  editScrapMap() {
    this.marketplace_id = this.queryParam
    if (this.marketplace_id) {
      // Updation
      //  Getting the scrap data  
      this.marketPlaceService.getMarketPlacesDetails(this.marketplace_id).subscribe((res: any) => {
        console.log(res, "it res");

        if (res.status == "success") {
          this.scrapArray = res.data_found[0].scrapmap.steps
          this.scrap = {
            id: res.data_found[0].id,
            marketplace: res.data_found[0].scrapmap.marketplace,
            choosedMarketplace: res.data_found[0].scrapmap.choosedMarketplace,
            searchURL: res.data_found[0].scrapmap.searchURL,
            edit: true,
            steps: this.scrapArray
          }
        }
      });
    }
  }

  // Add from row dynamiclly
  addForm() {
    if (this.choosedStep) {
      this.scrapArray.push({
        name: '',
        selectorPath: '',
        type: this.choosedStep
      })
    
    } else {
      this.toastr.error('Please Choose Step Type!');
    }
  }

  // Custome form validation function
  checkValidation(index: any, formelement: any) {
    if (this.scrapArray[index][formelement]) {
      return true;
    } else {
      return false;
    }
  }

  //Add form in between two steps dynamically
  addFormInMiddle() {
    if (this.choosedStepMiddle) {
      var data = {
        name: '',
        selectorPath: '',
        type: this.choosedStepMiddle
      }
      let startcount = this.selectedIndex;
      let endcount = 0;
      this.scrapArray.splice(startcount, endcount, data)
     
      $('#brandModal').modal('hide');
    } else {
      this.toastr.error('Please Choose Step Type!');
    }
  }


  //To hide a brand 
  cancelmodel() {
    $('#brandModal').modal('hide');
  }

  //check button toggle
  check() {
    $('#check').toggle();
  }

  // Remove form row dynamically
  removeForm(index) {
    this.scrapArray.splice(index, 1)
  }

  // Get marketplace for select
  getMarketPlace() {
    var marketPlaceWithStep = []
    this.marketPlaceService.getMarketPlaces('').subscribe((res: any) => {
      if (res.status == 'success') {
        console.log(res, "tis is res");
        res.market_result.forEach((data: any) => {
          if(data.scrapmap){
            if (data.scrapmap['steps'].length >= 1) {
              marketPlaceWithStep.push(data)
            }else{
              return;
            }
          }else{
            return;
          }
        })
        console.log(marketPlaceWithStep,"stepsssss");
         marketPlaceWithStep.forEach(element => {
           if(element.id != this.queryParam){
             this.marketplacewithstatus.push(element)
           }
        });
        console.log(this.marketplacewithstatus, "marketplacewithstatus");
        this.marketplaces = res.market_result
      } else {
        if (res.message = 'invalid_accesstoken' || res.message == 'un_autherised') {
          this.toastr.error('Kindly login', "Session Expired");
          this.authServiceObj.logout();
        }
      }
    });
  }




  //Get name for select
  getName() {
    this.marketPlaceService.getName().subscribe((res: any) => {
      if (res.status == 'success') {
        this.namedata = res.data_found

      } else {
        if (res.message = 'invalid_accesstoken' || res.message == 'un_autherised') {
          this.toastr.error('Kindly login', "Session Expired");
          this.authServiceObj.logout();
        }
      }
    });
  }

  getScrapMapCheck() {
    // "B08DLL7C2D"
    this.scrapData = JSON.stringify(this.scrap)
    let data = {

      scrapmap: this.scrapData,
      productid: this.UPC
    }
    if(!data.productid){
      this.toastr.info('Please Enter UPC')
      return;
    } 
    console.log(data,'itsman');
    this.loaderService.display(true)
    this.marketPlaceService.getScrapMapCheck(data).subscribe((res: any) => {
      this.loaderService.display(false)
      if (res.status == 'success') {
        this.scrapCheckData = res.data
        console.log(this.scrapCheckData, "this is data");
      }else {
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          this.authServiceObj.logout();
        }
        else {
          this.toastr.error(res.message, 'Failed Please Try After Some Time');
          this.UPC =''
        }
      }
    });
  }



  getstep(index) {
    console.log(index, "this.index");
    this.selectedIndex = index
    //alert("dd")

    $('#brandModal').modal('show');

  }

  // Function works when  form is submitted marketplace
  onSubmit(valid) {
    this.submited = true;

    var user = this.localSt.retrieve('user_details');
    this.scrapData = JSON.stringify(this.scrap)
    console.log(this.scrapData, "scrapdata");

    let data = {
      id: this.queryParam,
      scrap: this.scrapData,
      user_id: user.id
    }
    if (valid) {
      this.loaderService.display(true);
      this.marketPlaceService.addEditScrapMap(data).subscribe((res: any) => {
        console.log(data, "tis is data");

        this.loaderService.display(false);
        if (res.status == "success") {
          this.toastr.success('Scrapmap Added Successfully!', 'SUCCESS');
          this.route.navigate(['marketplace/']);
        } else {
          if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
            this.authServiceObj.logout();
          }
          else {
            this.toastr.error(res.message, 'Error');
          }
        }
      });
    }
  }





  ngOnInit() {
    this.queryParam = parseInt(this.activatedRoute.snapshot.queryParams['marketplace_id']);
    $('#check').toggle(false);
    this.editScrapMap()
  }

}
