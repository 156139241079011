import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppSettings } from '../../settings.global';
declare var $: any;
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { BrandService } from '../../services/brand/brand.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LoaderService } from '../../services/loader/loader.service';
import { async } from 'q';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy {
  public minMaxValue: boolean;
  public screenShot: boolean;
  public success: number;
  public failed: number;
  public error_log: string;
  public file_base: any = AppSettings.IMAGE_BASE_URL;
  public success_status: boolean;
  subscription: Subscription;
  sub: Subscription;
  subscr: Subscription;
  public client: any;
  public brand: any;
  public urls: any;
  public social_media_url: any;
  public m_commerce_url: any;
  public e_commerce_url: any;
  public market_place_url: any;
  public standalone_url: any
  public img_url = AppSettings.IMAGE_BASE_URL;
  public screenshot_url = AppSettings.SCREENSHOT_BASE_URL;
  public request_id: any = null;
  public single_view_path: any;
  public merket_place_order: any = 'asc';
  public social_media_order: any = 'asc';
  public standalone_order: any = 'asc';
  public m_commerce_order: any = 'asc';
  public e_commerce_order: any = 'asc';

  public current: any;
  public max: any;
  public progress_count: boolean;

  //public progressInterval:any;
  constructor(private authService: AuthService,
    private loaderService: LoaderService,
    private toastr: ToastrService, private brandService: BrandService, public localSt: LocalStorageService) {
    this.success_status = true;
    this.success = 0;
    this.failed = 0;
    this.error_log = null;
    this.minMaxValue = AppSettings.screenShotMinMax;
    this.social_media_url = [];
    this.market_place_url = [];
    this.m_commerce_url = [];
    this.e_commerce_url = [];
    this.standalone_url = [];
    this.single_view_path = "";
    this.current = (this.localSt.retrieve("current")) ? this.localSt.retrieve("current") : 0;
    this.max = (this.localSt.retrieve("max")) ? this.localSt.retrieve("max") : 100;
    this.progress_count = (this.localSt.retrieve("progress_count")) ? this.localSt.retrieve("progress_count") : false;



    //this.screenShot=AppSettings.screenShot;
    this.subscription = this.authService.getMessage().subscribe(message => {
      console.log("eeeeeee", message)
      this.screenShot = message.message.screenshot;
      this.brand = message.message.brand;
      this.client = message.message.client;
      this.urls = message.message.urls;
      this.localSt.store("brand", this.brand);
      this.localSt.store("client", this.client);
      this.localSt.store("urls", this.urls);
      this.success = 0;
      this.failed = 0;
      this.error_log = null;
      this.progress_count = false;


      //  this.progressInterval = setInterval(() => {
      //   this.urls=this.urls-1;
      //   if(this.urls>=0){
      //     this.localSt.store("urls",this.urls);
      //     this.authService.sendUrlCount(this.urls);
      //     console.log('this.counter', this.urls);
      //  }

      //   if(this.urls <= 0){
      //     clearInterval(this.progressInterval);
      //     //this.subscr.unsubscribe();
      //     console.log('cleaned and finished');
      //   }
      // },20000);
    }
    );


  }

  ngOnInit() {
    // setInterval(()=>{ 
    //   console.log("sdsdsd",this.current,this.max)
    //   if(this.current!==this.max){
    //     console.log("sdsd")
    //     this.current=this.current+1;
    //   }
    // }, 1000);
    this.getRequestCount()
    //this.subscription.unsubscribe();
    this.sub = this.authService.getQuotes().subscribe(quote => {
        console.log(quote, "New quote recieved");
        if (quote.type == 'completed') {
          if (quote.requests == 0) {
            this.progress_count = false;
            this.success_status = false;
            this.success = quote.success;
            this.failed = quote.failed;
            this.error_log = quote.log;
            this.request_id = quote.id;
            this.localSt.clear('brand');
            this.localSt.clear('client');
            this.localSt.clear('urls');
            this.current = quote.urls;
            this.max = quote.urls;
            this.localSt.clear('current');
            this.localSt.clear('max');
            this.localSt.clear('progress_count');
          }
        } else if (quote.type == 'progress') {
          this.progress_count = true;
          this.max = quote.urls;
          this.current = quote.cmpltd_urls;
          this.localSt.store("current", this.current);
          this.localSt.store("max", this.max);
          this.localSt.store("progress_count", this.progress_count);
          this.localSt.store("client", quote.client);
          this.localSt.store("brand", quote.brand);
          this.localSt.store("urls", quote.cmpltd_urls);
          this.screenShot = true;
          this.client = quote.client;
          this.brand = quote.brand;
        }

      });
    
  }
  minOrMax() {
    if (this.minMaxValue == false) {
      this.minMaxValue = true;
      AppSettings.screenShotMinMax = true;
    } else {
      this.minMaxValue = false;
      AppSettings.screenShotMinMax = false;
    }

  }
  closeWindow() {
    if (this.success_status == false) {
      AppSettings.screenShot = false;
      this.screenShot = false;
      this.success_status = true;
      this.current = 0;
      this.max = 100;
      this.progress_count = false;
      this.localSt.clear('brand');
      this.localSt.clear('client');
      this.localSt.clear('urls');
      this.localSt.clear('current');
      this.localSt.clear('max');
      this.localSt.clear('progress_count');
    } else {
      var cnf = confirm("Are you sure you want to cancel screenshot process?");
      if (cnf) {
        this.brandService.cancelScreenshotRequest().subscribe((res: any) => {
          console.log(res)
          if (res.status == 'success') {
            AppSettings.screenShot = false;
            this.screenShot = false;
            this.success_status = true;
            this.current = 0;
            this.max = 100;
            this.progress_count = false;
            this.localSt.clear('brand');
            this.localSt.clear('client');
            this.localSt.clear('urls');
            this.localSt.clear('current');
            this.localSt.clear('max');
            this.localSt.clear('progress_count');
          } else {
            if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
              //   this.toastr.error('Invalid User','Request Failed');
              this.authService.logout();
            }
          }
        });
      }
    }
  }
  ngOnDestroy() {

  }
  getRequestCount() {
    this.brandService.getScreenshotRequestCount().subscribe((res: any) => {
      if (res.status == 'success') {
        if (res.data != 0) {
          AppSettings.screenShot = true;

          this.screenShot = true;
          this.brand = this.localSt.retrieve("brand");
          this.client = this.localSt.retrieve("client");
          this.urls = this.localSt.retrieve("urls");

          let screenshotDetails = {
						screenshot: true,
						brand: this.brand,
            client: this.client,
            urls:this.urls
					}
					this.authService.sendMessage(screenshotDetails);

        } else {
          AppSettings.screenShot = false;

          this.screenShot = false;
          this.localSt.clear('brand');
          this.localSt.clear('client');
          this.localSt.clear('urls');
          this.localSt.clear('current');
          this.localSt.clear('max');
          this.localSt.clear('progress_count');
        }
      }
      else {
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          //   this.toastr.error('Invalid User','Request Failed');
          this.authService.logout();
        }
      }
    })
  }
  getSuccessScreenshots() {
    if (this.request_id == null) {
      return false;
    }
    this.brandService.getSuccessScreenshots(this.request_id).subscribe((res: any) => {
      if (res.status == 'success') {
        this.social_media_url = (res.social_media_url) ? res.social_media_url : [];
        this.market_place_url = (res.market_place_url) ? res.market_place_url : [];
        this.m_commerce_url = (res.m_commerce_url) ? res.m_commerce_url : [];
        this.e_commerce_url = (res.e_commerce_url) ? res.e_commerce_url : [];
        this.standalone_url = (res.standalone_url) ? res.standalone_url : [];
        $('#successScreenshotModal').modal('show');
      }
      else {
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          //   this.toastr.error('Invalid User','Request Failed');
          this.authService.logout();
        }
      }
    });
  }

  downloadScreenshot(type: any) {

    let request = { "id": this.request_id, "type": type }
    this.brandService.downloadSuccessScreenshots(request).subscribe(async (res: any) => {
      if (res.status == 'success') {
        console.log('res00000', res);
        
        var link = document.createElement("a");
        link.download = res.path;
        link.href = this.screenshot_url + res.path;
        document.body.appendChild(link);
        await link.click();
        // this.brandService.deleteZip(res.path).subscribe(async (res: any) => {
        //   console.log("success")
        // }
        // )

      }
    })

  }
  singleView(path) {
    this.single_view_path = path;
    $('#singleView').modal('show');
  }
  onSort(orderby: string, type: any) {
    if (type == 'market_place') {
      if (this.merket_place_order === "asc") {
        this.merket_place_order = "desc";
      } else {
        this.merket_place_order = "asc";
      }
      this.market_place_url = this.brandService.sortBrandList(this.market_place_url, orderby, this.merket_place_order)

    }else if (type == 'm_commerce') {
      if (this.m_commerce_order === "asc") {
        this.m_commerce_order = "desc";
      } else {
        this.m_commerce_order = "asc";
      }
      this.m_commerce_url= this.brandService.sortBrandList(this.m_commerce_url, orderby, this.m_commerce_order)

    }
    else if (type == 'e_commerce') {
      if (this.e_commerce_order === "asc") {
        this.e_commerce_order = "desc";
      } else {
        this.e_commerce_order = "asc";
      }
      this.e_commerce_url= this.brandService.sortBrandList(this.e_commerce_url, orderby, this.e_commerce_order)

    }
     else if (type == 'social_media') {
      if (this.social_media_order === "asc") {
        this.social_media_order = "desc";
      } else {
        this.social_media_order = "asc";
      }
      this.social_media_url = this.brandService.sortBrandList(this.social_media_url, orderby, this.social_media_order)
    } else if (type == 'standalone') {
      if (this.standalone_order === "asc") {
        this.standalone_order = "desc";
      } else {
        this.standalone_order = "asc";
      }
      this.standalone_url = this.brandService.sortBrandList(this.standalone_url, orderby, this.standalone_order)
    }
  }


}
