export class AppSettings {
  public static IS_IE_OR_EDGE = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
  //---------------------------------------------------------------------
  // Live  
  // public static API_ENDPOINT ='https://api.insiderscorp.com/api/v1'; 
  // by pass cross domain issue with proxy in IE and edge browsers
  // public static API_ENDPOINT = AppSettings.IS_IE_OR_EDGE?  'https://webapp.insiderscorp.com/api/v1': 'https://api.insiderscorp.com/api/v1';  

  //---------------------------------------------------------------------
  //Local

  // public static API_ENDPOINT = 'http://localhost:4400/api/v1';
  // public static API_ENDPOINT_V2 = 'http://localhost:4400/api/v2';

  // public static API_ENDPOINT = 'https://api.insiderscorp.com/api/v1';
  // public static API_ENDPOINT_V2 = 'https://api.insiderscorp.com/api/v2';
  // //---------------------------------------------------------------------
  //Staging 
  public static API_ENDPOINT = 'https://staging-api.insiderscorp.com/api/v1';
  public static API_ENDPOINT_V2 = 'https://staging-api.insiderscorp.com/api/v2';
  //---------------------------------------------------------------------
  //Local
  //public static API_ENDPOINT ='http://yajnesh.amtpl.in:4400/api/v1';
  //---------------------------------------------------------------------
  //Other
  //public static API_ENDPOINT ='http://104.196.111.184:3000/api/v1';
  //---------------------------------------------------------------------

  public static APP_START_YEAR = 2015;
  public static DEFAULT_PAGER_COUNT = 10;
  public static DEFAULT_QUERY_LIMIT = 10000;
  //---------------------------------------------------------------------
  // Live  
  // public static IMAGE_BASE_URL ='https://api.insiderscorp.com/files/'; 
  // public static FILE_BASE_URL  ='https://api.insiderscorp.com/uploads/';
  // public static IMAGE_BASE_URL = AppSettings.IS_IE_OR_EDGE?  'https://webapp.insiderscorp.com/files/': 'https://api.insiderscorp.com/files/';  
  // public static FILE_BASE_URL = AppSettings.IS_IE_OR_EDGE?  'https://webapp.insiderscorp.com/uploads/': 'https://api.insiderscorp.com/uploads/';  

  //---------------------------------------------------------------------

  // //Local
  // public static IMAGE_BASE_URL = 'http://localhost:4400/files/';
  // public static FILE_BASE_URL = 'http://localhost:4400/uploads/';
  // public static SCREENSHOT_BASE_URL = 'https://d2del47p5ovamj.cloudfront.net/';

  // //---------------------------------------------------------------------
  //Stagings
  public static IMAGE_BASE_URL = 'https://staging-api.insiderscorp.com/files/';
  public static FILE_BASE_URL = 'https://staging-api.insiderscorp.com/uploads/';
  public static SCREENSHOT_BASE_URL = 'https://d1br57uxtwv17b.cloudfront.net/';
  //---------------------------------------------------------------------
  public static screenShotMinMax = false;
  public static screenShot = false;
}