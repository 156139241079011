import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  public email:string;
  public email_error_flag:boolean;
  public errorMessage:any;

  constructor(private route:Router,private AuthServiceObj:AuthService,) {
    this.email="";
    this.email_error_flag=false;
    this.errorMessage='';
  }
  onSubmit = function (valid) {

    let request_params: any = {
      email: this.email,

    }
    this.AuthServiceObj.forgotPassword(request_params).subscribe((res: any) => {
      if (res.status == "success") {
        this.email_error_flag=false;
        this.errorMessage ='';
        $("#success_message").show();

      } else {
        if(res.message=="invalid_email"){
          this.email_error_flag=true;
        }
        else{
          this.errorMessage = res.message;
        }
      }
    });
  }
  resetEmailErrors(){
    this.email_error_flag=false;
  }

  ngOnInit() {
  }
  ngAfterContentInit() {

    $("body").height("100%");
    $("html").height("100%");
  }

}
