import { Component, OnInit, TemplateRef } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { AuthService } from '../../../services/auth/auth.service';
import { LoaderService } from '../../../services/loader/loader.service';
import { CountryService } from '../../../services/master-data/country/country.service';
import { MotherCompanyService } from '../../../services/master-data/mother-company/mother-company.service';
import { MarketplaceService } from '../../../services/settings/marketplace/marketplace.service';
import { AppSettings } from '../../../settings.global';
declare var $: any;
import * as _ from 'lodash';
import { ProductService } from '../../../services/product/product.service';
import * as moment from 'moment';
import { SellersService } from '../../../services/sellers/sellers.service';
import { takeWhile } from 'rxjs/operators';
import { SellerService } from '../../../services/settings/sellers/seller.service';
// import { CropperSettings } from 'ng2-img-cropper';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-seller',
  templateUrl: './seller.component.html',
  styleUrls: ['./seller.component.css']
})
export class SellerComponent implements OnInit {
  modalRefScreenshot: BsModalRef;
  public objectKeys = Object.keys
  public inputName:any;
  public m_request_params: any;
  public queryLimit: number = AppSettings.DEFAULT_QUERY_LIMIT;
  public offSet: number = 0;
  public apiCallCount: number = 0;
  public mcompanies: any;
  public editedOutput: any;
  public seller: any;
  public pinned: any;
  edited: { key: string; value: any; }[];
  extraDynamiData: { key: string; value: any; }[];
  public noPointOfContact: any;
  public selectedMcompany: any;
  public selectedMplace: any;
  public imageData: any
  public mplaces: any;
  source: string;
  public sortOrder: boolean;
  public cropedImage: any;
  public p_request_params: any;
  public marketplaces: any;
  public noData: any;
  public no_of_entry: any;
  public formArray: any;
  public filteredSeller: any;
  public filteredAds: any;
  public firstMplace: boolean;
  public currentCurrency: any;
  public country_request_params: any;
  public countries: any;
  // cropperSettings1: CropperSettings;
  public request_params: any;
  public selectedMcompanyName: any;
  public sellerArray: any;
  public expand: any;
  static sellers: any;
  public sellerData: any;
  public temp: any;
  public pager: any;
  public adIds;
  public editDetails: any;
  public dynamictemp: any;
  public itemPerPageCount: number = AppSettings.DEFAULT_PAGER_COUNT;
  daterange: any = {
    start: moment().startOf('year').utc().format(),
    end: moment.utc().format()
  };
  public sellerLogo: any;
  public sellersLogo: any;
  public dropDown: boolean;
  public selectedFile: any;
  field: string;
  public image_base: any = AppSettings.IMAGE_BASE_URL;
  selectedSeller: any;
  selectedMplaceName: any;
  no_data: string;
  public datepickerOptions: any = {
		startDate: moment().startOf('year'),
		endDate: moment(),
		minDate: '01/01/2000',
		maxDate: moment(),
		showDropdowns: !0,
		showWeekNumbers: !0,
		timePicker: !1,
		timePickerIncrement: 1,
		timePicker12Hour: !0,
		opens: 'right',
		buttonClasses: ['btn btn-default'],
		applyClass: 'btn-small btn-primary',
		cancelClass: 'btn-small',
		format: 'MM/DD/YYYY',
		separator: ' to ',
		locale: {
			applyLabel: 'Submit',
			cancelLabel: 'Clear',
			fromLabel: 'From',
			toLabel: 'To',
			customRangeLabel: 'Time Range',
			daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
			// tslint:disable-next-line:max-line-length
			monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
			firstDay: 1
		}
	};


  constructor(private loaderService: LoaderService,
    private toastr: ToastrService,
    private motherCompanyService: MotherCompanyService,
    private localStorage: LocalStorageService,
    private authServiceObj: AuthService,
    private marketplaceService: MarketplaceService,
    private countryService: CountryService,
    private sellersService: SellersService,
    private productService: ProductService,
    private sellerService: SellerService,
  ) {

    this.m_request_params = {
      searchkey: "",
      orderby: "",
      order: "",
      limit: this.queryLimit,
      offset: 0
    };
    this.sellerData = [];
    this.editedOutput = [];
    this.imageData = {};

    this.p_request_params = {
      searchkey: "",
      orderby: "",
      order: "",
      limit: this.queryLimit,
      offset: 0
    };
    this.inputName = '';
    this.no_of_entry = 10;
    this.editDetails = {
      id: '',
      name: '',
      phone: '',
      email: '',
      website: '',
      country: '',
      address: '',
      notes: '',
      old_logo: '',
      logo: ''
    }
    this.dynamictemp = {};
    this.noPointOfContact = false;
    this.temp = [];
    this.seller = [];
    this.cropedImage = true;
    this.sortOrder = true;
    this.pinned = false;
    this.country_request_params = {
      searchkey: "",
      orderby: "",
      order: "",
      limit: this.queryLimit,
      offset: 0
    }
    this.formArray = []
    this.request_params = {
      searchkey: "",
      orderby: "",
      order: "",
      limit: this.queryLimit,
      offset: 0,
      marketplace: "",
      date_range: "",
      condition: "",
      seller_country: ""
    };
    this.pager = {
      limit: this.itemPerPageCount,
      offset: 0,
      total_count: 0,
      current_page: 1
    };
    this.selectedFile = {
      src: ''
    }
    this.mcompanies = [];
    this.mplaces = [];
    this.marketplaces = [];
    this.firstMplace = true;
    this.countries = [];
    this.sellerArray = [];
    this.expand = false;
    SellerComponent.sellers = [];
    this.adIds = [];
    this.filteredSeller = [];
    this.filteredAds = [];
    this.dropDown = false;
    var link_selectedSummaryObj = { id: 0, name: 'All', spcialClass: 'active-brands-tab ' };
    //Image cropper
    // this.cropperSettings1 = new CropperSettings();
    // this.cropperSettings1.width = 200;
    // this.cropperSettings1.height = 200;
    // this.cropperSettings1.keepAspect = false;

    // this.cropperSettings1.croppedWidth = 400;
    // this.cropperSettings1.croppedHeight = 400;

    // this.cropperSettings1.canvasWidth = 330;
    // this.cropperSettings1.canvasHeight = 200;

    // this.cropperSettings1.minWidth = 100;
    // this.cropperSettings1.minHeight = 100;

    // this.cropperSettings1.rounded = false;
    // this.cropperSettings1.minWithRelativeToResolution = false;

    // this.cropperSettings1.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
    // this.cropperSettings1.cropperDrawSettings.strokeWidth = 2;
  }

  ngAfterContentInit() {
    $("body").height("100%");
    $("html").height("100%");
    this.getCountry();
    this.getParentCompany();
  }

  ngOnInit() {
  }

  //get the mothercompany for the top bar
  getParentCompany() {
    this.m_request_params['offset'] = this.offSet;
    this.m_request_params['type'] = 'all';
    this.loaderService.display(true);
    this.motherCompanyService.getParentcompany().subscribe((res: any) => {
      this.apiCallCount++;
      console.log('res m c', res);
      if (res.status == 'success' && !_.isNull(res.data_found)) {
        this.loaderService.display(false);
        this.mcompanies = res.data_found;
        console.log('this.mcompany', this.mcompanies);
        this.getMarketplace(this.mcompanies[0].id)
        this.selectedMcompany = this.mcompanies[0].id;
        this.selectedMcompanyName = this.mcompanies[0].name;
      } else {
        if (this.mcompanies.length === 0 || res.max_possible_res_length === 0) {
          this.mcompanies = [];
        }
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          this.toastr.error("Kindly login", "Session Expired");
          this.authServiceObj.logout();
        }
      }
    })
  }

  //function to get the list of marketplaces
  getMarketplace(id) {
    console.log('id', id);
    this.mplaces = [];
    this.p_request_params['offset'] = 0;
    this.loaderService.display(true);
    this.marketplaceService.getMarketPlaces(this.p_request_params).subscribe((res: any) => {
      if (res.status == 'success') {
        this.loaderService.display(false);
        this.marketplaces = res.market_result;
        console.log('resresres', this.marketplaces);
        if (this.marketplaces.length > 0) {
          this.marketplaces.forEach(element => {
            if (element.parent_id === id) {
              this.mplaces.push(element);
            }
          });
        }
        console.log('parent', this.mcompanies);
        console.log('child', this.mplaces);
        if (this.mplaces.length > 0 && this.firstMplace) {
          this.selectedMplace = this.mplaces[0].id;
          this.selectedMplaceName = this.mplaces[0].name;
          this.currentCurrency = this.mplaces[0].currency_code;
        }
        console.log('abc', this.selectedMplace);
        this.getSellerProduct(1, this.selectedMplace);
      }
    });
  }

  //To get Country
  getCountry() {
    this.country_request_params['offset'] = this.offSet;
    this.countryService.getCountry(this.country_request_params).subscribe((res: any) => {
      this.apiCallCount++;
      if (res.status == 'success' && !_.isNull(res.country_result)) {
        this.countries = res.country_result;
      } else {
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          this.toastr.error("Kindly login", "Session Expired");
          this.authServiceObj.logout();
        }
      }
    })
  }

  // to scroll marketplaces on the top when '>' clicks
  slideBrand(position: any, type: any) {
    //alert(type);
    //   const conent = document.querySelector('link_brndListCoverMain');
    const abc = document.getElementById('link_brndListCoverMain');
    //   console.log('conent', conent);
    if (position == 'right') abc.scrollLeft += 100;;
    if (position == 'left') abc.scrollLeft -= 100;;
    event.preventDefault();
    //alert(position);
  }

  // to scroll marketplaces on the top when '>' clicks
  slideBrands(position: any, type: any) {
    //alert(type);
    //   const conent = document.querySelector('link_brndListCoverMain');
    const abc = document.getElementById('link_brndListCoverMains');
    //   console.log('conent', conent);
    if (position == 'right') abc.scrollLeft += 100;;
    if (position == 'left') abc.scrollLeft -= 100;;
    event.preventDefault();
    //alert(position);
  }

  //function to get the details of products and sellers corresponding to a marketplace
  getSellerProduct(page, mId) {
    console.log('mId', mId);

    this.loaderService.display(true);
    this.expand = false;
    this.request_params.marketplace = mId;
    var start_date = new Date(this.daterange.start);
    var end_date = new Date(this.daterange.end);
    var start = moment.utc(start_date).format();
    var end = moment.utc(end_date).format();
    this.request_params.date_range = start + '#' + end;
    this.sellersService.getSellerProducts(this.request_params).subscribe((res: any) => {
      console.log('res', res);
      this.loaderService.display(false);
      this.apiCallCount++;
      console.log('res.dataset.json_agg', res.p_scrap_result);
      if (res.status == 'success' && !_.isNull(res.p_scrap_result)) {
        var slrArray = res.p_scrap_result;
        res.p_scrap_result.forEach(element => {
          if (element.ads_details.length > 0) {
            element.pinSeller = false;
            element.expanded = false;
            element.checkedAll = false;
            console.log('element789', element);
            element.searchkey = ''
          }
        });
        this.sellerArray = res.p_scrap_result;
        this.filteredSeller = _.cloneDeep(this.sellerArray);
        this.filteredAds = _.cloneDeep(this.sellerArray);
        if (this.sellerArray.length > 0) {
          SellerComponent.sellers.forEach(element1 => {
            console.log('here', element1, this.sellerArray);
            var idx = this.sellerArray.findIndex(x => x.id == element1.id);
            console.log('idx', idx);
            if (idx != -1) {
              this.sellerArray[idx].pinSeller = true;
              this.sellerArray[idx].expanded = true;
            }
          });

        }
        this.seller = _.cloneDeep(this.sellerArray);
        if (this.request_params.condition != "") {
          this.sellerArray.forEach((element, i) => {
            if (element.ads_details.length > 0) {
              console.log('element.ads_details', element.ads_details);

              element.ads_details = element.ads_details.filter(item =>
                item.details.condition == this.request_params.condition)
            }
          });
          this.sellerArray = this.sellerArray.filter(ele => ele.ads_details.length > 0)
        }
      } else {
        this.sellerArray = []
        console.log('here in esle', this.sellerArray.length);
        this.seller = _.cloneDeep(this.sellerArray);
        if (this.sellerArray.length === 0 || res.max_possible_res_length === 0) {
          this.sellerArray = [];
          this.noData = 'No Data';
          this.pager["total_count"] = 0;
          this.pager["current_page"] = page;
        }
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          this.toastr.error("Kindly login", "Session Expired");
          this.authServiceObj.logout();
        }
      }
    })
  };

  // display marketplace specific data when selecting on the top list of marketplaces
  onMarketplaceSelection(mPlace) {
    this.selectedMplace = mPlace.id;
    this.selectedMplaceName = mPlace.name;
    this.getSellerProduct(1, this.selectedMplace)
  }

  //function to expand all products when clicking + icon
  expandAll() {
    this.expand = !this.expand;
    if (SellerComponent.sellers.length > 0) {
      SellerComponent.sellers.forEach(element => {
        this.sellerArray.forEach(seller => {
          if (element.pinSeller) {
            if (element.id == seller.id) {
              console.log('product((((', seller);
              seller.pinSeller = true;
              seller.expanded = true;
            }
          }
        })
      });
      this.seller = _.cloneDeep(this.sellerArray);
    }
    else {
      this.sellerArray.map(seller => {
        seller.expanded = this.expand
      });
      this.seller = _.cloneDeep(this.sellerArray);
    }

    this.sellerArray.map(seller => {
      if (!seller.pinSeller) {
        seller.expanded = this.expand
      }
    });
    this.seller = _.cloneDeep(this.sellerArray);
  }

  //expanding individual sellers
  onExpand(seller) {
    seller.expanded = !seller.expanded;
    console.log('this.poio', this.sellerArray);
    this.seller = _.cloneDeep(this.sellerArray);
  }

  //To make all check box true or false
  selectAll(seller, index) {
    seller.checkedAll = !seller.checkedAll;
    console.log('seller aftr', seller);
    this.seller = _.cloneDeep(this.sellerArray);
  }

  // function to change ads status.
  changeProductStatus(type, index, id) {
    console.log('index', index);

    if (type === 'Approved') {
      var type1 = 'approve'
    } else {
      var type1 = 'reject'
    }
    this.adIds = [];
    if (index != '') {
      console.log('herreeee');

      this.sellerArray[index].ads.forEach(element => {
        this.adIds.push(element.id)
      });
    } else {
      console.log('this.ser', this.sellerArray);
      this.sellerArray.forEach(element => {
        if (element.checkedAll) {
          if (element.ads_details.length > 0) {
            element.ads_details.forEach(ad => {
              this.adIds.push(ad.id)
            });
          }
        }
      });
    }

    var user = this.localStorage.retrieve('user_details');
    let data = {
      id: this.adIds,
      status: type,
      user_id: user.id
    }
    console.log('data', data);

    if (data.id.length == 0) {
      this.toastr.info('Please select the checkbox to ' + type1 + ' the seller');
    } else {
      if (index != '') {
        var cnf = confirm('Are you sure you want to ' + type1 + ' this seller?');
      } else {
        var cnf = confirm('Are you sure you want to ' + type1 + ' these sellers?');
      }
      if (cnf) {
        this.productService.statusChange(data).subscribe((res: any) => {
          if (res.status == "success") {
            this.toastr.success(type,'SUCCESS');
            // this.sellerArray.forEach(element => {
            //   element.checkedAll = false;
            // });
            console.log('this.productsArray[index]1', this.sellerArray);
            // if (this.productsArray[index].pinSeller) {
            // 	console.log('this.ad_ids', this.ad_ids);
            // 	this.ad_ids.forEach(element => {
            // 		const indx = this.productsArray[index].ads.findIndex(x => x.id == element);
            // 		console.log('index90', indx);

            // 		if (index != 1) {
            // 			this.productsArray[index].ads[indx].status = type;
            // 		}
            // 	});
            // }
            this.getSellerProduct(1, this.selectedMplace);
            // $('#sellerModal').modal('show');
          } else {
            if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
              this.authServiceObj.logout();
            }
            else {
              this.toastr.error('Failed');
            }
          }
        });
      }

    }
    this.seller = _.cloneDeep(this.sellerArray);
  }

  // display marketplaces under parent companies
  onMcompanySelection(parent) {
    this.selectedMcompany = parent.id;
    this.selectedMcompanyName = parent.name;
    this.firstMplace = false;
    this.getMarketplace(parent.id);
  }

  //function works when search icon is clicked
  onSearch() {
    this.apiCallCount = 0;
    this.offSet = 0;
    this.pager["offset"] = 0;
    this.request_params.orderby = "",
      this.request_params.order = "",
      this.getSellerProduct(1, this.selectedMplace);
  }

  //function works when enter pressed on search box
  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.onSearch();
    }
  }

  //function works when a date range is submitted from the top dropdown box.
  selectedDate(value: any) {
    this.daterange.start = moment(value.start._d).toISOString();
    this.daterange.end = moment(value.end._d).utc().format();
  }

  // get seller array when clicks GO button
  goSearch() {
    console.log('hui89');
    this.getSellerProduct(1, this.selectedMplace)
  }

  //To view seller info in pop up  dialoge box------------------------------------------------
  openSellerModal(id: any) {
    console.log('id2', id);

    $('#sellerInfo').modal('show');
    this.sellerLogo = ''
    this.sellersLogo = ''
    this.dropDown = false
    this.selectedFile.src = '';
    this.field = "Add More..."
    this.formArray = []
    this.loaderService.display(true);
    var ids = {
      id: id,
      mp_id: this.selectedMplace
    }
    this.sellerService.getSellerById(ids).subscribe((res: any) => {
      console.log('resres', res);
      this.loaderService.display(false);
      if (res.status == "success") {
        console.log(res, "part2seller");
        this.temp = res.data_found[0]
        console.log(this.temp.details, 'sellerlogo');
        if (!res.data_found[0].details.email) {
          this.noPointOfContact = true
        } else {
          this.noPointOfContact = false
        }
        if (res.data_found[0].details.logo || res.data_found[0].details.old_logo) {
          this.sellerLogo = res.data_found[0].details.logo || res.data_found[0].details.old_logo
          // this.source = URL.createObjectURL(res.data_found[0].sellerdetails.logo)
          // this.selectedFile.src =   this.source
        } else {
          this.sellersLogo = 'images/sellersLogo.jpg'
          // this.source = URL.createObjectURL('images/yourlogohere.jpg')
          // this.selectedFile.src =  this.source
        } if (!this.sellerLogo) {
          this.sellersLogo = "../../../../assets/images/yourlogohere.jpg"
          // this.source = URL.createObjectURL("../../../../assets/images/yourlogohere.jpg" )
          // this.selectedFile.src =  this.source
        }
        this.dynamictemp = _.cloneDeep(res.data_found[0].details);
        delete this.dynamictemp['country_id']
        delete this.dynamictemp['marketplace_id']
        delete this.dynamictemp['name']
        delete this.dynamictemp['country']
        delete this.dynamictemp['website']
        delete this.dynamictemp['phone']
        delete this.dynamictemp['email'],
          delete this.dynamictemp['logo'],
          delete this.dynamictemp['old_logo']
        delete this.dynamictemp['address']
        delete this.dynamictemp['notes']
        delete this.dynamictemp['id']
      } else {
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          this.authServiceObj.logout();
        }
        else {
          this.toastr.error(res.message, 'Failed');
        }
      }
    })
  }

  // TO edit seller info in pop-up Dialoge box
  editSeller(seller_id: any, mp_id: any) {
    $('#sellerInfo').modal('hide');
    setTimeout(() => {
      $('#editSeller').modal('show');
    }, 10);
    var ids = {
      id: seller_id,
      mp_id: this.selectedMplace
    }
    if (seller_id) {
      this.loaderService.display(true);
      this.sellerService.getSellerById(ids).subscribe((res: any) => {
        this.loaderService.display(false);
        console.log(res, "resulterrrs");
        if (res.status == "success") {
          this.editDetails = {
            id: seller_id,
            name: res.data_found[0].details.name || null,
            phone: res.data_found[0].details.phone || null,
            email: res.data_found[0].details.email || null,
            address: res.data_found[0].details.address || null,
            website: res.data_found[0].details.website || null,
            country: res.data_found[0].details.country || null,
            notes: res.data_found[0].details.notes || null,
            old_logo: this.sellerLogo || this.sellersLogo,
            logo: ''
          }
          if (!res.data_found[0].details.email) {
            this.noPointOfContact = true
          } else {
            this.noPointOfContact = false
          }
        } else {
          if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
            this.authServiceObj.logout();
          }
          else {
            this.toastr.error(res.message, 'Failed');
          }
        }
      })
    }
    this.edited = Object.entries(this.editDetails).map(([key, value]) => ({ key, value }));
    this.extraDynamiData = Object.entries(this.dynamictemp).map(([key, value]) => ({ key, value }));
    const formArrayData = [...this.edited, ...this.formArray]
    const deepClonedOutPut = _.cloneDeep(formArrayData)
    this.editedOutput = [...deepClonedOutPut, ...this.extraDynamiData]
  }

  //loding image file into background
  loadFile(event: any) {
    this.cropedImage = false;
    this.source = URL.createObjectURL(event.target.files[0]);
    this.selectedFile.src = this.source
  };


  // cropped logo image in imagedata
  logoCropped(event) {
    this.editDetails['old_logo'] = ''
    this.editDetails['logo'] = this.imageData.image;
    console.log(this.imageData, "image man")
  }

  // to sort table column seller
  onSort(orderby: string, id: any) {
    console.log('orderby', orderby);
    console.log('this.request_params["orderby"]', this.request_params["orderby"]);


    if (this.request_params["orderby"] == orderby) {
      if (this.request_params["order"] == "asc") {
        this.request_params["order"] = "desc";
      } else {
        this.request_params["order"] = "asc";
      }
    } else {
      console.log('inside else');

      this.request_params["orderby"] = orderby;
      if (this.request_params["order"] == "asc") {
        this.request_params["order"] = "desc";
      } else {
        this.request_params["order"] = "asc";
      }
      console.log('this.request_params["orderby"]#####', this.request_params["orderby"]);
      console.log('this.request_params["orderby"]****', this.request_params["order"]);
    }
    //Reset the page to 1, offset to 0
    this.pager["current_page"] = 1;
    this.pager["offset"] = 0;
    this.sellerArray = this.sellersService.sortSellerList(this.sellerArray, this.request_params["orderby"], this.request_params["order"]);
    console.log(this.sellerArray, 'sort');

  }


  // to sort table column based on product ad details
  onAdSort(orderby: string, id: any, index) {
    console.log('this.productsArray[index].ads', this.sellerArray[index].ads_details);
    this.sortOrder = !this.sortOrder;
    if (this.sortOrder) {
      if (orderby == 'name') {
        this.sellerArray[index].ads_details = this.sellerArray[index].ads_details.sort(function (a, b) {
          if (a.details.name < b.details.name) { return -1; }
          if (a.details.name > b.details.name) { return 1; } return 0;
        })
      }
      if (orderby == 'productupc') {
        this.sellerArray[index].ads_details = this.sellerArray[index].ads_details.sort(function (a, b) {
          if (a.details.productupc < b.details.productupc) { return -1; }
          if (a.details.productupc > b.details.productupc) { return 1; } return 0;
        })
      }
      if (orderby == 'condition') {
        this.sellerArray[index].ads_details = this.sellerArray[index].ads_details.sort(function (a, b) {
          if (a.details.condition < b.details.condition) { return -1; }
          if (a.details.condition > b.details.condition) { return 1; } return 0;
        })
      }
      if (orderby == 'price') {
        this.sellerArray[index].ads_details = this.sellerArray[index].ads_details.sort(function (a, b) {
          if (a.details.price < b.details.price) { return -1; }
          if (a.details.price > b.details.price) { return 1; } return 0;
        })
      }
    } else {
      if (orderby == 'name') {
        this.sellerArray[index].ads_details = this.sellerArray[index].ads_details.sort(function (a, b) {
          if (a.details.name > b.details.name) { return -1; }
          if (b.details.name > a.details.name) { return 1; } return 0;
        })
      }
      if (orderby == 'productupc') {
        this.sellerArray[index].ads_details = this.sellerArray[index].ads_details.sort(function (a, b) {
          if (a.details.productupc > b.details.productupc) { return -1; }
          if (b.details.productupc > a.details.productupc) { return 1; } return 0;
        })
      }
      if (orderby == 'condition') {
        this.sellerArray[index].ads_details = this.sellerArray[index].ads_details.sort(function (a, b) {
          if (a.details.condition > b.details.condition) { return -1; }
          if (b.details.condition > a.details.condition) { return 1; } return 0;
        })
      }
      if (orderby == 'price') {
        this.sellerArray[index].ads_details = this.sellerArray[index].ads_details.sort(function (a, b) {
          if (a.details.price > b.details.price) { return -1; }
          if (b.details.price > a.details.price) { return 1; } return 0;
        })
      }
    }
  }

  getScreenshots(ad) {
    this.selectedSeller = ad;
    $('#screenshotModal').modal('show');
  }

  //function works when export seller button clicked
  //function works when export products button clicked
  exportSeller(type: string) {
    //Copy to variable to avoid original data mutation
    this.sellerData = [...this.sellerArray]
    if (this.sellerData.length == 0) {
      return false;
    };
    //Format the data to avoid unwanted fields
    const productMapArray = [];
    this.sellerData.forEach(element => {
      element.ads_details.map((item, index) => {
        let prodObj = {
          sellerId: element.s_details.sellerId,
          name: element.s_details.name,
          email: element.s_details.email,
          website: element.s_details.website,
          address: element.s_details.address,
          country: element.s_details.country,
          phone: element.s_details.phone,
          productId: item.details.title
        }
        productMapArray.push(prodObj);
      })

    });
    console.log('productMapArray', productMapArray);

    var keyMap;
    keyMap = {
      sellerId: 'SellerId',
      name: 'Name',
      email: 'Email',
      website: 'Website',
      address: 'Address',
      country: 'Country',
      phone: 'Phone',
      productId: 'ProductId'
    };
    //Product ID, Name, Description, Seller Name, Product Price, Product URL, Product Status
    var excelData = productMapArray.map((obj) => {
      return _.mapKeys(obj, (value, key) => {
        return keyMap[key];
      });
    });
    console.log('excelData', excelData);

    const excelDataModified = excelData.map((sData, i) => {
      return {
        SellerId: sData.SellerId,
        Name: sData.Name,
        Email: sData.Email,
        Website: sData.Website,
        Address: sData.Address,
        Country: sData.Country,
        Phone: sData.Phone,
        ProductId: sData.ProductId
      }
    });
    // const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.myChart.nativeElement);
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // /* save to file */
    // XLSX.writeFile(wb,'Market_Place_'+this.breakdown.company+'Report.xlsx');
    if (type == 'excel') {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelDataModified);
      console.log('worksheet', worksheet);

      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      XLSX.writeFile(workbook, this.selectedMplaceName + '_' + 'seller.xlsx', { bookType: 'xlsx', type: 'buffer' });
    }
  }
  //function works when export products button clicked
  exportProducts(type: string, product) {
    console.log('ertio', product);
    var productData = [];
    product.forEach(element => {
      productData.push(element.details)
    });
    console.log('productData', productData);

    //Format the data to avoid unwanted fields
    var keyMap;
    keyMap = {
      productupc: 'ProductId',
      title: 'Name',
      description: 'Description',
      price: 'ProductPrice',
      productUrl: 'ProductUrl',
      condition: 'ProductStatus'
    };

    var excelData = productData.map((obj) => {
      return _.mapKeys(obj, (value, key) => {
        return keyMap[key];
      });
    });
    const excelDataModified = excelData.map((sData, i) => {
      return {
        Id: sData.ProductId,
        Name: sData.Name,
        Description: sData.Description,
        ProductPrice: sData.ProductPrice,
        ProductUrl: sData.ProductUrl

      }
    });
    // const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.myChart.nativeElement);
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // /* save to file */
    // XLSX.writeFile(wb,'Market_Place_'+this.breakdown.company+'Report.xlsx');

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelDataModified);
    // console.log('worksheet', worksheet);

    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, this.selectedMplaceName + '_' + 'products.xlsx', { bookType: 'xlsx', type: 'buffer' });
  }

  //function to pin a product to the top of the page
  pinSeller(sellerId, status) {
    this.pinned = !this.pinned;
    console.log('this.pinned', status);
    if (!status) {
      this.sellerArray.forEach((element, i) => {
        if (element.id == sellerId) {
          element.pinSeller = true;
          SellerComponent.sellers.push(element)
        }
      });
    } else {
      this.sellerArray.forEach((element, i) => {
        if (element.id == sellerId) {
          element.pinSeller = false;
        }
      });
      SellerComponent.sellers.forEach((element, i) => {
        if (element.id == sellerId) {
          SellerComponent.sellers.splice(i, 1);
        }
      });
    }
    console.log('ProductListComponent.products', SellerComponent.sellers);
    this.seller = _.cloneDeep(this.sellerArray);
  }

  //function works when search icon for ads is clicked
  onSellerSearch(ad, index) {
    console.log('ic', ad, index);
    this.filteredAds = _.cloneDeep(this.seller[index]);
    this.sellerArray[index] = this.filteredAds;
		this.sellerArray[index].searchkey = ad.searchkey;
    // this.filteredAds = _.cloneDeep(this.seller);
    console.log('this.filteredSeller', this.filteredAds);
    var searchKey = ad.searchkey;
    this.sellerArray[index] = this.filteredAds;
    console.log('o[k]', this.sellerArray[index],)
    if (searchKey == '') {
      this.sellerArray[index] = this.filteredAds;
      this.sellerArray[index].expanded = true;
			this.seller[index] = _.cloneDeep(this.sellerArray[index]);
    } else {
      if (ad.ads_details.length > 0) {
        this.sellerArray[index].ads_details = this.sellerArray[index].ads_details.filter(item => {
          for (const [key, value] of Object.entries(item)) {
            console.log('itemitemitem', item);
            if (item[key] !== null && item[key] != "") {
              for (const [key1, value1] of Object.entries(value)) {
                console.log('valuesderg', key1);
                if (key1 == 'productupc' || key1 == 'title' || key1 == 'condition' || key1 == 'price') {
                  console.log('value1', key1, value1);
                  if (value1.toString().toLowerCase().includes(ad.searchkey.toString().toLowerCase())) {
                    console.log('item5', item);
                    return item
                  }
                }
              }
            }

          }
        });


      }
      this.no_data = "No data"
    }


    // var abc = this.productsArray[index].filter(x => x.type.toLowerCase().includes('pizza'))
    // if (this.productsArray[index].sellers.length == 0) {
    // 	this.no_data = 'No Data'

    // }

    // }
    // function filterByValue(array, string) {
    // 	return  array.filter(o =>
    // 		Object.keys(o).some(k => o[k].toString().toLowerCase().includes(strings.toLowerCase())));
    // }

    // const arrayOfObject = [{ name: 'Paul', country: 'Canada', }, { name: 'Lea', country: 'Italy', }, { name: 'John', country: 'Italy' }];

    // console.log(filterByValue(this.productsArray[0].sellers, 'lea')); // [{name: 'Lea', country: 'Italy'}]

    // this.productsArray
    // this.productsArray.forEach(element => {
    // 	if (element.id == id) {
    // 		const filtered = element.sellers.filter(function (str) {
    // 			return str.includes(string);
    // 		});
    // 		// element.sellers.filter((seller) =>
    // 		// Object.keys(seller).some(k =>
    // 		// console.log('seller[k]', seller[k])))

    // 		// seller[k].toString().toLowerCase().includes(string.toLowerCase())))
    // 		// console.log('56',seller[k].toString().toLowerCase().includes(string.toLowerCase()))))
    // 	}



    // });


    // this.getSeller(1, index);
  }

  //function works when enter pressed on seller's search box
  keyDownFunctionSeller(event, ad, i) {
    if (event.keyCode === 13) {
      this.onSellerSearch(ad, i);
    }
  }
  onSearchChange(event, i) {
    console.log('event', i, event, this.sellerArray);
    // this.s_request_params.searchkey = this.productsArray[i].searchKey;
  }

  // on changing entries count per page
  entryNoChange(value: number) {
    this.apiCallCount = 0;
    this.offSet = 0;
    this.pager["limit"] = value;
    this.pager["total_count"] = 0;
    this.pager["current_page"] = 1;
    this.pager["offset"] = 0;
    this.getSellerProduct(1, this.selectedMplace);
    this.itemPerPageCount = value;
  }

  // function works when page number changes
  onPageChange(page: number) {
    this.pager["current_page"] = page;
    this.pager["offset"] = this.itemPerPageCount * (page - 1);
  }

   // canceling seller pop-up dialoe box
	 cancelModel() {
		$('#sellerInfo').modal('hide');
	  }
// checking whether there is email||phone  in no ponit of contact checkbox
onChangeContact(event:any){
  // if(event == !empty){
  //   this.noPointOfContact = true;
  // }else if(event != empty){
  //   this.noPointOfContact = false;
  // }
}

onSubmit(valid){

}
}
