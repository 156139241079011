
import {fromEvent as observableFromEvent} from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { DataDeleteRequestService } from '../../services/data-delete-request/data-delete-request.service';
import {ClientService} from '../../services/client/client.service';
import { BrandService } from '../../services/brand/brand.service';
declare var $ : any;
import * as _ from 'lodash';
import {Observable} from 'rxjs/';
import {LoaderService} from '../../services/loader/loader.service';
import {NgxPaginationModule} from 'ngx-pagination';//importing ng2-pagination
import {AuthService} from '../../services/auth/auth.service';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {AppSettings} from '../../settings.global';
import {Routes, RouterModule, ActivatedRoute, Router, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {debounceTime} from 'rxjs/operators';
@Component({
	selector: 'app-data-delete-request',
	templateUrl: './data-delete-request.component.html',
	styleUrls: ['./data-delete-request.component.css']
})
export class DataDeleteRequestComponent implements OnInit {
	public requests:any;
	public request_params:any;
	public pager:any;
	public queryLimit: number = AppSettings.DEFAULT_QUERY_LIMIT;
	public itemPerPageCount: number = AppSettings.DEFAULT_PAGER_COUNT;
	public apiCallCount: number = 0;
	public offSet: number = 0;
	public brands:any;
	public brand:any;
	public clients:any;
	public client:any;
	public no_data:any;
	public searchInputObservable$: any;
	public admin:any;
	public request_data:any;
	public request_id:string;

	constructor(private dataDeleteRequestService:DataDeleteRequestService,
		private brandService:BrandService, private clientService:ClientService, 
		private toastr: ToastrService,private loaderService:LoaderService,
		public localSt: LocalStorageService,private authServiceObj:AuthService,
		private activatedRoute: ActivatedRoute) { 
		this.no_data="Loading...."
		this.request_params = {
			user_id:this.localSt.retrieve("user_details").id,
			searchkey: "",
			orderby: "",
			order: "",
			limit: this.queryLimit,
			offset: 0
		}
		this.pager = {
			limit: this.itemPerPageCount,
			offset: 0,
			total_count: 0,
			current_page: 1
		}
		this.brands=[];
		this.requests=[];
		this.admin=[];
		this.request_data ={
			request_id:'',
			sender_id:''
		}
		this.request_id='';
	}

	ngAfterContentInit() {

		//Input search observable - to avoid the  onInputChange() firing whenever the input changes
		const searchInputObj = document.querySelector('#search_text');
		this.searchInputObservable$ = observableFromEvent(searchInputObj, 'keyup');

		this.searchInputObservable$.pipe(debounceTime(100))
		.subscribe((event: any) => {
		  this.onInputChange(event.target.value);
		});
		$("body").height("100%");
		$("html").height("100%");
		
		 /*
			 * API call and grab full data
			 */
			 setTimeout(() => {
			 	this.getRequests(1);
			 },10);

			 
			 this.request_id = this.activatedRoute.snapshot.queryParams['request_id']

			 
			}
			highLight(id:any){
				$("#"+this.request_id).attr("style", "background-color: #dedddd!important;");
				// setTimeout(() => {
					// 	console.log("hello");
					// 	$("#"+this.request_id).attr("style", "background-color: ");
					// }, 100);
				}


				delete(request_id){
					if(request_id!=this.request_id){
						$("#"+this.request_id).attr("style", "background-color: null;");
					}
					var cnf=confirm("Are you sure you want to delete the data?")
					if(cnf){
						this.admin=this.localSt.retrieve('user_details');
						this.request_data={
							request_id:request_id,
							sender_id:this.admin['id']
						}
						this.dataDeleteRequestService.deleteRequests(this.request_data).subscribe((res:any)=>{
							if(res.status=='success'){
								this.toastr.success('Deleted Successfully!','SUCCESS');
								this.requests = [];
        						this.apiCallCount = 0;
        						this.offSet = 0; 
								this.getRequests(1);

							}else {
								if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
									this.toastr.error("Kindly login","Session Expired");
									this.authServiceObj.logout();             
								}else{
									this.toastr.error(res.message,'Success');
								}
							}
						})
					}
				}

				restore(request_id){
					var cnf=confirm("Are you sure you want to restore the data ?")
					if(cnf){
						this.admin=this.localSt.retrieve('user_details');
						this.request_data={
							request_id:request_id,
							sender_id:this.admin['id']
						}
						this.dataDeleteRequestService.restoreRequests(this.request_data).subscribe((res:any)=>{
							if(res.status=='success'){
								this.toastr.success('Deleted Successfully!','SUCCESS');
								this.getRequests(1);

							}else {
								if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
									this.toastr.error("Kindly login","Session Expired");
									this.authServiceObj.logout();             
								}else{
									this.toastr.error(res.message,'Success');
								}
							}

						});
					}
				}

				getRequests(page){
					// if(this.offSet != 0 && this.request_params['searchkey'] !='' ){
					// 	this.brands = [];
					// 	this.apiCallCount = 0;
					// 	this.offSet = 0;
					// }
					this.request_params['offset'] = this.offSet;
					this.loaderService.display(true); 
					this.dataDeleteRequestService.getRequests(this.request_params).subscribe((res:any)=>{
						this.loaderService.display(false);
						this.apiCallCount++;
						if(res.status=='success' && !_.isNull(res.request_result)){
							this.pager["total_count"] = this.requests.length + res.request_result.length;
							this.pager["current_page"] = page;
							const result = this.requests.concat(res.request_result);
							this.requests = res.request_result;
							//Load full result
							if(!_.isNil(res.max_possible_res_length) && res.max_possible_res_length > this.requests.length) {
								this.offSet = this.apiCallCount * this.queryLimit;
								//this.getBrands(this.client,1);
								// this.getCountry(1);
							}    
						}else {
							if(this.requests.length === 0 || res.max_possible_res_length === 0) {
								this.requests = [];
								this.no_data = 'No Data';
								this.pager["total_count"] = 0;
								this.pager["current_page"] = page;
							}
							if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
								this.toastr.error("Kindly login","Session Expired");
								this.authServiceObj.logout();
							}
						}
					})

				}
				onPageChange(page: number) {
					this.pager["current_page"] = page;
					this.pager["offset"] = this.itemPerPageCount * (page - 1);
				}

				onSort(orderby: string, id: any) {
					if (this.request_params["orderby"] === orderby) {
						if (this.request_params["order"] === "asc") {
							this.request_params["order"] = "desc";
						} else {
							this.request_params["order"] = "asc";
						}
					} else {
						this.request_params["orderby"] = orderby;
						this.request_params["order"] = "desc"
					}
					//Reset the page to 1, offset to 0
					this.pager["current_page"] = 1;
					this.pager["offset"] = 0;
					this.requests = this.dataDeleteRequestService.sortRequestList(this.requests, this.request_params["orderby"], this.request_params["order"]);
				}

				onSearch() {
					this.requests = [];
					this.apiCallCount = 0;
					this.offSet = 0;
					this.pager["offset"] =0;
					this.getRequests(1);
				}
				onInputChange(text) {
					this.onSearch();
				} 

				ngOnInit() {
				}

			}
