import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { HttpHeaders, HttpClient } from '@angular/common/http'; 
import { LocalStorageService } from "ngx-webstorage";
import { Observable } from "rxjs";
import { AppSettings } from "../settings.global";

@Injectable()
export class SmartProxyService {    
	constructor(private httpClient: HttpClient, private localSt: LocalStorageService) {
	}    
	getProxyLimit() {  
        let headers: HttpHeaders; 
        headers = new HttpHeaders({
			'Accept': 'application/json',
			'x-access-token': this.localSt.retrieve("access_token")
		});  
		let options: any = { headers: headers, responseType: 'json', observe: 'response' };
		return this.httpClient.get(AppSettings.API_ENDPOINT+'/smartproxy/usage', options)
			.map(function(res:any){
				console.log('res', res);
				
				return res.body || {
					status:"failed",
					message:"Something went wrong"
				};
			});
    }    
}
