
import {fromEvent as observableFromEvent,  concat } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { Observable } from 'rxjs/';
import { Routes, RouterModule, ActivatedRoute, Router, Params } from '@angular/router';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { AppSettings } from '../../../settings.global'
import { LoaderService } from '../../../services/loader/loader.service';
import { ClientService } from '../../../services/client/client.service';
import { AuthService } from '../../../services/auth/auth.service';
import { BrandService } from '../../../services/brand/brand.service';
import * as _ from 'lodash';
declare var $: any;


import { UpcService } from '../../../services/upc/upc.service'
// import { forEach } from '@angular/router/src/utils/collection';
import { ToastrService } from 'ngx-toastr';

// 


@Component({
  selector: 'app-upc',
  templateUrl: './upc.component.html',
  styleUrls: ['./upc.component.css']
})

export class UpcComponent implements OnInit {
  public request_params: any;
  public pager: any;
  public queryLimit: number = AppSettings.DEFAULT_QUERY_LIMIT;
  public itemPerPageCount: number = AppSettings.DEFAULT_PAGER_COUNT;
  public apiCallCount: number = 0;
  public offSet: any = 0;
  public upcs: any;
  public clients: any;//dropdown data
  public client: any;// client dropdown data
  public no_data: any;
  public searchInputObservable$: any;
  public modal_title: any;
  public selectAllFlag: boolean;//checkbox
  public selectFalg: boolean;
  public brands: any;
  public user_id: any
  public clientsOfBulkImport: any;
  public upc: any;
  public bulkClient: any
  public newClient: any
  public checkBox: any;
  public no_of_entry: any;
  public brandtitle: any
  public clientNames: any;
  public prodId: any
  public upcIds: any
  public paramData: any
  public selectOne:any
  public editedFlag:boolean
  singleprodId: any;
  constructor(private upcService: UpcService,
    private brandService: BrandService,
    private clientService: ClientService,
    private toastr: ToastrService,
    private route: Router,
    public localSt: LocalStorageService,
    private loaderService: LoaderService,
    private activatedRoute: ActivatedRoute,
    private authServiceObj: AuthService) {
    this.no_data = "Loading...."
    this.request_params = {
      searchkey: "",
      orderby: "",
      order: "",
      limit: this.queryLimit,
      offset: 0
    }
    this.upc = {
      id: '',
      brand: '',
      markertPlace: [],
      productId: [],
      edit: false,

    }
    this.no_of_entry = 10;
    this.pager = {
      limit: this.no_of_entry,
      offset: 0,
      total_count: 0,
      current_page: 1
    }
    this.itemPerPageCount = this.no_of_entry;
    this.upcs = []
    this.newClient = '';
    this.selectAllFlag = false;
    this.selectFalg = false;
    this.checkBox = [];
    this.clientNames = [];
   
    this.prodId = [];
    this.singleprodId=[];
    



  }


  ngAfterContentInit() {

    //Input search observable - to avoid the  onInputChange() firing whenever the input changes
    const searchInputObj = document.querySelector('#search_text');
    this.searchInputObservable$ = observableFromEvent(searchInputObj, 'keyup');

    // this.searchInputObservable$.debounceTime(100)
    //   .subscribe((event: any) => {
    //      this.onInputChange(event.target.value);
    //   });

    $("body").height("100%");
    $("html").height("100%");
    this.getClientOfBulkImport()
    /*
      * API call and grab full data
      */
    setTimeout(() => {
      this.getClient(1);
    }, 10);

  }
  
  // this.brandtitle = this.clientNames[newValue]
  // get clients for dropdown
  getClient(page) {
    
    this.clientService.getClientSelect().subscribe((res: any) => {


      if (res.status == 'success') {
        if (res.data_found) {
          this.clients = res.data_found;
          this.bulkClient = this.clients
          console.log(this.clients,"this is cient")
          this.clients.forEach((res: any) => {
            this.clientNames[res.id] = res.name

          })
          this.brandtitle = this.clients[0].name
        } else {
          this.clients = null;
        }
        if (this.activatedRoute.snapshot.queryParams['client_id']) {
          this.client = parseInt(this.activatedRoute.snapshot.queryParams['client_id'])

        } else {
          if (this.clients) {
            this.client = this.clients[0].id;
          } else {
            this.client = null;
          }
        }
        if (this.client != null) {
          this.getUpc(this.client, page);
        } else {
          this.no_data = 'No Data';
        }
      }
      else {
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          //   this.toastr.error('Invalid User','Request Failed');
          this.authServiceObj.logout();
        }
      }
    })
  }
  // search input

  //add button
  addUpc() {
    this.route.navigate(['upc/add'], { queryParams: { client_id: this.client } });
  }



  //get upc data in table
  getUpc(client_id, page) {
    if (this.offSet != 0 && this.request_params['searchkey'] != '') {
      this.upcs = [];
      this.apiCallCount = 0;
      this.offSet = 0;
    }
    this.request_params['offset'] = this.offSet;
    this.request_params['client'] = client_id;
    this.loaderService.display(true);
    this.upcService.getUpcs(this.request_params).subscribe((res: any) => {
    console.log(res,"search response")

      this.loaderService.display(false);
      this.apiCallCount++;
      if (res.status == 'success' && !_.isNull(res.market_result)) {
        this.pager["total_count"] = this.upcs.length + res.market_result.length;
        this.pager["current_page"] = page;
        const result = this.upcs.concat(res.market_result);
        this.upcs = result;
        console.log(this.upcs,'UPCS')
        //Load full result
        if (!_.isNil(res.max_possible_res_length) && res.max_possible_res_length > this.upcs.length) {
          this.offSet = this.apiCallCount * this.queryLimit;
          //this.getBrands(this.client,1);
          // this.getCountry(1);
        }
      } else {
        if (this.upcs.length === 0 || res.max_possible_res_length === 0) {
          this.upcs = [];
          this.no_data = 'No Data';
          this.pager["total_count"] = 0;
          this.pager["current_page"] = page;
        }
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          this.toastr.error("Kindly login", "Session Expired");
          this.authServiceObj.logout();
        }
      }
    })
  }
// pagination control
  onChange(newValue) {
    this.client = newValue;
    this.selectAllFlag = false;//checkbox select all
    this.selectFalg = false;
    this.checkBox = [];//checkbox selectall
    this.upcs = [];
    this.apiCallCount = 0;
    this.offSet = 0;
    this.brandtitle = this.clientNames[newValue]
    this.getUpc(newValue, 1);
  }

  onPageChange(page: number) {
    this.pager["current_page"] = page;
    this.pager["offset"] = this.itemPerPageCount * (page - 1);
  }
// search function
  onSearch() {
    this.upcs = [];
    this.apiCallCount = 0;
    this.offSet = 0;
    this.pager["offset"] = 0;
    this.getUpc(this.client,1)
    console.log(this.client,"search client")
  }

// search funciton works when enter key pressed
  keyDownFunction(event) {
    console.log(event, "its event");
    if (event.keyCode === 13) {
      this.onSearch()
    }
  }
  //  page display number changer
  entryNoChange(value: number) {
    this.upcs = [];
    this.apiCallCount = 0;
    this.offSet = 0;
    this.pager["limit"] = value;
    this.pager["total_count"] = 0;
    this.pager["current_page"] = 1;
    this.pager["offset"] = 0;
    this.getUpc(this.client, 1);

    this.itemPerPageCount = value;
  }
  // to sort elements in the table
  onSort(orderby: string, id: any) {
    if (this.request_params["orderby"] === orderby) {
      if (this.request_params["order"] === "asc") {
        this.request_params["order"] = "desc";
      } else {
        this.request_params["order"] = "asc";
      }
    } else {
      this.request_params["orderby"] = orderby;
      this.request_params["order"] = "desc"
    }
    //Reset the page to 1, offset to 0
    this.pager["current_page"] = 1;
    this.pager["offset"] = 0;
    this.upcs = this.upcService.sortUpcList(this.upcs, this.request_params["orderby"], this.request_params["order"]);
  }

  //inactivate and activate upc

  statuschanger(id: any, status: string, value: any) {
    console.log(typeof(status),status,"status");
    if(value=='single'){
   var  state = status.toString()
    }
    var user = this.localSt.retrieve('user_details');
    if (status==='false'||state=='false') {
      if (value == 'single') {
        this.singleprodId =[]
        this.singleprodId.push(id)
        let singleId = { id: this.singleprodId, status: false };
        this.paramData = singleId
      }else{
        if(this.prodId.length==0){
          this.toastr.info('Please select the data to change status.');
          return false;
        }
        let multipleId = { id: this.prodId, status: status };
      this.paramData = multipleId
      }
      let data ={
        param:this.paramData,
        userId:user.id
      } 
      var cnf = confirm("Are you sure you want to deactivate this product?");
      if (cnf) {
        this.upcService.inActivate(data).subscribe((res: any) => {
          if (res.status == "success") {

            this.prodId = []
            this.toastr.success('Deactivated', 'SUCCESS');
            this.selectAllFlag = false;
            this.selectFalg = false;
            this.checkBox = false;
            this.checkBox = [];
            this.upcs = [];
            this.singleprodId =[]
            this.apiCallCount = 0;
            this.offSet = 0;
            this.getUpc(this.client, 1);
          }
          else {
            if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
              this.authServiceObj.logout();
            }
          }
        })
      }
    } else if(status=='true'||state=='true') {
      if (value == 'single') {
        this.singleprodId =[]
        
        this.singleprodId.push(id)
        let singleId = { id: this.singleprodId, status: true };
        this.paramData = singleId
      }else{
        if(this.prodId.length==0){
          this.toastr.info('Please select the data to change status.');
          return false;
        }
        let multipleId = { id: this.prodId, status: status };
      this.paramData = multipleId
      }
      let data ={
        param:this.paramData,
        userId:user.id
      }

      var cnf = confirm("Are you sure you want to activate this product?");
      if (cnf) {
        this.upcService.activate(data).subscribe((res: any) => {
          if (res.status == "success") {
            this.prodId = []
            this.toastr.success('Activated', 'SUCCESS');
            this.selectAllFlag = false;
            this.selectFalg = false;
            this.checkBox = false;
            this.checkBox = [];
            this.upcs = [];
            this.singleprodId =[]
            this.apiCallCount = 0;
            this.offSet = 0;
            this.getUpc(this.client, 1);

            //this.getClientData(1);
          } else {
            if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
              this.authServiceObj.logout();
            }
            else {
              this.toastr.error(res.message, 'Failed');
            }
          }
        })
      }

    }
  }



  //get the client to bulk import
  getClientOfBulkImport() {
    this.clientService.getClientSelect().subscribe((res: any) => {
      if (res.status == 'success') {
        this.clientsOfBulkImport = this.client

        this.getBrand(this.client)
      }
      else {
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          this.toastr.error("Kindly login", "Session Expired");
          this.authServiceObj.logout();
        }
      }
    })
  }





  //To make all check box true or false
  selectAll() {
    this.prodId = []
    if (this.selectAllFlag == false) {
      for (let e of this.upcs) {
        this.prodId.push(e.product_id)
        this.checkBox[e.product_id] = true;
      }
      this.selectAllFlag = true;
      // this.selectFalg = true;
    }
    else {
      for (let e of this.upcs) {
        this.checkBox[e.product_id] = false;
      }
      this.prodId = []
      this.selectAllFlag = false;
      // this.selectFalg = false;
    }

  }

  //To manage check box selection
  selection(payout: any) {
    console.log(payout,'payout')
    this.selectOne =[]
    var idx = this.prodId.indexOf(payout)
    if (idx > -1) {
      this.prodId.splice(idx, 1)
    }
    // is newly selected
    else if (idx < 0) {
      this.prodId.push(payout)
    }
    //To make select all check box true or false
    for (let e of this.upcs) {
      this.selectOne.push(e.product_id)
    }
  

    if (this.selectOne.length != this.prodId.length) {
      this.selectAllFlag = false;

    } else {
      this.selectAllFlag = true;

    }
   console.log(this.upcs,'itupcman');
   

  }


// get brands to dropdown
  getBrand(newValue) {

    this.loaderService.display(true);
    this.brandService.getBrandSelect(newValue).subscribe((res: any) => {
      this.loaderService.display(false);
      if (res.status == 'success') {
        this.brands = res.data_found;
      }
      else {
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          this.toastr.error("Kindly login", "Session Expired");
          this.authServiceObj.logout();
        }
      }
    })
  }



  // show popUp of Bulk import
  getBrands() {
    $('#brandModal').modal('show');
  }

  //  deleteUpc(id:any){
  //   var cnf=confirm("Are you sure you want to delete this Admin?")
  //   if(cnf){
  //     this.upcService.delete(id).subscribe((res:any)=>{
  //       if(res.status=='success'){
  //         this.toastr.success('Admin Deleted Successfully!','SUCCESS');
  //         this.apiCallCount = 0;
  //         this.offSet = 0;         
  //         this.getUsers(1,1);
  //         }else {
  //           if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
  //             this.toastr.error("Kindly login","Session Expired");
  //             this.authServiceObj.logout();             
  //           }else{
  //             this.toastr.error(res.message,'Failed');
  //           }
  //         }
  //     })
  //   }
  // }

  onSubmit(valid) {

  }

  ngOnInit() {
  }

}
