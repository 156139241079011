import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, Renderer } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'ngx-webstorage';
import { AuthService } from '../../services/auth/auth.service';
import { LoaderService } from '../../services/loader/loader.service';
import { BrandService } from '../../services/brand/brand.service';
import { SellerDataService } from '../../services/seller-data/seller-data.service';
import { DuplicateModalComponent } from '../duplicate-modal/duplicate-modal.component';
import { AppSettings } from '../../settings.global';
import * as $ from 'jquery';
declare var $: any;
import * as _ from 'lodash';
import * as _swal from 'sweetalert';
import { SweetAlert } from 'sweetalert/typings/core';

@Component({
  selector: 'app-seller-data',
  templateUrl: './seller-data.component.html',
  styleUrls: ['./seller-data.component.css'],
  providers: [DatePipe]
})
export class SellerDataComponent implements OnInit {
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  public client: any;
  public clientName: any;
  public barndName: any;
  public sourceName: any;
  public brands: any;
  public brand: any;
  public searchQuery: any;
  public queryLimit: number = 100000;
  public listData: any;
  public offSet: number = 0;
  public apiCallCount: number = 0;
  public pager: any;
  public itemPerPageCount: number;
  public numberOfPages: any;
  public start: any;
  public end: any;
  public headers: any = [];
  public no_of_entry: any;
  public searchInputObservable$: any;
  public screenShots: any;
  public activeIndex: any;
  public img_url = AppSettings.IMAGE_BASE_URL;
  public selectAllFlag: boolean;
  public urlToDelete: any;
  public selectFalg: boolean;
  public checkBox: any;
  public test: any;
  public colspan: any;
  public screenshotFile: any;
 public market_place_header = [
  { "old_key": "client_name", "new_key": "Client" },
  { "old_key": "brand_name", "new_key": "Brand" },
  { "old_key": "country", "new_key": "Country" },
  { "old_key": "seller_id", "new_key": "Seller ID" },
  { "old_key": "seller_name", "new_key": "Seller Name" },
  { "old_key": "detection_date", "new_key": "Detection Date" },
  { "old_key": "phone_number_1", "new_key": "Phone No. #1" },
  { "old_key": "phone_number_2", "new_key": "Phone No. #2" },
  { "old_key": "email_address_1", "new_key": "Email Address #1" },
  { "old_key": "email_address_2", "new_key": "Email Address #2" },
  { "old_key": "address", "new_key": "Address" },
  { "old_key": "social_network_link", "new_key": "Social Network Link" },
  { "old_key": "marketplace_link", "new_key": "Marketplace Link" },
  { "old_key": "standalone_website_link", "new_key": "Standalone Website Link" }
  ];

  public social_media_header = [ { "old_key": "url_profile", "new_key": "URL Profile" },
  { "old_key": "detection_date", "new_key": "Detection Date" },
  { "old_key": "client_name", "new_key": "Client" },
  { "old_key": "brand_name", "new_key": "Brand" },
  { "old_key": "country", "new_key": "Country" },
  { "old_key": "seller_id", "new_key": "Seller ID" },
  { "old_key": "seller_name", "new_key": "Seller Name" },
  { "old_key": "phone_number_1", "new_key": "Phone No. #1" },
  { "old_key": "phone_number_2", "new_key": "Phone No. #2" },
  { "old_key": "email_address_1", "new_key": "Email Address #1" },
  { "old_key": "email_address_2", "new_key": "Email Address #2" },
  { "old_key": "address", "new_key": "Address" },
  { "old_key": "social_network_link", "new_key": "Social Network Link" },
  { "old_key": "marketplace_link", "new_key": "Marketplace Link" },
  { "old_key": "standalone_website_link", "new_key": "Standalone Website Link" }
  ];

  public standalone_header = [
    { "old_key": "client_name", "new_key": "Client" },
    { "old_key": "brand_name", "new_key": "Brand" },
    { "old_key": "country", "new_key": "Country" },
    { "old_key": "seller_id", "new_key": "Seller ID" },
    { "old_key": "seller_name", "new_key": "Seller Name" },
    { "old_key": "detection_date", "new_key": "Detection Date" },
    { "old_key": "phone_number_1", "new_key": "Phone No. #1" },
    { "old_key": "phone_number_2", "new_key": "Phone No. #2" },
    { "old_key": "email_address_1", "new_key": "Email Address #1" },
    { "old_key": "email_address_2", "new_key": "Email Address #2" },
    { "old_key": "address", "new_key": "Address" },
    { "old_key": "social_network_link", "new_key": "Social Network Link" },
    { "old_key": "marketplace_link", "new_key": "Marketplace Link" },
    { "old_key": "standalone_website_link", "new_key": "Standalone Website Link" }
  ];
  public pressed: boolean = false;
  public startX: any = null;
  public startWidth: any = null;
  public indexes: any = [];
  public user_role:number = this.localSt.retrieve("user_details").user_role;
  public is_delete_available: boolean = false;

  

  constructor(
    public localSt: LocalStorageService,
    private loaderService: LoaderService,
    private authServiceObj: AuthService,
    private toastr: ToastrService,
    private brandService: BrandService,
    private dataSellerService: SellerDataService,
    public datepipe: DatePipe,
    private modalService: BsModalService,
    private renderer: Renderer) {
    this.client = '';
    this.sourceName = 'Market_place';
    this.searchQuery = {
      user_id: this.localSt.retrieve("user_details").id,
      source: 'm_commerce',
      client: null,
      brand: null,
      market_place: null,
      social_media: null,
      reportedLink: true,
      fromDate: null,
      toDate: null,
      searchkey: "",
      orderby: "",
      order: "",
      limit: this.queryLimit,
      offset: 0,
      seller_name:false,
      phone:false,
      email:false,
      address:false
    };
    this.listData = [];
    this.no_of_entry = 50;
    this.pager = {
      limit: this.no_of_entry,
      offset: 0,
      total_count: 0,
      current_page: 1
    };
    this.itemPerPageCount=this.no_of_entry;

    if (this.searchQuery.source == 'market_place' || this.searchQuery.source == 'm_commerce' || this.searchQuery.source == 'e_commerce') {
      this.headers = this.market_place_header;
    } else if (this.searchQuery.source == 'social_media') {
      this.headers = this.social_media_header;
    } else if (this.searchQuery.source == 'stand_alone') {
      this.headers = this.standalone_header;
    }
    this.colspan = this.headers.length;
    this.screenShots = [];
    this.activeIndex = 0;
    this.selectAllFlag = false;
    this.selectFalg = false;
    this.urlToDelete = [];
    this.checkBox = [];
    this.test = [];
    this.screenshotFile = '';
    if(this.user_role==1)
    {
      this.is_delete_available=true;
    }
  }

  ngOnInit() {
  }
  ngAfterContentInit() {
   
    $("body").height("100%");
    $("html").height("100%");

    this.getSellerData(this.searchQuery, 1);
  }
  
  onSorceChange(source: any) {
    this.listData = [];
    this.selectAllFlag = false;
    this.selectFalg = false;
    this.urlToDelete = [];
    this.checkBox = [];
    this.test = [];
    this.searchQuery["orderby"] = "";
    this.searchQuery["order"] = "";
    if (source == 'market_place') {
      this.headers = this.market_place_header;
      this.sourceName = 'Market_place';
    } else if (source == 'social_media') {
      this.headers = this.social_media_header;
      this.sourceName = 'Social_media';
    } else if (source == 'stand_alone') {
      this.headers = this.standalone_header;
      this.sourceName = 'Standalone_website';
    }
    this.colspan = this.headers.length;
    this.apiCallCount = 0;
    this.offSet = 0;
    console.log(this.searchQuery);
    this.getSellerData(this.searchQuery, 1);
  }
  
  getSellerData(data, page: number) {
    this.indexes.forEach(index => {
      $('.glowTableHeader tr th:nth-child(' + index + ')').css({ 'min-width': 150, 'max-width': 150 });
    });
    this.indexes=[];
    this.searchQuery['offset'] = this.offSet;
    this.loaderService.display(true);
    this.dataSellerService.getSellerData(this.searchQuery).subscribe((res: any) => {
      if (res.status == 'success' && !_.isNil(res.data) && !_.isNull(res.data)) {
        console.log("success",res)
        this.pager["total_count"] = this.listData.length + res.data.length;
        this.pager["current_page"] = page;
        const result = this.listData.concat(res.data);
        this.listData = result;
        if (!_.isNil(res.max_possible_res_length) && !_.isNull(res.data) && res.max_possible_res_length > this.listData.length + 1) {
          this.apiCallCount++;
          this.offSet = this.apiCallCount * this.queryLimit;
          this.getSellerData(this.searchQuery, 1);
        } else {
          this.loaderService.display(false);
        }
      } else {
        console.log("failed")
        this.loaderService.display(false);
        if (this.listData.length === 0 || res.max_possible_res_length === 0) {
          this.listData = [];
          this.pager["total_count"] = 0;
          this.pager["current_page"] = page;
          const totalItemsCount = this.pager["total_count"];
          const numberOfItemsPerPage = this.pager["limit"];
          const pages = this.pager["current_page"];
          this.numberOfPages = totalItemsCount;
          this.start = (pages * parseInt(numberOfItemsPerPage)) - (parseInt(numberOfItemsPerPage) - 1);
          this.end = Math.min(this.start + parseInt(numberOfItemsPerPage) - 1, totalItemsCount);
        }
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          //this.toastr.error(this.errorDscrp,this.errorMsg);
          this.authServiceObj.logout();
        }
      }
    })
    this.initResizableColumns();
  }

  onSort(orderby: string, id: any) {
    this.indexes.forEach(index => {
      $('.glowTableHeader tr th:nth-child(' + index + ')').css({ 'min-width': 150, 'max-width': 150 });
      $('.glowTableBody tr td:nth-child(' + index + ')').css({ 'min-width': 150, 'max-width': 150 });
    });
    this.indexes=[];
    console.log(orderby, id);
    if (this.searchQuery["orderby"] === orderby) {
      if (this.searchQuery["order"] === "asc") {
        this.searchQuery["order"] = "desc";
      } else {
        this.searchQuery["order"] = "asc";
      }
    } else {
      this.searchQuery["orderby"] = orderby;
      this.searchQuery["order"] = "desc"
    }
    //Reset the page to 1, offset to 0
    this.pager["current_page"] = 1;
    this.pager["offset"] = 0;
    this.listData = this.dataSellerService.sortListData(this.listData, this.searchQuery["orderby"], this.searchQuery["order"]);
    
  }

  onSearch() {
    this.listData = [];
    this.apiCallCount = 0;
    this.offSet = 0;
    this.pager["offset"] = 0;
    this.pager["total_count"] = 0;
    this.pager["current_page"] = 1;
    this.searchQuery["orderby"] = "";
    this.searchQuery["order"] = "";
    this.getSellerData(this.searchQuery, 1)
  }
  
  entryNoChange(value: number) {
    this.listData = [];
    this.apiCallCount = 0;
    this.offSet = 0;
    // this.dashData.limit = value;
    this.pager["limit"] = value;
    this.pager["total_count"] = 0;
    this.pager["current_page"] = 1;
    this.pager["offset"] = 0;
    this.searchQuery["orderby"] = "";
    this.searchQuery["order"] = "";
    this.getSellerData(this.searchQuery, 1);
    this.itemPerPageCount=value;
  }

  onPageChange(page: number) {
    this.pager["current_page"] = page;
    this.pager["offset"] = this.itemPerPageCount * (page - 1);
    const totalItemsCount = this.pager["total_count"];
    const numberOfItemsPerPage = this.pager["limit"];
    const pages = this.pager["current_page"];
    this.numberOfPages = totalItemsCount;
    this.start = (pages * parseInt(numberOfItemsPerPage)) - (parseInt(numberOfItemsPerPage) - 1);
    this.end = Math.min(this.start + parseInt(numberOfItemsPerPage) - 1, totalItemsCount);
    this.indexes.forEach(index => {
      $('.glowTableHeader tr th:nth-child(' + index + ')').css({ 'min-width': 150, 'max-width': 150 });
    });
    this.indexes=[];
  }

  //To make all check box true or false
  selectAll() {
    console.log(this.selectAllFlag,"selectall");
    
    if (this.selectAllFlag == false) {
      
      this.urlToDelete = [];
      for (let e of this.listData) {
        this.urlToDelete.push(e.id)
        this.checkBox[e.id] = true;
      }
      this.selectAllFlag = true;
      this.selectFalg = true;
    }
    else {
      for (let e of this.listData) {

        this.checkBox[e.id] = false;

      }
      this.urlToDelete = [];
      this.selectAllFlag = false;
      this.selectFalg = false;
    }

  }
  //To manage check box selection
  
  public onMouseDown(event) {
    this.start = event.target;
    this.pressed = true;
    this.startX = event.x;
    this.startWidth = $(this.start).parent().width();
    if (!this.indexes.includes($(this.start).parent().index() + 1)) {
      this.indexes.push($(this.start).parent().index() + 1);
    }
    this.initResizableColumns();
  }
  private initResizableColumns() {
    this.renderer.listenGlobal('body', 'mousemove', (event) => {
      if (this.pressed) {
        let width = this.startWidth + (event.x - this.startX);
        console.log(width, "widthhh")
        $(this.start).parent().css({ 'min-width': width, 'max-width': width });
        let index = $(this.start).parent().index() + 1;
        $('.glowTableBody tr td:nth-child(' + index + ')').css({ 'min-width': width, 'max-width': width });
      }
    });
    this.renderer.listenGlobal('body', 'mouseup', (event) => {
      if (this.pressed) {
        this.pressed = false;
      }
    });
  }

  openDuplicateFilterModal() {
  const initialState = {
    sellerName: this.searchQuery.seller_name,
    phone: this.searchQuery.phone,
    email: this.searchQuery.email,
    address: this.searchQuery.address
  };
  const modalRef = this.modalService.show(DuplicateModalComponent, { initialState });
  modalRef.content.onClose.subscribe((result: any) => {
    if (result) {
      this.applyDuplicateFilters(result);
    }
  });
}
  
applyDuplicateFilters(filters: any) {
  this.searchQuery.seller_name = filters.seller_name;
  this.searchQuery.phone = filters.phone;
  this.searchQuery.email = filters.email;
  this.searchQuery.address = filters.address;

  // Reset the data and pagination
  this.listData = [];
  this.apiCallCount = 0;
  this.offSet = 0;
  this.pager["offset"] = 0;
  this.pager["total_count"] = 0;
  this.pager["current_page"] = 1;
  this.searchQuery["orderby"] = "";
  this.searchQuery["order"] = "";

  // Call the API with the updated filters
  this.getSellerData(this.searchQuery, 1);
}

clearFilters() {
  this.searchQuery.seller_name = false;
  this.searchQuery.phone = false;
  this.searchQuery.email = false;
  this.searchQuery.address = false;
}

}