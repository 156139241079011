
import {fromEvent as observableFromEvent} from 'rxjs';
import { Component, OnInit } from '@angular/core';
declare var $:any;
import * as _ from 'lodash';
import {Observable} from 'rxjs/';
import {NgxPaginationModule} from 'ngx-pagination';//importing ng2-pagination
import {Routes, RouterModule, ActivatedRoute, Router, Params} from '@angular/router';
import {LoaderService} from '../../services/loader/loader.service';
import {AuthService} from '../../services/auth/auth.service';
import {AppSettings} from '../../settings.global';
import {ClientService} from '../../services/client/client.service';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import {debounceTime} from 'rxjs/operators';

@Component({
	selector: 'app-client-list',
	templateUrl: './client-list.component.html',
	styleUrls: ['./client-list.component.css']
})
export class ClientListComponent implements OnInit {
	public request_params:any;
	public pager:any;
	public clients:any;
	public queryLimit: number = AppSettings.DEFAULT_QUERY_LIMIT;
	public itemPerPageCount: number = AppSettings.DEFAULT_PAGER_COUNT;
	public apiCallCount: number = 0;
	public offSet: number = 0;
	public searchInputObservable$: any;
	public no_data:any;
	public modal_title:any;
	public brands:any;
	public user_details:any;
	constructor(private clientService: ClientService, 
		private loaderService: LoaderService,
		private authServiceObj:AuthService,
		private toastr: ToastrService,
		public localSt:LocalStorageService) {
		this.clients = [];
		this.no_data="Loading....."
		
		this.pager = {
			limit: this.itemPerPageCount,
			offset: 0,
			total_count: 0,
			current_page: 1
		}
		this.user_details = this.localSt.retrieve("user_details");
		this.request_params = {
			user_id:this.user_details.id,
			searchkey: "",
			orderby: "",
			order: "",
			limit: this.queryLimit,
			offset: 0
		}
	}
	ngAfterContentInit() {
		//Input search observable - to avoid the  onInputChange() firing whenever the input changes
		const searchInputObj = document.querySelector('#search_text');
		this.searchInputObservable$ = observableFromEvent(searchInputObj, 'keyup');

		this.searchInputObservable$.pipe(debounceTime(100))
		.subscribe((event: any) => {
		  this.onInputChange(event.target.value);
		});
		$("body").height("100%");
		$("html").height("100%");

        /*
         * API call and grab full data
         */
         setTimeout(() => {
         	this.getClientData(1);
         },10);
     }

     getClientData(page: number) {
     	this.request_params['offset'] = this.offSet;
     	this.loaderService.display(true);
     	console.log('req',this.request_params);
     	this.clientService.getClients(this.request_params).subscribe((res: any) => {
     		this.loaderService.display(false);

			//To Load more result
			this.apiCallCount++;

			if (res.status === "success" && !_.isNil(res.client_list) && !_.isNull(res.client_list)) {
				this.pager["total_count"] = this.clients.length + res.client_list.length;
				this.pager["current_page"] = page;
				const result = this.clients.concat(res.client_list);
				this.clients = result;
				
				//Load full result
				if(!_.isNil(res.max_possible_res_length) && res.max_possible_res_length > this.clients.length) {
					this.offSet = this.apiCallCount * this.queryLimit;

					this.getClientData(1);
				}
			} else {
				if(this.clients.length === 0) {
					this.clients = [];
					this.no_data="No Data"
					this.pager["total_count"] = 0;
					this.pager["current_page"] = page;
				}
				if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
					this.authServiceObj.logout();
				}
				console.log('Final api call result Or No data', res);
			}
		});
     }
     onPageChange(page: number) {
     	this.pager["current_page"] = page;
     	this.pager["offset"] = this.itemPerPageCount * (page - 1);
     }

     onSearch() {
     	this.clients = [];
     	this.apiCallCount = 0;
     	this.offSet = 0;
		this.pager["offset"] =0;
     	this.getClientData(1);
     }
     onInputChange(text) {
		this.onSearch();
	} 

     onSort(orderby: string, id: any) {
     	if (this.request_params["orderby"] === orderby) {
     		if (this.request_params["order"] === "asc") {
     			this.request_params["order"] = "desc";
     		} else {
     			this.request_params["order"] = "asc";
     		}
     	} else {
     		this.request_params["orderby"] = orderby;
     		this.request_params["order"] = "desc"
     	}
		//Reset the page to 1, offset to 0
		this.pager["current_page"] = 1;
		this.pager["offset"] = 0;
		this.clients = this.clientService.sortClientList(this.clients, this.request_params["orderby"], this.request_params["order"]);
	}
	 //Activate client
	 activate(id:any,type:any,sid:any){
	 	var cnf=confirm("Are you sure you want to activate this client?");
	 	if(cnf){
	 		let data={id:id};
	 		this.clientService.activate(data).subscribe((res:any)=>{
	 			if(res.status=="success"){
	 				this.toastr.success('Activated','SUCCESS');
	 				this.clients = [];
	 				this.apiCallCount = 0;
	 				this.offSet = 0;
	 				this.getClientData(1);
	 			} else {
	 				if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
	 					this.authServiceObj.logout();
	 				}
	 				else{
	 					this.toastr.error(res.message,'Failed');
	 				}
	 			}

	 		})

	 	}

	 }
       //Activate client
       inActivate(id:any){
       	let data={id:id};
       	var cnf=confirm("Are you sure you want to deactivate this client?");
       	if(cnf){
       		this.clientService.inActivate(data).subscribe((res:any)=>{
       			if(res.status=="success"){
       				this.toastr.success('Inactivated','SUCCESS');
       				this.clients = [];
       				this.apiCallCount = 0;
       				this.offSet = 0;
       				this.getClientData(1);
       			}
       			else {
       				if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
       					this.authServiceObj.logout();
       				}
       			}

       		})

       	}

       }
       getBrands(title:any,brands:any){
   	//alert("dd")
   	this.brands=[];
   	this.modal_title=title;
   	if(brands){
   		this.brands=brands;
   		$('#brandModal').modal('show');
   	}
   }

       ngOnInit() {
       }

   }
