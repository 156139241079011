import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public activeComponent:any;

  constructor(private route: ActivatedRoute) { 
 this.activeComponent=this.route.snapshot.data["activeComponent"];
   }
 ngAfterContentInit() { $("body").height("100%");
        $("html").height("100%");

    }
    ngOnInit() {
  }
}
