
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';

import {AppSettings} from '../../settings.global';
import {ActivatedRoute, Router} from '@angular/router';
declare var $:any;
import * as _ from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ReportService {

	public headers: HttpHeaders;

	constructor(private httpClient: HttpClient, private localSt: LocalStorageService) { 
		this.headers = new HttpHeaders({
			'Content-Type': 'application/json;charset=UTF-8',
			'Accept': 'application/json',
			'x-access-token': this.localSt.retrieve("access_token")
		});
	}

	//Generate report
	generateReport(body:any){
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.post(AppSettings.API_ENDPOINT+'/report/generate',body,options).pipe(
		map(function(res:any){
			return res.body || {
				status:"failed",
				message:"Something went wrong"
			};
		}));
	}
	public uploadReport(body:any,file:any): Promise<any> {
		return new Promise((resolve, reject) => {
			let token=this.localSt.retrieve("access_token")
			let formData:FormData = new FormData(),
			xhr: XMLHttpRequest = new XMLHttpRequest();
			if(file){
				formData.append('client_file', file, file.name);
			}
			
			for(let index in body){
				formData.append(index,body[index]);
			}
			xhr.onreadystatechange = () => {

				if (xhr.readyState === 4) {
					
					if (xhr.status === 200) {
						resolve(JSON.parse(xhr.response));
					} else {
						reject(xhr.response);
					}
				}
			};
			xhr.open('POST', AppSettings.API_ENDPOINT+'/report/client-file', true);
			xhr.setRequestHeader('x-access-token',token);
			xhr.send(formData);

		})
	}


}
