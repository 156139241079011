
import {of as observableOf, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';

import {AppSettings} from '../../../settings.global';
import {ActivatedRoute, Router} from '@angular/router';
declare var $:any;
import * as _ from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()
export class SellerService {
	public headers: HttpHeaders;
	constructor(private httpClient: HttpClient, private localSt: LocalStorageService) { 
		this.headers = new HttpHeaders({
			'Content-Type': 'application/json;charset=UTF-8',
			'Accept': 'application/json',
			'x-access-token': this.localSt.retrieve("access_token")
		});
	}
// get sellers to the table
    getSeller(body:any){
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
          let bodyString = $.param(body);
          return this.httpClient.get(AppSettings.API_ENDPOINT_V2 + '/settings/sellers?' + bodyString, options).pipe(
          map(function (res: any) {
  
              return res.body || {
                  status: "failed",
                  message: "Something went wrong"
              };
          }),catchError((error:any) => {
              //return Observable.throw(error.json().error || 'Server error occured')
              return observableOf({status:"error",message:"Server error occured"})
          }),);
    }

	
    // change status
    changeStatus(body:any){
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		if(body.id){
			return this.httpClient.put(AppSettings.API_ENDPOINT_V2+'/settings/sellers',body,options).pipe(
			map(function(res:any){
				return res.body || {
					status:"failed",
					message:"Something went wrong"
				};
			}));
		}
	}
	
	// sort sellers
	sortSellersList(chestData, orderBy, order) {
		return _.orderBy(chestData, [orderBy], [order]);
	}


	addEditSellerInfo(body:any){
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.post(AppSettings.API_ENDPOINT_V2 + '/settings/sellers', body, options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));
	}

	getSellerById(ids:any) {
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		let bodyString = $.param(ids);
		return this.httpClient.get(AppSettings.API_ENDPOINT_V2 + '/settings/sellers/single?' +bodyString,options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));
	}
  


}