
import { fromEvent as observableFromEvent } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';

declare var $: any;
import * as _ from 'lodash';
import { AuthService } from '../../../services/auth/auth.service';
import { MarketplaceService } from '../../../services/settings/marketplace/marketplace.service';
import { Observable } from 'rxjs/';
import { SchedulerService } from '../../../services/settings/scheduler/scheduler.service';
import * as moment from 'moment';
import { LoaderService } from '../../../services/loader/loader.service';
import { LocalStorageService } from 'ngx-webstorage';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';


@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.css'],
  styles: [
    `
       :host >>> my-date-picker >>> .mydp .selection  {
          color: #55595c !important;
          font-family: 'Montserrat', sans-serif !important;
          margin-bottom: 4px !important;
          margin-left: 5px !important;
          width: 97% !important;
        }
        :host >>> my-date-picker >>> .mydp .disabled  { 
          background: #e7e7e7 !important;
        }
       :host >>> my-date-picker >>> .btnpicker {
          color: #747b87;
        }
        :host >>> .multiselect-dropdown >>> .dropdown-btn {
          border: 1px solid #d2d6de !important ;
          height: 40px !important;
          margin-top: 1px !important;
          color: #595b5d !important;
        }
        :host >>> .multiselect-dropdown >>> .dropdown-btn >>> .dropdown-multiselect__caret{
          width: 29px !important;
        }
    `
  ]
})
export class SchedulerComponent implements OnInit {
  options: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mm/yyyy',
    sunHighlight: false,
    showSelectorArrow: true
  };

  myDatePickerOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mm/yyyy',
    sunHighlight: false,
    showSelectorArrow: true
  };

  myDateInit: boolean = true;
  model: IMyDateModel = null;
  _: any = _;
  date: Date = new Date();
  currentYear = new Date();
  yesterday = moment().subtract(1, 'days')
  // date1 = this.yesterday.setDate(this.yesterday.getDate() - 1);
  // yesterDay = this.date1.toDateString;
  ght = moment().toDate();
  test: any;
  public mplaces: any;
  public scheduler: any;
  public submited: boolean;
  public searchInputObservable$: any;
  public title: string;
  public frequencies: any;
  public invalidMplace: any;
  public scheduler_id: any;
  public scheduler_data: any;
  public selected: any;
  public lesserDate: any;
  dropdownSettings = {};
  private placeholder: string = 'Choose Start Date';
  private placeholders: string = 'Choose End Date';
  scheduler_ids: any;
  constructor(private marketplaceService: MarketplaceService,
    private toastr: ToastrService,
    private authServiceObj: AuthService,
    private schedulerService: SchedulerService,
    private loaderService: LoaderService,
    private localStorage: LocalStorageService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.title = "ADD NEW SCHEDULE ";
    this.scheduler_ids = [];
    this.invalidMplace = false;
    this.scheduler = {
      id: "",
      marketplace: [],
      start_date: "",
      end_date: "",
      frequency: "",
      edit: false,
      status: true
    };
    this.mplaces = [];
    this.selected = false;
    this.frequencies = [];
    this.lesserDate = false;
  }

  ngOnInit() {

  }

  ngAfterContentInit() {
    if (this.myDateInit) {
      // Initialize to specific date (14.05.2019) with IMyDate object
      this.model = {
        isRange: false, singleDate: {
          date: {
            year: 2019,
            month: 5,
            day: 14
          }
        }
      };
    }
    else {
      // Initialize to today with javascript date object
      this.model = { isRange: false, singleDate: { jsDate: new Date() } };
    }
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
    };
    //Input search observable - to avoid the  onInputChange() firing whenever the input changes
    const searchInputObj = document.querySelector('#search_text');
    this.searchInputObservable$ = observableFromEvent(searchInputObj, 'keyup');
    $("body").height("100%");
    $("html").height("100%");
    this.getMarketplace('');
    this.getFrequencies();
    // Function to get single scheduler data by ID
    this.activatedRoute.params.subscribe((params: Params) => {
      
      this.scheduler_id = params['id'];
      if (this.scheduler_id) {
        // Updation
        this.title = "EDIT SCHEDULER ";
        this.loaderService.display(true);
        this.schedulerService.getSchedulerById(this.scheduler_id).subscribe((res: any) => {
          if (res.status == "success") {
            this.loaderService.display(false);
            this.scheduler_data = res.data_found[0];
            var start_date = new Date(this.scheduler_data.start_time)
            var start_day = start_date.getDate();
            var start_month = start_date.getMonth() + 1;
            var start_year = start_date.getFullYear();
            var end_date = new Date(this.scheduler_data.end_time)
            var end_day = end_date.getDate();
            var end_month = end_date.getMonth() + 1;
            var end_year = end_date.getFullYear();

            this.scheduler = {
              id: this.scheduler_data.id,
              marketplace: this.scheduler_data.marketplace_id,
              frequency: this.scheduler_data.frequency_id,
              edit: true,
              status: this.scheduler_data.status,
              start_date: { singleDate: {date: { year: start_year, month: start_month, day: start_day }} },
              end_date: { singleDate: {date: { year: end_year, month: end_month, day: end_day }} },
            }
            
            console.log('this.scheduler', this.scheduler);
            
            this.options = {
              // other options...
              dateFormat: 'dd/mm/yyyy',
              sunHighlight: false,
              disableUntil: { year: start_year, month: start_month, day: start_day }
            };

            this.myDatePickerOptions = {
              // other options...
              dateFormat: 'dd/mm/yyyy',
              sunHighlight: false,
              disableSince: { year: end_year, month: end_month, day: end_day }
            };
          } else {
            if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
              this.authServiceObj.logout();
            }
            else {
              this.toastr.error(res.message, 'Failed');
            }
          }
        });
      }

    });
  }

  //function to get the list of marketplaces
  getMarketplace(page) {
    this.marketplaceService.getMarketPlaces('').subscribe((res: any) => {
      if (res.status == 'success') {
        this.mplaces = res.market_result;
      }
      if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
        this.toastr.error("Kindly login", "Session Expired");
        this.authServiceObj.logout();
      }
    });
  }

  //function to get the list of Frequencies
  getFrequencies() {
    this.schedulerService.getFrequencies().subscribe((res: any) => {
      if (res.status == 'success') {
        this.frequencies = res.data_found;
      }
      if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
        this.toastr.error("Kindly login", "Session Expired");
        this.authServiceObj.logout();
      }
    });
  }

  // function to create update scheduler
  onSubmit(valid) {
    console.log('valid', valid);
    this.submited = true;
    var user = this.localStorage.retrieve('user_details');
    if (this.scheduler.marketplace.length == 0) {
      this.invalidMplace = true;
    } else {
      this.invalidMplace = false;
    }
    if (valid) {
      console.log('this.scheduler567', this.scheduler);
      
      if (this.scheduler.start_date.singleDate.date) {
        console.log('kok',this.scheduler.start_date, this.scheduler.end_date);
        
        var start_date = this.scheduler.start_date.singleDate.date.year + '-' +
          this.scheduler.start_date.singleDate.date.month + '/' + this.scheduler.start_date.singleDate.date.day;
        var start_time = new Date(start_date);
        this.scheduler.start_date = start_time.toISOString();
        var end_date = this.scheduler.end_date.singleDate.date.year + '-' +
          this.scheduler.end_date.singleDate.date.month + '/' + this.scheduler.end_date.singleDate.date.day;
        var end_time = new Date(end_date);
        this.scheduler.end_date = end_time.toISOString();
      }
      let data = {
        scheduler: this.scheduler,
        user_id: user.id
      }
      console.log('data', data);
      
      this.loaderService.display(true);
      this.schedulerService.createUpdateScheduler(data).subscribe((res: any) => {
        this.loaderService.display(false);
        if (res.status == "success") {
          if (data.scheduler.edit) {
            if (!data.scheduler.status) {
              var date1 = new Date(data.scheduler.end_date);
              var date2 = new Date();
              date2.setUTCHours(0, 0, 0, 0);
              if (date1.getTime() > date2.getTime() && this.lesserDate) {
                this.changeSchedulerStatus(data.scheduler.id, data.scheduler.status)
              } else {
                this.toastr.success('Scheduler Updated Successfully!', 'SUCCESS');
                this.route.navigate(['scheduler/']);
              }
            } else {
              this.toastr.success('Scheduler Updated Successfully!', 'SUCCESS');
              this.route.navigate(['scheduler/']);
            }
          } else {
            this.toastr.success('Scheduler Added Successfully!', 'SUCCESS');
            this.route.navigate(['scheduler/']);
          }
        } else {
          if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
            this.authServiceObj.logout();
          }
          else {
            this.toastr.error(res.message, 'Error');
          }
        }
      });
    }
  }

  // function works when date changed

  // seleting all elements in dropdown
  onSelectAll(marketplaces) {
    marketplaces.forEach(element => {
      console.log(element)
      this.scheduler.marketplace.push(element.id);
    });
    this.invalidMplace = false;
  }

  // un seleting all elements in dropdown
  onDeSelectAll(event) {
    this.scheduler.marketplace = [];
    this.invalidMplace = true;
  }

  // to select a marketplace from dropdown
  onMarketplaceSelect(marketplace) {
    this.scheduler.marketplace.push(marketplace.id);
    this.invalidMplace = false;
  }

  // to deselect a marketplace from dropdown
  onMarketplaceDeSelect(marketplace) {
    var idx = this.scheduler.marketplace.indexOf(marketplace.id)
    if (idx > -1) {
      this.scheduler.marketplace.splice(idx, 1)
    }
    this.invalidMplace = true;
  }

  // To change status of scheduler
  changeSchedulerStatus(id, status) {
    var user = this.localStorage.retrieve('user_details');
    this.scheduler_ids.push(id);
    var data = {
      scheduler: this.scheduler_ids,
      status: true,
      user_id: user.id
    }
    this.schedulerService.statusChange(data).subscribe((res: any) => {
      if (res.status == "success") {
        this.scheduler_id = [];
        this.toastr.success('Scheduler Updated Successfully!', 'SUCCESS');
        this.route.navigate(['scheduler/']);
      } else {
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          this.authServiceObj.logout();
        }
        else {
          this.toastr.error(res.message, 'Failed');
        }
      }
    })
    this.scheduler_id = [];
  }

  // function works when date changed
  onDateChanged(event: IMyDateModel, value) {
    console.log('this.scheduler_data', event);
    if (this.scheduler.id) {
      var date1 = new Date(this.scheduler_data.end_time);
    }

    //           var date2 = new Date();
    //           date2.setUTCHours(0, 0, 0, 0);
    //           if (date1.getTime() > date2.getTime()) {
    //             this.changeSchedulerStatus(data.scheduler.id, data.scheduler.status)
    if (value == "start_date") {
      console.log('ddd', this.scheduler);
      this.options = {
        // other options...
        dateFormat: 'dd/mm/yyyy',
        sunHighlight: false,
        disableUntil: { year: event.singleDate.date.year, month: event.singleDate.date.month, day: event.singleDate.date.day }
      };
      this.myDatePickerOptions.disableUntil = { year: 2021, month: 2, day: 15 };
      console.log('event.singleDate.jsDate', event.singleDate.jsDate.toISOString());
      var sDate = event.singleDate.jsDate.toISOString();
      this.scheduler.start_date = sDate;
      console.log('this.scheduler.start_date', sDate);

    } else {
      console.log('uiui', this.scheduler);
      this.myDatePickerOptions = {
        // other options...
        dateFormat: 'dd/mm/yyyy',
        sunHighlight: false,
        disableSince: { year: event.singleDate.date.year, month: event.singleDate.date.month, day: event.singleDate.date.day }
      };
      var sDate = event.singleDate.jsDate.toISOString();
      console.log('sDate,', sDate);
      
      this.scheduler.end_date = sDate;
      console.log('this.scheduler.end_date', this.scheduler.end_date);
      if (this.scheduler.id) {
        var date2 = new Date(this.scheduler.end_date);
        console.log('date1', date1, date2);
        var date3 = new Date();
        date3.setUTCHours(0, 0, 0, 0);
        // check end date is greater than previous end date selected

        if (date1.getTime() <= date3.getTime()) {
          console.log('less date');
          this.lesserDate = true;
        } else {
          this.lesserDate = false;
        }
      }

    }
    console.log('this.scheduler', this.scheduler);
  }


}
