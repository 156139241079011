
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';

import {AppSettings} from '../../settings.global';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ProfileService {

  public headers: HttpHeaders;

  constructor(private httpClient: HttpClient, private localSt: LocalStorageService) {
    this.headers = new HttpHeaders({
			'Content-Type': 'application/json;charset=UTF-8',
			'Accept': 'application/json',
			'x-access-token': this.localSt.retrieve("access_token")
		});
   }
   //Function to get Admin details
   getAdminDetails(){
   	let user_details=this.localSt.retrieve("user_details");
   	let user_id=user_details.id;
     let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
    return this.httpClient.get(AppSettings.API_ENDPOINT +'/user/admin-profile/'+ user_id,options).pipe(
    map(function (res: any) {
      return res.body || {
        status: "failed",
        message: "Something went wrong"
      };
    }));
   }
   //update profile
   updateProfile(body:any){
   	let bodyString: string = "firstname=" + body.firstname+"&middlename="+body.middlename +"&lastname=" + body.lastname +"&id=" + body.id+"&email="+body.email+"&mobile="+body.mobile
   	let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
   	 return this.httpClient.post(AppSettings.API_ENDPOINT+'/user/admin-profile/',bodyString,options).pipe(
   	 map(function(res:any){
   	 	return res.body || {
   	 		status:"failed",
   	 		message:"Something went wrong"
   	 	};
   	 }));
   }
}
