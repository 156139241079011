
import {of as observableOf, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {AppSettings} from '../../../settings.global';
import {ActivatedRoute, Router} from '@angular/router';
declare var $:any;
import * as _ from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class MarketPlacesService {

	public headers: HttpHeaders;

  constructor(private httpClient: HttpClient, private localSt: LocalStorageService) {
	this.headers = new HttpHeaders({
		'Content-Type': 'application/json;charset=UTF-8',
		'Accept': 'application/json',
		'x-access-token': this.localSt.retrieve("access_token")
	});

   }

	createUpdateMarket(body:any){
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		if(body.id){
			return this.httpClient.put(AppSettings.API_ENDPOINT +'/masterdata/marketplace',body, options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));

		}else{
			return this.httpClient.post(AppSettings.API_ENDPOINT +'/masterdata/marketplace',body, options).pipe(
			map(function (res: any) {
				return res.body || {
					status: "failed",
					message: "Something went wrong"
				};
			}));

	    }

	}

	getMarketPlaces(body:any){
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		let bodyString = $.param(body);
		return this.httpClient.get(AppSettings.API_ENDPOINT +'/masterdata/marketplace?'+bodyString, options).pipe(
		map(function (res: any) {
			return res.body || {
				status: "failed",
				message: "Something went wrong"
			};
		}),catchError((error:any) => {
			//return Observable.throw(error.json().error || 'Server error occured')
			return observableOf({status:"error",message:"Server error occured"})
		}),);
	}
	sortMarketList(marketData, orderBy, order) {
		return _.orderBy(marketData, [orderBy], [order]);
	 }

	delete(id:any){
		let options: any = { headers: this.headers, responseType: 'json', observe: 'response' };
		return this.httpClient.delete(AppSettings.API_ENDPOINT +'/masterdata/marketplace?id='+id,options).pipe(
		map(function(res:any){
			return res.body || {status: "failed", message: "Somthing went wrong"};
		}));
	}

}
