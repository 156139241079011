
import {fromEvent as observableFromEvent} from 'rxjs';
import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user/user.service';
import {ClientService} from '../../services/client/client.service';
//import * as $ from 'jquery';
import * as _ from 'lodash';
import {Observable} from 'rxjs/';
import {LoaderService} from '../../services/loader/loader.service';
import {Routes, RouterModule, ActivatedRoute, Router, Params} from '@angular/router';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {NgxPaginationModule} from 'ngx-pagination';//importing ng2-pagination
import {AuthService} from '../../services/auth/auth.service';
import {AppSettings} from '../../settings.global';
import { ToastrService } from 'ngx-toastr';
declare var $ : any;
import {debounceTime} from 'rxjs/operators';
@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})

export class UserListComponent implements OnInit {
	public request_params:any;
	public pager:any;
	public queryLimit: number = AppSettings.DEFAULT_QUERY_LIMIT;
	public itemPerPageCount: number = AppSettings.DEFAULT_PAGER_COUNT;
	public apiCallCount: number = 0;
	public offSet: number = 0;
	public users:any;
	public clients:any;
	public client:any;
	public no_data:any;
	public searchInputObservable$: any;
	public modal_title:any;
	public brands:any;

	constructor(private userService:UserService,
		private clientService:ClientService,
		private toastr: ToastrService,
		private route: Router,
		public localSt: LocalStorageService, 
		private loaderService:LoaderService,
		private activatedRoute: ActivatedRoute,
		private authServiceObj:AuthService) { 
			this.no_data="Loading...."
			this.request_params = {
				searchkey: "",
				orderby: "",
				order: "",
				limit: this.queryLimit,
				offset: 0
			}
			this.pager = {
				limit: this.itemPerPageCount,
				offset: 0,
				total_count: 0,
				current_page: 1
			}
			this.users=[]
    }

  ngAfterContentInit() {

	//Input search observable - to avoid the  onInputChange() firing whenever the input changes
	const searchInputObj = document.querySelector('#search_text');
	this.searchInputObservable$ = observableFromEvent(searchInputObj, 'keyup');

	this.searchInputObservable$.pipe(debounceTime(100))
      .subscribe((event: any) => {
        this.onInputChange(event.target.value);
      });

	$("body").height("100%");
	$("html").height("100%");
	
		/*
			* API call and grab full data
			*/
		setTimeout(() => {
			this.getClient(1);
		},10);
  }
  
  //get the client 
	getClient(page){
		this.clientService.getClientSelect().subscribe((res:any)=>{
		  if(res.status=='success'){
			if(res.data_found){
				this.clients=res.data_found;
			  }else{
				  this.clients=null;
			  }
			if(this.activatedRoute.snapshot.queryParams['client_id']){
				this.client=parseInt(this.activatedRoute.snapshot.queryParams['client_id'])
	
			}else{
				if(this.clients){
					this.client=this.clients[0].id;	
				}else{
					this.client=null;
				}
			}	
			if(this.client!=null){
				this.getUsers(this.client,page);
			}	else{
				this.no_data='No Data';
			}					   
		    
		  }
		  else{
			if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
				//   this.toastr.error('Invalid User','Request Failed');
				  this.authServiceObj.logout();
			}
		  }
		})
  }

  onInputChange(text) {
	this.onSearch();
  }

  addUser(){
	this.route.navigate(['user/add'], { queryParams: { client_id: this.client } });
  }
  
  getUsers(client_id,page){
		if(this.offSet != 0 && this.request_params['searchkey'] !='' ){
			this.users = [];
			this.apiCallCount = 0;
			this.offSet = 0;
		}
		this.request_params['offset'] = this.offSet;
		this.request_params['client'] = client_id;
		this.loaderService.display(true); 
		this.userService.getUsers(this.request_params).subscribe((res:any)=>{
			console.log(res);
			
			this.loaderService.display(false);
			this.apiCallCount++;
			if(res.status=='success' && !_.isNull(res.user_result)){
				this.pager["total_count"] = this.users.length + res.user_result.length;
				this.pager["current_page"] = page;
				const result = this.users.concat(res.user_result);
        this.users = res.user_result;
				//Load full result
				if(!_.isNil(res.max_possible_res_length) && res.max_possible_res_length > this.users.length) {
					this.offSet = this.apiCallCount * this.queryLimit;
					//this.getBrands(this.client,1);
					// this.getCountry(1);
				}    
			}else {
				if(this.users.length === 0 || res.max_possible_res_length === 0) {
					this.users = [];
					this.no_data = 'No Data';
					this.pager["total_count"] = 0;
					this.pager["current_page"] = page;
				}
				if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
				this.toastr.error("Kindly login","Session Expired");
				this.authServiceObj.logout();
				}
			}
		})

  }
  
  onChange(newValue) {
		this.client = newValue;
		this.users = [];
		this.apiCallCount = 0;
		this.offSet = 0; 
		this.getUsers(newValue,1);
  }
  
  onPageChange(page: number) {
		this.pager["current_page"] = page;
		this.pager["offset"] = this.itemPerPageCount * (page - 1);
	}

	onSearch() {
		this.users = [];
		this.apiCallCount = 0;
		this.offSet = 0;
		this.pager["offset"] =0;
		this.getUsers(this.client,1);
   }

   onSort(orderby: string, id: any) {
		if (this.request_params["orderby"] === orderby) {
			if (this.request_params["order"] === "asc") {
				this.request_params["order"] = "desc";
			} else {
				this.request_params["order"] = "asc";
			}
		} else {
			this.request_params["orderby"] = orderby;
			this.request_params["order"] = "desc"
		}
	   //Reset the page to 1, offset to 0
	   this.pager["current_page"] = 1;
	   this.pager["offset"] = 0;
	   this.users = this.userService.sortUserList(this.users, this.request_params["orderby"], this.request_params["order"]);
   }

   //inactivate user
	inActivate(id:any){
		let data={id:id};
		var cnf=confirm("Are you sure you want to deactivate this user?");
		if(cnf){
			this.userService.inActivate(data).subscribe((res:any)=>{
				if(res.status=="success"){
					this.toastr.success('Inactivated','SUCCESS');
					this.users = [];
					this.apiCallCount = 0;
					this.offSet = 0;
					this.getUsers(this.client,1);
					
				}
				else {
					if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
						this.authServiceObj.logout();
					}
				}
			})
		}
	}

	//Activate user
	activate(id:any,type:any,sid:any){
		var cnf=confirm("Are you sure you want to activate this user?");
		if(cnf){
			let data={id:id};
			this.userService.activate(data).subscribe((res:any)=>{
				if(res.status=="success"){
					this.toastr.success('Activated','SUCCESS');
					this.users = [];
					this.apiCallCount = 0;
					this.offSet = 0;
					this.getUsers(this.client,1);
					//this.getClientData(1);
				} else {
					if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
						this.authServiceObj.logout();
					}
					else{
						this.toastr.error(res.message,'Failed');
					}
				}
			})
		}

	}
	getBrands(first_name:any,last_name:any,brands:any){
   	//alert("dd")
   	this.brands=[];
   	this.modal_title=first_name+' '+last_name;
   	if(brands){
   		this.brands=brands;
   		$('#brandModal').modal('show');
   	}
   }

  ngOnInit() {
  }

}
