
import {of as observableOf,  Observable } from 'rxjs';
import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRoute} from '@angular/router';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';


@Injectable()
export class SuperAdminProvider implements CanActivate {
    constructor(private router: Router, private localSt: LocalStorageService) {
    }

    canActivate() : Observable<boolean> {
        //console.log("canActivate");
        //console.log("Access token",this.localSt.retrieve("access_token"));
        if (this.localSt.retrieve("user_details").user_role != 1) {
            console.log(this.localSt.retrieve("user_details").user_role);
            this.router.navigate(['/notfound']);
            return observableOf(false)
        }
        return observableOf(true)
    }
}