import { Component, OnInit } from '@angular/core';
import { ClientService } from '../../services/client/client.service';
import { LoaderService } from '../../services/loader/loader.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { AuthService } from '../../services/auth/auth.service';
declare var $: any;
import { AdminService } from '../../services/admin/admin.service';
import { Routes, RouterModule, ActivatedRoute, Router, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  public admin: any;
  public clients: any;
  public submited: boolean;
  public title: string;
  public admin_id: any;
  constructor(private clientService: ClientService,
    private localSt: LocalStorageService,
    private loaderService: LoaderService,
    private toastr: ToastrService,
    private authServiceObj: AuthService,
    private adminServiceObj: AdminService,
    private activatedRoute: ActivatedRoute,
    private route: Router, ) {
    this.admin = {
      id: null,
      client: [],
      fname: '',
      lname: '',
      mname: '',
      email: '',
      mobile_no: '',
      edit: false
    }
    this.submited = false;
    this.title = "Add Admin";
  }
  ngAfterContentInit() {

    $("body").height("100%");
    $("html").height("100%");

    this.getClient();
    this.activatedRoute.params.subscribe((params: Params) => {
      this.admin_id = params['id'];
      if (this.admin_id) {
        // Updation
        this.title = "Edit Admin";
        // this.edit_flag=true;

        // Getting the Brand data  
        this.adminServiceObj.getAdminDetails(this.admin_id).subscribe((res: any) => {
          if (res.status == "success") {
            this.admin = {
              id: this.admin_id,
              client: res.data_found.client_id,
              fname: res.data_found.first_name,
              mname: res.data_found.middle_name,
              lname: res.data_found.last_name,
              email: res.data_found.email,
              mobile_no: res.data_found.mobile,
              edit: true
            }

          } else {
            if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
              this.authServiceObj.logout();
            }
            else {
              this.toastr.error(res.message, 'Failed');
            }
          }
        });
      } else {
        this.admin = {
          id: null,
          client: [],
          fname: '',
          lname: '',
          mname: '',
          email: '',
          mobile_no: '',
          edit: false
        }
      }

    });
  }
  getClient() {
    this.clientService.getClientSelect().subscribe((res: any) => {
      if (res.status == 'success') {
        this.clients = res.data_found;
      }
      else {
        if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
          this.toastr.error("Kindly login", "Session Expired");
          this.authServiceObj.logout();
        }
      }
    })
  }
  onSubmit(valid) {
    this.submited = true;
    if (valid) {
      this.loaderService.display(true);
      this.adminServiceObj.createUpdateAdmin(this.admin).subscribe((res: any) => {
        this.loaderService.display(false);
        if (res.status == "success") {
          this.toastr.success('Updated Successfully!', 'SUCCESS');
          this.route.navigate(['admin/']);
        }
        else {
          if (res.message == "invalid_accesstoken" || res.message == "un_authorised") {
            this.authServiceObj.logout();
          }
          else {
            this.toastr.error(res.message, 'Error');
          }
        }

      }, (e) => {

      });
    }

  }

  cancel() {
    this.route.navigate(['admin/']);
  }

  ngOnInit() {
  }

}
