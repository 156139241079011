import { Component, OnInit } from '@angular/core';
import {ClientService} from '../../services/client/client.service';
import { BrandService } from '../../services/brand/brand.service';
import * as $ from 'jquery';
import {LoaderService} from '../../services/loader/loader.service';
import {AuthService} from '../../services/auth/auth.service';
import {Routes, RouterModule, ActivatedRoute, Router, Params} from '@angular/router';
import {AppSettings} from '../../settings.global';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.css']
})
export class BrandComponent implements OnInit {
  public submited:boolean;
  public title:string;
  public brand:any;
  public brands:any;
  public clients:any;
  public client:any;
  public brand_id:any;

  constructor(private brandService:BrandService, 
    private activatedRoute: ActivatedRoute, 
    private route: Router,
    private clientService:ClientService,
    private loaderService:LoaderService,
    private toastr: ToastrService,
    private authServiceObj:AuthService) { 
    this.title="Add Brand";
    this.brand={
      id:'',
      client:'',
      name:'',
      description:'',
      edit:false
    };
    this.brands=[];   
  }

  ngAfterContentInit() {

    $("body").height("100%");
    $("html").height("100%");

    this.getClient();

    this.activatedRoute.params.subscribe((params: Params) => {
    this.brand_id = params['id'];
  
      if (this.brand_id) { 
               // Updation
               this.title="Edit Brand";
              // this.edit_flag=true;
  
               // Getting the Brand data  
               this.brandService.getBrandDetails(this.brand_id).subscribe((res: any) => {
                 if (res.status == "success") {
                   this.brand={
                     id: res.data_found.id,
                     client:res.data_found.client_id,
                     name:res.data_found.name,
                     description:res.data_found.description,
                     edit:true
                   }
                   this.client=res.data_found.client_id;
                   
                 } else {
                   if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
                     this.authServiceObj.logout();
                   }
                   else{
                     this.toastr.error(res.message,'Failed');
                   }
                 }
               });
           }else{
            this.client=this.activatedRoute.snapshot.queryParams['client_id'];
            this.brand={
              id:'',
              client:parseInt(this.client),
              name:'',
              description:'',
              edit:false
            };
           } 
  
       });
  }

  //get the client 
  getClient(){
    this.clientService.getClientSelect().subscribe((res:any)=>{
      if(res.status=='success'){
      this.clients=res.data_found;
      }
      else{
        if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
              this.toastr.error("Kindly login","Session Expired");
              this.authServiceObj.logout();
        }
      }
    })
  }

  onSubmit(valid){
    console.log('hello hai',this.brand);
    this.submited=true;
    if(valid){
      this.loaderService.display(true);
      this.brandService.createUpdateBrand(this.brand).subscribe((res: any) => {
        this.loaderService.display(false);
        if(res.status=="success"){
          this.toastr.success('Added Successfully!','SUCCESS');
          this.route.navigate(['brand/'], { queryParams: { client_id: this.client } });
        }
        else {
          if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
            this.authServiceObj.logout();
          }
          else{
            this.toastr.error(res.message,'Error');
          }
        }
  
      },(e)=>{
  
      });
    }
  
  }

  cancel(){
    this.route.navigate(['brand/'], { queryParams: { client_id: this.client } });
  }

  ngOnInit() {
  }

}
