
import {fromEvent as observableFromEvent} from 'rxjs';
import { Component, OnInit } from '@angular/core';
import {SocialMediaService} from '../../../services/master-data/social-media/social-media.service';
import { MotherCompanyService } from '../../../services/master-data/mother-company/mother-company.service';
import * as $ from 'jquery';
import * as _ from 'lodash';
import {Observable} from 'rxjs/';
import {LoaderService} from '../../../services/loader/loader.service';
import {NgxPaginationModule} from 'ngx-pagination';//importing ng2-pagination
import {AuthService} from '../../../services/auth/auth.service';
import {AppSettings} from '../../../settings.global';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.css']
})
export class SocialMediaComponent implements OnInit {
  dropdownList = [
    {
      "item_id":12,
      "item_text":"minhal"
    }
  ];
  dropdownSettings = {};
  
  
  
  public request_params:any;
  public pager:any;
  public queryLimit: number = AppSettings.DEFAULT_QUERY_LIMIT;
	public itemPerPageCount: number = AppSettings.DEFAULT_PAGER_COUNT;
	public apiCallCount: number = 0;
	public offSet: number = 0;
  public social:any;
  public socials:any;
  public title:any;
  public saveButton:any;
  public cancelButton:any;
  public submitted:boolean;
  public no_data:any;
  public searchInputObservable$: any;
  public mcompanies: any;
  public user_details:any;
  public countries:any;


  constructor(private socialMediaService:SocialMediaService,public localSt:LocalStorageService,private motherCompanyService:MotherCompanyService,
     private loaderService:LoaderService, private toastr: ToastrService, private authServiceObj:AuthService) { 
    this.title='Social Media';
    this.saveButton=' Add ';
    this.cancelButton= 'Clear';
    this.submitted=false;    
    this.no_data="Loading...."
    this.request_params = {
			searchkey: "",
			orderby: "",
			order: "",
			limit: this.queryLimit,
			offset: 0
		}
		this.pager = {
			limit: this.itemPerPageCount,
			offset: 0,
			total_count: 0,
			current_page: 1
		} 

    this.social={
      id:'',
      mcompany:'',
      name:'',
      description:'',
      country:0
    },
      this.socials=[];
      this.user_details = this.localSt.retrieve("user_details");
      
   }

   ngAfterContentInit() {
     //Input search observable - to avoid the  onInputChange() firing whenever the input changes
		const searchInputObj = document.querySelector('#search_text');
		this.searchInputObservable$ = observableFromEvent(searchInputObj, 'keyup');

	 this.searchInputObservable$.pipe(debounceTime(100))
    .subscribe((event: any) => {
      this.onInputChange(event.target.value);
    });

    $("body").height("100%");
    $("html").height("100%");
    this.getMcompany();
    this.getAllCountries();

    /*
         * API call and grab full data
         */
		setTimeout(() => {
      this.getSocialMedia(1);
		},10);  
  }

  onInputChange(text) {
    this.onSearch();
  }

  //To get mothercompany
  getMcompany(){ 
    this.motherCompanyService.getMcompanySelect('social_media').subscribe((res:any)=>{
      if(res.status=='success'){
      this.mcompanies=res.data_found;
      }
      else{
        if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
              this.toastr.error("Kindly login","Session Expired");
              this.authServiceObj.logout();
        }
      }
    })
  }

  getAllCountries()
  {
    this.motherCompanyService.getCountrySelect("").subscribe((res:any)=>{
      if(res.status=='success'){
      this.countries=res.country_result;
      }
      else{
        if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
              this.toastr.error("Kindly login","Session Expired");
              this.authServiceObj.logout();
        }
      }
    });
  }

  onSubmit(valid){
    this.submitted=true;
    if(valid){

      if(this.social.country===0)
      {
        this.social.country=null;
      }
      
      this.loaderService.display(true);
      this.socialMediaService.createUpdateSocial(this.social).subscribe((res:any)=>{
        this.loaderService.display(false);
        if(res.message=="success"){
          this.toastr.success('Updated Successfully!','SUCCESS');
          this.socials = [];
          this.apiCallCount = 0;
          this.offSet = 0;
          this.getSocialMedia(1);
          this.title='Social Media';
          this.saveButton=' Add ';
          this.cancelButton= 'Clear';
          this.submitted=false;
          this.social={
            id:'',
            mcompany:'',
            name:'',
            description:'',
            country:0
          };
        }else if(res.message == "Social Media already exists"){
          this.toastr.error('Social Media already exists','Request Failed');
        }else {
          if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
            this.toastr.error("Kindly login","Session Expired");
            this.authServiceObj.logout();
          }else{
            this.toastr.error(res.message,'Failed');
          }
        }
      },(e)=>{

          });
    }
  }

  //To get socialmedia
  getSocialMedia(page: number){
    this.request_params['offset'] = this.offSet;
    this.loaderService.display(true);  
    this.socialMediaService.getSocialMedia(this.request_params).subscribe((res:any)=>{
      this.loaderService.display(false);
      this.apiCallCount++;
      if(res.status=='success' && !_.isNull(res.social_result)){
        this.pager["total_count"] = this.socials.length + res.social_result.length;
				this.pager["current_page"] = page;
				const result = this.socials.concat(res.social_result);
        this.socials = res.social_result;
        //Load full result
				if(!_.isNil(res.max_possible_res_length) && res.max_possible_res_length > this.socials.length) {
					this.offSet = this.apiCallCount * this.queryLimit;

					this.getSocialMedia(1);
				}    
      }else {
        if(this.socials.length === 0 || res.max_possible_res_length === 0) {
          this.socials = [];
          this.no_data = 'No Data';
					this.pager["total_count"] = 0;
					this.pager["current_page"] = page;
				}
        if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
          this.toastr.error("Kindly login","Session Expired");
          this.authServiceObj.logout();
        }
      }
    })
  }

  onSearch() {
		this.socials = [];
		this.apiCallCount = 0;
    this.offSet = 0;
    this.pager["offset"] =0;    
    this.getSocialMedia(1);
   }

  onPageChange(page: number) {
  this.pager["current_page"] = page;
  this.pager["offset"] = this.itemPerPageCount * (page - 1);
  }

  onSort(orderby: string, id: any) {
    if (this.request_params["orderby"] === orderby) {
      if (this.request_params["order"] === "asc") {
        this.request_params["order"] = "desc";
      } else {
        this.request_params["order"] = "asc";
      }
    } else {
      this.request_params["orderby"] = orderby;
      this.request_params["order"] = "desc"
    }
    //Reset the page to 1, offset to 0
    this.pager["current_page"] = 1;
    this.pager["offset"] = 0;
    this.socials = this.socialMediaService.sortSocialList(this.socials, this.request_params["orderby"], this.request_params["order"]);
  }

//Get socialmedia for edit
editSocialMedia(social:any){
  this.title='Edit Social Media';
  this.saveButton=' Save ';
  this.cancelButton= 'Cancel';
  this.social={
    id:social.id,
    mcompany:social.mother_company_id,
    name:social.name,
    description:social.description,
    country:social.country
  };
}

//delete selected market place
deleteSocialMedia(id:any){
  var cnf=confirm("Are you sure you want to delete this Social Media?")
  if(cnf){
    this.socialMediaService.delete(id).subscribe((res:any)=>{
      if(res.status=='success'){
        this.toastr.success('Social Media Deleted Successfully!','SUCCESS');
        this.social={
          id:'',
          mcompany:'',
          name:'',
          description:'',
          country:0
        };
        this.socials = [];
        this.apiCallCount = 0;
        this.offSet = 0;        
        this.getSocialMedia(1);
        }else {
          if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
            this.toastr.error("Kindly login","Session Expired");
            this.authServiceObj.logout();             
          }else{
            this.toastr.error(res.message,'Failed');
          }
        }
    })
  }
}

//To cancel editing and show add form
cancel(){
  this.title='Social Media';
  this.saveButton=' Add ';
  this.cancelButton= 'Clear';
  this.social={
    id:'',
    mcompany:'',
    name:'',
    description:'',
    country:0
  };
}

  ngOnInit() {
    // multidropdown settings
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  

}
